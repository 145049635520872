import { useNavigation } from "@react-navigation/native";
import { Sx, useSx } from "dripsy";
import { useConnectionQuery } from "../../hooks/useConnections";
import {
  DeleteSpaceMemberDocument,
  SpaceMembersInfoFragment,
} from "../../state/generated/graphql";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
  useMemo,
  PropsWithChildren,
} from "react";
import { MotiPressable } from "moti/interactions";
import PersonAvatar from "../../components/PersonAvatar";
import {
  Icon,
  Pressable,
  Text,
  View,
  SafeAreaView,
} from "../../components/basics";
import BottomSheet, {
  BottomSheetView,
  useBottomSheetDynamicSnapPoints,
} from "@gorhom/bottom-sheet";
import { BottomSheetBackdrop, BottomSheetModal } from "@gorhom/bottom-sheet";
import { useSharedValue } from "react-native-reanimated";
import { getOrCreateStaticSpace } from "../../utils/space";
import { deleteInvitation } from "../../lib/invitations";
import { useMutation } from "urql";
import { Keyboard } from "react-native";
import { getPersonFromMember } from "../../screens/spaces/ManageMembersScreen";
import { useUser } from "../../hooks/auth";
import { useSpace } from "../../hooks/spaces";
import DRIPSY_THEME from "../../constants/DripsyTheme";
import { useSafeAreaInsets } from "react-native-safe-area-context";

const ProfilePeekDrawerContext = React.createContext<ProfilePeekDrawerHandler>({
  onPressPerson: () => {},
  closeBottomSheet: () => {},
});

type ProfilePeekDrawerHandler = {
  onPressPerson: ({
    member,
    uid,
  }: {
    member?: SpaceMembersInfoFragment | undefined;
    uid?: string;
  }) => void;
  closeBottomSheet: () => void;
};

const SNAP_POINTS = ["50%"];

export function ProfilePeekDrawerProvider({
  children,
}: PropsWithChildren<Record<never, never>>) {
  const bottomSheetRef = useRef<BottomSheetModal>(null);
  const currentSnapIndex = useSharedValue<number>(-1);
  const [selectedMember, setSelectedMember] = useState(undefined);
  const [selectedUserId, setSelectedUserId] = useState(undefined);
  const connectionQuery = useConnectionQuery();
  const [deleteState, deleteMember] = useMutation(DeleteSpaceMemberDocument);
  const navigation = useNavigation();
  const pendingMember =
    selectedMember && selectedMember?.invitation?.status === "PENDING";
  const currentUser = useUser();
  const selectedPerson = getPersonFromMember(
    connectionQuery,
    selectedMember ?? { user_id: selectedUserId }
  );
  // console.log(selectedMember);
  const isCurrentUser = selectedPerson?.id === currentUser?.id;
  const hasProfile = selectedPerson?.key === "PROFILE";
  const space = useSpace(selectedMember?.space_id);
  // const space = undefined;

  const isStaticSpace = selectedMember && space?.data?.type === "STATIC";
  const isConnection = hasProfile;

  const closeBottomSheet = useCallback(() => {
    setSelectedMember(undefined);
    setSelectedUserId(undefined);
    bottomSheetRef.current?.close();
  }, []);
  const openBottomSheet = useCallback(() => {
    bottomSheetRef.current?.present();
  }, []);

  const onPressPerson = useCallback(
    ({ member, uid }) => {
      if (currentSnapIndex.value === -1) {
        if (member) setSelectedMember(member);
        if (uid) setSelectedUserId(uid);
        openBottomSheet();
        return;
      }
      if (
        (selectedMember && member.id === selectedMember.id) ||
        (selectedUserId && uid === selectedUserId)
      ) {
        closeBottomSheet();
        return;
      }
      closeBottomSheet();
      setTimeout(() => {
        setSelectedMember(member);
        openBottomSheet();
      }, 700);
    },
    [currentSnapIndex, bottomSheetRef, selectedMember, selectedUserId]
  );

  useEffect(() => {
    const handleKeyboardShow = () => {
      closeBottomSheet();
    };
    const showSubscription = Keyboard.addListener(
      "keyboardWillShow",
      handleKeyboardShow
    );

    return () => showSubscription.remove();
  }, []);

  const initiateDirectMessage = useCallback(async () => {
    closeBottomSheet();
    const spaceId = await getOrCreateStaticSpace([
      selectedPerson?.id,
      currentUser?.id,
    ]);
    if (spaceId)
      navigation.navigate("conversation", {
        spaceId,
      });
  }, [navigation, selectedPerson, currentUser]);

  const goToProfile = useCallback(() => {
    closeBottomSheet();
    navigation.navigate("profiles", {
      screen: "profile",
      params: {
        userId: selectedPerson?.id as string,
        type: "PROFILE",
      },
    });
  }, [selectedPerson, navigation]);

  const deleteSpaceInvitation = useCallback(async () => {
    try {
      closeBottomSheet();
      if (selectedMember?.invitation) {
        const { error } = await deleteInvitation(
          selectedMember?.invitation?.id
        );
        if (error) {
          console.error(error);
          throw error;
        }
      }
    } finally {
    }
  }, [selectedMember]);

  const deleteSpaceMember = useCallback(async () => {
    // id in this case is the id of their Space Membership
    if (selectedMember?.id) {
      deleteMember({ id: selectedMember.id });
    }
    closeBottomSheet();
  }, [deleteMember, selectedMember]);

  const buttonText = !!space?.data?.name ? space?.data?.name : "the group";
  const {
    animatedHandleHeight,
    animatedSnapPoints,
    animatedContentHeight,
    handleContentLayout,
  } = useBottomSheetDynamicSnapPoints(["CONTENT_HEIGHT"]);
  const insets = useSafeAreaInsets();
  return (
    <ProfilePeekDrawerContext.Provider
      value={{ onPressPerson, closeBottomSheet }}
    >
      {children}
      <BottomSheetModal
        ref={bottomSheetRef}
        index={0}
        enablePanDownToClose
        handleHeight={animatedHandleHeight}
        snapPoints={animatedSnapPoints}
        contentHeight={animatedContentHeight}
        backdropComponent={(props) => (
          <BottomSheetBackdrop {...props} disappearsOnIndex={-1} />
        )}
      >
        <BottomSheetView
          onLayout={handleContentLayout}
          style={{ minHeight: 50 }}
        >
          <SafeAreaView
            edges={["bottom"]}
            sx={{ justifyContent: "flex-start" }}
          >
            {selectedPerson ? (
              <>
                <View
                  sx={{
                    flexDirection: "row",
                    paddingLeft: "$5",
                    marginTop: "$2",
                    marginBottom: "$3",
                    paddingRight: "$4",
                    alignItems: "center",
                  }}
                >
                  <PersonAvatar size={55} person={selectedPerson} />
                  <Text sx={{ fontSize: 18, marginLeft: "$4" }}>
                    {selectedPerson?.name}
                  </Text>
                </View>
                <View
                  sx={{
                    flexDirection: "column",
                    marginX: "$4",
                    marginBottom: "$4",
                    // alignItems: "center",
                  }}
                >
                  {isConnection && (
                    <>
                      {!isCurrentUser && (
                        <ProfileActionButton
                          label="Send a direct message"
                          icon="chatbubble-outline"
                          onPress={initiateDirectMessage}
                        />
                      )}
                      <ProfileActionButton
                        label="View profile"
                        icon="person-outline"
                        onPress={goToProfile}
                      />
                    </>
                  )}
                  {!isStaticSpace && selectedMember ? (
                    pendingMember ? (
                      <ProfileActionButton
                        label={`Uninvite from ${buttonText}`}
                        icon="person-remove-outline"
                        iconStyle={{ color: "$danger.600" }}
                        labelStyle={{ color: "$danger.600" }}
                        onPress={deleteSpaceInvitation}
                      />
                    ) : (
                      <ProfileActionButton
                        label={`${
                          isCurrentUser ? "Leave" : "Remove from"
                        } ${buttonText}`}
                        icon="person-remove-outline"
                        iconStyle={{ color: "$danger.600" }}
                        labelStyle={{ color: "$danger.600" }}
                        onPress={deleteSpaceMember}
                      />
                    )
                  ) : null}
                </View>
              </>
            ) : null}
          </SafeAreaView>
        </BottomSheetView>
      </BottomSheetModal>
    </ProfilePeekDrawerContext.Provider>
  );
}

function ProfileActionButton({
  icon,
  label,
  onPress,
  iconStyle,
  labelStyle,
  containerStyle,
}: {
  icon: string;
  label: string;
  onPress: () => void;
  iconStyle?: Sx;
  labelStyle?: Sx;
  containerStyle?: Sx;
}) {
  const sx = useSx();
  return (
    <MotiPressable
      style={sx({ ...defaultHorizontalButtonStyles, ...containerStyle })}
      onPress={onPress}
      animate={useMemo(
        () =>
          ({ hovered, pressed }) => {
            "worklet";

            return {
              backgroundColor:
                hovered || pressed
                  ? DRIPSY_THEME.colors.$coolGray[200]
                  : "white",
              scale: hovered || pressed ? 0.98 : 1,
            };
          },
        []
      )}
    >
      <Icon size={22} sx={{ ...defaultIconStyles, ...iconStyle }} name={icon} />

      <Text sx={{ ...defaultHorizontalTextStyles, ...labelStyle }}>
        {label}
      </Text>
    </MotiPressable>
  );
}

const defaultButtonStyles: Sx = {
  paddingY: "$3",
  paddingX: "$2",
  backgroundColor: "$coolGray.200",
  borderRadius: "md",
  height: 70,
  // width: 70,
  marginRight: "$4",
  minWidth: 70,
  maxWidth: 140,
};

const defaultIconStyles: Sx = {
  textAlign: "center",
  color: "$coolGray.600",
};

const defaultTextStyles: Sx = {
  textAlign: "center",
  color: "$coolGray.600",
  marginTop: 6,
};

const defaultHorizontalTextStyles: Sx = {
  textAlign: "left",
  color: "$coolGray.600",
  marginLeft: "$4",
};

const defaultHorizontalButtonStyles: Sx = {
  flexDirection: "row",
  alignItems: "center",
  paddingY: "$3",
  paddingX: "$5",
  width: "100%",
  borderRadius: "md",
  // backgroundColor: "$coolGray.200",
  // borderBottomWidth: "hairline",
  borderColor: "$coolGray.100",
};

export function useProfilePeekDrawer() {
  return useContext(ProfilePeekDrawerContext);
}
