import { Avatar } from "../components/basics";
import { useMemo, ComponentProps } from "react";
import supabase from "../lib/supabase";
import { ProfilesInfoFragment } from "../state/generated/graphql";
import { imageSizes, optimizedImagePath } from "../utils/images";

export default function useProfileAvatarUri(
  profile: ProfilesInfoFragment | undefined,
  size: ComponentProps<typeof Avatar>["size"]
) {
  const avatarUri = useMemo(() => {
    const publicUrl = profile?.profile_image_path
      ? supabase.storage
          .from("avatars")
          .getPublicUrl(profile.profile_image_path).data?.publicURL
      : undefined;
    if (!publicUrl) return undefined;

    const avatarSize = size ?? 64;
    const imageWidth =
      imageSizes.find((s) => s > avatarSize * 2) ??
      imageSizes[imageSizes.length - 1];
    return optimizedImagePath(publicUrl, { w: imageWidth });
  }, [profile, size]);
  return avatarUri;
}
