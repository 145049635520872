import { useCallback } from "react";
import { useMutation } from "urql";
import { AddCollectionPermissionsDocument } from "../state/generated/graphql";

export default function useImportCollections() {
  const [_addPermissionsResp, addPermissions] = useMutation(
    AddCollectionPermissionsDocument
  );

  return useCallback(
    (spaceId: string, collectionIds: string[]) => {
      const permissions = collectionIds.map((collectionId) => ({
        collection_id: collectionId,
        space_id: spaceId,
      }));
      return addPermissions({
        permissions,
      });
    },
    [addPermissions]
  );
}
