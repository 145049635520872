import { ProfilesInfoFragment } from "../state/generated/graphql";
import { useContacts } from "./useContacts";
import usePeople from "./usePeople";
import { useAllSpaceMembers } from "./useSpaceMembers";
import { useMemo } from "react";
import {
  Connection,
  ContactItem,
  ProfileItem,
  profileToPerson,
} from "../utils/people";

export function useAllPeople(): Connection[] {
  const [contacts] = useContacts();
  const people = usePeople();

  return useMemo(() => {
    const validContacts = contacts.filter(
      (x) => x.name && x.data.phoneNumbers?.length
    );
    return [
      ...[...people.values()].map((p) => profileToPerson(p)),
      ...validContacts,
    ];
  }, [contacts, people]);
}

export function useConnections(): Connection[] {
  const [contacts] = useContacts();
  const spaceMembers = useAllSpaceMembers();
  const people = usePeople();
  // TODO: merge by phone #
  const connections = useMemo<Connection[]>(() => {
    const filteredSpaceMembers = Object.values(
      spaceMembers.reduce<Record<string, ProfilesInfoFragment>>(
        (members, member) => {
          if (member.user_id && people.has(member.user_id)) {
            members[member.user_id] = people.get(member.user_id)!;
          }
          return members;
        },
        {}
      )
    );
    const validContacts = contacts.filter(
      (x) => x.name && x.data.phoneNumbers?.length
    );
    return [
      ...filteredSpaceMembers.map((p) => profileToPerson(p)),
      ...validContacts,
    ];
  }, [people, contacts, spaceMembers]);

  return connections;
}

export function useConnection(id: string, type: "PROFILE" | "CONTACT") {
  const connections = useConnections();
  return connections.find((c) => c.key === type && c.id == id);
}

export function useConnectionQuery() {
  const connections = useConnections();
  const [_contacts, contactsByPhone] = useContacts();
  const connectionLookups = useMemo(() => {
    return {
      profiles_by_id: Object.fromEntries(
        connections
          .filter((c) => c.key === "PROFILE")
          .map((c) => [c.id, c as ProfileItem])
      ),
      contacts_by_id: Object.fromEntries(
        connections
          .filter((c) => c.key === "CONTACT")
          .map((c) => [c.id, c as ContactItem])
      ),
      contacts_by_phone: contactsByPhone,
    };
  }, [connections, contactsByPhone]);

  return (
    queryType: "profiles_by_id" | "contacts_by_id" | "contacts_by_phone",
    value: string | undefined
  ) => {
    if (!value) return undefined;
    return connectionLookups[queryType][value];
  };
}
