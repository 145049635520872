import { useNavigation } from "@react-navigation/native";
import { ReactPropTypes } from "react";
import IconButton, { IconButtonProps } from "./IconButton";

function BackButton({
  size,
  icon,
  variant,
  _iconStyles,
  ...props
}: IconButtonProps) {
  const navigation = useNavigation();
  return (
    <IconButton
      onPress={() => {
        navigation.goBack();
      }}
      size="lg"
      icon="arrow-back-outline"
      variant="unstyled"
      _iconStyles={{ color: "$darkText" }}
      {...props}
    />
  );
}

export default BackButton;
