import { createNativeStackNavigator } from "@react-navigation/native-stack";
import React from "react";
import { Button } from "../components/basics";
import PeopleScreen from "./PeopleScreen";
import PersonScreen from "./PersonScreen";
import SpaceAppIcon from "../components/SpaceAppIcon";

export const ProfilesStack = createNativeStackNavigator();

export default function ProfilesScreen() {
  return (
    <ProfilesStack.Navigator initialRouteName="profiles">
      <ProfilesStack.Screen
        name="profiles"
        options={{
          headerShown: true,
          headerShadowVisible: false,
          title: "People",
          headerTitleAlign: "center",
          headerLeft: () => <SpaceAppIcon appName="profiles" />,
          animationTypeForReplace: "pop",
        }}
        component={PeopleScreen}
      />
      <ProfilesStack.Screen
        name="profile"
        component={PersonScreen}
        options={({ navigation }) => ({
          headerShown: true,
          title: "",
          headerShadowVisible: false,
          headerLeft: () => {
            return (
              <Button
                leftIcon="arrow-back"
                variant="ghost"
                colorScheme="$blueGray"
                onPress={() => {
                  navigation.reset({
                    index: 0,
                    routes: [{ name: "profiles" }],
                  });
                }}
              >
                All People
              </Button>
            );
          },
        })}
      />
    </ProfilesStack.Navigator>
  );
}
