import { ActivityIndicator } from "dripsy";
import React, { useMemo, useState } from "react";
import { ListRenderItem } from "react-native";
import { useQuery } from "urql";
import {
  View,
  StyledTextInput,
  Image,
  Icon,
  Text,
  FlatList,
} from "../components/basics";
import useDebounce from "../hooks/useDebounce";
import { SearchProfilesDocument } from "../state/generated/graphql";
import { Connection } from "../utils/people";
import { PeopleListProfileItem } from "./PeopleScreen";

export default function SearchScreen() {
  const [searchText, setSearchText] = useState("");

  const debouncedText = useDebounce(searchText, 300);

  const query = useMemo(() => {
    if (debouncedText.length <= 1) return null;
    return `%${debouncedText}%`;
  }, [debouncedText]);

  const [{ data, fetching, error }] = useQuery({
    query: SearchProfilesDocument,
    variables: { query },
    pause: !query,
  });

  const profiles =
    searchText.length > 0 && data?.profiles ? data?.profiles : [];

  const renderItem: ListRenderItem<Connection> = ({ item }) => {
    return <PeopleListProfileItem person={item} />;
  };

  return (
    <View sx={{ flex: 1 }}>
      <View>
        <StyledTextInput
          sx={{
            borderRadius: 24,
            paddingX: 12,
            marginX: 12,
            mb: 0,
          }}
          placeholder="Find someone on Aspen"
          rightIcon={() =>
            fetching ? (
              <ActivityIndicator size="small" />
            ) : (
              <Image
                source={require("../assets/images/logo.png")}
                sx={{
                  width: 24,
                  height: 24,
                }}
              />
            )
          }
          leftIcon={() => <Icon name="search" size={18} />}
          onChangeText={(text) => setSearchText(text)}
          value={searchText}
        />
      </View>
      <View sx={{ flex: 1 }}>
        <FlatList renderItem={renderItem} data={profiles} />
      </View>
    </View>
  );
}
