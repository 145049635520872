import { useRecoilState } from "recoil";
import { peopleState } from "../state/atoms/people";

export default function usePeople() {
  const [people] = useRecoilState(peopleState);
  return people;
}

export function usePerson(userId: string | undefined) {
  const people = usePeople();
  return userId ? people.get(userId) : undefined;
}
