import Animated from "react-native-reanimated";
import { FlatList } from "../components/basics";
import { forwardRef } from "react";

const FlatListWithEventThrottle = forwardRef((props, ref) => (
  <FlatList
    {...props}
    scrollEventThrottle={0.0001}
    // @ts-ignore
    ref={ref}
  />
));

export const AnimatedFlatList: typeof FlatList =
  Animated.createAnimatedComponent(FlatListWithEventThrottle);
