import {
  createNativeStackNavigator,
  NativeStackHeaderProps,
} from "@react-navigation/native-stack";
import { useSx } from "dripsy";
import React from "react";
import { BackButton, IconButton, SafeAreaView } from "../components/basics";
import SpaceAppIcon from "../components/SpaceAppIcon";
import SpaceAppWrapper from "../components/SpaceAppWrapper";
import SpacesList from "../components/SpacesList";
import SpacesScreenHeader from "../components/SpacesScreenHeader";
import SpaceEditScreen from "./spaces/SpaceEditScreen";
import ManageMembersScreen from "./spaces/ManageMembersScreen";
import InviteLinkScreen from "./spaces/InviteLinkScreen";

import SpaceScreen from "./SpaceScreen";
import { ProfilePeekDrawerProvider } from "../components/chat/ProfilePeekDrawer";
import AddMemberModalScreen from "./AddMemberModalScreen";

export const ChatStack = createNativeStackNavigator();

export default function ChatScreen() {
  const sx = useSx();
  return (
    <ChatStack.Navigator screenOptions={{ headerTitleAlign: "center" }}>
      <ChatStack.Screen
        name="chat-list"
        options={{
          headerShown: true,
          headerShadowVisible: false,
          animationTypeForReplace: "pop",
          title: "Chat",
          headerLeft: () => <SpaceAppIcon appName="Chat" />,
        }}
        component={SpacesList}
      />
      <ChatStack.Screen
        name="conversation"
        component={SpaceScreen}
        options={({ navigation, route }) => ({
          headerShadowVisible: false,

          headerRight: () => (
            <IconButton
              onPress={() => {
                navigation.navigate("edit", {
                  spaceId: route.params?.spaceId,
                });
              }}
              size="lg"
              icon="menu"
              variant="unstyled"
              _containerStyles={{ flexShrink: 0, py: 0 }}
              _iconStyles={{ color: "$darkText" }}
            />
          ),
        })}
      />
      <ChatStack.Screen
        name="edit"
        component={SpaceEditScreen}
        options={{
          title: "Settings",
          headerShadowVisible: false,
          headerStyle: sx({ backgroundColor: "$systemBg" }),
        }}
      />
      <ChatStack.Screen
        name="manage-members"
        component={ManageMembersScreen}
        options={({ navigation, route }) => ({
          title: "Manage Members",
          headerShadowVisible: false,

          headerRight: () => (
            <IconButton
              onPress={() => {
                navigation.navigate("AddMemberModal", {
                  spaceId: route.params?.spaceId,
                });
              }}
              size="lg"
              icon="add"
              variant="ghost"
              _containerStyles={{ padding: 0 }}
            />
          ),
        })}
      />
      <ChatStack.Screen
        name="AddMemberModal"
        component={AddMemberModalScreen}
        options={{
          title: "Add Members",
          headerShadowVisible: false,
        }}
      />
      <ChatStack.Screen
        name="invite-link"
        component={InviteLinkScreen}
        options={({ navigation, route }) => ({
          title: "Invite",
          headerStyle: sx({ backgroundColor: "$systemBg" }),
          headerShadowVisible: false,
        })}
      />
    </ChatStack.Navigator>
  );
}
