import { ApiError, User } from "@supabase/supabase-js";
import React, {
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import supabase from "../lib/supabase";
import useSession from "./useSession";

export function useUser() {
  return supabase.auth.user();
}

type UserAccountContextData = [
  User | null,
  () => Promise<{
    user: User | null;
    error: ApiError | Error | null;
  }>
];

export const UserAccountContext = React.createContext<UserAccountContextData>([
  null,
  async () => ({ user: null, error: new Error("Uninitialized") }),
]);

export function UserAccountProvider({ children }: PropsWithChildren<{}>) {
  const session = useSession();
  const [user, setUser] = useState<User | null>(supabase.auth.user());

  const reload: () => Promise<{
    user: User | null;
    error: ApiError | Error | null;
  }> = useCallback(async () => {
    if (!session?.access_token)
      return { user: null, error: new Error("no session") };
    const { user: updatedUser, error } = await supabase.auth.api.getUser(
      session?.access_token
    );
    if (!error) setUser(updatedUser);
    return { user: updatedUser, error };
  }, [session]);

  useEffect(() => {
    reload();
  }, [reload, session]);

  return (
    <UserAccountContext.Provider value={[user, reload]}>
      {children}
    </UserAccountContext.Provider>
  );
}

export function useUserAccount() {
  return useContext(UserAccountContext);
}
