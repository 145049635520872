import { Avatar, Spinner, View, Text } from "../components/basics";
import React, { useMemo } from "react";
import PersonAvatar from "./PersonAvatar";
import { SpaceMembersInfoFragment } from "../state/generated/graphql";
import { useConnectionQuery } from "../hooks/useConnections";
import { Person } from "../utils/people";

const MAX_AVATARS = 4;

export interface SpaceMembership {
  user_id: string;
  invitation: {
    external_metadata: {
      type: string;
      value: string;
    };
  };
}

export default React.memo(SpaceAvatars);
function SpaceAvatars({
  spaceMembers,
  size = 60,
}: {
  spaceMembers: SpaceMembership[];
  size: number;
}) {
  const connectionQuery = useConnectionQuery();
  const avatarProfiles = useMemo(() => {
    return spaceMembers
      .slice(0, MAX_AVATARS)
      .reduce<Person[]>((people, member) => {
        const profile = connectionQuery("profiles_by_id", member.user_id);
        if (profile) {
          people.push(profile);
          return people;
        }
        const phone =
          member?.invitation?.external_metadata?.type === "PHONE"
            ? member?.invitation?.external_metadata?.value
            : undefined;
        if (phone) {
          const contact = phone
            ? connectionQuery("contacts_by_phone", phone)
            : undefined;
          if (contact) {
            people.push(contact);
            return people;
          } else {
            people.push({
              key: "PHONE",
              id: phone,
              name: phone,
              data: phone,
            });
            return people;
          }
        }

        return people;
      }, []);
  }, [spaceMembers, connectionQuery]);

  return <PeopleAvatars people={avatarProfiles} size={size} />;
}

function getProportionalSize(index: number, count: number) {
  if (count === 1) {
    return 1.0;
  }
  if (count === 2) {
    if (index === 0) {
      return 0.75;
    }
    return 0.66;
  }
  if (count === 3) {
    if (index === 0) {
      return 0.75;
    }
    return 0.5;
  }
  if (count === 4) {
    if (index === 0) {
      return 0.75;
    }
    return 0.5;
  }
  return 0.5;
}

function getPosition(index: number, count: number) {
  if (count === 1) {
    return { left: 0, top: 0 };
  }
  if (count === 2) {
    if (index === 0) {
      return { left: 0, top: 0 };
    }
    return { bottom: 0, right: 0 };
  }
  if (count === 3) {
    if (index === 0) {
      return { left: 0, top: 0 };
    }
    if (index === 1) {
      return { bottom: 14, right: 0 };
    }
    return { right: 10, bottom: 0 };
  }
  if (count === 4) {
    if (index === 0) {
      return { left: 0, top: 0 };
    }
    if (index === 1) {
      return { top: "10%", right: 0 };
    }
    if (index === 2) {
      return { bottom: 0, left: "10%" };
    }
    return { bottom: 0, right: 0 };
  }
}

export function PeopleAvatars({
  people,
  size = 60,
}: {
  people: Person[];
  size: number;
}) {
  const displayPeople = people.slice(0, MAX_AVATARS);
  const overflow = people.length - MAX_AVATARS;

  return people.length ? (
    <View
      sx={{
        width: size,
        height: size,
        flexGrow: 0,
        flexShrink: 0,
      }}
    >
      {displayPeople.map((profile, i) => (
        <PersonAvatar
          sx={{
            position: "absolute",
            zIndex: MAX_AVATARS - i,
            borderColor: "$gray.50",
            borderWidth: 1,
            boxShadow: "$2",
            ...getPosition(i, displayPeople.length),
          }}
          size={size * getProportionalSize(i, displayPeople.length)}
          key={profile.id}
          person={profile}
        />
      ))}
    </View>
  ) : (
    <Spinner size="lg" color="white" />
  );
}
