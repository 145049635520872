import { CaptureContext } from "@sentry/types";
import { Platform } from "react-native";
import * as Sentry from "sentry-expo";

export function reportError(err: Error | string, context?: CaptureContext) {
  if (__DEV__) {
    console.error(err);
  }
  if (Platform.OS === "web") {
    Sentry.Browser.captureException(err, context);
  } else {
    Sentry.Native.captureException(err, context);
  }
}

export function reportMessage(message: string, context?: CaptureContext) {
  if (Platform.OS === "web") {
    Sentry.Browser.captureMessage(message, context);
  } else {
    Sentry.Native.captureMessage(message, context);
  }
}
