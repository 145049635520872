import {
  Center,
  Icon,
  Pressable,
  Row,
  View,
  Image,
  IconButton,
} from "../basics";
import React, { Suspense, useEffect, useMemo, useState } from "react";
import { MessageImageProps as MessageMediaProps } from "react-native-gifted-chat";
import { AspenChatMessage } from "../../screens/spaces/ChatScreen";
import { calculateResize, optimizedImagePath } from "../../utils/images";
import { getUploadUrl } from "../../utils/image-uploads";
import useDoublePress from "../../hooks/useDoublePress";
import { ChatImage } from "../ChatImage";
import MediaGallery from "../MediaGallery";
import { Platform } from "react-native";
import { readUploadQueue, readUploadQueueState } from "../../lib/upload-queue";
import { VideoThumbnail } from "../video-thumbail";

const FastImage =
  Platform.OS === "web" ? undefined : require("react-native-fast-image");

interface ImageViewerProps extends MessageMediaProps<AspenChatMessage> {
  uri: string;
}

const MAX_DIMENSIONS = { maxHeight: 350, maxWidth: 300 };
const GALLERY_IMAGE_DIMENSIONS = { height: 120, width: 120 };

interface DisplayImage {
  key: string;
  uri: string;
  type: "photo" | "video";
}

function MessageMedia({
  position,
  currentMessage,
  onDoublePress,
  onLongPress,
}: {
  position: "left" | "right";
  currentMessage?: AspenChatMessage;
  onDoublePress: () => void;
  onLongPress: (e: any) => void;
}) {
  const [mediaGalleryState, setMediaGalleryState] = useState({
    visible: false,
    index: 0,
  });

  const images = useMemo(() => {
    return (
      currentMessage?.attachments
        ?.map(({ id: imageKey, type, ...rest }) => {
          return {
            key: imageKey,
            uri: resolveLocalVideoUri(imageKey) as string,
            type,
            ...rest,
          };
        })
        .filter((i) => !!i.uri) ?? []
    );
  }, [currentMessage?.attachments]);

  const isGallery = (images?.length ?? 0) > 1;

  return !!currentMessage && !!images.length ? (
    <View sx={{ padding: 0, marginBottom: 0 }}>
      <MediaGallery
        data={images}
        visible={mediaGalleryState.visible}
        index={mediaGalleryState.index}
        handleClose={() => {
          setMediaGalleryState((prevState) => ({
            ...prevState,
            visible: false,
          }));
        }}
      />
      <View
        sx={{
          flexWrap: "wrap",
          justifyContent: position === "right" ? "flex-end" : "flex-start",
          flexDirection: "row",
        }}
      >
        {images.map((image, i) => (
          <ImageTile
            image={image}
            key={image.key}
            isGallery={isGallery}
            onPress={() => {
              setMediaGalleryState({ visible: true, index: i });
            }}
            onLongPress={onLongPress}
            onDoublePress={onDoublePress}
          />
        ))}
      </View>
    </View>
  ) : null;
}

function resolveLocalVideoUri(imageKey: string) {
  const attachmentInfo = readUploadQueueState(imageKey);
  if (attachmentInfo?.localUri) {
    return attachmentInfo.localUri;
  }
  return getUploadUrl(imageKey);
}

function ImageTile({
  image,
  isGallery,
  onPress,
  onDoublePress,
  onLongPress,
}: {
  image: DisplayImage;
  isGallery: boolean;
  onPress?: () => void;
  onDoublePress?: () => void;
  onLongPress?: (e: any) => void;
}) {
  const { key: imageKey, uri } = image;
  const imageDimensions = isGallery
    ? GALLERY_IMAGE_DIMENSIONS
    : image.height && image.width
    ? calculateResize(
        { height: image.height, width: image.width },
        MAX_DIMENSIONS
      )
    : { height: undefined, width: undefined, ...MAX_DIMENSIONS };
  const optimizedImageSize = isGallery ? 256 : 384;

  const videoUri = (() => {
    if (image.type === "video") {
      const attachentState = readUploadQueue()[image.key];
      const localUri = attachentState?.localUri || attachentState?.uri;
      return localUri || uri;
    }
    return uri;
  })();

  const doublePressHandler = useDoublePress();

  return (
    <Pressable
      onPress={() =>
        doublePressHandler({
          onPress,
          onDoublePress: onDoublePress,
        })
      }
      onLongPress={onLongPress}
      style={({ pressed }) => pressed && { opacity: 0.8 }}
      sx={{ marginRight: 4, marginBottom: 4 }}
    >
      {image.type === "video" ? (
        <View
          sx={{
            height: imageDimensions.height,
            width: imageDimensions.width,
            borderRadius: 8,
            overflow: "hidden",
            backgroundColor: "$gray.300",
          }}
        >
          <VideoThumbnail
            localKey={imageKey}
            videoUri={videoUri}
            height={imageDimensions.height}
            width={imageDimensions.width}
          />
          <View
            sx={{
              position: "absolute",
              zIndex: 10,
              width: "100%",
              height: "100%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Icon name="play-circle" color="white" size={48} />
          </View>
        </View>
      ) : (
        <ChatImage
          resizeMode={isGallery ? "cover" : "contain"}
          attachmentKey={imageKey}
          source={{
            uri: optimizedImagePath(uri, { w: optimizedImageSize }),
            priority: FastImage?.priority.high,
          }}
          // size={imageSize}
          alt={"Message attachment"}
          sx={{
            borderRadius: 4,
            overflow: "hidden",
          }}
          width={imageDimensions.width}
          height={imageDimensions.height}
          maxWidth={imageDimensions.maxWidth}
          maxHeight={imageDimensions.maxHeight}
        />
      )}
    </Pressable>
  );
}

// export default React.memo(MessageImage);
export default MessageMedia;

const MemoChatImage = React.memo(ChatImage);

function CacheFallbackImage(props: React.ComponentProps<typeof ChatImage>) {
  return (
    <View
      sx={{
        borderRadius: 4,
        width: 120,
        height: 120,
        marginRight: "$1",
        marginBottom: "$1",
        overflow: "hidden",
      }}
    >
      <ChatImage
        sx={{ width: 120, height: 120 }}
        {...props}
        key={props.source.uri}
      />
    </View>
  );
}
