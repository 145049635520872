import { Avatar, Text } from "./basics";
import { ComponentProps } from "react";
import useProfileAvatarUri from "../hooks/useProfileAvatarUri";
import { ProfilesInfoFragment } from "../state/generated/graphql";
import { getInitials } from "../utils/name";
import Icon from "./basics/Icon";
import React from "react";
import { Person } from "../utils/people";

type AvatarWithoutSource = Omit<ComponentProps<typeof Avatar>, "source">;

export type ProfileAvatarProps = AvatarWithoutSource & {
  profile?: ProfilesInfoFragment;
};

export type PersonAvatarProps = AvatarWithoutSource & {
  person: Person;
};

export type BasePersonAvatarProps = AvatarWithoutSource & {
  name: string;
  uri: string;
  fallbackColor: string;
};
const colors = ["red", "blue", "green", "yellow", "orange", "purple", "pink"];

function getHash(str: string) {
  var hash = 0,
    i,
    chr;
  if (str.length === 0) return hash;
  for (i = 0; i < str.length; i++) {
    chr = str.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
}

function getColor(str: string) {
  if (!str) return colors[0];
  const hash = Math.abs(getHash(str));
  return colors[hash % colors.length];
}

// TODO: watch for ability to use cached avatars - https://github.com/GeekyAnts/NativeBase/discussions/5177
export function ProfileAvatar({ profile, ...props }: ProfileAvatarProps) {
  const avatarUri = useProfileAvatarUri(profile, props.size);
  return (
    <BasePersonAvatar
      name={profile?.name}
      uri={avatarUri}
      fallbackColor={getColor(profile?.id)}
      {...props}
    />
  );
}

export function PhoneAvatar(props: AvatarWithoutSource) {
  return (
    <Avatar
      {...props}
      sx={{
        backgroundColor: `$gray.400`,
        ...props.sx,
      }}
    >
      <Icon name="call-outline" size="lg" color="white" />
    </Avatar>
  );
}

function PersonAvatar({ person, ...props }: PersonAvatarProps) {
  if (person?.key === "PHONE") return <PhoneAvatar {...props} />;
  if (person?.key === "PROFILE")
    return <ProfileAvatar profile={person.data} {...props} />;
  return (
    <BasePersonAvatar
      name={person?.name}
      uri={person?.image}
      fallbackColor={getColor(person?.id)}
      {...props}
    >
      {props.children}
    </BasePersonAvatar>
  );
}

function BasePersonAvatar({
  name,
  uri,
  fallbackColor,
  ...props
}: BasePersonAvatarProps) {
  return (
    <Avatar
      source={uri ? { uri } : undefined}
      {...props}
      sx={{
        backgroundColor: `$${fallbackColor}.500`,
        boxShadow: "$2",
        ...props.sx,
      }}
    >
      {props.children}
      {name ? (
        <Text sx={{ color: "$white", fontSize: Math.floor(props.size / 2) }}>
          {getInitials(name)}
        </Text>
      ) : null}
    </Avatar>
  );
}

export default React.memo(PersonAvatar);
