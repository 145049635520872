import { nanoid } from "nanoid";
import reminderResource, {
  ReminderNewEventParams,
  REMINDER_RESOURCE_KEY,
} from "../../resources/reminder";

export function createReminderLocal(data: ReminderNewEventParams) {
  const resourceData = reminderResource.applyEvent(
    undefined,
    "NEW",
    {
      data,
    },
    false
  );

  return {
    id: nanoid(),
    collection_key: REMINDER_RESOURCE_KEY,
    data: resourceData,
  };
}
