import {
  createNavigatorFactory,
  DefaultNavigatorOptions,
  DrawerNavigationState,
  ParamListBase,
  useNavigationBuilder,
} from "@react-navigation/native";
import * as React from "react";

import MasterDetailRouter from "./md-router";
import { MDNavigationState, MDRouterOptions } from "./types";
import MDView from "./views/md-view";

type Props = DefaultNavigatorOptions<{}, MDNavigationState, {}, {}> &
  MDRouterOptions;

function MDNavigator({
  initialRouteName,
  children,
  screenOptions,
  sidebarContent,
  isSplitView,
  initialState,
}: Props) {
  const { state, descriptors, navigation, NavigationContent } =
    useNavigationBuilder<MDNavigationState, MDRouterOptions, {}, {}, {}>(
      MasterDetailRouter,
      {
        initialState,
        isSplitView,
        initialRouteName,
        children,
        screenOptions,
        sidebarContent,
      }
    );

  return (
    <NavigationContent>
      <MDView
        state={state}
        descriptors={descriptors}
        navigation={navigation}
        isSplitView={isSplitView}
        sidebarContent={sidebarContent}
      />
    </NavigationContent>
  );
}

export default createNavigatorFactory<
  DrawerNavigationState<ParamListBase>,
  {},
  {},
  typeof MDNavigator
>(MDNavigator);
