import { useMemo } from "react";
import { useQuery } from "urql";
import { GetResourcePermissionsDocument } from "../state/generated/graphql";

export function useResourcePermissions(
  resourceKey: string,
  resourceId: string
) {
  const [queryResult] = useQuery({
    query: GetResourcePermissionsDocument,
    requestPolicy: "cache-and-network",
  });
  const filteredData = useMemo(() => {
    return queryResult.data?.resource_permissions.filter(
      (rp) => rp.resource_key === resourceKey && rp.resource_id === resourceId
    );
  }, [queryResult.data]);

  return {
    ...queryResult,
    data: queryResult.data
      ? { ...queryResult.data, resource_permissions: filteredData }
      : queryResult.data,
  };
}
