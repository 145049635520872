import { useNavigation } from "@react-navigation/native";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import React, { useCallback, useMemo, useState, ComponentProps } from "react";
import { ListRenderItem, Platform, SafeAreaView, Share } from "react-native";
import { useToast } from "../../hooks/useToast";
import Constants from "expo-constants";

import {
  Button,
  FlatList,
  View,
  Text,
  Row,
  IconButton,
  Center,
  Heading,
  VStack,
  Modal,
  Column,
  Switch,
  TextInput,
} from "../../components/basics";
import { MotiView } from "moti";
import { SpaceStackParamList } from "../../types";
import usePeople from "../../hooks/usePeople";
import { useUser } from "../../hooks/auth";
import { MemberSectionItem } from "../../components/MemberItems";
import { CommonActions } from "@react-navigation/native";
import { useSpace } from "../../hooks/spaces";
import SpaceAppsSelector from "../../components/SpaceAppsSelector";
import Icon from "../../components/basics/Icon";
import Pressable from "../../components/basics/Pressable";
import { deleteInvitation } from "../../lib/invitations";
import { deleteSpace, renameSpace } from "../../state/atoms/spaces";
import {
  useSortedSpaceMembers,
  useSpaceMember,
  useSpaceMembers,
} from "../../hooks/useSpaceMembers";
import { useMutation } from "urql";
import usePushNotice from "../../hooks/usePushNotice";
import { useActionSheet } from "@expo/react-native-action-sheet";
import {
  DeleteSpaceMemberBySpaceAndUserDocument,
  DeleteSpaceMemberDocument,
  RemoveSpaceAppDocument,
  SetMemberNotificationMuteDocument,
  SpaceInfoFragment,
  SpaceMembersInfoFragment,
} from "../../state/generated/graphql";
import { getIconForApp } from "../../utils/apps";
import { CoverPhotoView } from "../../components/CoverPhotoView";
import { ScrollView, Sx, useSx } from "dripsy";
import DRIPSY_THEME from "../../constants/DripsyTheme";
import SpaceAppIcon from "../../components/SpaceAppIcon";
import { phoneToPerson } from "../../utils/people";
import { useConnectionQuery } from "../../hooks/useConnections";
import PersonAvatar from "../../components/PersonAvatar";
import StyledTextInput from "../../components/basics/StyledTextInput";
import {
  EventsCard,
  itemStyles,
  NotesCard,
  PhotosCard,
  PollsCard,
  RemindersCard,
  ResourceCardHeader,
  ShoppingListCard,
  wrapperStyles,
} from "../../components/app-cards";
import { SpaceIdContext } from "../../hooks/useSpaceId";
import { getPersonFromMember } from "./ManageMembersScreen";
import { useProfilePeekDrawer } from "../../components/chat/ProfilePeekDrawer";
import { MotiPressable } from "moti/interactions";
import { reportError } from "../../state/utils/errors";

const SPACE_MEMBERS_TO_DISPLAY = 10;

function AppItem({ app, spaceId }: { app: any; spaceId: string }) {
  const { showActionSheetWithOptions } = useActionSheet();
  const navigation = useNavigation();
  const options = ["Open", "Remove", "Cancel"];
  const destructiveButtonIndex = 1;
  const cancelButtonIndex = 2;
  const [removeAppResp, removeApp] = useMutation(RemoveSpaceAppDocument);

  return (
    <Pressable
      onPress={() => {
        navigation.navigate("SpaceMain", { spaceId, screen: app.app?.name });
      }}
      style={({ pressed }) => ({
        backgroundColor: pressed
          ? DRIPSY_THEME.colors.$gray["200"]
          : "transparent",
      })}
      onLongPress={(e) => {
        showActionSheetWithOptions(
          {
            options,
            destructiveButtonIndex,
            cancelButtonIndex,
          },
          (buttonIndex) => {
            switch (buttonIndex) {
              case 0:
                navigation.navigate(app.app?.name);
                break;
              case 1:
                removeApp({
                  space_id: spaceId,
                  app_name: app.app_name,
                });
                break;
              default:
                break;
            }
          }
        );
      }}
      sx={{
        padding: 12,
        marginRight: 12,
        maxWidth: 80,
        alignItems: "center",
        justifyContent: "flex-start",
        borderRadius: 6,
      }}
    >
      <SpaceAppIcon size="md" appName={app.app?.name} />
      <Text
        sx={{ mt: "$1", textAlign: "center", color: "$black", fontSize: "xs" }}
      >
        {app.app?.title}
      </Text>
    </Pressable>
  );
}

function AvatarAndNameCard({ currentUser, spaceId, isPreview }) {
  const navigation = useNavigation();
  const [isEditing, setIsEditing] = useState(false);
  const addToast = useToast();
  const pushNotice = usePushNotice();
  const sx = useSx();
  const { data: space } = useSpace(spaceId);
  const [draftGroupName, setDraftGroupName] = useState(space?.name ?? "");

  const handleRenameSpace = useCallback(async () => {
    setIsEditing(false);
    if (draftGroupName === space?.name) return;
    const { error } = await renameSpace(spaceId, draftGroupName.trim());
    if (error) {
      addToast({
        title: "Could not rename the space",
        position: "bottom",
        variant: "ERROR",
      });
      setDraftGroupName(space?.name);
      return;
    }
    if (draftGroupName) {
      pushNotice(`%0 renamed the space to "${draftGroupName}"`, [
        currentUser!.id,
      ]);
    }
  }, [draftGroupName, spaceId]);

  function startEditing() {
    setIsEditing(true);
  }
  return (
    <MotiView
      style={sx({
        // backgroundColor: "$white",
        // boxShadow: "md",
        // paddingTop: "$4",
        marginBottom: "$4",
      })}
    >
      <Pressable
        onPress={() =>
          navigation.navigate("CoverPhotoSelector", {
            spaceId,
          })
        }
        style={({ pressed }) =>
          pressed && {
            backgroundColor: DRIPSY_THEME.colors.$gray["100"],
          }
        }
        sx={{ alignItems: "center", marginTop: "$4", marginX: "$4" }}
      >
        {!space?.cover_photo && !isPreview ? (
          <View
            sx={{
              height: 120,
              width: "100%",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: "$blueGray.200",
              borderRadius: "sm",
            }}
          >
            <Icon name={"images-outline"} color="$primary.600" size={22} />
            <Text
              sx={{ fontSize: "md", marginTop: "$2", color: "$primary.600" }}
            >
              Set cover photo
            </Text>
          </View>
        ) : (
          <CoverPhotoView
            size={space?.cover_photo.type === "emoji" ? 128 : 256}
            coverPhoto={space?.cover_photo}
            sx={{
              width: "100%",
              borderRadius: "md",
            }}
          />
        )}
      </Pressable>
      {isEditing ? (
        <StyledTextInput
          sx={{ marginX: "$4", marginTop: "$3" }}
          value={draftGroupName}
          onChangeText={(text) => setDraftGroupName(text)}
          rightIcon={() => (
            <Pressable sx={{ marginRight: "$2" }} onPress={handleRenameSpace}>
              <Text sx={{ color: "$primary.600" }}>Save</Text>
            </Pressable>
          )}
        />
      ) : space?.name === "" && !isPreview ? (
        <Pressable
          style={({ pressed }) =>
            pressed && {
              backgroundColor: DRIPSY_THEME.colors.$gray["100"],
            }
          }
          onPress={startEditing}
          sx={{
            paddingY: "$4",
            width: "100%",
            alignItems: "center",
          }}
        >
          <Text sx={{ fontSize: "md", color: "$primary.600" }}>
            Set group name
          </Text>
        </Pressable>
      ) : (
        <View
          sx={{
            paddingY: "$4",
            alignItems: "center",
            paddingX: "$4",
            width: "100%",
          }}
        >
          <Text
            sx={{ fontSize: "xl", fontWeight: "medium", textAlign: "center" }}
          >
            {space?.name}
          </Text>
          {!isPreview && (
            <Pressable sx={{ paddingTop: "$2" }} onPress={startEditing}>
              <Text sx={{ color: "$primary.600" }}>Change name</Text>
            </Pressable>
          )}
        </View>
      )}
    </MotiView>
  );
}

export function SpaceMemberRow({
  member,
  onLongPress = () => null,
  onPress,
  index,
  sx,
  showCaret = true,
}: {
  member: SpaceMembersInfoFragment;
  onLongPress?: () => null;
  onPress?: () => null;
  index: number;
  sx?: Sx;
  showCaret?: boolean;
}) {
  const connectionQuery = useConnectionQuery();
  const navigation = useNavigation();
  const pendingMember = member.invitation?.status === "PENDING";
  const person = getPersonFromMember(connectionQuery, member);
  const { onPressPerson, closeBottomSheet } = useProfilePeekDrawer();
  const currentUser = useUser();
  const isCurrentUser = currentUser?.id === member.user_id;
  function onPressProfile() {
    if (onPress) {
      onPress(member);
      return;
    }
    onPressPerson({ member });
  }

  if (!person) return null;
  return (
    <Pressable
      sx={{ ...itemStyles(index), ...sx }}
      onPress={onPressProfile}
      onLongPress={() => onLongPress(member)}
    >
      <View sx={{ flexDirection: "row", alignItems: "center" }}>
        <PersonAvatar size={40} person={person} />
        <View sx={{ marginLeft: "$4" }}>
          <Text
            numberOfLines={1}
            sx={{
              fontSize: "md",
              fontWeight: isCurrentUser ? "semibold" : "normal",
            }}
          >
            {person.name}
          </Text>
          {pendingMember && (
            <Text
              sx={{ fontStyle: "italic", fontSize: "xs", color: "$gray.500" }}
            >
              Invited
            </Text>
          )}
        </View>
      </View>
      {showCaret && (
        <Icon
          name="chevron-forward-outline"
          size={20}
          sx={{ color: "$gray.500" }}
        />
      )}
    </Pressable>
  );
}

export function getSpaceInviteLink(spaceId) {
  const { data: space } = useSpace(spaceId);
  return `${Constants.manifest?.extra?.IMAGE_OPTIMIZATION_BASE_URL}/invite/${space.join_id}`;
}

export function PeopleCardPressable({ onPress, iconName, label, index }) {
  const sx = useSx();
  return (
    <MotiPressable
      animate={useMemo(
        () =>
          ({ hovered, pressed }) => {
            "worklet";

            return {
              backgroundColor:
                hovered || pressed
                  ? DRIPSY_THEME.colors.$coolGray[200]
                  : "white",
            };
          },
        []
      )}
      style={sx({
        ...itemStyles(index),
        justifyContent: "flex-start",
        paddingY: "$3",
      })}
      onPress={onPress}
    >
      <Icon
        name={iconName}
        size={20}
        sx={{
          color: "$primary.600",
          textAlign: "center",
          marginLeft: "$3",
        }}
      />
      {/* <View sx={{ width: 56 }} /> */}
      <Text sx={{ fontSize: "md", color: "$primary.600", marginLeft: "$6" }}>
        {label}
      </Text>
    </MotiPressable>
  );
}

function SpaceMembersCard({ isPreview, spaceId }) {
  const navigation = useNavigation();
  const sx = useSx();
  const { data: space } = useSpace(spaceId);
  const sortedMembers = useSortedSpaceMembers(spaceId) ?? [];
  const firstNMembers = sortedMembers.slice(0, SPACE_MEMBERS_TO_DISPLAY);
  const overflowMembers = sortedMembers.length > SPACE_MEMBERS_TO_DISPLAY;
  const handleAddSomeone = useCallback(
    () => navigation.navigate("AddMemberModal", { spaceId }),
    [navigation, spaceId]
  );
  const handleShowQRCode = useCallback(
    () => navigation.navigate("invite-link", { spaceId }),
    [navigation, spaceId]
  );

  return (
    <MotiView style={sx(wrapperStyles)}>
      <ResourceCardHeader
        icon="people"
        title="People"
        onViewAllPress={() => {
          navigation.navigate("manage-members", {
            spaceId,
          });
        }}
      />
      {!isPreview && space?.type !== "STATIC" && (
        <View
          sx={{ overflow: "hidden", borderRadius: "sm", marginBottom: "$3" }}
        >
          <PeopleCardPressable
            onPress={handleAddSomeone}
            label={"Add someone"}
            iconName={"add-outline"}
            index={0}
          />
          <PeopleCardPressable
            onPress={handleShowQRCode}
            label={"Invite with link"}
            iconName={"link-outline"}
            index={1}
          />
        </View>
      )}
      <View sx={{ overflow: "hidden", borderRadius: "sm" }}>
        {firstNMembers.map((member, index) => (
          <SpaceMemberRow
            showCaret={false}
            member={member}
            index={index}
            key={member.id}
          />
        ))}

        {overflowMembers && (
          <Pressable
            sx={{
              ...itemStyles(sortedMembers.length),
              justifyContent: "center",
            }}
            onPress={() => {
              navigation.navigate("manage-members", {
                spaceId,
              });
            }}
          >
            {/* <View sx={{ width: 56 }} /> */}
            <Text sx={{ fontSize: "md", color: "$primary.600" }}>
              {`${sortedMembers.length - SPACE_MEMBERS_TO_DISPLAY} other${
                sortedMembers.length - SPACE_MEMBERS_TO_DISPLAY === 1 ? "" : "s"
              }`}
            </Text>
          </Pressable>
        )}
      </View>
    </MotiView>
  );
}

function OptionsCard({ currentUser, currentUserMembership, spaceId }) {
  const [_setMemberNotificationMuteResp, setMemberNotificationMute] =
    useMutation(SetMemberNotificationMuteDocument);
  const sx = useSx();
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [leaveConversationModalOpen, setLeaveConversationModalOpen] =
    useState(false);
  const { data: space } = useSpace(spaceId);
  const isStaticSpace = space?.type === "STATIC";

  return (
    <>
      <MotiView style={sx({ ...wrapperStyles, marginBottom: "$4" })}>
        <View
          sx={{
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            paddingX: "$2",

            paddingBottom: "$3",
          }}
        >
          <Icon name="settings" size={20} />
          <Text sx={{ fontWeight: "medium", fontSize: "lg", marginLeft: "$2" }}>
            Settings
          </Text>
        </View>
        <View
          sx={{
            ...itemStyles(0),
            flexDirection: "column",
            borderRadius: "sm",
          }}
        >
          <View
            sx={{
              width: "100%",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              paddingX: "$2",
              // borderBottomWidth: 1,
              borderColor: "$gray.300",
              paddingY: "$3",
            }}
          >
            <Text sx={{ fontSize: "md" }}>Mute Notifications</Text>
            <Switch
              isChecked={currentUserMembership?.mute_notifications}
              size={"sm"}
              onToggle={() => {
                if (currentUserMembership)
                  setMemberNotificationMute({
                    id: currentUserMembership.id,
                    mute: !currentUserMembership.mute_notifications,
                  });
              }}
              onTrackColor={DRIPSY_THEME.colors.$primary[600]}
            />
          </View>
          {!isStaticSpace && (
            <>
              <Pressable
                sx={{
                  paddingY: "$2",
                  backgroundColor: "$danger.100",
                  width: "100%",
                  alignItems: "center",
                  borderRadius: "md",
                }}
                onPress={() => setLeaveConversationModalOpen(true)}
              >
                <Text sx={{ fontSize: "md", color: "$danger.600" }}>
                  Leave Conversation
                </Text>
              </Pressable>
              <Pressable
                sx={{
                  paddingY: "$2",
                  backgroundColor: "$danger.100",
                  width: "100%",
                  alignItems: "center",
                  borderRadius: "md",
                  mt: "$2",
                }}
                onPress={() => setDeleteModalOpen(true)}
              >
                <Text sx={{ fontSize: "md", color: "$danger.600" }}>
                  Delete Space
                </Text>
              </Pressable>
            </>
          )}
        </View>
      </MotiView>
      <SpaceDeleteModal
        spaceId={spaceId}
        isOpen={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
      />
      <LeaveConversationModal
        spaceId={spaceId}
        isOpen={leaveConversationModalOpen}
        onClose={() => setLeaveConversationModalOpen(false)}
      />
    </>
  );
}

interface SpaceModalProps extends ComponentProps<typeof Modal> {
  spaceId: string;
}

function SpaceDeleteModal(props: SpaceModalProps) {
  const { spaceId, onClose } = props;
  const { data: space } = useSpace(spaceId);
  const navigation = useNavigation();
  const addToast = useToast();
  const [isDeleting, setIsDeleting] = useState(false);
  const handleDeleteSpace = useCallback(async () => {
    if (!space) return;
    setIsDeleting(true);
    try {
      await deleteSpace(space.id);
      onClose && onClose();
      navigation.reset({
        index: 0,
        routes: [{ name: "chat-list" }],
      });
    } catch (e) {
      reportError(e);
      setIsDeleting(false);
      addToast({
        description: "Could not delete this space",
        position: "bottom",
        variant: "ERROR",
      });
    }
  }, [space, onClose]);

  return (
    <Modal {...props}>
      <Modal.Content>
        <Modal.CloseButton />
        <Modal.Header>Are you sure?</Modal.Header>
        <Modal.Body>
          <Column space="md">
            <Text>
              Are you sure you want to delete{" "}
              {space?.name ? (
                <Text sx={{ fontWeight: "bold" }}>{space.name}</Text>
              ) : (
                "this space"
              )}
              ?
            </Text>
            <Text>
              This space and any content in this space will no longer be
              accessible to you or any other members unless it is shared in
              another space.
            </Text>
            <Text>This action cannot be undone.</Text>
            <View sx={{ alignSelf: "flex-end", flexDirection: "row" }}>
              <Button
                variant="ghost"
                colorScheme="$blueGray"
                onPress={() => {
                  onClose();
                }}
              >
                Cancel
              </Button>
              <Button
                onPress={handleDeleteSpace}
                isLoading={isDeleting}
                colorScheme="$danger"
                sx={{ ml: "$1" }}
              >
                Delete
              </Button>
            </View>
            {/* </Button.Group> */}
          </Column>
        </Modal.Body>
      </Modal.Content>
    </Modal>
  );
}

function LeaveConversationModal(props: SpaceModalProps) {
  const { spaceId, onClose } = props;
  const { data: space } = useSpace(spaceId);
  const [deleteState, deleteMember] = useMutation(
    DeleteSpaceMemberBySpaceAndUserDocument
  );
  const currentUser = useUser();
  const navigation = useNavigation();
  const addToast = useToast();
  const [isDeleting, setIsDeleting] = useState(false);
  const handleDeleteSpaceMember = useCallback(async () => {
    if (!space) return;
    setIsDeleting(true);
    try {
      const { error } = await deleteMember({
        user_id: currentUser.id,
        space_id: spaceId,
      });
      if (error) throw error;

      onClose && onClose();
      navigation.reset({
        index: 0,
        routes: [{ name: "chat-list" }],
      });
    } catch (e) {
      reportError(e);
      setIsDeleting(false);
      addToast({
        description: "Could not leave the conversation",
        position: "bottom",
        variant: "ERROR",
      });
    }
  }, [space, onClose, currentUser]);

  return (
    <Modal {...props}>
      <Modal.Content>
        <Modal.CloseButton />
        <Modal.Header>Are you sure?</Modal.Header>
        <Modal.Body>
          <Column space="md">
            <Text>
              Are you sure you want to leave{" "}
              {space?.name ? (
                <Text sx={{ fontWeight: "bold" }}>{space.name}</Text>
              ) : (
                "this space"
              )}
              ?
            </Text>
            <Text>
              This space and any content in this space will no longer be
              accessible to you unless it is shared in another space.
            </Text>
            <View sx={{ alignSelf: "flex-end", flexDirection: "row" }}>
              <Button
                variant="ghost"
                colorScheme="$blueGray"
                onPress={() => {
                  onClose();
                }}
              >
                Cancel
              </Button>
              <Button
                onPress={handleDeleteSpaceMember}
                isLoading={isDeleting}
                colorScheme="$danger"
                sx={{ ml: "$1" }}
              >
                Leave
              </Button>
            </View>
            {/* </Button.Group> */}
          </Column>
        </Modal.Body>
      </Modal.Content>
    </Modal>
  );
}

export default function SpaceEditScreen({
  route,
}: NativeStackScreenProps<SpaceStackParamList, "SpaceEdit">) {
  const { spaceId } = route.params;
  const [modalUser, setModalUser] = useState<SpaceMembersInfoFragment>();

  const spaceMembers = useSpaceMembers(spaceId) ?? [];
  const currentUser = useUser();
  const people = usePeople();
  const currentUserMembership = useSpaceMember(spaceId, currentUser!.id);
  const sortedMembers = useMemo(() => {
    return [...spaceMembers]
      .filter((member) => member.user_id !== currentUser?.id)
      .sort((a, b) => {
        const getName = (member: SpaceMembersInfoFragment) =>
          member.user_id ? people.get(member.user_id)?.name : undefined;
        const getPhone = (member: SpaceMembersInfoFragment) =>
          member.invitation?.status === "PENDING" &&
          member.invitation?.external_metadata
            ? member.invitation?.external_metadata?.value
            : undefined;
        const aName = getName(a);
        const aPhone = getPhone(a);
        const bName = getName(b);
        const bPhone = getPhone(b);

        if (aName && bName) return aName < bName ? -1 : 1;
        if (aName && !bName) return -1;
        if (!aName && bName) return 1;

        return (aPhone ?? "") < (bPhone ?? "") ? -1 : 1;
      });
  }, [spaceMembers]);
  const isPreview = useMemo(
    () => currentUserMembership?.invitation?.status === "PENDING",
    [currentUserMembership]
  );
  const modalActiveMember = useMemo(
    () => (modalUser?.invitation?.status !== "PENDING" ? modalUser : undefined),
    [modalUser]
  );
  const activeMemberModalOpen = useMemo(
    () => !!modalActiveMember,
    [modalActiveMember]
  );
  const modalPendingMember = useMemo(
    () => (modalUser?.invitation?.status === "PENDING" ? modalUser : undefined),
    [modalUser]
  );
  const pendingMemberModalOpen = useMemo(
    () => !!modalPendingMember,
    [modalPendingMember]
  );
  const onMemberModalClose = useCallback(() => {
    setModalUser(undefined);
  }, []);
  const sx = useSx();
  const { onPressPerson, closeBottomSheet } = useProfilePeekDrawer();

  return (
    <SpaceIdContext.Provider value={spaceId}>
      <SafeAreaView style={sx({ flex: 1, backgroundColor: "$systemBg" })}>
        <ScrollView onScroll={closeBottomSheet} scrollEventThrottle={0}>
          <AvatarAndNameCard
            spaceId={spaceId}
            isPreview={isPreview}
            currentUser={currentUser}
          />
          <SpaceMembersCard spaceId={spaceId} isPreview={isPreview} />
          <PhotosCard spaceId={spaceId} />
          <EventsCard spaceId={spaceId} />
          <RemindersCard spaceId={spaceId} />
          <NotesCard spaceId={spaceId} />
          <ShoppingListCard spaceId={spaceId} />
          <PollsCard spaceId={spaceId} />
          <OptionsCard
            currentUser={currentUser}
            currentUserMembership={currentUserMembership}
            spaceId={spaceId}
          />
          {/* <LeWordCard /> */}
        </ScrollView>
      </SafeAreaView>
    </SpaceIdContext.Provider>
  );
}

function OldSpaceEditScreen({
  route,
}: NativeStackScreenProps<SpaceStackParamList, "SpaceEdit">) {
  const { spaceId } = route.params;
  const navigation = useNavigation();
  const people = usePeople();
  const { data: space } = useSpace(spaceId);

  const [shouldShowSpaceAppsManager, setShouldShowSpaceAppsManager] =
    useState(false);

  const spaceMembers = useSpaceMembers(spaceId) ?? [];
  const currentUser = useUser();
  const currentUserMembership = useSpaceMember(spaceId, currentUser!.id);

  const sortedMembers = useMemo(() => {
    return [...spaceMembers].sort((a, b) => {
      const getName = (member: SpaceMembersInfoFragment) =>
        member.user_id ? people.get(member.user_id)?.name : undefined;
      const getPhone = (member: SpaceMembersInfoFragment) =>
        member.invitation?.status === "PENDING" &&
        member.invitation?.external_metadata
          ? member.invitation?.external_metadata?.value
          : undefined;
      const aName = getName(a);
      const aPhone = getPhone(a);
      const bName = getName(b);
      const bPhone = getPhone(b);

      if (aName && bName) return aName < bName ? -1 : 1;
      if (aName && !bName) return -1;
      if (!aName && bName) return 1;

      return (aPhone ?? "") < (bPhone ?? "") ? -1 : 1;
    });
  }, [spaceMembers]);

  const isPreview = useMemo(
    () => currentUserMembership?.invitation?.status === "PENDING",
    [currentUserMembership]
  );

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [modalUser, setModalUser] = useState<SpaceMembersInfoFragment>();
  const modalActiveMember = useMemo(
    () => (modalUser?.invitation?.status !== "PENDING" ? modalUser : undefined),
    [modalUser]
  );
  const activeMemberModalOpen = useMemo(
    () => !!modalActiveMember,
    [modalActiveMember]
  );
  const modalPendingMember = useMemo(
    () => (modalUser?.invitation?.status === "PENDING" ? modalUser : undefined),
    [modalUser]
  );
  const pendingMemberModalOpen = useMemo(
    () => !!modalPendingMember,
    [modalPendingMember]
  );
  const onMemberModalClose = useCallback(() => {
    setModalUser(undefined);
  }, []);

  const pushNotice = usePushNotice();

  const [_setMemberNotificationMuteResp, setMemberNotificationMute] =
    useMutation(SetMemberNotificationMuteDocument);

  const renderAppItem: ListRenderItem<any> = ({ item }) => {
    return <AppItem app={item} spaceId={spaceId} />;
  };

  const addToast = useToast();

  return (
    <SafeAreaView style={{ flex: 1, backgroundColor: "#fafafa" }}>
      <ScrollView>
        {space?.cover_photo && (
          <Row justifyContent={"center"} py={2}>
            <CoverPhotoView size={256} coverPhoto={space?.cover_photo} />
            <Pressable
              style={({ pressed, hovered }) => ({
                backgroundColor: pressed
                  ? DRIPSY_THEME.colors.$gray["800"]
                  : hovered
                  ? DRIPSY_THEME.colors.$gray["900"]
                  : DRIPSY_THEME.colors.$black,
              })}
              sx={{
                position: "absolute",
                bottom: 15,
                right: 25,
                opacity: 0.75,
                padding: 4,
                borderRadius: 4,
              }}
              onPress={() =>
                navigation.navigate("CoverPhotoSelector", {
                  spaceId,
                })
              }
            >
              <Text sx={{ color: "white" }}>Update</Text>
            </Pressable>
          </Row>
        )}
        <Column space="3" padding="3" flex={1}>
          <Center flexDir={"row"}>
            <SpaceNameEdit
              isEditable={!isPreview}
              title={space?.name}
              onSubmit={async (value) => {
                if (value === space?.name) return;
                const { error } = await renameSpace(spaceId, value);
                if (error) {
                  addToast({
                    title: "Could not rename the space",
                    position: "bottom",
                    variant: "ERROR",
                  });
                  return;
                }

                if (value) {
                  pushNotice(`%0 renamed the space to "${value}"`, [
                    currentUser!.id,
                  ]);
                }
              }}
            />
          </Center>
          {!space?.cover_photo && (
            <Row justifyContent={"center"}>
              <Pressable
                onPress={() =>
                  navigation.navigate("CoverPhotoSelector", {
                    spaceId,
                  })
                }
                style={({ pressed }) =>
                  pressed && {
                    backgroundColor: DRIPSY_THEME.colors.$gray["100"],
                  }
                }
              >
                <Text sx={{ fontSize: "lg", color: "$primary.600" }}>
                  Set cover photo
                </Text>
              </Pressable>
            </Row>
          )}

          <VStack space={4}>
            <View>
              <Section
                title={"People"}
                onAddPress={
                  isPreview
                    ? undefined
                    : () => {
                        navigation.navigate("AddMemberModal", { spaceId });
                      }
                }
              />
              <FlatList
                horizontal
                data={sortedMembers}
                renderItem={({ item }) => (
                  <MemberSectionItem
                    direction="column"
                    member={item}
                    onPress={() => {
                      setModalUser(item);
                    }}
                  />
                )}
                keyExtractor={(item) => item.id}
              />
              <ActiveMemberActionModal
                isOpen={activeMemberModalOpen}
                onClose={onMemberModalClose}
                member={modalActiveMember}
              />
              <PendingMemberActionModal
                isOpen={pendingMemberModalOpen}
                onClose={onMemberModalClose}
                member={modalPendingMember}
              />
            </View>
            <View>
              <Section
                title={"Apps"}
                onAddPress={
                  isPreview
                    ? undefined
                    : () => {
                        setShouldShowSpaceAppsManager(true);
                      }
                }
              />
              <FlatList
                horizontal
                data={
                  space?.apps?.map((app) => ({
                    ...app,
                    icon: getIconForApp(app.app?.name!),
                  })) ?? []
                }
                keyExtractor={(item) => item.app?.name!}
                renderItem={renderAppItem}
              />
            </View>
            <SpaceAppsManagerModal
              isOpen={shouldShowSpaceAppsManager}
              onClose={() => {
                setShouldShowSpaceAppsManager(false);
              }}
              animationPreset="slide"
              spaceId={spaceId}
            />
            {!isPreview && (
              <View>
                <Row alignItems="center" justifyContent="space-between" py="2">
                  <Section title="Settings"></Section>
                </Row>
                <Row alignItems="center" justifyContent="space-between">
                  <Text>Mute Notifications</Text>
                  <Switch
                    isChecked={currentUserMembership?.mute_notifications}
                    onToggle={() => {
                      if (currentUserMembership)
                        setMemberNotificationMute({
                          id: currentUserMembership.id,
                          mute: !currentUserMembership.mute_notifications,
                        });
                    }}
                  />
                </Row>
              </View>
            )}
            {!isPreview && (
              <View>
                <Row alignItems="center" justifyContent="space-between" py="2">
                  <Section title="Actions"></Section>
                </Row>
                <Column space="3">
                  {/* <Button
                      variant="outline"
                      colorScheme="danger"
                      leftIcon="exit"
                    >
                      Leave Space
                    </Button> */}
                  <Button
                    leftIcon="trash"
                    onPress={() => setDeleteModalOpen(true)}
                    colorScheme="$danger"
                    variant="outline"
                  >
                    Destroy Space
                  </Button>
                </Column>
                <SpaceDeleteModal
                  space={space}
                  isOpen={deleteModalOpen}
                  onClose={() => setDeleteModalOpen(false)}
                />
              </View>
            )}
          </VStack>
        </Column>
      </ScrollView>
    </SafeAreaView>
  );
}

function SpaceNameEdit({
  onSubmit,
  title,
  isEditable = true,
}: {
  onSubmit: (value: string | undefined) => void | Promise<void>;
  title?: string;
  isEditable?: boolean;
}) {
  const [isEditing, setIsEditing] = useState(false);
  const [titleInput, setTitleInput] = useState(title);
  const tryStartEditing = useCallback(() => {
    if (isEditable) setIsEditing(true);
  }, [isEditable]);

  if (isEditing) {
    return (
      <TextInput
        value={titleInput}
        sx={{ fontSize: "2xl" }}
        onChangeText={setTitleInput}
        onBlur={async () => {
          const trimmed = titleInput?.trim();
          await onSubmit(trimmed);
          setTitleInput(trimmed);
          setIsEditing(false);
        }}
        autoFocus={true}
      />
    );
  }

  return titleInput ? (
    <Pressable onPress={tryStartEditing} sx={{ marginTop: "$2" }}>
      <Text sx={{ fontSize: "2xl" }}>
        {titleInput || "Set a name for this space"}
      </Text>
      <Text sx={{ color: "$primary.600" }}>Set group name</Text>
    </Pressable>
  ) : (
    <Button
      variant="ghost"
      onPress={tryStartEditing}
      _containerStyles={{ paddingY: 0 }}
      _textStyles={{ fontSize: "lg" }}
    >
      {"Set a name for this space"}
    </Button>
  );
}

function SpaceAppsManagerModal({
  spaceId,
  ...props
}: React.ComponentProps<typeof Modal> & { spaceId: string }) {
  return (
    <Modal {...props}>
      <Modal.Content>
        <Modal.CloseButton />
        <Modal.Header>Manage space apps</Modal.Header>
        <Modal.Body>
          <SpaceAppsSelector spaceId={spaceId} />
        </Modal.Body>
      </Modal.Content>
    </Modal>
  );
}

function Section({
  title,
  onAddPress,
}: {
  title: string;
  onAddPress?: () => void;
}) {
  return (
    <Row alignItems="center" py="2">
      <Heading
        sx={{
          fontSize: "md",
          textTransform: "uppercase",
          letterSpacing: 0.5,
          color: "$gray.500",
        }}
      >
        {title}
      </Heading>
      {onAddPress ? (
        <IconButton
          size="sm"
          _containerStyles={{
            borderRadius: "lg",
          }}
          _iconStyles={{ color: "$gray.500" }}
          variant={"unstyled"}
          icon="add-circle"
          onPress={onAddPress}
        />
      ) : null}
    </Row>
  );
}

interface PendingMemberActionModalProps extends ComponentProps<typeof Modal> {
  member: SpaceMembersInfoFragment | undefined;
}

function PendingMemberActionModal(props: PendingMemberActionModalProps) {
  const { member, onClose } = props;

  const [isDeleting, setIsDeleting] = useState(false);

  const onPressDelete = useCallback(async () => {
    try {
      setIsDeleting(true);
      if (member) {
        const { error } = await deleteInvitation(member.id);
        if (error) {
          console.error(error);
          throw error;
        }
      }
      onClose();
    } finally {
      setIsDeleting(false);
    }
  }, [member]);

  return (
    <Modal {...props}>
      <Modal.Content maxWidth="400px">
        <Modal.CloseButton />
        <Modal.Header>Invitation</Modal.Header>
        <Modal.Body>
          <Column space="md">
            <Button
              colorScheme="$danger"
              onPress={onPressDelete}
              isLoading={isDeleting}
            >
              Delete invitation
            </Button>
          </Column>
        </Modal.Body>
      </Modal.Content>
    </Modal>
  );
}

interface ActiveMemberActionModalProps extends ComponentProps<typeof Modal> {
  member: SpaceMembersInfoFragment | undefined;
}

function ActiveMemberActionModal(props: ActiveMemberActionModalProps) {
  const { member, onClose } = props;
  const people = usePeople();
  const currentUser = useUser();
  const user = useMemo(() => {
    return member?.user_id ? people.get(member.user_id) : undefined;
  }, [member, people]);
  const navigation = useNavigation();
  const [deleteState, deleteMember] = useMutation(DeleteSpaceMemberDocument);

  const onPressProfile = useCallback(() => {
    navigation.navigate("UserProfile", {
      userId: member?.user_id!,
      type: "PROFILE",
    });
  }, [navigation, member?.user_id]);

  const onPressDelete = useCallback(async () => {
    if (member?.id) {
      deleteMember({ id: member.id });
    }
    onClose();
  }, [onClose, member]);

  return (
    <Modal {...props}>
      <Modal.Content maxWidth="400px">
        <Modal.CloseButton />
        {user ? (
          <>
            <Modal.Header>{user.name}</Modal.Header>
            <Modal.Body>
              <Column space="md">
                <Button
                  colorScheme="$danger"
                  onPress={onPressDelete}
                  isLoading={deleteState.fetching}
                >
                  {user.id === currentUser?.id
                    ? "Leave space"
                    : "Remove from space"}
                </Button>
              </Column>
            </Modal.Body>
          </>
        ) : (
          <></>
        )}
      </Modal.Content>
    </Modal>
  );
}
