const UUID_NAMESPACE = "ced91de1-49d6-4b70-98fc-5924531945e5";
import { v5 as uuidv5 } from "uuid";
import {
  InsertSpaceDocument,
  InsertStaticSpaceDocument,
} from "../state/generated/graphql";
import urql from "../state/graphql/client";

export function getSpaceIdForMembers(userIds: string[]) {
  const sortedIds = userIds.sort();
  const spaceId = uuidv5(sortedIds.join("|"), UUID_NAMESPACE);
  return spaceId;
}

export async function getOrCreateStaticSpace(
  userIds: string[]
): Promise<string> {
  const spaceId = getSpaceIdForMembers(userIds);
  // TODO skip mutation if space exists in cache
  //   urql.readQuery(SpaceQuery, { id: spaceId });
  const { data, error } = await urql
    .mutation(InsertStaticSpaceDocument, {
      id: spaceId,
      members: userIds.map((id) => ({
        user_id: id,
      })),
    })
    .toPromise();

  if (error) {
    throw new Error(error.message);
  }
  if (!data || !data.insert_spaces_one) {
    throw new Error("Could not create space for an unexpected reason");
  }

  return data.insert_spaces_one.id;
}
