export function getHashParams(url: string) {
  const [_, hash] = url.split("#");
  if (!hash) {
    return new Map();
  }
  const hashParams = new Map<string, string>(
    hash
      .split("&")
      .map((keyvalues: string) => keyvalues.split("=") as [string, string])
  );
  return hashParams;
}
