import { format } from "date-fns";
import { Sx } from "dripsy";
import { GEO_GENIUS_RESOURCE_KEY } from "../resources/geo-genius";
import { LE_WORD_RESOURCE_KEY } from "../resources/le-word";
import { PAGES_RESOURCE_KEY } from "../resources/page";
import { POLL_RESOURCE_KEY } from "../resources/poll";
import { REMINDER_RESOURCE_KEY } from "../resources/reminder";
import { SCHEDULE_RESOURCE_KEY } from "../resources/schedule";
import { getIconForApp } from "../utils/apps";
import { parseNotePreview } from "../utils/apps/notes";
import { formatDurationText } from "../utils/apps/schedule";
import { View, Icon, Text, Column, Row } from "./basics";
import SpaceAppIcon from "./SpaceAppIcon";

const RESOURCE_TYPE_APP_MAP: Record<string, string> = {
  [PAGES_RESOURCE_KEY]: "Pages",
  [SCHEDULE_RESOURCE_KEY]: "Schedule",
  [LE_WORD_RESOURCE_KEY]: "le-word",
  [GEO_GENIUS_RESOURCE_KEY]: "geo-genius",
  [POLL_RESOURCE_KEY]: "Polls",
  [REMINDER_RESOURCE_KEY]: "Reminders",
};

export default function ResourceCard({
  resource,
  sx,
}: {
  resource: any;
  sx?: Sx;
}) {
  const { icon, title, snippet } = cardInfo(resource);
  return (
    <InfoCard
      icon={resource.collection_key}
      sx={sx}
      title={title}
      snippet={snippet}
    />
  );
}

export function InfoCard({
  icon,
  title,
  snippet,
  sx,
}: {
  icon: string;
  title: string;
  snippet: string;
  sx?: Sx;
}) {
  return (
    <View
      sx={{
        borderWidth: "hairline",
        borderColor: "$coolGray.400",
        // backgroundColor: "$coolGray.50",
        borderRadius: "md",
        flexDirection: "row",
        alignItems: "center",
        maxWidth: 250,
        minWidth: 150,
        // px: 6,
        py: "$3",
        ...sx,
      }}
    >
      <View sx={{ paddingX: "$3" }}>
        <SpaceAppIcon appName={icon} />
      </View>
      <View sx={{ flexGrow: 1, paddingRight: "$3", width: "90%" }}>
        <Text numberOfLines={1} sx={{ fontWeight: "normal", fontSize: 12 }}>
          {title}
        </Text>
        {!!snippet && (
          <Text
            sx={{ color: "$coolGray.500", fontWeight: "400", fontSize: 12 }}
            numberOfLines={2}
          >
            {snippet}
          </Text>
        )}
      </View>
    </View>
  );
}

export function cardInfo(resource: any) {
  const app = RESOURCE_TYPE_APP_MAP[resource?.collection_key] ?? "";
  const icon = getIconForApp(app);
  const { title, snippet } = parseTitleAndSnippet(resource);
  return { icon, title, snippet };
}

function parseTitleAndSnippet(resource: any) {
  switch (resource?.collection_key) {
    case PAGES_RESOURCE_KEY:
      const noteInfo = parseNotePreview(resource?.data?.rawText);
      return { title: noteInfo.title, snippet: noteInfo.snippet };
    case SCHEDULE_RESOURCE_KEY:
      return {
        title: resource?.data?.title,
        snippet: formatDurationText(resource?.data),
      };
    case LE_WORD_RESOURCE_KEY:
      return {
        title: "Le Word",
        snippet: resource?.data?.day,
      };
    case GEO_GENIUS_RESOURCE_KEY:
      return {
        title: "Geo Genius",
        snippet: resource?.data?.day,
      };
    case POLL_RESOURCE_KEY:
      return {
        title: "Poll",
        snippet: resource?.data?.title || "Untitled poll",
      };
    case REMINDER_RESOURCE_KEY:
      return {
        title: resource?.data?.title || "N/A",
        snippet: parseReminderPreview(resource.data ?? {}),
      };

    default:
      return { title: "N/A", snippet: "" };
  }
}

function parseReminderPreview(reminder: any) {
  const { start_date_utc, recurring_rate } = reminder;
  const date = new Date(start_date_utc);
  const dateString = format(date, "E, MMM d, yyyy, p");
  const recurrenceString = friendlyRecurrenceRate(recurring_rate);
  if (!recurrenceString) {
    return dateString;
  } else {
    return `${recurrenceString} starting ${dateString}`;
  }
}

function friendlyRecurrenceRate(recurrenceRate: string | undefined) {
  switch (recurrenceRate) {
    case "DAILY":
      return "Every day";
    case "WEEKLY":
      return "Every week";
    case "MONTHLY":
      return "Every month";
    default:
      return undefined;
  }
}
