import { useCallback } from "react";
import { useMutation } from "urql";
import { BulkCollectionPermissionMutationDocument } from "../state/generated/graphql";

export default function useShareCollection() {
  const [_updatePermissionsResp, updatePermissions] = useMutation(
    BulkCollectionPermissionMutationDocument
  );

  const updateCollectionPermissions = useCallback(
    (collectionId: string, additions: string[], removals: string[]) => {
      const addedPermissions = additions.map((sid) => ({
        collection_id: collectionId,
        space_id: sid,
      }));
      return updatePermissions({
        collection_id: collectionId,
        additions: addedPermissions,
        removals,
      });
    },
    [updatePermissions]
  );

  return updateCollectionPermissions;
}
