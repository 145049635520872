import { useNavigation } from "@react-navigation/native";
import { View, Text, ScrollView, Pressable } from "../components/basics";
import SpaceAppIcon from "../components/SpaceAppIcon";
import { useUser } from "../hooks/auth";
import usePersonFirstName from "../hooks/usePersonFirstName";
import {
  ChatCard,
  EventsCard,
  PhotosCard,
  RemindersCard,
} from "../components/app-cards";
import { useEffect } from "react";

export default function HomeScreen() {
  // const navigation = useNavigation();
  // const greeting = useGreeting();

  return (
    <View sx={{ flex: 1, backgroundColor: "$white" }}>
      <ScrollView sx={{ flex: 1 }}>
        {/* <Text
          sx={{
            fontSize: 24,
            fontFamily: "avenir",
            paddingX: "$3",
            paddingY: "$6",
            textAlign: "center",
          }}
        >
          {greeting}
        </Text> */}

        <ChatCard />
        <EventsCard />
        {/* <PhotosCard /> */}
        <RemindersCard />
      </ScrollView>
    </View>
  );
}

export function useGreeting() {
  const currentUser = useUser();
  const getName = usePersonFirstName();

  const hour = new Date().getHours();

  const isMorning = hour >= 4 && hour < 12;
  const isAfternoon = hour >= 12 && hour < 17;
  const isEvening = hour >= 17 || hour < 4;

  let message = "Hello";

  if (isMorning) {
    message = "Good morning";
  }
  if (isAfternoon) {
    message = "Good afternoon";
  }
  if (isEvening) {
    message = "Good evening";
  }

  return `${message}, ${getName(currentUser.id, false)} 👋`;
}
