import { Spinner } from "native-base";
import React, { useEffect, useState } from "react";
import { View, Text } from "react-native";
import { getThumbnailAsync } from "expo-video-thumbnails";
import * as MediaLibrary from "expo-media-library";
import Icon from "./basics/Icon";
import { Photo } from "../screens/spaces/PhotosScreen";
import { Image } from "./basics";
import * as FileSystem from "expo-file-system";
import { AttachmentState } from "../screens/spaces/ChatScreen";
import { reportError } from "../state/utils/errors";

export function ResourceVideoThumbnail({
  resource,
  width,
  height,
}: {
  resource: Photo;
  width?: number;
  height?: number;
}) {
  return (
    <VideoThumbnail
      localKey={resource.imageKey}
      videoUri={resource.uri}
      height={height}
      width={width}
    />
  );
}

export function AttachmentVideoThumbnail({
  attachment,
  width,
  height,
}: {
  attachment: AttachmentState;
  width?: number;
  height?: number;
}) {
  const [videoInfo, setVideoInfo] = useState<MediaLibrary.AssetInfo>();
  useEffect(() => {
    if (attachment.assetId) {
      MediaLibrary.getAssetInfoAsync(attachment.assetId).then((assetInfo) => {
        if (!assetInfo) return;
        setVideoInfo(assetInfo);
      });
    }
  }, []);
  return (
    <VideoThumbnail
      localKey={(attachment.assetId || attachment.id).replaceAll("/", "_")}
      videoUri={
        attachment?.localUri ||
        videoInfo?.localUri ||
        videoInfo?.uri ||
        attachment?.uri
      }
      duration={videoInfo?.duration}
      height={height}
      width={width}
    />
  );
}

export function AssetVideoThumbnail({
  asset,
  width,
  height,
}: {
  asset: MediaLibrary.Asset;
  width?: number;
  height?: number;
}) {
  const [videoInfo, setVideoInfo] = useState<MediaLibrary.AssetInfo>();
  useEffect(() => {
    MediaLibrary.getAssetInfoAsync(asset.id).then((assetInfo) => {
      if (!assetInfo) return;
      setVideoInfo(assetInfo);
    });
  }, []);
  if (!videoInfo) return null;
  return (
    <VideoThumbnail
      localKey={asset.id.replaceAll("/", "_")}
      videoUri={videoInfo?.localUri || videoInfo?.uri}
      duration={videoInfo?.duration}
      height={height}
      width={width}
    />
  );
}

function getLocalThumbnailUri(localKey: string) {
  return `${FileSystem.cacheDirectory}VideoThumbnails/${localKey}.jpg`;
}

export function VideoThumbnail({
  localKey,
  videoUri,
  duration,
  width,
  height,
}: {
  localKey: string;
  videoUri: string;
  duration?: number;
  width?: number;
  height?: number;
}) {
  // First check cache, then fallback to async thumbnail creation
  const [thumbnailUri, setThumbnailUri] = useState<string>(
    getLocalThumbnailUri(localKey)
  );
  const [fallbackAttempted, setFallbackAttempted] = useState(false);
  const [loading, setLoading] = useState(false);

  return (
    <View style={{ width, height, backgroundColor: "black" }}>
      <View
        style={{
          position: "absolute",
          width: "100%",
          left: 0,
          bottom: 0,
          zIndex: 2,
          overflow: "hidden",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          flexDirection: "row",
          padding: 4,
        }}
      >
        <Icon
          name="videocam-outline"
          style={{
            color: "white",
            textShadowColor: "black",
            textShadowRadius: 3,
          }}
          size={20}
        />
        {duration ? (
          <Text
            style={{
              color: "white",
              textShadowColor: "black",
              textShadowRadius: 3,
              textAlign: "center",
              padding: 4,
            }}
          >{`${Math.floor(duration / 60)}:${Math.floor(duration % 60)
            .toString()
            .padStart(2, "0")}`}</Text>
        ) : null}
      </View>
      {loading && <Spinner style={{ width: "100%", height: "100%" }} />}
      {thumbnailUri && (
        <Image
          key={`${thumbnailUri}${fallbackAttempted ? "_retry" : ""}`}
          source={{
            uri: thumbnailUri,
          }}
          onLoad={() => setLoading(false)}
          onError={() => {
            if (!fallbackAttempted) {
              setLoading(true);
              generateThumbnail(videoUri, localKey).then((result) => {
                if (result) setThumbnailUri(result);
                setFallbackAttempted(true);
                setLoading(false);
              });
            }
          }}
          sx={{
            height: "100%",
            width: "100%",
          }}
          style={{ zIndex: 1 }}
          alt="video thumbnail"
        />
      )}
    </View>
  );
}

const generateThumbnail = async (videoUri: string, localKey: string) => {
  try {
    console.log("GEN THUMB FOR", videoUri);
    const result = await getThumbnailAsync(videoUri, {
      time: 500,
      quality: 0.9,
    });
    const localUri = getLocalThumbnailUri(localKey);
    // Move thumbnail to known location instead of regenerating
    await FileSystem.moveAsync({ from: result.uri, to: localUri });
    return localUri;
  } catch (e) {
    reportError(e, { extra: { message: "Failed to generate thumbnail" } });
  }
};
