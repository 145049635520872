import libphonenumber, { AsYouTypeFormatter } from "google-libphonenumber";

const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();

export const phoneRegions = phoneUtil.getSupportedRegions();

export function getCountryCodeForRegion(region: string) {
  return phoneUtil.getCountryCodeForRegion(region);
}

function _parsePhoneNumberE164(phoneNumber: string) {
  const parsedPhone = getPhoneComponents(phoneNumber);
  if (parsedPhone)
    return phoneUtil.format(parsedPhone, libphonenumber.PhoneNumberFormat.E164);
}

// If you know the country code, use this
export function parsePhoneNumberWithCountryCode(
  phoneNumber: string,
  countryCode: string
) {
  const parsedPhone = getPhoneComponents(phoneNumber, countryCode);
  if (parsedPhone)
    return phoneUtil.format(parsedPhone, libphonenumber.PhoneNumberFormat.E164);
}

// If starts with +, assume of E164 format ... otherwise assume US country code
export function parsePhoneNumber(phoneNumber: string) {
  return phoneNumber.startsWith("+")
    ? _parsePhoneNumberE164(phoneNumber)
    : parsePhoneNumberWithCountryCode(phoneNumber, "US");
}

export function getPhoneComponents(phoneNumber: string, countryCode?: string) {
  try {
    return phoneUtil.parse(phoneNumber, countryCode);
  } catch {
    return undefined;
  }
}

export function getRegionCodeForCountryCode(countryCode: number) {
  return phoneUtil.getRegionCodeForCountryCode(countryCode);
}

export function parsePhoneNumberFriendly(phoneNumber: string) {
  return phoneNumber.startsWith("+")
    ? _parsePhoneNumberFriendly(phoneNumber)
    : parsePhoneNumberWithCountryCodeFriendly(phoneNumber, "US");
}

function _parsePhoneNumberFriendly(phoneNumber: string) {
  const parsedPhone = getPhoneComponents(phoneNumber);
  if (parsedPhone)
    return phoneUtil.format(
      parsedPhone,
      libphonenumber.PhoneNumberFormat.INTERNATIONAL
    );
}

export function parsePhoneNumberWithCountryCodeFriendly(
  phoneNumber: string,
  countryCode: string
) {
  const parsedPhone = getPhoneComponents(phoneNumber, countryCode);
  if (parsedPhone)
    return phoneUtil.format(
      parsedPhone,
      libphonenumber.PhoneNumberFormat.INTERNATIONAL
    );
}
