import { SxProp } from "dripsy";
import { ComponentProps, ReactNode, useEffect, useState } from "react";
import { StyleSheet } from "react-native";
import { TextInput, View } from ".";
import DRIPSY_THEME from "../../constants/DripsyTheme";

interface StyledTextInputProps extends ComponentProps<typeof TextInput> {
  leftIcon?: (props: { onFocus: () => void; onBlur: () => void }) => ReactNode;
  rightIcon?: (props: { onFocus: () => void; onBlur: () => void }) => ReactNode;
  containerSx?: SxProp;
  containerFocusedSx?: SxProp;
  textInputSx?: SxProp;
  textInputFocusedSx?: SxProp;
  forceFocusedStyles?: boolean;
}

const defaultContainerSx = {
  fontSize: 18,
  height: 45,
  borderRadius: 4,
  paddingX: "$1",
  mb: "5%",
  flexDirection: "row",
  justifyContent: "flex-start",
  alignItems: "center",
  backgroundColor: "$white",
  borderWidth: "hairline",
  borderColor: "$systemBorder",
};

const defaultFocusedContainerSx = {
  borderColor: "$primary.400",
  borderWidth: 1,
  boxShadow: "md",
};

const defaultTextInputSx = {
  fontSize: 18,
  marginX: "$3",
  flex: 1,
};

function StyledTextInput({
  sx,
  leftIcon = () => null,
  rightIcon = () => null,
  containerSx,
  containerFocusedSx = defaultFocusedContainerSx,
  textInputSx,
  textInputFocusedSx,
  forceFocusedStyles = false,
  ...props
}: StyledTextInputProps) {
  const [isInputFocused, setIsInputFocused] = useState<boolean | undefined>(
    false
  );
  const [isLeftIconFocused, setIsLeftIconFocused] = useState<
    boolean | undefined
  >(false);
  const [isRightIconFocused, setIsRightIconFocused] = useState<
    boolean | undefined
  >(false);

  const isFocused = isLeftIconFocused || isRightIconFocused || isInputFocused;

  useEffect(() => {
    if (isFocused === true && props.onFocus) props.onFocus();
    if (!isFocused === false && props.onBlur) props.onBlur();
  }, [isFocused]);
  return (
    <View
      sx={{
        ...defaultContainerSx,
        ...sx,
        ...containerSx,
        ...(isFocused || forceFocusedStyles
          ? { ...defaultFocusedContainerSx, ...containerFocusedSx }
          : {}),
      }}
    >
      {leftIcon({
        onFocus: () => {
          setIsLeftIconFocused(true);
        },
        onBlur: () => {
          setIsLeftIconFocused(false);
        },
      })}
      <TextInput
        placeholderTextColor={DRIPSY_THEME.colors.$gray[400]}
        onBlur={() => {
          setIsInputFocused(false);
        }}
        onFocus={() => {
          setIsInputFocused(true);
        }}
        sx={{
          ...defaultTextInputSx,
          ...textInputSx,
          ...(isFocused || forceFocusedStyles ? textInputFocusedSx : {}),
        }}
        {...props}
      />
      {rightIcon({
        onFocus: () => {
          setIsRightIconFocused(true);
        },
        onBlur: () => {
          setIsRightIconFocused(false);
        },
      })}
    </View>
  );
}

export default StyledTextInput;
