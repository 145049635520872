import { atom, AtomEffect } from "recoil";
import client from "../graphql/client";
import { pipe, subscribe } from "wonka";
import {
  GetConnectedProfilesDocument,
  ProfilesInfoFragment,
} from "../generated/graphql";

type PeopleState = Map<string, ProfilesInfoFragment>;

const peopleSyncEffect = (): AtomEffect<PeopleState> => {
  return ({ setSelf }) => {
    const spaces_subscription = pipe(
      client.query(GetConnectedProfilesDocument),
      subscribe(({ data, error }) => {
        if (data) {
          setSelf(new Map(data.profiles.map((prof) => [prof.id, prof])));
        }
      })
    );
    return () => {
      spaces_subscription.unsubscribe();
    };
  };
};

export const peopleState = atom<PeopleState>({
  key: "people-state", // unique ID (with respect to other atoms/selectors)
  default: new Map(), // default value (aka initial value)
  effects_UNSTABLE: [peopleSyncEffect()],
});
