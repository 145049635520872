import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { Button, KeyboardAvoidingView, View } from "../components/basics";
import { useToast } from "../hooks/useToast";
import React, { useCallback, useMemo, useState } from "react";
import { Platform } from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";
import SpaceMemberSelector, {
  SelectedMemberItem,
  ProfileItem,
} from "../components/SpaceMemberSelector";
import { useUser } from "../hooks/auth";
import { inviteToSpace } from "../lib/invitations";
import { RootStackParamList } from "../types";
import { parsePhoneNumber } from "../utils/phone";
import { useHeaderHeight } from "@react-navigation/elements";

export default function AddMemberModalScreen({
  route,
  navigation,
}: NativeStackScreenProps<RootStackParamList, "AddMemberModal">) {
  const { spaceId } = route.params;
  const currentUser = useUser();
  const [memberSelections, setMemberSelections] = useState<
    SelectedMemberItem[]
  >([]);
  const [isInvitingMembers, setIsInvitingMembers] = useState(false);
  const buttonText = useMemo(() => {
    return memberSelections.length > 1
      ? `Invite ${memberSelections.length}`
      : "Invite";
  }, [memberSelections]);
  const addToast = useToast();

  const handleAddMembersPress = useCallback(async () => {
    try {
      setIsInvitingMembers(true);
      const profileSubmissions = memberSelections.filter(
        (s) => s.key === "PROFILE"
      ) as ProfileItem[];

      const phoneNumbers = memberSelections.reduce<string[]>(
        (phoneSubmissions, submission) => {
          if (submission.key === "PHONE") {
            const parsedPhone = parsePhoneNumber(submission.data.phone);
            if (parsedPhone) phoneSubmissions.push(parsedPhone);
          }

          return phoneSubmissions;
        },
        []
      );

      const { error: invitationError } = await inviteToSpace(
        spaceId,
        currentUser!.id,
        profileSubmissions.map((s) => s.id),
        phoneNumbers.map((phone) => ({ type: "PHONE", value: phone }))
      );

      if (invitationError) {
        addToast({
          description: "Could not send invitations",
          variant: "ERROR",
        });
        return;
      }

      navigation.goBack();
    } finally {
      setIsInvitingMembers(false);
    }
  }, [memberSelections, currentUser, addToast]);

  const headerHeight = useHeaderHeight();

  return (
    <SafeAreaView style={{ flex: 1 }} edges={["bottom"]}>
      <View sx={{ flex: 1 }}>
        <SpaceMemberSelector
          data={memberSelections}
          textInputLabel={"Invite:"}
          onAdd={(selection) =>
            setMemberSelections((prev) => [...prev, selection])
          }
          onRemove={(selection) =>
            setMemberSelections((prev) =>
              prev.filter(
                (s) => !(s.key === selection.key && s.id === selection.id)
              )
            )
          }
        />
        <KeyboardAvoidingView
          behavior={Platform.OS === "ios" ? "padding" : "height"}
          keyboardVerticalOffset={headerHeight}
        >
          <Button
            onPress={handleAddMembersPress}
            isLoading={isInvitingMembers}
            disabled={memberSelections.length === 0}
            sx={{ borderRadius: 0 }}
          >
            {buttonText}
          </Button>
        </KeyboardAvoidingView>
      </View>
    </SafeAreaView>
  );
}
