import { Contact } from "expo-contacts";
import { ProfilesInfoFragment } from "../state/generated/graphql";

interface PersonBase {
  id: string;
  image?: string;
  name: string;
}

export interface ContactItem extends PersonBase {
  key: "CONTACT";
  data: Contact;
}

export interface ProfileItem extends PersonBase {
  key: "PROFILE";
  data: ProfilesInfoFragment;
}

export interface PhoneItem extends PersonBase {
  key: "PHONE";
  data: string;
}

export type Connection = ContactItem | ProfileItem;
export type Person = ContactItem | ProfileItem | PhoneItem;

export function profileToPerson(profile: ProfilesInfoFragment): ProfileItem {
  return {
    key: "PROFILE",
    id: profile.id,
    image: profile.profile_image_path,
    name: profile.name,
    data: profile,
  };
}

export function contactToPerson(contact: Contact): ContactItem {
  return {
    key: "CONTACT",
    id: contact.id,
    image: contact.image?.uri,
    name: contact.name,
    data: contact,
  };
}

export function phoneToPerson(phone: string): PhoneItem {
  return {
    key: "PHONE",
    id: phone,
    name: phone,
    data: phone,
  };
}
