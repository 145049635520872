import ResourceCollection from "./resource";

export interface ScheduleItem {
  title: string;
  description: string;
  start_date_utc: string;
  end_date_utc: string;
  all_day: boolean;
  all_day_start: string;
  all_day_end: string;
  reminder_offset_minutes?: number;
  time_zone: string;
}

export const SCHEDULE_RESOURCE_KEY = "SCHEDULE";

export interface ScheduleItemNewEventParams {
  title: string;
  description: string;
  start_date_utc: string;
  end_date_utc: string;
  all_day: boolean;
  all_day_start: string;
  all_day_end: string;
  reminder_offset_minutes?: number;
  time_zone: string;
}

const scheduleResource = new ResourceCollection<
  ScheduleItem,
  {
    NEW: ScheduleItemNewEventParams;
    EDIT_ITEM: {
      title: string;
      description: string;
      start_date_utc: string;
      end_date_utc: string;
      all_day: boolean;
      all_day_start: string;
      all_day_end: string;
      time_zone: string;
    };
  }
>(SCHEDULE_RESOURCE_KEY, {
  NEW: (_resource, { data }) =>
    sanitizeResource({
      ...data,
    }),
  EDIT_ITEM: (resource, { data }) =>
    sanitizeResource({
      ...resource,
      ...data,
    }),
});

function sanitizeResource(resource: any) {
  if (resource.all_day) {
    resource.reminder_offset_minutes = undefined;
  }

  return resource;
}

export default scheduleResource;
