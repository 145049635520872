import ResourceCollection from "./resource";

export interface ChatMessage {
  text: string;
  sender_id: string;
  attachment?: string | string[];
  is_local?: boolean;
  text_user_ids?: string[];
  is_notice?: boolean;
  reactions?: Record<string, string[]>;
}

export const CHAT_RESOURCE_KEY = "CHAT";

const chatResource = new ResourceCollection<
  ChatMessage,
  {
    NEW: {
      text: string;
      attachment?: string;
      is_notice?: boolean;
      text_user_ids?: string[];
    };
    ADD_REACTION: {
      type: string;
      user_id: string;
    };
    REMOVE_REACTION: {
      type: string;
      user_id: string;
    };
  }
>(CHAT_RESOURCE_KEY, {
  NEW: (_resource, { data, user_id }, fromServer) => {
    return {
      text: data.text,
      attachment: data.attachment,
      sender_id: user_id,
      is_notice: data.is_notice ?? false,
      text_user_ids: data.text_user_ids ?? [],
      is_local: !fromServer,
    };
  },
  ADD_REACTION: (resource, { data }, fromServer) => {
    const reactions = { ...(resource.reactions ?? {}) };
    // What happens if I leave/am removed from a group?
    const { type, user_id } = data;
    if (!reactions[type]) reactions[type] = [];
    if (!reactions[type].includes(user_id)) reactions[type].push(user_id);
    return { ...resource, reactions };
  },
  REMOVE_REACTION: (resource, { data }, fromServer) => {
    const reactions = { ...(resource.reactions ?? {}) };
    const { type, user_id } = data;
    if (reactions[type])
      reactions[type] = reactions[type].filter((uid) => uid !== user_id);

    return { ...resource, reactions };
  },
});

export default chatResource;
