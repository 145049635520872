import { useCallback, useState } from "react";
import {
  FlatListProps,
  ListRenderItem,
  ListRenderItemInfo,
} from "react-native";
import { FlatList } from "./basics";

interface ImageListRenderItemInfo<T> extends ListRenderItemInfo<T> {
  size: number;
}

export type ImageListRenderItem<T> = (
  info: ImageListRenderItemInfo<T>
) => React.ReactElement | null;

interface ImageFlatlistProps<T> extends Omit<FlatListProps<T>, "renderItem"> {
  renderItem: ImageListRenderItem<T> | null | undefined;
}

export function ImageFlatlist<T>(props: ImageFlatlistProps<T>) {
  const [rowSize, setRowSize] = useState(0);
  const renderItem: ListRenderItem<T> = useCallback(
    (renderProps) =>
      props.renderItem({
        ...renderProps,
        size: rowSize / (props.numColumns || 1),
      }),
    [props.renderItem, props.numColumns, rowSize]
  );
  return (
    <FlatList
      {...props}
      renderItem={renderItem}
      estimatedListSize={rowSize}
      onLayout={(e) => {
        props.onLayout && props.onLayout(e);
        setRowSize(e.nativeEvent.layout.width);
      }}
    />
  );
}
