import { Button, View, Text, Spinner } from "../components/basics";
import React, { useEffect, useState } from "react";
import supabase from "../lib/supabase";

export default function LoadingScreen() {
  const [shouldShowSignOut, setShouldShowSignOut] = useState(false);
  useEffect(() => {
    const timeout = setTimeout(() => {
      setShouldShowSignOut(true);
    }, 4000);
    return () => {
      clearTimeout(timeout);
    };
  }, []);
  return (
    <View
      style={{
        height: "100%",
        justifyContent: "space-around",
        alignItems: "center",
      }}
    >
      {shouldShowSignOut ? (
        <View sx={{ margin: "$8" }}>
          <Text>Having issues?</Text>
          <Button onPress={() => supabase.auth.signOut()}>Sign Out</Button>
        </View>
      ) : null}
      <View>
        <Spinner size="lg" />
        <Text>Loading profile...</Text>
      </View>
    </View>
  );
}
