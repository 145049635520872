import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Linking, StyleSheet, TextStyle } from "react-native";
// @ts-ignore
import ParsedText, { PATTERNS } from "react-native-parsed-text";
import Communications from "react-native-communications";
import { IMessage, MessageTextProps } from "react-native-gifted-chat";
import { Column, Image, Pressable, Row, Text, View, Skeleton } from "../basics";
import { Ionicons } from "@expo/vector-icons";
import Constants from "expo-constants";
import { useActionSheet } from "@expo/react-native-action-sheet";
import { reportError } from "../../state/utils/errors";
import DRIPSY_THEME from "../../constants/DripsyTheme";
import linkPreviewCache from "../../state/caches/link-preview-cache";

const LINK_PREVIEW_BASE_URL = Constants.manifest?.extra?.LINK_PREVIEW_BASE_URL;
const HAS_SCHEME_REGEX = /^[a-zA-Z]+:\/\//;

const textStyle = {
  fontSize: 17,
  lineHeight: 20,
  marginBottom: 4,
};

const styles = {
  left: StyleSheet.create({
    container: {},
    text: {
      color: "black",
      ...textStyle,
    },
    link: {
      color: "black",
      textDecorationLine: "underline",
    },
  }),
  right: StyleSheet.create({
    container: {},
    text: {
      color: "white",
      ...textStyle,
    },
    link: {
      color: "white",
      textDecorationLine: "underline",
    },
  }),
};

const DEFAULT_OPTION_TITLES = ["Call", "Text", "Cancel"];

export function MessageText<TMessage extends IMessage = IMessage>(
  props: MessageTextProps<TMessage>
) {
  const { showActionSheetWithOptions } = useActionSheet();
  const onUrlPress = useCallback((url: string) => {
    const fullURL = !HAS_SCHEME_REGEX.test(url) ? "https://" + url : url;
    try {
      Linking.openURL(fullURL);
    } catch (e) {
      reportError(e as Error);
    }
  }, []);

  const onPhonePress = useCallback(
    (phone: string) => {
      const options =
        props.optionTitles && props.optionTitles.length > 0
          ? props.optionTitles.slice(0, 3)
          : DEFAULT_OPTION_TITLES;
      const cancelButtonIndex = options.length - 1;
      // TODO: handle phone
      showActionSheetWithOptions(
        {
          options,
          cancelButtonIndex,
        },
        (buttonIndex) => {
          switch (buttonIndex) {
            case 0:
              Communications.phonecall(phone, true);
              break;
            case 1:
              Communications.text(phone);
              break;
            default:
              break;
          }
        }
      );
    },
    [props.optionTitles]
  );

  const onEmailPress = useCallback(
    (email: string) => Communications.email([email], null, null, null, null),
    []
  );

  const linkStyle = [
    styles[props.position].link,
    props.linkStyle && props.linkStyle[props.position],
  ];

  return (
    <View
      style={[
        styles[props.position].container,
        props.containerStyle && props.containerStyle[props.position],
      ]}
    >
      <ParsedText
        style={[
          styles[props.position].text,
          props.textStyle && props.textStyle[props.position],
          props.customTextStyle,
        ]}
        parse={[
          ...(props.parsePatterns
            ? props.parsePatterns(linkStyle as TextStyle)
            : []),
          { type: "url", style: linkStyle, onPress: onUrlPress },
          { type: "phone", style: linkStyle, onPress: onPhonePress },
          { type: "email", style: linkStyle, onPress: onEmailPress },
        ]}
        childrenProps={{ ...props.textProps }}
      >
        {props.currentMessage!.text}
      </ParsedText>
      <LinkPreview text={props.currentMessage!.text} onPress={onUrlPress} />
    </View>
  );
}

interface PreviewInfo {
  title: string;
  img?: string;
  description?: string;
  favicon?: string;
  url: string;
}

async function getLinkPreview(uri: string) {
  const cacheHit = await linkPreviewCache.get(uri);
  if (cacheHit) return JSON.parse(cacheHit);

  const networkResponse = await fetch(
    `${LINK_PREVIEW_BASE_URL}/api/link-preview?url=${encodeURIComponent(uri)}`
  );
  if (networkResponse.status === 200) {
    const pageInfo = await networkResponse.json();
    await linkPreviewCache.set(uri, JSON.stringify(pageInfo));
    return pageInfo;
  } else {
    return {};
  }
}

function LinkPreview({
  text,
  onPress,
}: {
  text: string;
  onPress: (url: string) => void;
}) {
  const [loadingPreview, setLoadingPreview] = useState(false);
  const [previewInfo, setPreviewInfo] = useState<PreviewInfo>();
  const match = useMemo(() => {
    const matches = text.match(PATTERNS.url);
    return (matches ?? [])[0];
  }, [text]);

  useEffect(() => {
    (async () => {
      if (match) {
        const previewInfo: PreviewInfo = {
          title: match,
          url: match,
        };
        try {
          setLoadingPreview(true);
          const pageInfo = await getLinkPreview(match);
          previewInfo.title = pageInfo.title ?? previewInfo.title;
          previewInfo.description = pageInfo.description;
          previewInfo.img = pageInfo.img;
          previewInfo.favicon = pageInfo.favicon;
        } catch (e) {
          // TODO: handle error
          reportError(e);
        } finally {
          setPreviewInfo(previewInfo);
          setLoadingPreview(false);
        }
      }
    })();
  }, [match]);

  const imageSize = 100;
  const faviconSize = 10;

  return loadingPreview ? (
    <View
      sx={{
        borderRadius: "md",
        overflow: "hidden",
        maxWidth: "100%",
        marginBottom: 8,
        width: 600,
        flexDirection: "row",
        backgroundColor: "$gray.200",
      }}
    >
      <Skeleton
        sx={{
          height: imageSize,
          width: imageSize,
          borderRadius: 0,
        }}
      />
      <View
        sx={{
          flexGrow: 1,
          padding: "$2",
        }}
      >
        <Skeleton
          sx={{
            height: 36,
          }}
        />
        <Skeleton
          sx={{
            mt: "$2",
            height: 16,
          }}
        />
        <Skeleton
          sx={{
            mt: "$2",
            height: 16,
          }}
        />
      </View>
    </View>
  ) : previewInfo ? (
    <Pressable
      onPress={() => onPress(previewInfo.url)}
      style={({ pressed }) => ({
        backgroundColor: pressed
          ? DRIPSY_THEME["colors"]["$gray"]["400"]
          : DRIPSY_THEME["colors"]["$gray"]["200"],
      })}
      sx={{
        borderRadius: "md",
        overflow: "hidden",
        maxWidth: "100%",
        marginBottom: 8,
        width: 600,
      }}
    >
      <Row>
        {!!previewInfo.img && (
          <Image
            source={{
              uri: previewInfo.img,
            }}
            sx={{ width: imageSize, height: imageSize }}
            alt="preview image"
          />
        )}
        <Column padding="2" flex={1}>
          <Text sx={{ fontSize: "md" }} numberOfLines={2}>
            {previewInfo.title}
          </Text>
          {!!previewInfo.description && (
            <Text sx={{ fontSize: "xs", color: "$gray.600" }} numberOfLines={2}>
              {previewInfo.description}
            </Text>
          )}
          <Row alignItems={"center"} space="1">
            {previewInfo.favicon ? (
              <Image
                source={{
                  uri: previewInfo.favicon,
                }}
                sx={{ width: faviconSize, height: faviconSize }}
                alt="favicon"
              />
            ) : (
              <Ionicons name="globe-outline" size={faviconSize} />
            )}

            <Text
              sx={{ fontSize: "xs", color: "$gray.600", fontStyle: "italic" }}
              numberOfLines={1}
            >
              {previewInfo.url}
            </Text>
          </Row>
        </Column>
      </Row>
    </Pressable>
  ) : (
    <></>
  );
}
