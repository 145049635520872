import { useNavigation } from "@react-navigation/native";
import React, { useMemo, useEffect, useState } from "react";
import { FlatListProps, ListRenderItem } from "react-native";
import { Button, FlatList, Icon } from "../components/basics";
import { useUser } from "../hooks/auth";
import { useSpacesQuery } from "../hooks/spaces";
import { useChatList } from "../hooks/useChatList";
import { useResourcePermissions } from "../hooks/useResourcePermissions";
import useSharedNavigationValue from "../hooks/useSharedNavigationValue";
import { SpaceCreationParams } from "../state/atoms/spaces";
import { SpaceInfoFragment } from "../state/generated/graphql";
import SimpleSpaceListItem, {
  PendingSpaceListItem,
} from "./SimpleSpaceListItem";

interface ActiveResourceShareSpace {
  type: "ACTIVE";
  data: SpaceInfoFragment;
}

interface PendingResourceShareSpace {
  type: "PENDING";
  data: SpaceCreationParams;
}

type ResourceShareSpace = ActiveResourceShareSpace | PendingResourceShareSpace;

export function ResourceShareList({
  resourceId,
  resourceKey,
  spaceIds,
  onSelectionChange,
  flatListProps,
}: {
  resourceId: string;
  resourceKey: string;
  spaceIds?: string[];
  onSelectionChange: (
    selectedSpaceIds: Set<string>,
    mockSpaces: SpaceCreationParams[]
  ) => void;
  flatListProps?: Partial<FlatListProps<any>>;
}) {
  const [{ data: spacesResp }] = useSpacesQuery();
  const [{ data: chatList }] = useChatList();
  const spaces = spacesResp?.spaces ?? [];

  const spaceIdSet = useMemo(
    () => (spaceIds ? new Set(spaceIds) : undefined),
    [spaceIds]
  );

  const currentUser = useUser();
  const filteredSpaces = useMemo(() => {
    const externalSpaces = spaces.filter(
      (s) => s.private_space_owner_id !== currentUser!.id
    );
    if (!spaceIdSet) return externalSpaces;
    return externalSpaces.filter((s) => spaceIdSet.has(s.id));
  }, [spaceIds, spaceIdSet, currentUser]);

  const mockSpacesSharedId = "resource_share_created_spaces";
  const [mockSpaces, setMockSpaces, evictMockSpaces] = useSharedNavigationValue<
    SpaceCreationParams[]
  >(mockSpacesSharedId, []);
  useEffect(() => {
    return () => {
      evictMockSpaces();
    };
  }, [evictMockSpaces]);

  const orderedSpaces = useMemo<ResourceShareSpace[]>(() => {
    const chatIndices = new Map(chatList?.chat_list.map((cl, i) => [cl.id, i]));
    const pendingSpaces = (mockSpaces ?? []).map((s) => ({
      type: "PENDING" as const,
      data: s,
    }));
    const activeSpaces = filteredSpaces
      .slice()
      .sort((a, b) => {
        const aIndex = chatIndices.get(a.id) ?? chatIndices.size;
        const bIndex = chatIndices.get(b.id) ?? chatIndices.size;
        let res = 0;
        if (!res) res = aIndex - bIndex;
        if (!res) res = a.id - b.id;
        return res;
      })
      .map((s) => ({ type: "ACTIVE" as const, data: s }));
    return [...pendingSpaces, ...activeSpaces];
  }, [filteredSpaces, chatList, mockSpaces]);

  const resourcePermissions = useResourcePermissions(resourceKey, resourceId);
  const [selectedSpaces, setSelectedSpaces] = useState<Set<string>>(new Set());
  useEffect(() => {
    onSelectionChange(selectedSpaces, mockSpaces);
  }, [onSelectionChange, selectedSpaces, mockSpaces]);
  const extraData = useMemo(
    () => ({
      resourcePermissions: resourcePermissions.data?.resource_permissions,
      selectedSpaces,
    }),
    [resourcePermissions, selectedSpaces]
  );

  const renderItem: ListRenderItem<ResourceShareSpace> = ({ item }) => {
    if (item.type === "PENDING") {
      return (
        <PendingSpaceListItem
          space={item.data}
          handlePress={() => {
            setMockSpaces(mockSpaces.filter((s) => s !== item.data));
          }}
        />
      );
    } else if (item.type === "ACTIVE") {
      return (
        <SimpleSpaceListItem
          space={item.data}
          handlePress={() => {
            setSelectedSpaces((prev) => {
              if (prev.has(item.data.id)) {
                prev.delete(item.data.id);
                return new Set(prev);
              }
              return new Set(prev.add(item.data.id));
            });
          }}
          selectedSpaces={extraData.selectedSpaces}
        />
      );
    } else {
      return null;
    }
  };

  const navigation = useNavigation();

  function keyExtractor(item, i) {
    switch (item.type) {
      case "ACTIVE":
        return item.data.id;
      case "PENDING":
      default:
        return i;
    }
  }

  return (
    <>
      <Button
        variant="outline"
        onPress={() => {
          navigation.navigate("CreateMockSpace");
        }}
        sx={{
          width: 130,
          paddingY: "$1",
          borderRadius: 50,
          marginRight: "$4",
          alignSelf: "flex-end",
          borderColor: "$primary.400",
        }}
        rightIcon={"add"}
      >
        New Space
      </Button>
      {/* Hacking this together until BottomSheetFlatList is fixed (https://github.com/gorhom/react-native-bottom-sheet/issues/1008)
        Expected behavior of BottomSheetFlatList is the drawer will continue to open until the last snap point 
        Going to try to do some hacking with flatlist and a bottom offset
      */}
      <FlatList
        {...flatListProps}
        estimatedItemSize={81}
        data={orderedSpaces}
        extraData={extraData}
        renderItem={renderItem}
        keyExtractor={keyExtractor}
      />
    </>
  );
}

export default ResourceShareList;
