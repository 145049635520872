import type { AndroidNativeProps } from "@react-native-community/datetimepicker";
import { format } from "date-fns";
import { useCallback, useState } from "react";
import { Platform } from "react-native";
import { Text, Pressable } from "./";
const RNDateTimePicker =
  Platform.OS === "web"
    ? undefined
    : require("@react-native-community/datetimepicker").default;

function DateTimePicker(props: any) {
  if (Platform.OS === "web") {
    return <input type="datetime-local" {...props} />;
  }
  if (Platform.OS === "android") {
    return <AndroidDateTimePicker {...props} />;
  }
  return <RNDateTimePicker themeVariant="light" {...props} />;
}

function AndroidDateTimePicker(props: AndroidNativeProps) {
  const { mode, value } = props;
  const [isActive, setIsActive] = useState(false);
  const displayText = mode === "date" ? format(value, "P") : format(value, "p");
  const onChange = useCallback(
    (...args: Parameters<NonNullable<typeof props.onChange>>) => {
      setIsActive(false);
      if (props.onChange) props.onChange(...args);
    },
    [props.onChange]
  );
  return (
    <>
      {isActive && <RNDateTimePicker {...props} onChange={onChange} />}
      <Pressable
        sx={{
          padding: "$2",
          backgroundColor: "$coolGray.100",
          borderRadius: "sm",
          boxShadow: "$1",
        }}
        onPress={() => {
          setIsActive(true);
        }}
      >
        <Text>{displayText}</Text>
      </Pressable>
    </>
  );
}

export default DateTimePicker;
