import { View } from "../basics";
import {
  isSameUser,
  Message as ChatMessage,
  MessageProps,
} from "react-native-gifted-chat";
import { AspenChatMessage } from "../../screens/spaces/ChatScreen";

export default function Message(props: MessageProps<AspenChatMessage>) {
  const { currentMessage } = props;
  const isEndOfBlock =
    !props.nextMessage ||
    props.nextMessage.system ||
    !isSameUser(currentMessage, props.nextMessage);

  return (
    <View>
      <ChatMessage
        containerStyle={{
          left: {
            marginLeft: 2,
            marginBottom: isEndOfBlock ? 10 : 2,
          },
          right: {
            marginRight: 2,
            marginBottom: isEndOfBlock ? 10 : 2,
          },
        }}
        {...props}
      />
    </View>
  );
}
