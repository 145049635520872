import supabase from "../lib/supabase";
import {
  GetResourceByIdDocument,
  LogEventFragment as LogEvent,
  PushEventDocument,
  PushEventsDocument,
} from "../state/generated/graphql";
import client from "../state/graphql/client";
import { reportError } from "../state/utils/errors";
import { getDeviceIdSync } from "./device-id";

const latestResourceEventsCache: Map<
  string,
  { order: number; space_session: number }
> = new Map();

type PushEventPayload = Pick<
  LogEvent,
  | "resource_key"
  | "type"
  | "data"
  | "resource_id"
  | "collection_id"
  | "space_id"
>;

function getAndValidateEventConstants() {
  const userId = supabase.auth.user()?.id;
  if (!userId) throw new Error("Missing user.");
  const deviceId = getDeviceIdSync();
  if (!deviceId) throw new Error("Missing device id.");
  return { userId, deviceId };
}

function hydrateEvent(
  deviceId: string,
  userId: string,
  event: PushEventPayload
) {
  if (!event?.space_id) throw new Error("Couldn't assign event to a space");
  const resourceId = event.resource_id;
  const collectionKey = event.resource_key;
  let inMemoryLatestEventInfo = latestResourceEventsCache.get(resourceId);
  const cachedResourceResp = client.readQuery(GetResourceByIdDocument, {
    collection_key: collectionKey,
    id: resourceId,
  });
  if (cachedResourceResp?.error) {
    throw new Error(
      cachedResourceResp?.error?.message ??
        "Error fetching latest events in space."
    );
  }
  const cachedResource = cachedResourceResp?.data?.resources_by_pk;
  const resource_session = (cachedResource?.session_num ?? 0) + 1;
  if (
    !inMemoryLatestEventInfo ||
    resource_session > inMemoryLatestEventInfo.space_session
  ) {
    latestResourceEventsCache.set(resourceId, {
      order: resource_session + 1,
      space_session: resource_session,
    });
    inMemoryLatestEventInfo = latestResourceEventsCache.get(resourceId);
  }

  const maxOrder = inMemoryLatestEventInfo!.order;
  const space_session = maxOrder;
  const order = space_session + 1;

  latestResourceEventsCache.set(resourceId, { order, space_session });
  return {
    ...event,
    user_id: userId,
    space_session,
    order,
    localId: [event.space_id, space_session, deviceId, order].join("|"),
    user_key: userId,
    sender_key: deviceId,
    collection_id: event.collection_id ?? null,
  };
}

export async function pushEvent(event: PushEventPayload) {
  const { deviceId, userId } = getAndValidateEventConstants();
  const fullEvent = hydrateEvent(deviceId, userId, event);

  const insertResp = await client
    .mutation(PushEventDocument, {
      event: fullEvent,
    })
    .toPromise();

  if (insertResp.error) {
    reportError(insertResp.error, {
      extra: {
        fullEvent,
      },
    });
  }

  return insertResp;
}

export async function pushEvents(events: PushEventPayload[]) {
  const { deviceId, userId } = getAndValidateEventConstants();
  const fullEvents = [];
  for (const event of events) {
    try {
      fullEvents.push(hydrateEvent(deviceId, userId, event));
    } catch (e) {
      throw new Error("PUSH FAILED");
    }
  }

  const insertResp = await client
    .mutation(PushEventsDocument, {
      events: fullEvents,
    })
    .toPromise();

  if (insertResp.error) {
    reportError(insertResp.error, {
      extra: {
        fullEvents,
      },
    });
  }

  return insertResp;
}

const event_queue: PushEventPayload[][] = [];

export function pushEventQueue(events: PushEventPayload[]) {
  event_queue.unshift(events);
  executeQueue();
}

let is_executing = false;
async function executeQueue() {
  if (is_executing) return;
  is_executing = true;
  while (event_queue.length > 0) {
    const events = event_queue.pop();
    if (!events) break;
    await pushEvents(events);
  }

  is_executing = false;
}
