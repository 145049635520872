import AsyncStorage from "@react-native-async-storage/async-storage";
import Resource from "../resources/resource";
import supabase from "./supabase";

const PENDING_RESOURCES_KEY = "pending_resources";

export async function getPendingResources() {
  return JSON.parse(
    (await AsyncStorage.getItem(PENDING_RESOURCES_KEY)) ?? "[]"
  ) as any[];
}

async function addPendingResources(newResources: any[]) {
  const resources = await getPendingResources();
  await AsyncStorage.setItem(
    PENDING_RESOURCES_KEY,
    JSON.stringify([...resources, ...newResources])
  );
  updatePendingResourceChangeListeners(newResources);
}

export async function evict(ids: string[]) {
  if (!ids?.length) return;
  const resources = await getPendingResources();
  await AsyncStorage.setItem(
    PENDING_RESOURCES_KEY,
    JSON.stringify(resources.filter((r) => !ids.includes(r.id)))
  );
  updatePendingResourceRemoveListeners(ids);
}

export async function evictAllExcept(ids: string[]) {
  if (!ids?.length) return;
  const resources = await getPendingResources();
  const toKeep = resources.filter((r) => ids.includes(r.id));
  const toRemove = resources.filter((r) => !ids.includes(r.id));
  await AsyncStorage.setItem(PENDING_RESOURCES_KEY, JSON.stringify(toKeep));
  updatePendingResourceRemoveListeners(toRemove.map((r) => r.id));
}

export async function pushPendingEvents(events: any[]) {
  const user_id = supabase.auth.user()!.id;
  const hydratedEvents = events.map((event) => ({ ...event, user_id }));
  const eventsByResource = hydratedEvents.reduce<Record<string, any[]>>(
    (groupedEvents, event) => {
      const key = `${event.resource_key}|${event.resource_id}|${event.space_id}`;
      if (!(key in groupedEvents)) groupedEvents[key] = [];
      groupedEvents[key].push(event);
      return groupedEvents;
    },
    {}
  );
  const pendingResources = await getPendingResources();
  const inserts = [];
  for (const [eventsKey, eventSet] of Object.entries(eventsByResource)) {
    const [resource_key, resource_id, space_id] = eventsKey.split("|");
    const existingResource = pendingResources.find(
      (r) => r.id === resource_id && r.collection_key === resource_key
    );

    const { data: reducedData, deleted } = Resource.reduceEvents(
      existingResource?.data,
      eventSet,
      false
    );

    if (deleted) {
      // TODO: handle deleted
    } else if (existingResource) {
      // TODO: handle update
    } else {
      // handle insert
      const newResource = {
        id: resource_id,
        collection_key: resource_key,
        data: reducedData,
        space_id,
        updated_at: new Date().toISOString(),
        created_at: new Date().toISOString(),
        _is_pending: true,
        resource_permissions: [
          {
            created_at: new Date().toISOString(),
            resource_id,
            resource_key,
            space_id,
          },
        ],
      };
      inserts.push(newResource);
    }
  }
  await addPendingResources(inserts);
}

let pendingResourceChangeListeners: ((resources: any[]) => void)[] = [];
export function addPendingResourceChangeListener(
  listener: (resources: any[]) => void
) {
  pendingResourceChangeListeners.push(listener);
}
export function removePendingResourceChangeListener(
  listener: (resources: any[]) => void
) {
  pendingResourceChangeListeners = pendingResourceChangeListeners.filter(
    (callback) => callback !== listener
  );
}
function updatePendingResourceChangeListeners(resources: any[]) {
  pendingResourceChangeListeners.forEach((callback) => callback(resources));
}

let pendingResourceRemoveListeners: ((resourceIds: string[]) => void)[] = [];
export function addPendingResourceRemoveListener(
  listener: (resourceIds: string[]) => void
) {
  pendingResourceRemoveListeners.push(listener);
}
export function removePendingResourceRemoveListener(
  listener: (resourceIds: string[]) => void
) {
  pendingResourceRemoveListeners = pendingResourceRemoveListeners.filter(
    (callback) => callback !== listener
  );
}
function updatePendingResourceRemoveListeners(resourceIds: string[]) {
  pendingResourceRemoveListeners.forEach((callback) => callback(resourceIds));
}
