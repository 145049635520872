import ResourceCollection from "./resource";

export interface Photo {
  key: string;
  caption?: string;
}

export const PHOTO_RESOURCE_KEY = "PHOTOS";

const photoResource = new ResourceCollection<
  Photo,
  {
    NEW: {
      key: string;
      caption?: string;
      type: "photo" | "video";
    };
  }
>(PHOTO_RESOURCE_KEY, {
  NEW: (_resource, { data }, fromServer) => {
    return {
      key: data.key,
      caption: data.caption,
      type: data.type,
    };
  },
});

export default photoResource;
