import { getFileName } from "./path";
import * as FileSystem from "expo-file-system";
import { FFmpegKit } from "ffmpeg-kit-react-native";
import { copyAsync } from "expo-file-system";
import { ensureDirExists } from "./file-system";

const CONVERSION_DIR = `${FileSystem.cacheDirectory}video-conversions`;

export async function getMp4Conversion(uri: string): Promise<string> {
  const fileNameWithExtension = getFileName(uri);
  const fileName = fileNameWithExtension.split(".")[0];
  const tempURI = `${CONVERSION_DIR}/${fileNameWithExtension}`;
  const targetURI = `${CONVERSION_DIR}/${fileName}.mp4`;
  await ensureDirExists(CONVERSION_DIR);
  await copyAsync({ from: uri, to: tempURI });
  try {
    await FFmpegKit.execute(`-y -i ${uri} ${targetURI}`);
  } catch (e) {
    throw new Error("Failed to convert the video");
  }
  return targetURI;
}
