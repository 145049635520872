import {
  Button,
  Column,
  FlatList,
  Row,
  Text,
  Image,
  View,
  ScrollView,
  IconButton,
  Circle,
  TextInput,
} from "../components/basics";
import { useToast } from "../hooks/useToast";
import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { SectionItemWrapper } from "./MemberItems";
import {
  parsePhoneNumber,
  parsePhoneNumberFriendly,
  parsePhoneNumberWithCountryCode,
} from "../utils/phone";
import Icon from "./basics/Icon";
import { useAssets } from "expo-asset";
import { THEMES } from "../hooks/theme";
import {
  Keyboard,
  ListRenderItem,
  Platform,
  TextInputKeyPressEventData,
} from "react-native";
import DRIPSY_THEME from "../constants/DripsyTheme";
import { useAllPeople, useConnections } from "../hooks/useConnections";
import PersonAvatar from "./PersonAvatar";
import { useContacts } from "../hooks/useContacts";
import { Person, ProfileItem, ContactItem } from "../utils/people";
import { NativeEvent } from "react-native-reanimated/lib/types/lib/reanimated2/commonTypes";
import { Sx } from "dripsy";
import { useUser } from "../hooks/auth";

const SelectedMemberWrapper: React.FC<{ onRemove: () => void; sx: Sx }> = ({
  children,
  onRemove,
  sx,
}) => {
  return (
    <View
      sx={{
        display: "flex",
        flexDirection: "row",
        backgroundColor: "$coolGray.100",
        padding: "$1",
        borderRadius: "sm",
        marginBottom: "$1",
        marginRight: "$1",
        alignItems: "center",
        ...sx,
      }}
    >
      {children}
      <IconButton
        _containerStyles={{ p: 0 }}
        onPress={onRemove}
        icon="close-outline"
        _iconStyles={sx}
      />
    </View>
  );
};

function SelectedPerson({
  person,
  onRemove,
  sx,
}: {
  person: Person;
  onRemove: () => void;
  sx: Sx;
}) {
  return (
    <SelectedMemberWrapper sx={sx} onRemove={onRemove}>
      {/* <PersonAvatar person={person} size={25} sx={{ marginRight: "$2" }} /> */}
      <Text sx={sx}>{person.name}</Text>
    </SelectedMemberWrapper>
  );
}

function SelectedPhone({
  phoneItem,
  onRemove,
  sx,
}: {
  phoneItem: PhoneItem;
  onRemove: () => void;
  sx: Sx;
}) {
  return (
    <SelectedMemberWrapper sx={sx} onRemove={onRemove}>
      {!!phoneItem.data.name && <Text>{phoneItem.data.name}: </Text>}
      <Text sx={sx}>{parsePhoneNumberFriendly(phoneItem.data.phone)}</Text>
    </SelectedMemberWrapper>
  );
}

function ContactSelectItem({
  membership,
  onPress,
  selectedContacts,
}: {
  membership: ContactItem;
  onPress: (index: number) => void;
  selectedContacts: string[];
}) {
  const phoneItems = useMemo(() => {
    return ContactItemToPhoneItems(membership);
  }, [membership]);

  const hasMultipleNumbers = useMemo(() => {
    return phoneItems.length > 1;
  }, [phoneItems]);

  const [displayPhonePicker, setDisplayPhonePicker] = useState(false);

  const handlePress = useCallback(() => {
    if (hasMultipleNumbers) {
      setDisplayPhonePicker((prev) => !prev);
    } else {
      onPress(0);
    }
  }, [onPress, hasMultipleNumbers]);

  return (
    <>
      <SectionItemWrapper
        onPress={handlePress}
        key={membership.id}
        style={({ pressed }) =>
          pressed && { backgroundColor: DRIPSY_THEME["colors"]["$gray"]["200"] }
        }
      >
        <View
          sx={{
            marginLeft: "$1",
            justifyContent: "center",
            paddingY: "$2",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <PersonAvatar person={membership} size={40} />
          <Text
            key={membership.id}
            sx={{ fontSize: "md", flexGrow: 1, ml: "$3" }}
            numberOfLines={1}
            ellipsizeMode="tail"
          >
            {membership.data.name}
          </Text>
          <Text sx={{ mr: "$4", color: "$coolGray.500" }}>From Contacts</Text>
          {!!selectedContacts.length && (
            <Icon
              name="checkmark-circle-outline"
              color="$primary.500"
              size="2xl"
            />
          )}
        </View>
      </SectionItemWrapper>
      {displayPhonePicker &&
        phoneItems.map((phoneItem, i) => {
          return (
            <>
              {i !== 0 && <Divider key={`${phoneItem.id}_DIVIDER`} />}
              <SectionItemWrapper
                onPress={() => onPress(i)}
                key={phoneItem.id}
                sx={{
                  paddingLeft: 60,
                  paddingY: "$2",
                }}
                style={({ pressed }) => ({
                  backgroundColor: pressed
                    ? DRIPSY_THEME["colors"]["$gray"]["300"]
                    : DRIPSY_THEME["colors"]["$gray"]["100"],
                })}
              >
                <Icon name="call" size={20} color={"$gray.600"} />
                <Text
                  sx={{ fontSize: "md", flexGrow: 1 }}
                  numberOfLines={1}
                  ellipsizeMode="tail"
                >
                  {parsePhoneNumberFriendly(phoneItem.data.phone)}
                </Text>
                {selectedContacts.includes(phoneItem.id) && (
                  <Icon
                    name="checkmark-circle-outline"
                    color="$primary.500"
                    size="2xl"
                  />
                )}
              </SectionItemWrapper>
            </>
          );
        })}
    </>
  );
}

function MemberSelectItem({
  membership,
  onPress,
  isSelected,
}: {
  membership: ProfileItem;
  onPress: () => void;
  isSelected: boolean;
}) {
  const [assets, error] = useAssets([require("../assets/images/logo.png")]);
  return (
    <SectionItemWrapper
      onPress={onPress}
      key={membership.id}
      style={({ pressed }) =>
        pressed && { backgroundColor: DRIPSY_THEME["colors"]["$gray"]["200"] }
      }
    >
      <View
        sx={{
          marginLeft: "$1",
          justifyContent: "center",
          paddingY: "$2",
        }}
      >
        {isSelected ? (
          <Circle
            borderColor={THEMES.people.primary}
            borderWidth={2}
            height={50}
            width={50}
            position={"absolute"}
            zIndex={"2"}
            backgroundColor={THEMES.people.primary}
            opacity={0.9}
          >
            <Icon size="xl" color={"white"} name="checkmark" />
          </Circle>
        ) : null}
        <PersonAvatar person={membership} size={40} />
      </View>
      <Text key={membership.id} sx={{ fontSize: "md", flexGrow: 1 }}>
        {membership.data.name}
      </Text>
      <View sx={{ marginRight: "$3" }}>
        {assets ? (
          <Image
            style={{ width: 25, height: 25 }}
            source={assets[0]}
            alt="Aspen Connection"
          />
        ) : (
          <Text>Aspen Connection</Text>
        )}
      </View>
    </SectionItemWrapper>
  );
}

function ByPhoneSelectItem({
  onPress,
  phone,
}: {
  onPress: () => void;
  phone: string;
}) {
  return (
    <SectionItemWrapper
      key={phone}
      onPress={onPress}
      style={({ pressed }) =>
        pressed && { backgroundColor: DRIPSY_THEME["colors"]["$gray"]["200"] }
      }
    >
      <Text>Add by Phone: {phone}</Text>
    </SectionItemWrapper>
  );
}

// TODO: join with other "People" definitions
export interface PhoneItem {
  key: "PHONE";
  id: string;
  data: {
    name: string | undefined;
    phone: string;
  };
}

export type MemberItem = ContactItem | ProfileItem | PhoneItem;
export type SelectedMemberItem = ProfileItem | PhoneItem;

function ContactItemToPhoneItems(contactItem: ContactItem): PhoneItem[] {
  return (contactItem.data.phoneNumbers ?? [])
    .map((contactPhoneInfo) => {
      const phone = parsePhoneNumberWithCountryCode(
        contactPhoneInfo.digits ?? contactPhoneInfo.number ?? "",
        contactPhoneInfo.countryCode ?? "US"
      );
      if (!phone) return;

      return {
        key: "PHONE",
        id: `${contactItem.id}_${phone}`,
        data: {
          name: contactItem.data.name,
          phone,
        },
      };
    })
    .filter((p) => !!p) as PhoneItem[];
}

export default function SpaceMemberSelector({
  data,
  onAdd,
  onRemove,
  aspenUsersOnly = false,
  textInputLabel = "To:",
  OptionalTopComponent,
}: {
  data: SelectedMemberItem[];
  onAdd: (item: SelectedMemberItem) => void;
  onRemove: (item: SelectedMemberItem) => void;
  aspenUsersOnly?: boolean;
  textInputLabel?: string;
  OptionalTopComponent?: ReactNode;
}) {
  const addToast = useToast();

  const [searchFilter, setSearchFilter] = useState("");
  const [aboutToDelete, setAboutToDelete] = useState(false);
  const currentUser = useUser();
  const allPeople = useAllPeople();
  const [
    _contacts,
    _contactsByPhone,
    contactPermission,
    askForContactPermission,
  ] = useContacts();

  const selectedPeopleMap = useMemo(
    () => data.reduce((prev, curr) => ({ ...prev, [curr.id]: curr }), {}),
    [data]
  );

  const justUsers = useMemo(
    () => allPeople.filter((person) => person.key === "PROFILE"),
    [allPeople]
  );

  const peopleExcludingCurrentUser = useMemo(
    () =>
      (aspenUsersOnly ? justUsers : allPeople).filter(
        (connection) => connection.id !== currentUser?.id
      ),
    [allPeople, currentUser, aspenUsersOnly]
  );

  const peopleExcludingSelectedPeople = useMemo(
    () =>
      peopleExcludingCurrentUser.filter(
        (person) => !selectedPeopleMap[person.id]
      ),
    [peopleExcludingCurrentUser, selectedPeopleMap]
  );
  const filteredPeople = useMemo(() => {
    if (!searchFilter) return [];

    return peopleExcludingSelectedPeople.filter((p) => {
      const [firstName, ...lastNames] = p.data.name.split(" ");
      const lastName = lastNames.join(" ");
      return (
        p.data.name.toLowerCase().startsWith(searchFilter.toLowerCase()) ||
        firstName.toLowerCase().startsWith(searchFilter.toLowerCase()) ||
        lastName.toLowerCase().startsWith(searchFilter.toLowerCase())
      );
    });
  }, [searchFilter, peopleExcludingSelectedPeople]);

  const updateStateOnAdd = useCallback(() => {
    setSearchFilter("");
    setAboutToDelete(false);
  }, []);

  const parsedNumber = parsePhoneNumber(searchFilter);

  const renderItem: ListRenderItem<Person> = ({ item }) => {
    switch (item.key) {
      case "CONTACT":
        return (
          <ContactSelectItem
            membership={item}
            selectedContacts={data
              .filter((s) => s.key === "PHONE" && s.id.startsWith(item.id))
              .map((s) => s.id)}
            onPress={(contactNumberIndex) => {
              const phoneItem =
                ContactItemToPhoneItems(item)[contactNumberIndex];
              if (phoneItem) {
                const parsedPhone = parsePhoneNumber(phoneItem.data.phone);
                if (!parsedPhone) {
                  addToast({
                    title: "Invalid phone number",
                    variant: "ERROR",
                    description: "Please ensure the phone number is valid",
                  });
                  return;
                }
                const index = data.findIndex(
                  (s) => s.id === phoneItem.id && s.key === phoneItem.key
                );
                if (index === -1) {
                  onAdd(phoneItem);
                  updateStateOnAdd();
                } else {
                  onRemove(phoneItem);
                }
              }
            }}
          />
        );
      case "PROFILE":
        return (
          <MemberSelectItem
            membership={item}
            isSelected={
              !!data.find(
                (s) => ["PROFILE"].includes(s.key) && s.id === item.id
              )
            }
            onPress={() => {
              if (
                data.findIndex(
                  (s) => s.id === item.id && s.key === item.key
                ) === -1
              ) {
                onAdd(item);
                updateStateOnAdd();
              } else {
                onRemove(item);
              }
            }}
          />
        );
      default:
        return <></>;
    }
  };

  const handleBackspacePress = useCallback(
    ({ nativeEvent }: { nativeEvent: TextInputKeyPressEventData }) => {
      if (nativeEvent.key == "Backspace" && !searchFilter) {
        if (data.length === 0) return;
        if (!aboutToDelete) setAboutToDelete(true);
        else {
          onRemove(data[data.length - 1]);
          setAboutToDelete(false);
        }
      } else {
        setAboutToDelete(false);
      }
    },
    [data, searchFilter, aboutToDelete]
  );
  const aboutToDeleteStyles: Sx = {
    backgroundColor: "$primary.600",
    color: "$white",
  };
  return (
    <Column flex={1}>
      <ScrollView
        keyboardDismissMode="on-drag"
        keyboardShouldPersistTaps="always"
        style={{
          flexGrow: 0,
          flexShrink: 0,
        }}
      >
        <View
          sx={{
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            flexWrap: "wrap",
            paddingX: "$3",
            marginBottom: "$1",
            paddingY: "$1",
            backgroundColor: "$coolGray.100",
          }}
        >
          <Text sx={{ color: "$gray.500", marginRight: "$2" }}>
            {textInputLabel}
          </Text>

          {data.map((selection, index) => {
            if (selection.key === "PHONE") {
              return (
                <SelectedPhone
                  phoneItem={selection}
                  key={selection.id}
                  onRemove={() => onRemove(selection)}
                  sx={
                    index === data.length - 1 && aboutToDelete
                      ? aboutToDeleteStyles
                      : {}
                  }
                />
              );
            } else if (selection.key === "PROFILE") {
              const profile = selection.data;
              if (profile)
                return (
                  <SelectedPerson
                    person={profile}
                    key={selection.id}
                    onRemove={() => onRemove(selection)}
                    sx={
                      index === data.length - 1 && aboutToDelete
                        ? aboutToDeleteStyles
                        : {}
                    }
                  />
                );
            }
          })}
          <TextInput
            sx={{
              fontSize: 14,
              width: data.length === 0 ? "50%" : aboutToDelete ? 0 : "20%",
              // borderWidth: 1,
              paddingY: 6,
              marginBottom: "$1",
            }}
            onChangeText={(value) => setSearchFilter(value)}
            value={searchFilter}
            onKeyPress={handleBackspacePress}
            placeholder={data.length === 0 ? "Start searching..." : ""}
            autoFocus
          />
        </View>
      </ScrollView>

      {/* <Row width={"100%"} marginY={1} paddingX={2}> */}
      {/* <IconButton
          onPress={() => setSearchFilter("")}
          icon="close-outline"
          _containerStyles={{
            padding: "$1",
            borderRadius: "lg",
          }}
        /> */}

      {/* <Icon name="search-outline" size={"lg"} color="$muted.400" /> */}
      {/* </Row> */}
      {contactPermission !== "granted" && Platform.OS !== "web" && (
        <Button
          variant={"outline"}
          sx={{ m: "$2" }}
          onPress={async () => {
            await askForContactPermission();
          }}
        >
          Import contacts from phone
        </Button>
      )}
      {/* if the phone number is parseable and doesn't contain any
       * non-phonenumber-y characters, show the add button
       */}
      {parsedNumber && !searchFilter.match(/[^()0-9\s\-.]+$/g) && (
        <Button
          disabled={searchFilter.length < 10}
          sx={{ marginTop: "$2" }}
          onPress={() => {
            if (
              data.findIndex(
                (s) => s.key === "PHONE" && s.id === parsedNumber
              ) === -1
            )
              onAdd({
                key: "PHONE",
                id: parsedNumber,
                data: {
                  phone: parsedNumber,
                },
              });
            setSearchFilter("");
          }}
          colorScheme="$secondary"
          leftIcon="call-outline"
        >
          Add {parsePhoneNumberFriendly(searchFilter)}
        </Button>
      )}
      <FlatList
        data={filteredPeople}
        extraData={data}
        keyExtractor={(profile) => JSON.stringify(profile)}
        keyboardDismissMode="on-drag"
        keyboardShouldPersistTaps="always"
        renderItem={renderItem}
        ItemSeparatorComponent={Divider}
        ListEmptyComponent={OptionalTopComponent}
      />
    </Column>
  );
}

function Divider({ sx }: { sx: Sx }) {
  return (
    <View
      sx={{
        width: "100%",
        borderBottomWidth: "hairline",
        borderColor: "$coolGray.200",
        ...sx,
      }}
    />
  );
}
