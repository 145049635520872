import { Ionicons } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import { useToast } from "../hooks/useToast";
import { Button, Icon, Input, View } from "../components/basics";
import { useState } from "react";
import { updateUserEmail } from "../lib/supabase";

export default function EditEmailScreen() {
  const [newEmail, setNewEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const addToast = useToast();
  const navigation = useNavigation();
  return (
    <View sx={{ flex: 1 }}>
      <Input
        leftElement={
          <Icon
            name="at-outline"
            size={5}
            sx={{ ml: "$2" }}
            color="muted.400"
          />
        }
        onChangeText={setNewEmail}
        value={newEmail}
        placeholder="email@address.com"
        autoCapitalize={"none"}
      />
      <Input
        onChangeText={(text) => setPassword(text)}
        leftElement={
          <Icon name="lock-closed-outline" size={5} ml="2" color="muted.400" />
        }
        value={password}
        secureTextEntry={true}
        textContentType={"none"}
        placeholder="password"
        autoCapitalize={"none"}
      />
      <Button
        onPress={async () => {
          try {
            setIsSubmitting(true);
            // TODO: check password
            // const { data, error } = await supabase.rpc("check_user_password", {
            //   current_plain_password: password,
            // });
            // if (!data || error) {
            //   console.error(error);
            //   throw new Error("Password check failed");
            // }
            await updateUserEmail(newEmail);
            addToast({
              title: "Email updated",
              description: `A confirmation email has been sent to ${newEmail}. Please confirm the email to update your profile.`,
              variant: "INFO",
              position: "top",
            });
            setNewEmail("");
            navigation.goBack();
          } catch (e) {
            const message =
              e instanceof Error
                ? e.message
                : "There was an issue updating your email";
            addToast({
              title: "Error",
              description: message,
              variant: "ERROR",
              position: "top",
            });
          } finally {
            setIsSubmitting(false);
          }
        }}
        isLoading={isSubmitting}
      >
        Submit
      </Button>
    </View>
  );
}
