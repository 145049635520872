import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { RootStackParamList } from "../types";
import {
  Button,
  Text,
  FlatList,
  Row,
  Icon,
  Pressable,
  Divider,
  Spinner,
  Column,
} from "../components/basics";
import { useSpace } from "../hooks/spaces";
import { ListRenderItem } from "react-native";
import CenteredSpinner from "../components/basics/CenteredSpinner";
import { ResourceCollectionInfoFragment } from "../state/generated/graphql";
import { useSpaceMember } from "../hooks/useSpaceMembers";
import { SafeAreaView } from "dripsy";
import { useUser } from "../hooks/auth";
import { useCallback, useMemo, useState } from "react";
import { useCollections } from "../hooks/useCollections";
import useImportCollections from "../hooks/useImportCollections";
import { useToast } from "../hooks/useToast";

export default function CollectionImportModalScreen({
  route,
  navigation,
}: NativeStackScreenProps<RootStackParamList, "CollectionImportModal">) {
  const { spaceId, collectionDevIds, collectionKey } = route.params;
  const { data: space, loading: spaceLoading } = useSpace(spaceId);
  const [{ data: collections, fetching: collectionsLoading }] =
    useCollections(collectionKey);
  const collectionDevIdsSet = useMemo(
    () => new Set(collectionDevIds),
    [collectionDevIds]
  );
  const filteredCollections = useMemo(() => {
    return collections?.resource_collections.filter((rc) =>
      collectionDevIdsSet.has(rc.dev_id)
    );
  }, [collectionDevIdsSet, collections]);
  const [selectedCollections, setSelectedCollections] = useState<Set<string>>(
    new Set()
  );
  const renderItem: ListRenderItem<ResourceCollectionInfoFragment> = ({
    item,
  }) => {
    return (
      <CollectionsListItem
        collection={item}
        handlePress={() => {
          setSelectedCollections((prev) => {
            const key = item.id;
            if (prev.has(key)) {
              prev.delete(key);
              return new Set(prev);
            }
            return new Set(prev.add(key));
          });
        }}
        selectedCollections={selectedCollections}
      />
    );
  };

  const importCollections = useImportCollections();
  const addToast = useToast();
  const [isSharing, setIsSharing] = useState(false);
  const handleImport = useCallback(async () => {
    try {
      setIsSharing(true);
      const { error } = await importCollections(spaceId, [
        ...selectedCollections,
      ]);
      if (error) {
        addToast({
          title: "Import failure",
          description:
            "The request to import these collections could not be completed.",
          variant: "ERROR",
        });
      } else {
        navigation.goBack();
      }
    } catch (e) {
      addToast({
        title: "Import failure",
        description:
          "The request to import these collections could not be completed.",
        variant: "ERROR",
      });
    } finally {
      setIsSharing(false);
    }
  }, [selectedCollections, spaceId, navigation, addToast]);

  if (!collections?.resource_collections || collectionsLoading)
    return <CenteredSpinner text="Loading collections" />;

  return (
    <SafeAreaView sx={{ flex: 1 }}>
      <Row justifyContent="center" alignItems="center" p={2}>
        {spaceLoading ? (
          <Spinner />
        ) : (
          <Row alignItems="center">
            <Text numberOfLines={1} sx={{ fontWeight: "bold" }}>
              Import to: {space?.display_name || "N/A"}
            </Text>
          </Row>
        )}
      </Row>
      <Divider />
      <FlatList
        data={filteredCollections}
        extraData={selectedCollections}
        renderItem={renderItem}
        keyExtractor={(item) => item.id}
      />
      <Button
        onPress={handleImport}
        isLoading={isSharing}
        disabled={selectedCollections.size === 0}
        mb={1}
      >
        Import
      </Button>
    </SafeAreaView>
  );
}

function CollectionsListItem({
  collection,
  handlePress,
  selectedCollections,
}: {
  collection: ResourceCollectionInfoFragment;
  handlePress: () => void | Promise<void>;
  selectedCollections: Set<string>;
}) {
  const isSelected = selectedCollections.has(collection?.id);
  const icon = "albums-outline";
  const title = collection?.name;
  const snippet = "";
  return (
    <Pressable
      onPress={handlePress}
      sx={{ backgroundColor: isSelected ? "$gray.200" : "$white" }}
    >
      <Row h={70} alignItems="center" justifyContent="center">
        <Column alignItems={"center"} justifyContent="center" w="1/5">
          <Icon name={icon} size="3xl" />
        </Column>
        <Column alignItems="center" justifyContent="center" w="3/5">
          <Text sx={{ fontWeight: "bold" }}>{title ?? "N/A"}</Text>
          {!!snippet && <Text>{snippet}</Text>}
        </Column>
        <Column alignItems="center" justifyContent="center" w="1/5">
          {isSelected && <Icon size={"xl"} name="checkmark-circle-outline" />}
        </Column>
      </Row>

      <Divider />
    </Pressable>
  );
}
