import { Ionicons } from "@expo/vector-icons";
import { ActivityIndicator, Sx, useSx } from "dripsy";
import React, { ComponentProps, PropsWithChildren } from "react";
import {
  PressableProps,
  StyleProp,
  TextStyle,
  ViewStyle,
  _Text,
  _View,
} from "react-native";
import {
  baseStyle as baseButtonStyle,
  variants as buttonVariants,
  buttonSizes,
} from "../../constants/ButtonThemes";
import Text from "./Text";
import Pressable from "./Pressable";
import Icon from "./Icon";

interface ButtonProps extends PressableProps {
  disabled?: boolean;
  variant: "unstyled" | "solid" | "outline" | "ghost" | "subtle"; // | "link";
  size: "xs" | "sm" | "md" | "lg";
  colorScheme: string;
  leftIcon?: ComponentProps<typeof Ionicons>["name"];
  rightIcon?: ComponentProps<typeof Ionicons>["name"];
  isLoading?: boolean;
  _showLoadingSpinner?: boolean;
  _textStyles?: Sx;
  _iconStyles?: Sx;
  _containerStyles?: Sx;
  _spinnerStyles?: Sx;
  _isDisabledStyles?: Sx;
  _isLoadingStyles?: Sx;
  sx?: Sx;
}

function Button({
  onPress,
  children,
  leftIcon,
  rightIcon,
  isLoading,
  _showLoadingSpinner = true,
  disabled = false,
  colorScheme = "$primary",
  variant = "solid",
  size = "md",
  _textStyles,
  _iconStyles,
  _containerStyles,
  _spinnerStyles,
  _isDisabledStyles,
  _isLoadingStyles,
  sx,
  ...props
}: PropsWithChildren<ButtonProps>) {
  const sizeStyles = buttonSizes[size];
  const colorStyles = buttonVariants[variant]({ colorScheme });
  const defaultLoadingStyles = isLoading ? baseButtonStyle._loading : undefined;
  const defaultDisabledStyles = disabled
    ? baseButtonStyle._disabled
    : undefined;
  const buttonContent =
    isLoading && _showLoadingSpinner ? (
      <ActivityIndicator
        // the spinner has `size` and `color` props which need to be passed
        // directly, not through `sx` or `style`
        {...baseButtonStyle._spinner}
        {...sizeStyles._spinner}
        {...colorStyles._spinner}
        sx={{
          ..._spinnerStyles,
        }}
      />
    ) : (
      <>
        {leftIcon ? (
          <Icon
            name={leftIcon}
            sx={{ ...colorStyles._icon, ...sizeStyles._icon, ..._iconStyles }}
          />
        ) : null}
        <Text
          sx={{
            ...baseButtonStyle._text,
            ...colorStyles._text,
            ...sizeStyles._text,
            ..._textStyles,
          }}
        >
          {children}
        </Text>
        {rightIcon ? (
          <Icon
            name={rightIcon}
            sx={{ ...colorStyles._icon, ...sizeStyles._icon, ..._iconStyles }}
          />
        ) : null}
      </>
    );
  return (
    <Pressable
      onPress={onPress}
      disabled={disabled || isLoading}
      // all borders and colors go here so they don't
      // get overwritten by sx...
      // said diff, style props in style and sx should be
      // mutually exclusive
      style={({ hovered, pressed }) =>
        pressed
          ? colorStyles._containerPressed
          : hovered
          ? colorStyles._containerHover
          : colorStyles._container
      }
      // remainder of the style props.
      sx={{
        ...baseButtonStyle._container,
        ...sizeStyles._container,
        ...sx,
        ..._containerStyles,
        ...defaultLoadingStyles,
        ...defaultDisabledStyles,
        ..._isDisabledStyles,
        ..._isLoadingStyles,
      }}
      {...props}
    >
      {buttonContent}
    </Pressable>
  );
}

export default Button;
