import { View, Text, Badge, Pressable } from "../components/basics";
import React, { ComponentProps, useMemo } from "react";
import usePeople from "../hooks/usePeople";
import { useUser } from "../hooks/auth";
import { formatMessageTime } from "../utils/dates";
import SpaceAvatars from "./SpaceAvatars";
import { Platform, ViewStyle } from "react-native";
import { useSpaceMember, useSpaceMemberLookup } from "../hooks/useSpaceMembers";
import { ChatListItemFragment } from "../state/generated/graphql";
import SpaceFeedInvitationCard from "./SpaceFeedInvitationCard";
import { CoverPhotoView } from "./CoverPhotoView";
import { normalizeAttachments } from "../utils/apps/chat";
import useNoticeFormatter from "../hooks/useNoticeFormatter";
import usePersonFirstName from "../hooks/usePersonFirstName";
import DRIPSY_THEME from "../constants/DripsyTheme";
import { Sx } from "dripsy";

const parseAttachmentSnippet = (
  attachments: { id: string; type: string }[]
) => {
  if (attachments.length === 1)
    return `a ${attachments[0].type === "photo" ? "pic" : "video"}`;
  if (attachments.length > 1) return `${attachments.length} attachments`;
  return "";
};

const parseSnippet = (
  event: any,
  resource: any,
  formatters: {
    noticeFormatter: (text: string, userIds: string[]) => string;
    userIdToNameFormatter: (text: string) => string;
  }
) => {
  if (resource) {
    const { resource_key: messageResourceKey } = event ?? {};
    switch (messageResourceKey) {
      case "NOTICE":
        return parseNoticeSnippet(event, resource, formatters);
      case "CHAT":
        return parseChatSnippet(event, resource, formatters);
    }
  }

  return "";
};

function parseNoticeSnippet(
  event: any,
  resource: any,
  formatters: {
    noticeFormatter: (text: string, userIds: string[]) => string;
    userIdToNameFormatter: (text: string) => string;
  }
) {
  const resourceData = resource?.data;
  return formatters.noticeFormatter(
    resourceData.text,
    resourceData.text_user_ids ?? []
  );
}

function parseChatSnippet(
  event: any,
  resource: any,
  formatters: {
    noticeFormatter: (text: string, userIds: string[]) => string;
    userIdToNameFormatter: (text: string) => string;
  }
) {
  const { type: messageType, data: messageData } = event ?? {};
  const resourceData = resource?.data;
  if (messageType === "NEW") {
    if (resourceData.text)
      return `${formatters.userIdToNameFormatter(event.user_id)}: ${
        resourceData.text
      }`;

    const normalizedAttachments = normalizeAttachments(resourceData.attachment);
    if (normalizedAttachments?.length)
      return `${formatters.userIdToNameFormatter(
        event.user_id
      )} shared ${parseAttachmentSnippet(normalizedAttachments)}`;
  } else if (messageType === "ADD_REACTION") {
    const reaction = messageData.type === "LIKE" ? "❤️" : messageData.type;
    const reactionContext = !!resourceData.text
      ? `"${resourceData.text}"`
      : "a message";
    return `${formatters.userIdToNameFormatter(
      event.user_id
    )}: ${reaction} ${reactionContext}`;
  } else if (messageType === "REMOVE_REACTION") {
    const reaction = messageData.type === "LIKE" ? "❤️" : messageData.type;
    const reactionContext = !!resourceData.text
      ? `"${resourceData.text}"`
      : "a message";
    return `${formatters.userIdToNameFormatter(
      event.user_id
    )}: Removed ${reaction} from ${reactionContext}`;
  }
}

export default React.memo(SpacesFeedItem);
function SpacesFeedItem({
  space,
  onPress,
  isActive = false,
  containerStyle,
  containerIntensity,
  spaceNameProps,
  messageTimeProps,
  snippetProps,
  sx,
}: {
  space: ChatListItemFragment;
  onPress: () => void;
  isActive?: boolean;
  containerStyle?: { container: ViewStyle; active: ViewStyle };
  containerIntensity?: number;
  spaceNameProps?: ComponentProps<typeof Text>;
  messageTimeProps?: ComponentProps<typeof Text>;
  snippetProps?: ComponentProps<typeof Text>;
  sx?: Sx;
}) {
  const currentUser = useUser();
  const currentUserMembership = useSpaceMember(space.id, currentUser.id);
  const people = usePeople();

  const noticeFormatter = useNoticeFormatter();
  const getShortName = usePersonFirstName();

  const spaceMembers = useSpaceMemberLookup(space.id);
  const orderedSpaceMembers = useMemo(() => {
    const resp = space.user_ids
      .map((uid) => spaceMembers.get(uid))
      .filter((sm) => !!sm);
    return resp;
  }, [spaceMembers, space.user_ids]);

  const { snippet, latestMessageTime } = useMemo(() => {
    if (space.latest_event) {
      return {
        snippet: parseSnippet(space.latest_event, space.resource, {
          noticeFormatter,
          userIdToNameFormatter: getShortName,
        }),
        latestMessageTime: new Date(space.created_at),
      };
    }
    return {
      snippet: "No messages to display",
      latestMessageTime: new Date(space.created_at),
    };
  }, [space.latest_event, space.sender, space.created_at, getShortName]);

  const unreadCount = space.unread_counts[0]?.unread_count ?? 0;

  return (
    <View sx={{ flexDirection: "column" }}>
      <Pressable
        onPress={onPress}
        style={({ hovered, pressed }) => ({
          opacity: Platform.OS === "ios" && (pressed || hovered) ? 0.7 : 1,
        })}
        sx={{
          flexDirection: "row",
          alignItems: "center",
          height: 72,
          backgroundColor: "$white",
          marginBottom: 0,
          borderRadius: 8,
          ...sx,
        }}
        android_ripple={{
          color: "$gray.300",
        }}
      >
        <View
          sx={{
            width: 48,
            height: 48,
            marginRight: "$3",
            alignItems: "center",
            justifyContent: "center",
            flexShrink: 0,
          }}
        >
          {space?.cover_photo ? (
            <View
              sx={{
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CoverPhotoView
                size={48}
                sx={{ borderRadius: 4, boxShadow: "$2" }}
                coverPhoto={space.cover_photo}
              />
            </View>
          ) : (
            <SpaceAvatars
              spaceMembers={
                orderedSpaceMembers.length > 1
                  ? orderedSpaceMembers.filter(
                      (member) => member.user_id !== currentUser!.id
                    )
                  : orderedSpaceMembers
              }
              size={48}
            />
          )}
        </View>
        <View sx={{ flex: 1, justifyContent: "center" }}>
          <View
            sx={{
              flexDirection: "row",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              marginBottom: "$1",
            }}
          >
            <Text
              numberOfLines={1}
              sx={{
                maxWidth: "75%",
                fontSize: "md",
                fontWeight: unreadCount > 0 ? "bold" : "normal",
              }}
              {...spaceNameProps}
            >
              {space.display_name}
            </Text>
            {latestMessageTime ? (
              <Text
                sx={{ flexShrink: 0, marginX: "$3", color: "$coolGray.500" }}
                {...messageTimeProps}
              >
                {formatMessageTime(latestMessageTime)}
              </Text>
            ) : null}
          </View>
          {people && orderedSpaceMembers[0] && (
            <View
              sx={{
                display: "flex",
                flexDirection: "row",
                marginRight: 3,
              }}
            >
              <Text
                numberOfLines={1}
                sx={{
                  flex: 1,
                  flexWrap: "wrap",
                  flexGrow: 1,
                  marginRight: "$3",
                  color: "$coolGray.500",
                }}
                {...snippetProps}
              >
                <Text sx={{ fontWeight: unreadCount > 0 ? "bold" : "normal" }}>
                  {snippet}
                </Text>
              </Text>
              {unreadCount > 0 ? (
                <View
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginLeft: 3,
                  }}
                >
                  <Badge
                    height="6"
                    backgroundColor="yellow.100"
                    _text={{ color: "blueGray.600" }}
                    alignItems={"center"}
                    justifyContent={"center"}
                    rounded={"full"}
                  >
                    {unreadCount > 99 ? "99+" : unreadCount}
                  </Badge>
                </View>
              ) : null}
            </View>
          )}
        </View>
      </Pressable>
      {currentUserMembership?.invitation?.status === "PENDING" && (
        <SpaceFeedInvitationCard
          spaceId={space.id}
          invitation={currentUserMembership.invitation}
        />
      )}
    </View>
  );
}
