import { useNavigation } from "@react-navigation/native";
import { useDripsyTheme, useSx } from "dripsy";
import { AnimatePresence, MotiText, MotiView } from "moti";
import { MotiPressable } from "moti/interactions";
import { useCallback } from "react";
import {
  SystemMessage as ChatSystemMessage,
  SystemMessageProps,
} from "react-native-gifted-chat";
import DRIPSY_THEME from "../../constants/DripsyTheme";
import { useUser } from "../../hooks/auth";
import { usePushEvent } from "../../hooks/usePushEvent";
import useResource from "../../hooks/useResource";
import { navigationRef } from "../../navigation/RootNavigation";
import { LE_WORD_RESOURCE_KEY } from "../../resources/le-word";
import { PAGES_RESOURCE_KEY } from "../../resources/page";
import { PHOTO_RESOURCE_KEY } from "../../resources/photo";
import { POLL_RESOURCE_KEY } from "../../resources/poll";
import { SCHEDULE_RESOURCE_KEY } from "../../resources/schedule";
import { AspenChatMessage } from "../../screens/spaces/ChatScreen";
import { View, Text, Row, Pressable } from "../basics";
import ResourceCard from "../ResourceCard";
import { Icon } from "../../components/basics";
import { REMINDER_RESOURCE_KEY } from "../../resources/reminder";
function symbolToEmoji(symbol: string): string {
  switch (symbol) {
    case "_":
      return "⬜";
    case "?":
      return "🟨";
    case "!":
      return "🟩";
    default:
      return "❓";
  }
}

interface CustomNoticeProps {
  text: string;
  metadata: Record<string, string>;
}

function onPressAnimation({ hovered, pressed }) {
  "worklet";

  return {
    scale: pressed ? 0.8 : 1,
    opacity: pressed ? 0.5 : 1,
  };
}

function PollNotice({ text, metadata }: CustomNoticeProps) {
  const sx = useSx();
  const { resourceKey, resourceId } = metadata;
  const navigation = useNavigation();
  const onPress = useCallback(() => {
    navigation.navigate("polls", {
      appQuery: { selectedId: resourceId },
    });
  }, [resourceId, resourceKey]);
  const pushEvent = usePushEvent();
  const user = useUser();
  const [{ data: resourceResp }] = useResource(resourceKey, resourceId);
  const resource = resourceResp?.resources_by_pk;
  const { data: poll } = resource ?? {};
  const toggleVote = useCallback(
    (option_id: string) => {
      if (!poll) return;
      if (!resource?.id) return;
      if (
        poll.votes[option_id] &&
        poll.votes[option_id].find((uid) => uid === user?.id)
      ) {
        pushEvent({
          resource_key: POLL_RESOURCE_KEY,
          type: "REMOVE_VOTE",
          resource_id: resource.id,
          data: { option_id },
        });
      } else {
        pushEvent({
          resource_key: POLL_RESOURCE_KEY,
          type: "VOTE",
          resource_id: resource.id,
          data: { option_id },
        });
      }
    },
    [poll, user?.id, resource?.id]
  );
  if (!resource) return <DefaultSystemMessage text={text} />;

  const totalVotes = Object.values(
    poll.votes as Record<string, string[]>
  ).reduce((total, votes) => total + votes.length, 0);
  const votesPerOption = Object.fromEntries(
    Object.entries(poll.votes as Record<string, string[]>).map(
      ([id, votes]) => [id, votes.length]
    )
  );
  const percentages = Object.fromEntries(
    Object.entries(poll.votes as Record<string, string[]>).map(
      ([id, votes]) => [id, totalVotes ? votes.length / totalVotes : 0]
    )
  );
  const userVotes = Object.entries(
    poll.votes as Record<string, string[]>
  ).filter(([_id, votes]) => votes.findIndex((uid) => uid === user?.id) !== -1);
  const votedOptions = new Set(userVotes.map(([id, _votes]) => id));

  return (
    <SystemMessageContainer>
      <Pressable onPress={onPress} sx={{ flexDirection: "row" }}>
        <DefaultSystemMessageText
          text={text}
          sx={{ color: "$primary.500", marginRight: "$1", marginBottom: "$2" }}
        />
        <Icon name={"open-outline"} color={"$primary.500"} />
      </Pressable>
      {poll.title !== "" && (
        <Text
          sx={{
            marginBottom: "$2",
            color: "$gray.500",
            textAlign: "center",
            lineHeight: 20,
          }}
        >
          {poll.title}
        </Text>
      )}
      <View
        sx={{
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "center",
        }}
      >
        {poll.options.map(({ id, value }) => (
          <MotiPressable
            key={id}
            animate={onPressAnimation}
            transition={{ stiffness: 250, mass: 1 }}
            containerStyle={{ maxWidth: "100%" }}
            style={sx({
              position: "relative",
              borderColor: votedOptions.has(id) ? "$primary.500" : "$gray.300",
              borderWidth: 1,
              borderRadius: "md",
              backgroundColor: votedOptions.has(id) ? "$primary.100" : "$white",
              margin: 2,
              alignItems: "center",
            })}
            onPress={() => toggleVote(id)}
          >
            <View
              sx={{
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                paddingX: "$4",
                paddingY: "$2",
              }}
            >
              <Text>{value}</Text>
              <MotiText
                key={votesPerOption[id]}
                from={{ opacity: 0, translateY: 10 }}
                animate={{ opacity: 1, translateY: 0 }}
                //transition={{ type: "timing", duration: 400 }}
                exit={{ opacity: 0, translateY: -10 }}
                style={sx({
                  textAlign: "right",
                  color: "$gray.500",
                  ml: 8,
                })}
              >
                {votesPerOption[id] ?? 0}
              </MotiText>
            </View>
          </MotiPressable>
        ))}
      </View>
    </SystemMessageContainer>
  );
}

function LeWordGameNotice({ text, metadata }: CustomNoticeProps) {
  const { resourceKey, resourceId } = metadata;
  const [{ data: resourceResp }] = useResource(resourceKey, resourceId);
  const resource = resourceResp?.resources_by_pk;

  const navigation = useNavigation();
  const onPress = useCallback(() => {
    navigation.navigate("le-word", { appRoute: "leaderboard" });
  }, []);

  if (!resource) return <DefaultSystemMessage text={text} />;

  return (
    <SystemMessageContainer>
      <Pressable
        style={({ pressed }) => ({
          backgroundColor: pressed
            ? DRIPSY_THEME.colors.$gray["100"]
            : undefined,
        })}
        sx={{
          alignItems: "center",
          justifyContent: "center",
          flex: 1,
          p: 8,
        }}
        onPress={onPress}
      >
        <DefaultSystemMessage text={text} />
        {resource.data.guesses.map((g, i) => (
          <Row key={i} justifyContent={"center"}>
            <Text>{g.hint.map(symbolToEmoji).join("")}</Text>
          </Row>
        ))}
      </Pressable>
    </SystemMessageContainer>
  );
}

function ScheduleCardNotice({ text, metadata }: CustomNoticeProps) {
  const { resourceKey, resourceId } = metadata;
  const [{ data: resourceResp }] = useResource(resourceKey, resourceId);
  const resource = resourceResp?.resources_by_pk;

  const navigation = useNavigation();
  const onPress = useCallback(() => {
    navigation.navigate("schedule", {
      appQuery: { selectedId: resourceId },
    });
  }, [resourceId, resourceKey]);

  if (!resource) return <DefaultSystemMessage text={text} />;

  return (
    <SystemMessageContainer>
      <Pressable
        onPress={onPress}
        style={({ pressed }) => ({
          backgroundColor: pressed
            ? DRIPSY_THEME.colors.$gray["100"]
            : undefined,
        })}
        sx={{
          alignItems: "center",
          justifyContent: "center",
          flex: 1,
          borderRadius: "md",
        }}
      >
        <DefaultSystemMessage text={text} />
        <ResourceCard resource={resource} />
      </Pressable>
    </SystemMessageContainer>
  );
}

function NotesCardNotice({ text, metadata }: CustomNoticeProps) {
  const { resourceKey, resourceId } = metadata;
  const [{ data: resourceResp }] = useResource(resourceKey, resourceId);
  const resource = resourceResp?.resources_by_pk;

  const navigation = useNavigation();
  const onPress = useCallback(() => {
    navigation.navigate("Pages", { resourceId });
  }, []);

  if (!resource) return <DefaultSystemMessage text={text} />;

  return (
    <SystemMessageContainer>
      <Pressable
        onPress={onPress}
        style={({ pressed }) => ({
          backgroundColor: pressed
            ? DRIPSY_THEME.colors.$gray["100"]
            : undefined,
        })}
        sx={{
          alignItems: "center",
          justifyContent: "center",
          flex: 1,
        }}
      >
        <DefaultSystemMessage text={text} />
        <ResourceCard resource={resource} />
      </Pressable>
    </SystemMessageContainer>
  );
}

function PhotosUploadNotice({ text }: CustomNoticeProps) {
  const navigation = useNavigation();
  const onPress = useCallback(() => {
    navigation.navigate("Photos");
  }, []);

  return (
    <SystemMessageContainer>
      <Pressable
        onPress={onPress}
        style={({ pressed }) => ({
          backgroundColor: pressed
            ? DRIPSY_THEME.colors.$gray["100"]
            : undefined,
        })}
        sx={{
          alignItems: "center",
          justifyContent: "center",
          flex: 1,
        }}
      >
        <DefaultSystemMessage text={text} />
      </Pressable>
    </SystemMessageContainer>
  );
}

function GeoGeniusNotice({ text }: CustomNoticeProps) {
  // const navigation = useNavigation();
  // const onPress = useCallback(() => {
  //   navigation.navigate("Geo Genius");
  // }, []);

  return (
    <SystemMessageContainer>
      {/* <Pressable
        onPress={onPress}
        style={({ pressed }) => ({
          backgroundColor: pressed
            ? dripsyTheme.colors.$gray["100"]
            : undefined,
        })}
      > */}
      <DefaultSystemMessage text={text} />
      {/* </Pressable> */}
    </SystemMessageContainer>
  );
}

function RemindersCardNotice({ text, metadata }: CustomNoticeProps) {
  const { resourceKey, resourceId } = metadata;
  const [{ data: resourceResp }] = useResource(resourceKey, resourceId);
  const resource = resourceResp?.resources_by_pk;
  const navigation = useNavigation();
  const onPress = useCallback(() => {
    navigation.navigate("reminders");
  }, []);

  if (!resource) return <DefaultSystemMessage text={text} />;

  return (
    <SystemMessageContainer>
      <Pressable
        onPress={onPress}
        style={({ pressed }) => ({
          backgroundColor: pressed
            ? DRIPSY_THEME.colors.$gray["100"]
            : undefined,
        })}
        sx={{
          alignItems: "center",
          justifyContent: "center",
          flex: 1,
        }}
      >
        <DefaultSystemMessage text={text} />
        <ResourceCard resource={resource} />
      </Pressable>
    </SystemMessageContainer>
  );
}

function CustomNotice({ metadata, text }: CustomNoticeProps) {
  const { resourceKey, noticeKey } = metadata;
  switch (resourceKey) {
    case LE_WORD_RESOURCE_KEY:
      switch (noticeKey) {
        case "SHARE":
          return <LeWordGameNotice metadata={metadata} text={text} />;
      }
    case SCHEDULE_RESOURCE_KEY:
      switch (noticeKey) {
        case "NEW":
        case "SHARE":
          return <ScheduleCardNotice metadata={metadata} text={text} />;
      }
    case PAGES_RESOURCE_KEY:
      switch (noticeKey) {
        case "NEW":
        case "SHARE":
          return <NotesCardNotice metadata={metadata} text={text} />;
      }
    case PHOTO_RESOURCE_KEY:
      switch (noticeKey) {
        case "UPLOAD":
          return <PhotosUploadNotice metadata={metadata} text={text} />;
      }
    case POLL_RESOURCE_KEY:
      switch (noticeKey) {
        case "NEW":
        case "SHARE":
          return <PollNotice metadata={metadata} text={text} />;
      }
    case REMINDER_RESOURCE_KEY:
      switch (noticeKey) {
        case "NEW":
        case "SHARE":
          return <RemindersCardNotice metadata={metadata} text={text} />;
      }
    default:
      return <DefaultSystemMessage text={text} />;
  }
}

export default function SystemMessage(
  props: SystemMessageProps<AspenChatMessage>
) {
  const { currentMessage } = props;
  if (!currentMessage) return null;
  const { metadata } = currentMessage;
  if (metadata)
    return <CustomNotice metadata={metadata} text={currentMessage.text} />;

  return <DefaultSystemMessage text={currentMessage.text} />;
}

function DefaultSystemMessage({ text }: { text: string }) {
  return (
    <SystemMessageContainer>
      <DefaultSystemMessageText text={text} />
    </SystemMessageContainer>
  );
}

const SystemMessageContainer = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <View
      sx={{
        alignItems: "center",
        justifyContent: "center",
        flex: 1,
        marginTop: 5,
        marginBottom: 10,
        mx: 32,
      }}
    >
      {children}
    </View>
  );
};

function DefaultSystemMessageText({ text, ...props }: { text: string }) {
  return (
    <Text
      sx={{
        backgroundColor: "transparent",
        color: "$coolGray.500",
        fontSize: 12,
        fontWeight: "300",
        textAlign: "center",
        ...props.sx,
      }}
    >
      {text}
    </Text>
  );
}
