/**
 * If you are not familiar with React Navigation, refer to the "Fundamentals" guide:
 * https://reactnavigation.org/docs/getting-started
 *
 */
import "react-native-get-random-values";
import {
  NavigationContainer,
  Theme as ReactNavTheme,
  useNavigation,
} from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import { InteractionManager, Platform } from "react-native";

import ModalScreen from "../screens/ModalScreen";
import NotFoundScreen from "../screens/NotFoundScreen";
import { RootStackParamList } from "../types";
import LinkingConfiguration from "./LinkingConfiguration";
import { useEffect, useRef, useState, useMemo, useCallback } from "react";
import supabase from "../lib/supabase";
import { Session } from "@supabase/supabase-js";
import AuthScreen from "../screens/SignUpForm";
import AppLoading from "expo-app-loading";
import CompleteProfile from "../screens/CompleteProfile";
import LoadingScreen from "../screens/LoadingScreen";
import registerPushToken from "../utils/registerPushToken";
import { registerForPushNotificationsAsync } from "../utils/notifications";
import VerifyMobileScreen from "../screens/VerifyMobileScreen";
import {
  startChatListSubscription,
  startEventSubscription,
  startProfilesSubscription,
  startProfileSubcription,
  startResourceCollectionSubscription,
  startSharedResourceSubscription,
  startSpaceMembersSubscription,
  startSpacesSubscription,
} from "../state/graphql/client";
import { Button } from "../components/basics";
import MainScreen from "../screens/MainScreen";
import EditMobileScreen from "../screens/EditMobileScreen";
import { useQuery, useSubscription } from "urql";
import { setBadgeCountAsync } from "expo-notifications";
import EditEmailScreen from "../screens/EditEmailScreen";
import AddMemberModalScreen from "../screens/AddMemberModalScreen";
import {
  ChatAttachmentPickerScreen,
  PhotoPickerScreen,
} from "../screens/AttachmentPickerScreen";
import useSession from "../hooks/useSession";
import { useUserAccount } from "../hooks/auth";
import { MDNavigationState } from "../navigators/master-detail/types";
import NotInvitedScreen from "../screens/NotInvitedScreen";
import { useTheme } from "../hooks/theme";
import * as NavigationBar from "expo-navigation-bar";
import { useURL } from "expo-linking";
import { getHashParams } from "../state/utils/url";
import EmailVerificationMessageScreen from "../screens/EmailVerificationMessageScreen";
import { GetUserProfileDocument } from "../state/generated/graphql";
import SignInScreen from "../screens/auth/SignIn";
import ConfirmSMSCode from "../screens/auth/ConfirmSMSCode";
import SignUpScreen from "../screens/auth/SignUp";
import EmailSignInMessageScreen from "../screens/EmailSignInMessageScreen";
import ResourceShareModalScreen from "../screens/ResourceShareModalScreen";
import { navigationRef } from "./RootNavigation";
import CoverPhotoSelectorScreen from "../screens/CoverPhotoSelectorScreen";
import EmojiPickerScreen from "../screens/EmojiPickerScreen";
import ResourceImportModalScreen from "../screens/ResourceImportModalScreen";
import CollectionShareModalScreen from "../screens/CollectionShareModalScreen";
import CollectionImportModalScreen from "../screens/CollectionImportModalScreen";
import MobileSignInScreen from "../screens/auth/MobileSignIn";
import CreateGroupScreen, {
  CreateMockSpaceScreen,
} from "../screens/spaces/CreateGroupScreen";
import { ResourceShareModalProvider } from "../hooks/useResourceShare";
import FollowCollectionsModalScreen from "../screens/FollowCollectionsModalScreen";
import AppsScreen from "../screens/AppsScreen";
import ResourceAttributionModalScreen from "../screens/ResourceAttributionModalScreen";
import NewChatScreen from "../screens/spaces/NewChatScreen";
import { ProfilePeekDrawerProvider } from "../components/chat/ProfilePeekDrawer";

export default function Navigation() {
  const url = useURL();

  const NavigationTheme: ReactNavTheme = {
    dark: false,
    colors: {
      text: "#27272a",
      background: "#FFF",
      card: "white",
      border: "transparent",
      notification: "white",
      primary: "#000",
    },
  };

  useEffect(() => {
    if (!url) return;
    const hashParams = getHashParams(url);
    if (hashParams.has("refresh_token")) {
      supabase.auth.signIn({ refreshToken: hashParams.get("refresh_token") });
    }
  }, [url]);

  useEffect(() => {
    if (Platform.OS === "android") {
      NavigationBar.setBackgroundColorAsync("#FFFFFF");
    }
  }, []);
  return (
    <NavigationContainer
      linking={LinkingConfiguration}
      theme={NavigationTheme}
      ref={navigationRef}
    >
      <RootNavigator />
    </NavigationContainer>
  );
}

/**
 * A root stack navigator is often used for displaying modals on top of all other content.
 * https://reactnavigation.org/docs/modal
 */
const Stack = createNativeStackNavigator<RootStackParamList>();

function RootNavigator() {
  const session = useSession();

  const navigation = useNavigation();

  const [currentAccount] = useUserAccount();

  const [initialRouteState, setIntialRouteState] = useState<
    Partial<MDNavigationState>
  >({});

  useEffect(() => {
    const initialState = navigation.getState();
    if (initialState && initialState.routes.length > 0) {
      setIntialRouteState(navigation.getState().routes[0].state);
    }
  }, []);

  // This is used to ensure latest events are brough in and used to update local resources
  useEffect(() => {
    if (!session) return;
    let eventSubscription: any;
    let userProfileSubscription: any;
    let spacesSubscription: any;
    let spaceMembersSubscription: any;
    let profilesSubscription: any;
    let sharedResourceSubscription: any;
    let resourceCollectionSubscription: any;
    let chatListSubscription: any;
    const user = supabase.auth.user();
    if (user) {
      const now = new Date().toISOString();
      InteractionManager.runAfterInteractions(() => {
        eventSubscription = startEventSubscription(now);
        userProfileSubscription = startProfileSubcription(user.id);
        spacesSubscription = startSpacesSubscription();
        spaceMembersSubscription = startSpaceMembersSubscription();
        profilesSubscription = startProfilesSubscription();
        sharedResourceSubscription = startSharedResourceSubscription(now);
        resourceCollectionSubscription = startResourceCollectionSubscription();
        chatListSubscription = startChatListSubscription();
      });
    }
    return () => {
      eventSubscription?.unsubscribe();
      userProfileSubscription?.unsubscribe();
      spacesSubscription?.unsubscribe();
      spaceMembersSubscription?.unsubscribe();
      profilesSubscription?.unsubscribe();
      sharedResourceSubscription?.unsubscribe();
      resourceCollectionSubscription?.unsubscribe();
      chatListSubscription?.unsubscribe();
    };
  }, [session]);

  const [profileResult] = useQuery({
    query: GetUserProfileDocument,
    variables: {
      id: supabase.auth.user()?.id,
    },
    pause: !session,
    requestPolicy: "cache-first",
  });

  useEffect(() => {
    if (!session) return;
    const userId = supabase.auth.user()!.id;
    registerForPushNotificationsAsync(userId)
      .then((token) => {
        if (!token) return;
        return registerPushToken(userId, token);
      })
      .catch((e) => {
        console.error("Could not register for push notifications");
        console.error(e);
      });
  }, [session]);

  useEffect(() => {
    if (profileResult.data) {
      const { profiles_by_pk: profile } = profileResult.data;
      if (profile && profile.notification_badge?.value) {
        setBadgeCountAsync(profile.notification_badge.value);
        return;
      }
    }
    setBadgeCountAsync(0);
  }, [profileResult]);

  if (session === undefined) {
    return <AppLoading />;
  }

  const getScreens = () => {
    if (!session) {
      return (
        <>
          <Stack.Screen
            name="Auth"
            component={SignInScreen}
            options={{ headerShown: false }}
          />
          <Stack.Screen
            name="SignUp"
            component={SignUpScreen}
            options={{ headerShown: false }}
          />
          <Stack.Screen
            name="EmailVerificationMessage"
            component={EmailVerificationMessageScreen}
            options={{ headerShown: false }}
          ></Stack.Screen>
          <Stack.Screen
            name="EmailSignInMessage"
            component={EmailSignInMessageScreen}
            options={{ headerShown: false }}
          ></Stack.Screen>
          <Stack.Screen
            name="ConfirmSMSCode"
            component={ConfirmSMSCode}
            options={{ headerShown: false }}
          ></Stack.Screen>
        </>
      );
    } //
    if (!profileResult.data || !currentAccount || profileResult.error) {
      return (
        <Stack.Screen
          name="Loading"
          component={LoadingScreen}
          options={{ headerShown: false }}
        />
      );
    }
    if (
      !currentAccount.phone_confirmed_at ||
      !currentAccount.phone ||
      currentAccount.phone === ""
    ) {
      return (
        <>
          <Stack.Screen
            name="EditMobile"
            component={EditMobileScreen}
            options={{ headerShown: false }}
            navigationKey={
              currentAccount.phone_confirmed_at
                ? "phone-registration"
                : "phone-change"
            }
            initialParams={{
              number:
                currentAccount.phone ||
                currentAccount.user_metadata.auth?.phone,
            }}
          />

          <Stack.Screen
            name="VerifyMobile"
            navigationKey={
              currentAccount.phone_confirmed_at
                ? "phone-registration"
                : "phone-change"
            }
            component={VerifyMobileScreen}
            options={{ headerShown: false }}
          />
        </>
      );
    }
    const { profiles_by_pk: profile } = profileResult.data;
    if (!profile.name) {
      return (
        <Stack.Screen
          name="CompleteProfile"
          component={CompleteProfile}
          options={{ headerShown: false }}
        />
      );
    }
    const ModalStack = createNativeStackNavigator();

    const SpaceCreationModal = () => (
      <ModalStack.Navigator
        screenOptions={{
          headerShown: true,
          headerShadowVisible: false,
        }}
      >
        <ModalStack.Screen
          name="new-chat"
          component={NewChatScreen}
          options={{
            title: "New Chat",
            // headerShown: false,
          }}
        />
        <ModalStack.Screen
          name="create-space"
          component={CreateGroupScreen}
          options={{
            title: "New Group",

            // headerShown: false,
          }}
        />
      </ModalStack.Navigator>
    );

    // if (!profile.is_invited) {
    //   return (
    //     <Stack.Screen
    //       name="NotInvited"
    //       component={NotInvitedScreen}
    //       options={{ headerShown: false }}
    //     />
    //   );
    // }

    return (
      <>
        <Stack.Screen
          name="Root"
          component={MainScreen}
          initialParams={{ initialRoute: initialRouteState }}
          options={{ headerShown: false }}
        />

        <Stack.Screen
          name="NotFound"
          component={NotFoundScreen}
          options={{ title: "Oops!" }}
        />
        <Stack.Group
          screenOptions={{
            presentation: "card",
            animation: "slide_from_right",
          }}
        >
          <Stack.Screen name="Modal" component={ModalScreen} />
        </Stack.Group>
        <Stack.Screen
          name="space-creation-modal"
          component={SpaceCreationModal}
          options={{
            presentation: "modal",
            headerShown: false,
          }}
        />
        <Stack.Group
          screenOptions={{
            presentation: "modal",
            animation: "slide_from_bottom",
          }}
        >
          <Stack.Screen
            name="AllApps"
            component={AppsScreen}
            options={{ title: "Apps" }}
          />
          <Stack.Screen
            name="AttachmentPicker"
            component={ChatAttachmentPickerScreen}
          />
          <Stack.Screen name="PhotoPicker" component={PhotoPickerScreen} />
          <Stack.Screen
            name="ResourceShareModal"
            component={ResourceShareModalScreen}
            options={{
              title: "Share",
            }}
          />
          <Stack.Screen
            name="ResourceAttributionModal"
            component={ResourceAttributionModalScreen}
            options={{
              title: "Shared with",
            }}
          />
          <Stack.Screen
            name="ResourceImportModal"
            component={ResourceImportModalScreen}
            options={{
              title: "Import",
            }}
          />
          <Stack.Screen
            name="CollectionShareModal"
            component={CollectionShareModalScreen}
            options={{
              title: "Share",
            }}
          />
          <Stack.Screen
            name="CollectionImportModal"
            component={CollectionImportModalScreen}
            options={{
              title: "Import",
            }}
          />
          <Stack.Screen
            name="FollowCollectionsModal"
            component={FollowCollectionsModalScreen}
            options={{
              title: "Leaderboard",
              headerRight: ({}) => {
                return (
                  <Button variant="unstyled" onPress={navigation.goBack}>
                    Close
                  </Button>
                );
              },
            }}
          />
          <Stack.Screen
            name="CoverPhotoSelector"
            component={CoverPhotoSelectorScreen}
            options={{
              title: "Select cover photo",
            }}
          />
          <Stack.Screen
            name="EmojiPicker"
            component={EmojiPickerScreen}
            options={{ title: "Select emoji" }}
          />
          {/* <Stack.Screen
            name="NewChat"
            component={NewChatScreen}
            options={{
              headerShown: false,
            }}
          /> */}
          <Stack.Screen
            name="CreateSpace"
            component={CreateGroupScreen}
            options={{
              headerShown: false,
            }}
          />
          <Stack.Screen
            name="CreateMockSpace"
            component={CreateMockSpaceScreen}
            options={{
              headerShown: false,
            }}
          />
        </Stack.Group>
        <Stack.Screen
          name="EditMobile"
          navigationKey={
            currentAccount.phone_confirmed_at
              ? "phone-registration"
              : "phone-change"
          }
          component={EditMobileScreen}
          options={{ title: "Edit Phone" }}
        />
        <Stack.Group
          screenOptions={{
            presentation: "card",
            animation: "slide_from_bottom",
          }}
        >
          <Stack.Screen
            name="VerifyMobile"
            component={VerifyMobileScreen}
            navigationKey={
              currentAccount.phone_confirmed_at
                ? "phone-registration"
                : "phone-change"
            }
            options={{ title: "Verify Phone" }}
            initialParams={{ redirect: "BACK" }}
          />
        </Stack.Group>
        <Stack.Screen
          name="EditEmail"
          component={EditEmailScreen}
          options={{ title: "Edit Email" }}
        />
      </>
    );
  };

  return (
    <ResourceShareModalProvider>
      <ProfilePeekDrawerProvider>
        <Stack.Navigator>{getScreens()}</Stack.Navigator>
      </ProfilePeekDrawerProvider>
    </ResourceShareModalProvider>
  );
}

type MaybeScreens = () => {
  loading: boolean;
  screens: JSX.Element | null;
  error?: any;
};
