import { SxProp, TextInput as DripsyTextInput } from "dripsy";
import React, { ComponentProps, useState } from "react";
import DRIPSY_THEME from "../../constants/DripsyTheme";

interface FocusableTextInputProps
  extends ComponentProps<typeof DripsyTextInput> {
  focusedSx?: SxProp;
}

function TextInput(props: FocusableTextInputProps, ref: any) {
  const [isFocused, setIsFocused] = useState(false);
  return (
    <DripsyTextInput
      ref={ref}
      onFocus={() => {
        setIsFocused(true);
        props.onFocus;
      }}
      onBlur={() => {
        setIsFocused(false);
        props.onBlur;
      }}
      placeholderTextColor={DRIPSY_THEME.colors.$gray[500]}
      sx={{ ...props.sx, ...(isFocused ? props.focusedSx : {}) }}
      {...props}
    />
  );
}

export default React.forwardRef(TextInput);
