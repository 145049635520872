import { pushEvents } from "../utils/push-event";
import AsyncStorage from "@react-native-async-storage/async-storage";
import * as Notifications from "expo-notifications";
import {
  deleteFromUploadQueueBulk,
  readUploadQueueKeys,
  uploadStatus,
} from "./upload-queue";
import {
  getPendingResources,
  pushPendingEvents,
  evict,
  evictAllExcept,
} from "./pending-resources";
import { reportError } from "../state/utils/errors";

const EVENT_OUTBOX_KEY = "event_outbox";

export interface OutboxItem {
  dependencies: string[];
  eventPayload: any;
  failureAlertSent: boolean;
}

let localOutbox: OutboxItem[] = [];
let outboxFlushing = false;
let retryQueue = new Set();
let outboxDeletes: string[] = [];

export function queueDelayedEvents(items: OutboxItem[]) {
  localOutbox.push(...items);
  flushEventOutbox();
}

export function cancelDelayedEvents(ids: string[]) {
  outboxDeletes.push(...ids);
}

export async function flushEventOutbox() {
  if (outboxFlushing) return;
  try {
    outboxFlushing = true;
    const localOutboxCopy = [...localOutbox];

    // Ensure untracked items are tracked
    if (localOutboxCopy.length) {
      await pushOutboxItems(localOutboxCopy);
      localOutbox = localOutbox.filter(
        (item) => !localOutboxCopy.includes(item)
      );
      // create pending resources from unprocessed events
      pushPendingEvents(localOutboxCopy.map((item) => item.eventPayload));
    }

    let outbox = await getOutboxItems();

    // If we have items in the outbox, try pushing and other data
    if (outbox.length) {
      // mark some items for deletion
      const deleteSet = new Set(outboxDeletes);
      outboxDeletes = [];
      const deletes = outbox.filter((item) =>
        deleteSet.has(item.eventPayload.resource_id)
      );

      // try to push events that need to be sent
      const outboxToSend = outbox.filter(
        (item) => !deleteSet.has(item.eventPayload.resource_id)
      );
      const responses = await tryPushEvents(outboxToSend);

      const sent = outboxToSend.filter(
        (item) => responses.find((r) => r.item === item)?.status === "sent"
      );
      const unsent = outboxToSend.filter(
        (item) => responses.find((r) => r.item === item)?.status === "unsent"
      );
      const lost = outboxToSend.filter(
        (item) => responses.find((r) => r.item === item)?.status === "lost"
      );

      // notify on send failure
      trySendFailedUploadNotifications(unsent);

      // Update outbox
      // TODO: Need to handle lost items, they will stay in the event outbox forever at the moment
      // Can maybe recover if we check for them via supabase API
      await AsyncStorage.setItem(
        EVENT_OUTBOX_KEY,
        JSON.stringify([...unsent, ...lost])
      );

      // Delete successful uploads from upload tracking
      // Deal with unsent (pending, failed, lost) in userland
      const successfulUploads = new Set(
        sent.flatMap((item) => item.dependencies)
      );
      const uploadQueue = readUploadQueueKeys();
      deleteFromUploadQueueBulk([
        ...uploadQueue.filter((id) => successfulUploads.has(id)),
        ...deletes.flatMap((item) => item.dependencies),
      ]);
      // Evict pending resources now that items have been sent
      evict([
        ...sent.map((item) => item.eventPayload.resource_id),
        ...deletes.map((item) => item.eventPayload.resource_id),
      ]);
    }
  } catch (e) {
    reportError(e, { extra: { message: "outbox flushing error!" } });
  }

  outboxFlushing = false;
  // Continue flushing if new events have come in, otherwise wait for interval
  if (localOutbox.length > 0) flushEventOutbox();
}

async function tryPushEvents(items: OutboxItem[]): Promise<
  {
    item: OutboxItem;
    status: "sent" | "unsent" | "lost";
    error: any;
  }[]
> {
  const responses = [];

  const { itemsToSend, itemsToKeep, lostItems } = items.reduce<{
    itemsToSend: OutboxItem[];
    itemsToKeep: OutboxItem[];
    lostItems: OutboxItem[];
  }>(
    (categories, item) => {
      const { dependencies } = item;
      const ready = readyToSend(dependencies);
      if (ready === "YES") categories.itemsToSend.push(item);
      else if (ready === "NO") categories.itemsToKeep.push(item);
      else categories.lostItems.push(item);
      return categories;
    },
    { itemsToSend: [], itemsToKeep: [], lostItems: [] }
  );

  // Block here to prevent memory leak caused by urql: https://github.com/FormidableLabs/urql/issues/2355
  if (itemsToSend.length) {
    const pushResp = await pushEvents(
      itemsToSend.map((item) => item.eventPayload)
    );

    if (pushResp.error) {
      responses.push(
        ...itemsToSend.map((item) => ({
          item,
          status: "unsent" as const,
          error: pushResp.error,
        }))
      );
    } else {
      responses.push(
        ...itemsToSend.map((item) => ({
          item,
          status: "sent" as const,
          error: undefined,
        }))
      );
    }
  }

  responses.push(
    ...itemsToKeep.map((item) => ({
      item,
      status: "unsent" as const,
      error: undefined,
    }))
  );
  responses.push(
    ...lostItems.map((item) => ({
      item,
      status: "lost" as const,
      error: undefined,
    }))
  );
  return responses;
}

async function getOutboxItems() {
  return JSON.parse(
    (await AsyncStorage.getItem(EVENT_OUTBOX_KEY)) ?? "[]"
  ) as OutboxItem[];
}

async function pushOutboxItems(items: OutboxItem[]) {
  const outbox = await getOutboxItems();
  await AsyncStorage.setItem(
    EVENT_OUTBOX_KEY,
    JSON.stringify([...outbox, ...items])
  );
}

export function clearRetryNotifStatus(resourceId: string) {
  retryQueue.add(resourceId);
}

function trySendFailedUploadNotifications(items: OutboxItem[]) {
  items.forEach((item) => {
    // if the user clicked retry, we can send them another notification if this retry fails
    if (retryQueue.has(item.eventPayload.resource_id))
      Object.assign(item, { failureAlertSent: false });

    const failure = hasFailure(item.dependencies);
    // if any of the attachments to a message have failed and we haven't notified the user already, then do so
    // Lost attachments we assume are failed and unfindable
    if ((failure === "YES" || failure === "IDK") && !item.failureAlertSent) {
      Notifications.scheduleNotificationAsync({
        content: {
          title: "Aspen Spaces",
          body: "A photo failed to upload",
          data: { space_id: item.eventPayload.space_id },
        },
        trigger: null,
      });
      // set the flag so that we won't send another notification until they retry
      Object.assign(item, { failureAlertSent: true });
    }
  });
  retryQueue = new Set();
}

type AnswerOrIDK = "YES" | "NO" | "IDK";

function readyToSend(attachmentIds: string[]): AnswerOrIDK {
  // TODO: set up something to confirm with remote if upload status is lost (can use supabase API)
  const uploadStatuses = getAttachmentStatuses(attachmentIds);
  for (const upload of uploadStatuses) {
    if (!upload) return "IDK";
    if (upload.status !== "success") return "NO";
  }
  return "YES";
}

function hasFailure(attachmentIds: string[]) {
  // TODO: set up something to confirm with remote if upload status is lost (can use supabase API)
  const uploadStatuses = getAttachmentStatuses(attachmentIds);
  for (const upload of uploadStatuses) {
    if (!upload) return "IDK";
    if (upload.status === "failed") return "YES";
  }
  return "NO";
}

function getAttachmentStatuses(attachmentIds: string[]) {
  return attachmentIds.map((id) => uploadStatus(id));
}
