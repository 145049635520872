import React, { useCallback } from "react";
import { OperationResult } from "urql";
import { LogEventFragment as LogEvent } from "../state/generated/graphql";

import { pushEvent, pushEvents } from "../utils/push-event";
import { useEventPushSpaceId } from "./useSpaceId";

export const PushEventContext = React.createContext<
  (
    event: Required<
      Pick<LogEvent, "resource_key" | "type" | "data" | "resource_id">
    >
  ) => Promise<OperationResult<any, object>>
>(() => {
  throw new Error("Push Event not available.");
});

export function usePushEvent() {
  const spaceId = useEventPushSpaceId();
  const pushEventAdmin = usePushEventAdmin();
  return useCallback(
    (
      event: Pick<LogEvent, "resource_key" | "type" | "data" | "resource_id">
    ) => {
      return pushEventAdmin({ ...event, space_id: spaceId });
    },
    [spaceId, pushEventAdmin]
  );
}

export function usePushEvents() {
  const spaceId = useEventPushSpaceId();
  const pushEventsAdmin = usePushEventsAdmin();
  return useCallback(
    (
      events: Pick<LogEvent, "resource_key" | "type" | "data" | "resource_id">[]
    ) => {
      return pushEventsAdmin(
        events.map((event) => ({ ...event, space_id: spaceId }))
      );
    },
    [spaceId, pushEventsAdmin]
  );
}

export function usePushEventAdmin() {
  return pushEvent;
}

export function usePushEventsAdmin() {
  return pushEvents;
}
