import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { RootStackParamList } from "../types";
import {
  Button,
  Text,
  FlatList,
  Row,
  Divider,
  Spinner,
} from "../components/basics";
import { useSpaces } from "../hooks/spaces";
import { ListRenderItem } from "react-native";
import CenteredSpinner from "../components/basics/CenteredSpinner";
import { SpaceInfoFragment } from "../state/generated/graphql";
import React, { useCallback, useMemo, useState } from "react";
import { SafeAreaView } from "dripsy";
import { InfoCard } from "../components/ResourceCard";
import { useUser } from "../hooks/auth";
import { useCollection } from "../hooks/useCollections";
import useShareCollection from "../hooks/useShareCollection";
import { useToast } from "../hooks/useToast";
import SimpleSpaceListItem from "../components/SimpleSpaceListItem";
import { useChatList } from "../hooks/useChatList";

export default function CollectionShareModalScreen({
  route,
  navigation,
}: NativeStackScreenProps<RootStackParamList, "CollectionShareModal">) {
  const { collectionId, spaceIds } = route.params;
  const { data: spaces, loading } = useSpaces();
  const spaceIdSet = useMemo(
    () => (spaceIds ? new Set(spaceIds) : undefined),
    [spaceIds]
  );
  const [{ data: chatList }] = useChatList();

  const currentUser = useUser();
  const filteredSpaces = useMemo(() => {
    const externalSpaces = spaces.filter(
      (s) => s.private_space_owner_id !== currentUser!.id
    );
    if (!spaceIdSet) return externalSpaces;
    return externalSpaces.filter((s) => spaceIdSet.has(s.id));
  }, [spaceIds, spaceIdSet, currentUser]);

  const orderedSpaces = useMemo(() => {
    const chatIndices = new Map(chatList?.chat_list.map((cl, i) => [cl.id, i]));
    return filteredSpaces.slice().sort((a, b) => {
      const aIndex = chatIndices.get(a.id) ?? chatIndices.size;
      const bIndex = chatIndices.get(b.id) ?? chatIndices.size;
      let res = 0;
      if (!res) res = aIndex - bIndex;
      if (!res) res = a.id - b.id;
      return res;
    });
  }, [filteredSpaces, chatList]);

  const [{ data: collection, fetching: collectionLoading }] =
    useCollection(collectionId);
  const [additions, setAdditions] = useState<Set<string>>(new Set());
  const [removals, setRemovals] = useState<Set<string>>(new Set());
  const collectionSpaces = useMemo<Set<string>>(() => {
    return new Set(
      collection?.resource_collections_by_pk?.permissions?.map(
        (p) => p.space_id
      )
    );
  }, [collection]);
  const selectedSpaces = useMemo(() => {
    removals.forEach((r) => {
      collectionSpaces.delete(r);
    });
    return new Set([...collectionSpaces, ...additions]);
  }, [collectionSpaces, additions, removals]);

  const renderItem: ListRenderItem<SpaceInfoFragment> = ({ item }) => {
    function onPress() {
      if (collectionSpaces.has(item.id)) {
        if (selectedSpaces.has(item.id)) {
          setRemovals((prev) => new Set(prev.add(item.id)));
        } else {
          setRemovals((prev) => {
            prev.delete(item.id);
            return new Set(prev);
          });
        }
      } else {
        if (selectedSpaces.has(item.id)) {
          setAdditions((prev) => {
            prev.delete(item.id);
            return new Set(prev);
          });
        } else {
          setAdditions((prev) => new Set(prev.add(item.id)));
        }
      }
    }
    return (
      <SimpleSpaceListItem
        space={item}
        handlePress={onPress}
        selectedSpaces={selectedSpaces}
      />
    );
  };

  const updateCollectionPermissions = useShareCollection();
  const addToast = useToast();
  const [isSharing, setIsSharing] = useState(false);
  const handleResourceShare = useCallback(async () => {
    try {
      setIsSharing(true);
      const { error } = await updateCollectionPermissions(
        collectionId,
        [...additions],
        [...removals]
      );
      if (error) {
        addToast({
          title: "Share failure",
          description:
            "The request to share this collection could not be completed.",
          variant: "ERROR",
        });
      } else {
        navigation.goBack();
      }
    } catch {
      addToast({
        title: "Share failure",
        description:
          "The request to share this collection could not be completed.",
        variant: "ERROR",
      });
    } finally {
      setIsSharing(false);
    }
  }, [selectedSpaces, collectionId, addToast]);

  if (loading) return <CenteredSpinner text="Loading spaces" />;

  return (
    <SafeAreaView sx={{ flex: 1 }}>
      <Row justifyContent="center" alignItems="center" p={2}>
        {collectionLoading ? (
          <Spinner />
        ) : (
          <CollectionCard collection={collection?.resource_collections_by_pk} />
        )}
      </Row>
      <Divider />
      <FlatList
        contentContainerStyle={{ overflow: "hidden" }}
        data={orderedSpaces}
        extraData={selectedSpaces}
        renderItem={renderItem}
        keyExtractor={(item) => item.id}
      />
      <Button
        onPress={handleResourceShare}
        isLoading={isSharing}
        sx={{ borderRadius: 0 }}
        disabled={selectedSpaces.size === 0}
      >
        Share
      </Button>
    </SafeAreaView>
  );
}

function CollectionCard({ collection }: { collection: any }) {
  if (!collection) return null;
  return (
    <InfoCard icon={"albums-outline"} title={collection.name} snippet={""} />
  );
}
