import { Box, View, BackButton } from "../../../components/basics";
import React, { useEffect } from "react";
import {
  MDDescriptorMap,
  MDNavigationHelpers,
  MDNavigationState,
  MDRouterOptions,
} from "../types";
import { Screen, Header, getHeaderTitle } from "@react-navigation/elements";
import { SafeAreaView, useSafeAreaFrame } from "react-native-safe-area-context";
import Animated, {
  useAnimatedStyle,
  useSharedValue,
  withTiming,
} from "react-native-reanimated";
import { ScreenStack, Screen as NativeScreen } from "react-native-screens";
import { Platform, StyleSheet } from "react-native";
import { Ionicons } from "@expo/vector-icons";

type Props = MDRouterOptions & {
  state: MDNavigationState;
  navigation: MDNavigationHelpers;
  descriptors: MDDescriptorMap;
};

export default function MDView({
  state,
  navigation,
  descriptors,
  isSplitView,
  sidebarContent,
}: Props) {
  const descriptor = descriptors[state.routes[state.index]?.key];
  const isDrawerOpen = isSplitView || state.isOpen;

  useEffect(() => {
    navigation.setShouldSplitView(isSplitView);
  }, [isSplitView]);

  const {
    header = ({ layout, options }: any) => (
      <Header
        {...options}
        layout={layout}
        title={getHeaderTitle(options, state.routes[state.index].name)}
        headerLeft={
          options.headerLeft ??
          ((state.index > 0 || Platform.OS !== "web") &&
            ((props) => <BackButton {...props} />))
        }
      />
    ),
    sceneContainerStyle,
  } = descriptor.options as any;

  const dimensions = useSafeAreaFrame();

  const screenOpacity = useSharedValue(isDrawerOpen ? 0 : 1);

  useEffect(() => {
    screenOpacity.value = isDrawerOpen && !isSplitView ? 0 : 1;
  }, [isDrawerOpen]);

  return (
    <View style={{ flex: 1, flexDirection: "row" }}>
      {isSplitView ? (
        <Box width={300} maxW={"45%"} bg="#F7F7F7">
          {sidebarContent({ state, navigation, descriptors })}
        </Box>
      ) : null}
      <ScreenStack style={{ flex: 1, height: "100%" }}>
        {state.isSplitView ? null : (
          <NativeScreen
            enabled
            replaceAnimation={"push"}
            stackPresentation={"push"}
            gestureEnabled={Platform.OS === "ios"}
            style={StyleSheet.absoluteFill}
          >
            <Box
              // bg={{
              //   linearGradient: {
              //     colors: ["#014D6E", "rgba(4, 104, 56, 42)"],
              //     start: [0, 0],
              //     end: [0, 1],
              //   },
              // }}
              flex="1"
            >
              {sidebarContent({ state, navigation, descriptors })}
            </Box>
          </NativeScreen>
        )}
        {isSplitView || !state.isOpen ? (
          <NativeScreen
            enabled
            key={descriptor.route.key}
            stackPresentation="push"
            stackAnimation={isSplitView ? "none" : "default"}
            gestureEnabled={true}
            style={{
              flex: 1,
              width: "100%",
              height: "100%",
            }}
            onDismissed={() => {
              navigation.goBack();
            }}
          >
            <Screen
              focused={true}
              route={descriptor.route}
              navigation={descriptor.navigation}
              headerShown={descriptor.options.headerShown}
              headerTransparent={descriptor.options.headerTransparent}
              headerStatusBarHeight={descriptor.options.headerStatusBarHeight}
              header={header({
                layout: dimensions,
                route: descriptor.route,
                navigation: descriptor.navigation,
                options: descriptor.options,
              })}
              style={sceneContainerStyle}
            >
              {descriptor.render()}
            </Screen>
          </NativeScreen>
        ) : null}
      </ScreenStack>
    </View>
  );
}
