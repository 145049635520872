import { useQuery } from "urql";
import { GetResourceByIdDocument } from "../state/generated/graphql";

export default function useResource(
  collectionKey: string | undefined,
  id: string | undefined
) {
  const [response, ...rest] = useQuery({
    query: GetResourceByIdDocument,
    variables: { collection_key: collectionKey!, id: id! },
    requestPolicy: "cache-and-network",
    pause: !collectionKey || !id,
  });
  return [
    {
      ...response,
      data: !!response.data?.resources_by_pk?.data ? response.data : undefined,
    },
    ...rest,
  ];
}
