import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { RootStackParamList } from "../types";
import SimpleSpaceListItem from "../components/SimpleSpaceListItem";
import {
  Button,
  FlatList,
  Row,
  Icon,
  Divider,
  Spinner,
} from "../components/basics";
import { useSpaces } from "../hooks/spaces";
import { useResourcePermissions } from "../hooks/useResourcePermissions";
import { ListRenderItem } from "react-native";
import CenteredSpinner from "../components/basics/CenteredSpinner";
import { SpaceInfoFragment } from "../state/generated/graphql";
import { useCallback, useMemo, useState } from "react";
import { SafeAreaView } from "dripsy";
import useShareResource from "../hooks/useShareResource";
import ResourceCard from "../components/ResourceCard";
import useResource from "../hooks/useResource";
import { useUser } from "../hooks/auth";
import { SCHEDULE_RESOURCE_KEY } from "../resources/schedule";
import { formatNoticeDate } from "../utils/apps/schedule";
import { PAGES_RESOURCE_KEY } from "../resources/page";
import { parseNotePreview } from "../utils/apps/notes";
import { GEO_GENIUS_RESOURCE_KEY } from "../resources/geo-genius";
import { useToast } from "../hooks/useToast";
import { LE_WORD_RESOURCE_KEY } from "../resources/le-word";
import { usePushNoticeAdmin } from "../hooks/usePushNotice";
import { useChatList } from "../hooks/useChatList";
import { POLL_RESOURCE_KEY } from "../resources/poll";

export default function ResourceShareModalScreen({
  route,
  navigation,
}: NativeStackScreenProps<RootStackParamList, "ResourceShareModal">) {
  const { resourceId, resourceKey, spaceIds } = route.params;
  const { data: spaces, loading } = useSpaces();
  const [{ data: chatList }] = useChatList();

  const spaceIdSet = useMemo(
    () => (spaceIds ? new Set(spaceIds) : undefined),
    [spaceIds]
  );

  const currentUser = useUser();
  const filteredSpaces = useMemo(() => {
    const externalSpaces = spaces.filter(
      (s) => s.private_space_owner_id !== currentUser!.id
    );
    if (!spaceIdSet) return externalSpaces;
    return externalSpaces.filter((s) => spaceIdSet.has(s.id));
  }, [spaceIds, spaceIdSet, currentUser]);

  const orderedSpaces = useMemo(() => {
    const chatIndices = new Map(chatList?.chat_list.map((cl, i) => [cl.id, i]));
    return filteredSpaces.slice().sort((a, b) => {
      const aIndex = chatIndices.get(a.id) ?? chatIndices.size;
      const bIndex = chatIndices.get(b.id) ?? chatIndices.size;
      let res = 0;
      if (!res) res = aIndex - bIndex;
      if (!res) res = a.id - b.id;
      return res;
    });
  }, [filteredSpaces, chatList]);

  const resourcePermissions = useResourcePermissions(resourceKey, resourceId);
  const [{ data: resource, fetching: resourceLoading }] = useResource(
    resourceKey,
    resourceId
  );
  const [selectedSpaces, setSelectedSpaces] = useState<Set<string>>(new Set());
  const extraData = useMemo(
    () => ({
      resourcePermissions: resourcePermissions.data?.resource_permissions,
      selectedSpaces,
    }),
    [resourcePermissions, selectedSpaces]
  );
  const renderItem: ListRenderItem<SpaceInfoFragment> = ({ item }) => {
    return (
      <SimpleSpaceListItem
        space={item}
        handlePress={() => {
          setSelectedSpaces((prev) => {
            if (prev.has(item.id)) {
              prev.delete(item.id);
              return new Set(prev);
            }
            return new Set(prev.add(item.id));
          });
        }}
        selectedSpaces={extraData.selectedSpaces}
      />
    );
  };

  const shareResource = useShareResource();
  const addToast = useToast();
  const [isSharing, setIsSharing] = useState(false);
  const pushNoticeAdmin = usePushNoticeAdmin();
  const handleResourceShare = useCallback(async () => {
    try {
      setIsSharing(true);
      const { error } = await shareResource(resourceId, resourceKey, [
        ...selectedSpaces,
      ]);
      if (error) {
        addToast({
          title: "Share failure",
          description:
            "The request to share this resource could not be completed.",
          variant: "ERROR",
        });
      } else {
        // TODO: push notices in bulk
        const resourceData = resource?.resources_by_pk?.data;
        if (currentUser && resourceData) {
          if (resourceKey === SCHEDULE_RESOURCE_KEY) {
            for (const spaceId of selectedSpaces) {
              pushNoticeAdmin(
                `%0 shared an event "${
                  resourceData.title
                }" on ${formatNoticeDate(
                  new Date(resourceData.start_date_utc),
                  resourceData.all_day
                )}`,
                [currentUser.id],
                { resourceId, resourceKey, noticeKey: "SHARE" },
                spaceId
              );
            }
          } else if (resourceKey === PAGES_RESOURCE_KEY) {
            for (const spaceId of selectedSpaces) {
              const { title } = parseNotePreview(resourceData.rawText);
              pushNoticeAdmin(
                `%0 shared a note "${title}"`,
                [currentUser.id],
                { resourceId, resourceKey, noticeKey: "SHARE" },
                spaceId
              );
            }
          } else if (resourceKey === POLL_RESOURCE_KEY) {
            for (const spaceId of selectedSpaces) {
              const { title } = resourceData;
              pushNoticeAdmin(
                `%0 shared a poll${title ? `: "${title}"` : ""}`,
                [currentUser.id],
                { resourceId, resourceKey, noticeKey: "SHARE" },
                spaceId
              );
            }
          } else if (resourceKey === GEO_GENIUS_RESOURCE_KEY) {
            for (const spaceId of selectedSpaces) {
              let variableMessage: string;
              const { distance } = resourceData;
              if (distance < 100) {
                variableMessage = "pinpointed today’s Geo Genius!";
              } else if (distance <= 1000) {
                variableMessage = "came close on today’s Geo Genius";
              } else if (distance <= 7000) {
                variableMessage = "missed today's Geo Genius";
              } else {
                variableMessage = "made a wild guess in today's Geo Genius";
              }
              pushNoticeAdmin(
                `🌎 %0 ${variableMessage} (${
                  resourceData.distance.toString().split(".")[0]
                }km)`,
                [currentUser.id],
                { resourceId, resourceKey, noticeKey: "SHARE" },
                spaceId
              );
            }
          }
        }
        navigation.goBack();
      }
    } catch {
      addToast({
        title: "Share failure",
        description:
          "The request to share this resource could not be completed.",
        variant: "ERROR",
      });
    } finally {
      setIsSharing(false);
    }
  }, [selectedSpaces, resourceId, resourceKey, resource, currentUser]);

  if (loading) return <CenteredSpinner text="Loading spaces" />;

  return (
    <SafeAreaView sx={{ flex: 1 }}>
      <Row justifyContent="center" alignItems="center" p={2}>
        {resource.resources_by_pk ? (
          <ResourceCard resource={resource.resources_by_pk} />
        ) : (
          <Spinner />
        )}
      </Row>
      <Divider />
      <FlatList
        data={orderedSpaces}
        extraData={extraData}
        renderItem={renderItem}
        keyExtractor={(item) => item.id}
      />
      <Button
        onPress={handleResourceShare}
        isLoading={isSharing}
        disabled={selectedSpaces.size === 0}
        sx={{ borderRadius: 0 }}
      >
        Share
      </Button>
    </SafeAreaView>
  );
}
