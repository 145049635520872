import {
  Text,
  View,
  Button,
  Spinner,
  KeyboardAvoidingView,
} from "../components/basics";
import { MotiView } from "moti";
import React, { ReactNode, useState } from "react";
import Lottie from "lottie-react-native";
import { useNavigation } from "@react-navigation/native";
import supabase from "../lib/supabase";
import CodeInput from "./CodeInput";
import { useToast } from "../hooks/useToast";
import { Platform } from "react-native";

export default function AuthMessage({
  title,
  message,
  email = "the email you provided",
  page,
}: {
  title: string | ReactNode;
  message: string | ReactNode;
  email: string;
  page: "SIGN_IN" | "SIGN_UP";
}) {
  const navigation = useNavigation();
  const [isChecking, setIsChecking] = useState(false);
  const [code, setCode] = useState("");
  const CODE_LENGTH = 6;

  const addToast = useToast();

  async function onInput(code: string) {
    setCode(code);
    if (code.length === CODE_LENGTH) {
      setIsChecking(true);
      try {
        const { user, session, error } = await supabase.auth.verifyOTP({
          email,
          token: code,
          type: page === "SIGN_IN" ? "magiclink" : "signup",
        });
        if (error) {
          setCode("");
          addToast({
            description: "Code failed",
            position: "bottom",
            variant: "ERROR",
          });
        }
      } catch (e) {
        setCode("");
        addToast({
          description: "Code failed",
          position: "bottom",
          variant: "ERROR",
        });
      }
      setIsChecking(false);
    }
  }

  return (
    <KeyboardAvoidingView
      behavior={Platform.OS === "ios" ? "padding" : "height"}
      style={{ flex: 1 }}
    >
      <View
        sx={{
          flex: 1,
          justifyContent: "center",
          alignItems: "center",
          paddingX: 50,
        }}
      >
        <Button
          variant="unstyled"
          sx={{ position: "absolute", left: 0, top: 40 }}
          leftIcon={"arrow-back"}
          onPress={() => navigation.goBack()}
        >
          Back
        </Button>
        <Lottie
          source={require("../assets/animations/sending-mail.json")}
          style={{ width: 250, height: 250 }}
          autoPlay
        />
        <MotiView
          style={{ alignItems: "center" }}
          from={{ opacity: 0, translateY: 20 }}
          animate={{ opacity: 1, translateY: 0 }}
        >
          <View sx={{ mb: "$4" }}>
            {isChecking ? (
              <Spinner />
            ) : (
              <CodeInput
                value={code}
                onChange={onInput}
                length={CODE_LENGTH}
                disabled={isChecking}
              />
            )}
          </View>
          <Text sx={{ fontSize: "2xl", fontWeight: "bold", mb: "$2" }}>
            {title}
          </Text>
          <Text sx={{ textAlign: "center", fontSize: "lg", mb: "$2" }}>
            {message}
          </Text>
          <Text sx={{ fontSize: "xl", fontWeight: "bold", mb: "$4" }}>
            {email}
          </Text>
          <Text
            sx={{
              textAlign: "center",
              fontSize: "md",
              color: "$secondary.800",
            }}
          >
            If you don't see an email from Aspen please check your spam folder
          </Text>
        </MotiView>
      </View>
    </KeyboardAvoidingView>
  );
}
