import React from "react";
import { useQuery } from "urql";
import { View, Image, FlatList, Text } from "../components/basics";
import { CoverPhotoView } from "../components/CoverPhotoView";
import {
  GetGroupsDocument,
  GetGroupsQuery,
  SpaceInfoFragment,
} from "../state/generated/graphql";

const COLORS = [
  "$aspenRed",
  "$aspenBlue",
  "$aspenGreen",
  "$aspenYellow",
  "$aspenPink",
];

function renderGroup({ item: space, index }: { item: SpaceInfoFragment }) {
  console.log(index, index % (COLORS.length - 1));
  return (
    <View
      sx={{
        alignItems: "center",
        justifyContent: "center",
        flex: 1,
        marginTop: "$5",
      }}
    >
      {space.cover_photo ? (
        <CoverPhotoView
          sx={{ borderRadius: "md" }}
          size={100}
          coverPhoto={space.cover_photo}
        />
      ) : (
        <View
          sx={{
            width: 100,
            height: 100,
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: COLORS[index % (COLORS.length - 1)],
            borderRadius: "md",
          }}
        />
      )}
      <Text sx={{ margin: "$2", textAlign: "center" }}>
        {space.display_name}
      </Text>
    </View>
  );
}

export default function GroupsPage() {
  const [{ data, fetching, error }] = useQuery<GetGroupsQuery>({
    query: GetGroupsDocument,
  });

  const groups: SpaceInfoFragment[] = data?.spaces ?? [];
  return (
    <View sx={{ flex: 1 }}>
      <FlatList
        numColumns={2}
        sx={{ flex: 1 }}
        data={groups}
        renderItem={renderGroup}
      />
    </View>
  );
}
