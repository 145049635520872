import ResourceCollection from "./resource";

export interface Notice {
  text: string;
  text_user_ids?: string[];
  static_text?: string;
  metadata?: Record<string, string>;
}

export const NOTICE_RESOURCE_KEY = "NOTICE";

const noticeResource = new ResourceCollection<
  Notice,
  {
    NEW: {
      text_user_ids?: string[];
      text: string;
      static_text?: string;
      metadata?: Record<string, string>;
    };
  }
>(NOTICE_RESOURCE_KEY, {
  NEW: (_resource, { data }, fromServer) => {
    return {
      text: data.text,
      text_user_ids: data.text_user_ids,
      static_text: data.static_text,
      metadata: data.metadata,
    };
  },
});

export default noticeResource;
