import {
  InputToolbarProps,
  InputToolbar as ChatInputToolbar,
} from "react-native-gifted-chat";
import {
  AspenChatMessage,
  AttachmentState,
} from "../../screens/spaces/ChatScreen";
import { View, IconButton, ScrollView, Spinner } from "../../components/basics";
import { AttachmentMedaiCard, LocalAssetMediaCard } from "../MediaCard";
import ResourceChatAttachment from "../ResourceChatAttachment";
import { useSx } from "dripsy";
interface AspenInputToolbarProps extends InputToolbarProps<AspenChatMessage> {
  attachments?: AttachmentState[];
  resourceAttachments: any[];
  attachmentUploading?: boolean;
  color: string;
  onImageButtonPress: () => Promise<void>;
  onCameraButtonPress: () => Promise<void>;
  onAttachmentDelete: (attachmentId: string) => void;
  onResourceAttachmentDelete: (resourceId: string) => void;
  isBottomSheetOpen: boolean;
}

export function InputToolbar(props: AspenInputToolbarProps) {
  const attachmentHeight = 100,
    attachmentWidth = 100;
  const padding = 8;
  const shouldDisplay =
    !!props.attachments?.length ||
    props.attachmentUploading ||
    !!props.resourceAttachments?.length;
  const sx = useSx();
  return (
    <View style={{ flex: 1 }}>
      {shouldDisplay ? (
        <View
          sx={{
            position: "absolute",
            top: -attachmentHeight - 2 * padding,
            left: 0,
            width: "100%",
            height: attachmentHeight + 2 * padding,
            alignItems: "stretch",
            flexDirection: "row",
          }}
        >
          {props.attachmentUploading ? (
            <Spinner
              accessibilityLabel="Uploading image"
              position={"absolute"}
              left={attachmentWidth / 2 - 16}
              top={attachmentHeight / 2 - 16}
              size="sm"
            />
          ) : props.attachments?.length || props.resourceAttachments?.length ? (
            <ScrollView
              contentContainerStyle={{
                alignItems: "center",
                paddingRight: 8,
              }}
              showsHorizontalScrollIndicator={false}
              horizontal={true}
            >
              {props.resourceAttachments?.map((ra) => (
                <View key={ra.resource.id} sx={{ marginLeft: "$2" }}>
                  <IconButton
                    _containerStyles={{
                      position: "absolute",
                      zIndex: 200,
                      right: 0,
                      top: 0,
                      padding: 0,
                      marginRight: "$1",
                      marginTop: "$1",
                      borderRadius: "lg",
                    }}
                    icon="close-sharp"
                    _iconStyles={{ fontSize: "lg", color: "$gray.600" }}
                    onPress={() =>
                      props.onResourceAttachmentDelete(ra.resource.id)
                    }
                  />
                  <ResourceChatAttachment resource={ra.resource} />
                </View>
              ))}
              {props.attachments?.map((attachment) => (
                <View
                  key={attachment.id}
                  sx={{
                    borderRadius: "sm",
                    marginLeft: "$2",
                    boxShadow: "md",
                    height: attachmentHeight,
                    width: attachmentWidth,
                  }}
                >
                  <IconButton
                    _containerStyles={{
                      position: "absolute",
                      zIndex: 200,
                      right: 1,
                      top: 1,
                      padding: 0,
                      borderRadius: "lg",
                      boxShadow: "md",
                    }}
                    icon="close-circle-sharp"
                    _iconStyles={{ fontSize: "2xl", color: "$white" }}
                    onPress={() => props.onAttachmentDelete(attachment.id)}
                  />
                  <AttachmentMedaiCard
                    size={attachmentWidth}
                    sx={{ borderRadius: "xs" }}
                    attachment={attachment}
                  />
                </View>
              ))}
            </ScrollView>
          ) : (
            <></>
          )}
        </View>
      ) : null}

      <ChatInputToolbar
        {...props}
        primaryStyle={{
          color: props.color,
        }}
        textInputStyle={{
          color: props.color,
        }}
        containerStyle={sx({
          backgroundColor: "transparent",
          borderTopWidth: 0,
        })}
        renderActions={() => (
          <View
            sx={{
              marginX: "$1",
              flexDirection: "row",
              alignSelf: "center",
              alignItems: "center",
            }}
          >
            <IconButton
              _containerStyles={{
                padding: 5,
              }}
              icon="add-circle"
              _iconStyles={{
                fontSize: "3xl",
                transform: props.isBottomSheetOpen
                  ? [{ rotate: "45deg" }]
                  : undefined,
              }}
              onPress={props.onImageButtonPress}
            />
            <IconButton
              _containerStyles={{ padding: 5 }}
              icon="camera"
              _iconStyles={{ fontSize: "3xl" }}
              onPress={props.onCameraButtonPress}
            />
          </View>
        )}
      />
    </View>
  );
}
