import { Button, IconButton, View } from "../components/basics";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  InteractionManager,
  ListRenderItem,
  Platform,
  StyleSheet,
} from "react-native";
import useIsLargeScreen from "../hooks/useIsLargeScreen";
import { ChatListItemFragment } from "../state/generated/graphql";
import { AnimatedFlatList } from "./animated-flat-list";
import { TabScreenProps } from "./NavDrawer";
import SpacesFeedItem from "./SpacesFeedItem";
import { useChatList } from "../hooks/useChatList";

// export default React.memo(SpacesList);
export default function SpacesList({
  navigation,
  verticalOffset = { top: 0, bottom: 0 },
  scrollHandler,
}: TabScreenProps) {
  const state = navigation.getState();
  const [{ data: listItems }] = useChatList();
  const [selectedSpaceId, setSelectedSpaceId] = useState<string>(
    state.routes[state.index].params?.spaceId ?? ""
  );
  useEffect(() => {
    const parentNavigator = navigation.getParent();
    const onNewState = ({ data: { state } }) => {
      const screenState = state.routes[state.index].state;
      if (screenState?.isSplitView) {
        InteractionManager.runAfterInteractions(() => {
          setSelectedSpaceId(
            screenState?.routes[state.index].params?.spaceId ?? ""
          );
        });
      }
    };
    parentNavigator?.addListener("state", onNewState);
    return () => {
      parentNavigator?.removeListener("state", onNewState);
    };
  }, [navigation]);

  const isLargeScreen = useIsLargeScreen();

  const renderItem: ListRenderItem<ChatListItemFragment> = useCallback(
    ({ item: space }) => (
      <SpacesFeedItem
        onPress={() => {
          navigation.navigate("conversation", {
            spaceId: space.id,
          });
        }}
        isActive={isLargeScreen && selectedSpaceId === space.id}
        space={space}
        sx={{
          borderBottomWidth: "hairline",
          borderColor: "$coolGray.100",
          paddingX: "$3",
          borderRadius: 0,
        }}
        containerStyle={styles}
      />
    ),
    []
  );

  return (
    <View sx={{ flex: 1 }}>
      <AnimatedFlatList
        contentContainerStyle={{
          paddingTop: Platform.OS != "ios" ? verticalOffset.top : 0,
          paddingBottom: Platform.OS != "ios" ? verticalOffset.bottom : 0,
        }}
        contentInset={verticalOffset}
        estimatedItemSize={100}
        contentOffset={{
          x: 0,
          y: Platform.OS === "ios" ? -verticalOffset.top : 0,
        }}
        progressViewOffset={verticalOffset.top}
        sx={{
          flex: 1,
          height: "100%",
          zIndex: 2,
        }}
        data={listItems?.chat_list ?? []}
        keyExtractor={(item, i) => item.id}
        onScroll={scrollHandler}
        renderItem={renderItem}
      />
      <IconButton
        variant="solid"
        colorScheme="$primary"
        size="lg"
        sx={{
          borderRadius: 50,
          elevation: 20,
          width: 56,
          height: 56,
          zIndex: 20,
          boxShadow: "md",
          alignItems: "center",
          justifyContent: "center",
          padding: 0,
          position: "absolute",
          bottom: "$3",
          right: "$3",
        }}
        _iconStyles={{ fontSize: 32 }}
        icon="add"
        onPress={() => {
          // navigation.navigate("CreateSpace");
          navigation.navigate("space-creation-modal");
        }}
      />
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    // backgroundColor: "white",
    // borderColor: THEMES.spaces.primary,
    // borderWidth: 0,
    // borderRadius: 16,
  },
  active: {
    // borderWidth: 2,
  },
});
