import { atom, AtomEffect } from "recoil";
import {
  UploadMetadata,
  addImageUploadDequeueListener,
  addImageUploadStatusListener,
  removeImageUploadDequeueListener,
  removeImageUploadStatusListener,
  uploadStatuses,
  readUploadQueue,
} from "../../lib/upload-queue";

const uploadQueueEffect: AtomEffect<Record<string, UploadMetadata>> = ({
  setSelf,
}) => {
  setSelf(uploadStatuses());
  const enqueueListener = (item: UploadMetadata) => {
    setSelf((prev) => (prev ? { ...prev, [item.key]: item } : prev));
  };
  const dequeueListener = (itemId: string) => {
    setSelf((prev) => {
      if (prev) {
        const { [itemId]: _, ...remainingUploads } = prev;
        return remainingUploads;
      }
      return prev;
    });
  };

  addImageUploadStatusListener(enqueueListener);
  addImageUploadDequeueListener(dequeueListener);

  return () => {
    removeImageUploadStatusListener(enqueueListener);
    removeImageUploadDequeueListener(dequeueListener);
  };
};

export const uploadQueueState = atom<Record<string, UploadMetadata>>({
  key: "upload_queue",
  default: uploadStatuses(),
  effects_UNSTABLE: [uploadQueueEffect],
});
