import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { RootStackParamList } from "../types";
import {
  Button,
  Text,
  FlatList,
  Row,
  Icon,
  Pressable,
  Divider,
  Spinner,
  Column,
} from "../components/basics";
import { useSpace } from "../hooks/spaces";
import { ListRenderItem } from "react-native";
import CenteredSpinner from "../components/basics/CenteredSpinner";
import { ResourceFragment } from "../state/generated/graphql";
import { useSpaceMember, useSpaceMembers } from "../hooks/useSpaceMembers";
import { SafeAreaView } from "dripsy";
import { cardInfo } from "../components/ResourceCard";
import { useUser } from "../hooks/auth";
import { LE_WORD_RESOURCE_KEY } from "../resources/le-word";
import { useCustomResourceCollection } from "../hooks/useResourceCollection";
import useImportResources from "../hooks/useImportResource";
import { useCallback, useMemo, useState } from "react";
import { useToast } from "../hooks/useToast";

export default function ResourceImportModalScreen({
  route,
  navigation,
}: NativeStackScreenProps<RootStackParamList, "ResourceImportModal">) {
  const { resourceKey, spaceId } = route.params;
  const { data: space, loading: spaceLoading } = useSpace(spaceId);
  const currentUser = useUser();
  const spaceMembers = useSpaceMembers(space?.id);
  const { resources, fetching: resourcesLoading } = useCustomResourceCollection(
    {
      resourceQueries: { [resourceKey]: {} },
    }
  );

  const filteredResources = useMemo(() => {
    if (!resources) return undefined;
    if (resourceKey === LE_WORD_RESOURCE_KEY) {
      return resources.filter(
        (r) => currentUser?.id && r.data.playerId === currentUser.id
      );
    }
    return resources;
  }, [resources, resourceKey]);

  const [selectedResources, setSelectedResources] = useState<Set<string>>(
    new Set()
  );
  const renderItem: ListRenderItem<ResourceFragment> = ({ item }) => {
    return (
      <ResourcesListItem
        resource={item}
        handlePress={() => {
          setSelectedResources((prev) => {
            const key = `${item.collection_key}|${item.id}`;
            if (prev.has(key)) {
              prev.delete(key);
              return new Set(prev);
            }
            return new Set(prev.add(key));
          });
        }}
        selectedResources={selectedResources}
      />
    );
  };

  const importResources = useImportResources();
  const addToast = useToast();
  const [isSharing, setIsSharing] = useState(false);
  const handleImport = useCallback(async () => {
    try {
      setIsSharing(true);
      const { error } = await importResources(spaceId, [...selectedResources]);
      if (error) {
        addToast({
          title: "Import failure",
          description:
            "The request to import these resource could not be completed.",
          variant: "ERROR",
        });
      } else {
        navigation.goBack();
      }
    } catch {
      addToast({
        title: "Import failure",
        description:
          "The request to import these resource could not be completed.",
        variant: "ERROR",
      });
    } finally {
      setIsSharing(false);
    }
  }, [selectedResources, spaceId, navigation]);

  if (resourcesLoading) return <CenteredSpinner text="Loading resources" />;

  return (
    <SafeAreaView sx={{ flex: 1 }}>
      <Row justifyContent="center" alignItems="center" p={2}>
        {spaceLoading ? (
          <Spinner />
        ) : (
          <Row alignItems="center">
            <Text numberOfLines={1} sx={{ fontWeight: "bold" }}>
              Import to: {space?.display_name || "N/A"}
            </Text>
          </Row>
        )}
      </Row>
      <Divider />
      <FlatList
        data={filteredResources}
        extraData={selectedResources}
        renderItem={renderItem}
        keyExtractor={(item) => item.id}
      />
      <Button
        onPress={handleImport}
        isLoading={isSharing}
        disabled={selectedResources.size === 0}
        mb={1}
      >
        Import
      </Button>
    </SafeAreaView>
  );
}

function ResourcesListItem({
  resource,
  handlePress,
  selectedResources,
}: {
  resource: ResourceFragment;
  handlePress: () => void | Promise<void>;
  selectedResources: Set<string>;
}) {
  const key = `${resource.collection_key}|${resource.id}`;
  const isSelected = selectedResources.has(key);
  const { icon, title, snippet } = cardInfo(resource);
  return (
    <Pressable
      onPress={handlePress}
      sx={{ backgroundColor: isSelected ? "$gray.200" : "$white" }}
    >
      <Row h={70} alignItems="center" justifyContent="center">
        <Column alignItems={"center"} justifyContent="center" w="1/5">
          <Icon name={icon} size="3xl" />
        </Column>
        <Column alignItems="center" justifyContent="center" w="3/5">
          <Text sx={{ fontWeight: "bold" }}>{title}</Text>
          {!!snippet && <Text>{snippet}</Text>}
        </Column>
        <Column alignItems="center" justifyContent="center" w="1/5">
          {isSelected && <Icon size={"xl"} name="checkmark-circle-outline" />}
        </Column>
      </Row>

      <Divider />
    </Pressable>
  );
}
