import { SxProp, Image as DripsyImage } from "dripsy";
import React, { memo } from "react";
import { Image, View, Icon } from "../components/basics";
import {
  AssetVideoThumbnail,
  AttachmentVideoThumbnail,
} from "./video-thumbail";
import { ResizeMode, Video } from "expo-av";
import * as MediaLibrary from "expo-media-library";
import { AttachmentState } from "../screens/spaces/ChatScreen";

function UnMemoLocalAssetMediaCard({
  asset,
  size,
  sx,
}: {
  asset: MediaLibrary.Asset;
  size: number;
  sx?: SxProp;
}) {
  return (
    <MediaCardWrapper sx={sx} size={size}>
      {asset.mediaType === "photo" && (
        <DripsyImage
          source={{ uri: asset.uri }}
          sx={{ width: size, height: size }}
          style={{ width: size, height: size }}
          accessibilityLabel="cameraroll image"
        />
      )}
      {asset.mediaType === "video" && (
        <AssetVideoThumbnail asset={asset} width={size} height={size} />
      )}
    </MediaCardWrapper>
  );
}

function UnMemoAttachmentMediaCard({
  attachment,
  size,
  sx,
}: {
  attachment: AttachmentState;
  size: number;
  sx?: SxProp;
}) {
  return (
    <MediaCardWrapper sx={sx} size={size}>
      {attachment.mediaType === "photo" && (
        <DripsyImage
          source={{ uri: attachment.localUri || attachment.uri }}
          sx={{ width: size, height: size }}
          style={{ width: size, height: size }}
          accessibilityLabel="cameraroll image"
        />
      )}
      {attachment.mediaType === "video" && (
        <AttachmentVideoThumbnail
          attachment={attachment}
          width={size}
          height={size}
        />
      )}
    </MediaCardWrapper>
  );
}

export const LocalAssetMediaCard = memo(UnMemoLocalAssetMediaCard);
export const AttachmentMedaiCard = memo(UnMemoAttachmentMediaCard);

export default function RemoteMediaCard({
  uri,
  type,
  size,
  sx,
}: {
  uri: string;
  type: "video" | "photo";
  size: number;
  sx?: SxProp;
}) {
  return (
    <MediaCardWrapper sx={sx} size={size}>
      {type === "photo" && (
        <Image
          source={{ uri }}
          sx={{ width: size, height: size }}
          style={{ width: size, height: size }}
          accessibilityLabel="cameraroll image"
        />
      )}
      {type === "video" && (
        <>
          <Video
            source={{ uri }}
            shouldPlay={false}
            resizeMode={ResizeMode.COVER}
            style={{ height: size, width: size }}
          />
          <View
            sx={{
              position: "absolute",
              zIndex: 10,
              width: "100%",
              height: "100%",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Icon
              name="play-circle"
              color="white"
              size={48}
              sx={{
                textShadowOffset: { width: 1, height: 1 },
                textShadowRadius: 5,
                textShadowColor: "#00000099",
              }}
            />
          </View>
        </>
      )}
    </MediaCardWrapper>
  );
}

const MediaCardWrapper: React.FC<{ sx?: SxProp; size: number }> = ({
  sx,
  size,
  children,
}) => {
  return (
    <View
      sx={{
        height: size,
        width: size,
        flex: 1,
        overflow: "hidden",
        backgroundColor: "$gray.100",
        ...sx,
      }}
    >
      {children}
    </View>
  );
};
