import ResourceCollection from "./resource";

interface ShoppingItem {
  text: string;
  is_complete: boolean;
}

export const SHOPPING_LIST_RESOURCE_KEY = "SHOPPING_LIST";

const shoppingListResource = new ResourceCollection<
  ShoppingItem,
  {
    NEW: { text: string };
    UPDATE_STATUS: { isCompleted: boolean };
    EDIT_ITEM: { text: string };
  }
>(SHOPPING_LIST_RESOURCE_KEY, {
  NEW: (_resource, { data }) => ({
    text: data.text,
    is_complete: false,
  }),
  // REMOVE: (resource) => null,
  UPDATE_STATUS: (resource, { data }) => ({
    ...resource,
    is_complete: data.isCompleted,
  }),
  EDIT_ITEM: (resource, { data }) => ({
    ...resource,
    text: data.text,
  }),
});

export default shoppingListResource;
