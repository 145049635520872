import { useEffect } from "react";
import { Platform } from "react-native";
import * as Notifications from "expo-notifications";
import { reportError } from "../state/utils/errors";

async function clearNotificationsInSpace(spaceId: string) {
  if (Platform.OS === "web") return;
  const notifs = await Notifications.getPresentedNotificationsAsync();
  notifs
    .filter((notif) => notif.request.content.data.space_id === spaceId)
    .forEach((notif) =>
      Notifications.dismissNotificationAsync(notif.request.identifier)
    );
}

export default function useHandleSpaceNotifications(spaceId: string) {
  useEffect(() => {
    clearNotificationsInSpace(spaceId);
    // TODO maintain a reference to spaceId instead of reassinging function each time
    Notifications.setNotificationHandler({
      handleNotification: async (notif) => {
        return {
          shouldShowAlert: notif.request.content.data.space_id !== spaceId,
          shouldPlaySound: true,
          shouldSetBadge: true,
          priority: Notifications.AndroidNotificationPriority.MAX,
        };
      },
      handleError: (err) => {
        // console.error(err);
        // console.error("Notification error");
        reportError(err);
      },
      handleSuccess: () => {
        // console.log("Notification handled succesfully");
      },
    });
    /**
     * This should work in theory but doesn't seem to on Android at least
     */
    // const listenerSub = Notifications.addNotificationReceivedListener(
    //   async (notif) => {
    //     if (notif.request.content.data.space_id !== spaceId) return;
    //     setTimeout(async () => {
    //       try {
    //         await Notifications.dismissNotificationAsync(
    //           notif.request.identifier
    //         );
    //         console.log("Immediately dismissed notification in space", spaceId);
    //       } catch (err) {
    //         console.error(err);
    //         console.log("Could not dismiss notification");
    //       }
    //     }, 100);
    //   }
    // );

    // return () => {
    //   listenerSub.remove();
    // };
  }, [spaceId]);
}
