import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { SafeAreaView } from "dripsy";
import {
  Button,
  Text,
  Row,
  Icon,
  Pressable,
  Spinner,
  Column,
  View,
} from "../components/basics";
import { RootStackParamList } from "../types";
import * as ImagePicker from "expo-image-picker";
import { captureCameraImage } from "../utils/media";
import { useSpace } from "../hooks/spaces";
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { useMutation } from "urql";
import { SetSpaceCoverPhotoDocument } from "../state/generated/graphql";
import useSharedNavigationValue from "../hooks/useSharedNavigationValue";
import { CoverPhoto, CoverPhotoView } from "../components/CoverPhotoView";
import { useAssets } from "expo-asset";
import { v4 as uuidv4 } from "uuid";
import { uploadCoverPhotoImage } from "../utils/cover-photos";
import DRIPSY_THEME from "../constants/DripsyTheme";
import { useToast } from "../hooks/useToast";
import { reportError } from "../state/utils/errors";

export default function CoverPhotoSelectorScreen({
  route,
  navigation,
}: NativeStackScreenProps<RootStackParamList, "CoverPhotoSelector">) {
  const { spaceId } = route.params;
  const { loading, data: space } = useSpace(spaceId);

  const [selectedPhoto, setSelectedPhoto] = useState<CoverPhoto | undefined>(
    space?.cover_photo
  );

  const [_setCoverPhotoResult, setCoverPhoto] = useMutation(
    SetSpaceCoverPhotoDocument
  );

  const [uploadingPhoto, setUploadingPhoto] = useState(false);

  const addToast = useToast();

  const saveCoverPhoto = useCallback(async () => {
    const { error } = await setCoverPhoto({
      space_id: spaceId,
      cover_photo: selectedPhoto,
    });
    if (error)
      addToast({
        title: "Uh oh!",
        description: "We couldn't properly set your cover photo",
        variant: "ERROR",
      });
  }, [selectedPhoto, addToast]);

  useLayoutEffect(() => {
    navigation.setOptions({
      headerRight: () => (
        <Button
          variant={"unstyled"}
          onPress={async () => {
            await saveCoverPhoto();
            navigation.goBack();
          }}
        >
          Save
        </Button>
      ),
      headerLeft: () => (
        <Button
          variant={"unstyled"}
          onPress={() => {
            navigation.goBack();
          }}
        >
          Cancel
        </Button>
      ),
    });
  }, [navigation, saveCoverPhoto]);

  const emojiIdRef = useRef("emoji_selector");
  const [selectedEmoji, _setSelectedEmoji, evictSelectedEmoji] =
    useSharedNavigationValue(emojiIdRef.current);
  useEffect(() => {
    return () => {
      evictSelectedEmoji();
    };
  }, [evictSelectedEmoji]);

  useEffect(() => {
    if (selectedEmoji)
      setSelectedPhoto({ type: "emoji", value: selectedEmoji });
  }, [selectedEmoji]);

  const [assets, assetsError] = useAssets([
    require("../assets/images/logo.png"),
  ]);
  const logo = (assets ?? [])[0];

  const handleUpload = useCallback(
    async (uri: string) => {
      try {
        setUploadingPhoto(true);
        const photoId = uuidv4();
        const { error } = await uploadCoverPhotoImage(spaceId, photoId, uri);
        if (error) throw error;
        setSelectedPhoto({
          type: "image",
          value: `${spaceId}/${photoId}`,
        });
      } catch (e) {
        addToast({
          title: "Upload failed",
          description: "Could not update the cover photo",
          variant: "ERROR",
        });
        reportError(e);
      } finally {
        setUploadingPhoto(false);
      }
    },
    [spaceId]
  );

  const handleCameraRollSelect = useCallback(async () => {
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.All,
      allowsEditing: true,
      quality: 1,
    });

    if (!result.cancelled) {
      await handleUpload(result.uri);
    }
  }, [handleUpload]);

  const handleAspenImageSelect = useCallback(() => {}, []);

  const handleCaptureImage = useCallback(async () => {
    const result = await captureCameraImage();
    if (!result?.cancelled) {
      await handleUpload(result?.uri);
    }
  }, []);

  const handleEmojiSelect = useCallback(() => {
    navigation.navigate("EmojiPicker", {
      id: emojiIdRef.current,
    });
  }, []);

  return (
    <SafeAreaView sx={{ flex: 1, backgroundColor: "$gray.100" }}>
      <Row justifyContent={"center"} alignItems={"center"} minH="1/3">
        {uploadingPhoto ? (
          <Spinner />
        ) : !!selectedPhoto ? (
          <>
            <View
              sx={{
                borderRadius: "md",
                marginY: "$3",
                overflow: "hidden",
              }}
            >
              <CoverPhotoView size={256} coverPhoto={selectedPhoto} />
            </View>
            <Pressable
              style={({ pressed, hovered }) => ({
                backgroundColor: pressed
                  ? DRIPSY_THEME.colors.$gray["800"]
                  : hovered
                  ? DRIPSY_THEME.colors.$gray["900"]
                  : DRIPSY_THEME.colors.$black,
              })}
              sx={{
                position: "absolute",
                bottom: 15,
                right: 25,
                opacity: 0.75,
                padding: 4,
                borderRadius: 4,
              }}
              onPress={() => setSelectedPhoto(undefined)}
            >
              <Text sx={{ color: "white" }}>Remove</Text>
            </Pressable>
          </>
        ) : (
          <View
            sx={{
              height: 256,
              width: 256,
              borderRadius: 128,
              margin: 8,
              // borderWidth: 1,
              justifyContent: "center",
              alignItems: "center",
              borderColor: "$gray.500",
            }}
          >
            <Text sx={{ fontSize: 16, fontWeight: "bold" }}>None Selected</Text>
          </View>
        )}
      </Row>
      <Column mx={4} space={3}>
        <CoverPhotoSourceRow
          onPress={handleCameraRollSelect}
          text="Select an image from Camera Roll"
          icon="image-outline"
        />
        {/* <CoverPhotoSourceRow
          onPress={handleAspenImageSelect}
          text="Select an image from Aspen Photos"
          icon={
            logo ? (
              <Image
                style={{ width: 32, height: 32 }}
                source={logo}
                alt="Aspen Leaf"
              />
            ) : (
              "image-outline"
            )
          }
        /> */}
        <CoverPhotoSourceRow
          onPress={handleCaptureImage}
          text="Capture an image"
          icon="camera-outline"
        />
        <CoverPhotoSourceRow
          onPress={handleEmojiSelect}
          text="Select an emoji"
          icon={<Text sx={{ fontSize: 24 }}>🚀</Text>}
        />
      </Column>
    </SafeAreaView>
  );
}

function CoverPhotoSourceRow({
  onPress,
  icon,
  text,
}: {
  onPress: () => void | Promise<void>;
  text: string;
  icon: string | React.ReactNode;
}) {
  return (
    <Pressable
      onPress={onPress}
      style={({ pressed }) =>
        pressed && {
          backgroundColor: pressed
            ? DRIPSY_THEME.colors.$gray["200"]
            : DRIPSY_THEME.colors.$gray["100"],
        }
      }
      sx={{
        flexDirection: "row",
        alignItems: "center",
        paddingX: "$3",
        paddingY: "$2",
        backgroundColor: "$white",
        borderRadius: "md",
        boxShadow: "md",
      }}
    >
      {typeof icon === "string" ? (
        <Icon name={icon} size={"3xl"} color={"$black"} />
      ) : (
        icon
      )}
      <Text sx={{ flexGrow: 1, marginLeft: "$3" }}>{text}</Text>
    </Pressable>
  );
}
