import { NativeStackScreenProps } from "@react-navigation/native-stack";
import Constants from "expo-constants";
import { View } from "../../components/basics";
import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { Platform } from "react-native";
import { WebView } from "react-native-webview";
import {
  useEventPushSpaceId,
  useSpaceContainerId,
} from "../../hooks/useSpaceId";
import { SpacesTabParamList } from "../../types";
import {
  handleMessage,
  invalidateSpaceQueries,
  postSpaceId,
  unsubscribeWinResources,
} from "../../utils/web-bridge";
import CenteredSpinner from "../../components/basics/CenteredSpinner";
import SpaceAppIcon from "../../components/SpaceAppIcon";
import { MotiView } from "moti";
import { useResourceShareDialog } from "../../hooks/useResourceShare";
import webAppCache from "../../state/caches/web-app-cache";
import { SxProp } from "dripsy";
import { useProfilePeekDrawer } from "../../components/chat/ProfilePeekDrawer";

const APP_ORIGIN = Constants.manifest?.extra?.APPS_ORIGIN;

function buildQueryString(
  spaceId: string | undefined,
  navQuery: Record<string, string> | undefined
) {
  const components = getQueryComponents(spaceId, navQuery);
  const componentEntries = Object.entries(components);
  return componentEntries.length
    ? `?${componentEntries.map(([k, v]) => `${k}=${v}`).join("&")}`
    : "";
}

function getQueryComponents(
  spaceId: string | undefined,
  navQuery: Record<string, string> | undefined
) {
  const components: [string, string][] = [];
  if (spaceId) components.push(["spaceId", spaceId]);
  if (navQuery) components.push(...Object.entries(navQuery));
  return Object.fromEntries(components);
}

export default function WebSpaceApp({
  route,
  navigation,
}: NativeStackScreenProps<SpacesTabParamList, "SpaceApp">) {
  const { appRoute, appQuery, spaceId } = route.params ?? {};
  const eventPushTarget = useEventPushSpaceId();
  const queryString = buildQueryString(spaceId, appQuery);
  const APP_URL = `${APP_ORIGIN}/${route.name}${
    appRoute ? `/${appRoute}` : ""
  }${queryString}`;
  const webViewRef = useRef();
  const iframeRef = useRef<HTMLIFrameElement>();
  const [html, setHtml] = useState<string | null>(null);

  const shareResource = useResourceShareDialog();
  const { onPressPerson } = useProfilePeekDrawer();
  const handleIframeMessage = useCallback(
    (evnt) => {
      if (!iframeRef.current?.contentWindow) return;
      handleMessage(evnt.source, spaceId, eventPushTarget, evnt, navigation, {
        onShareResource: shareResource,
        onPressProfile: onPressPerson,
      });
    },
    [spaceId, eventPushTarget]
  );

  useEffect(() => {
    if (Platform.OS !== "web") return;
    if (!iframeRef.current?.contentWindow) return;
    window.addEventListener("message", handleIframeMessage);
    return () => {
      window.removeEventListener("message", handleIframeMessage);
    };
  }, [handleIframeMessage, iframeRef.current]);

  useEffect(() => {
    const windowRef = webViewRef.current ?? iframeRef.current?.contentWindow;
    if (!windowRef) return;
    postSpaceId(windowRef, spaceId);
    invalidateSpaceQueries(windowRef, spaceId);
  }, [spaceId]);

  useEffect(() => {
    const windowRef = webViewRef.current ?? iframeRef.current?.contentWindow;
    return () => {
      if (!windowRef) return;
      unsubscribeWinResources(windowRef);
    };
  }, []);

  useLayoutEffect(() => {
    (async () => {
      const cacheResp = await webAppCache.get(APP_URL);
      if (cacheResp) {
        setHtml(cacheResp);
      }
      fetch(APP_URL)
        .then((resp) => resp.text())
        .then((htmlText) => {
          if (!cacheResp) {
            setHtml(htmlText);
          }
          webAppCache.set(APP_URL, htmlText);
        })
        .catch((err) => {
          console.error(err);
        });
    })();
  }, [APP_URL]);

  return (
    <View
      sx={{
        flex: 1,
      }}
    >
      {html == null && <SpaceAppSplash appName={route.name} sx={{}} />}
      {Platform.OS === "web" ? (
        <iframe
          ref={iframeRef}
          style={{ flex: 1, border: "none" }}
          src={APP_URL}
          allowusermedia
          allow="microphone; camera; vr; speaker;"
        />
      ) : (
        <WebView
          ref={webViewRef}
          allowsInlineMediaPlayback
          mediaPlaybackRequiresUserAction={false}
          allowsFullscreenVideo
          onMessage={(evnt) => {
            handleMessage(
              webViewRef.current!,
              spaceId,
              eventPushTarget,
              evnt,
              navigation,
              {
                onShareResource: shareResource,
                onPressProfile: onPressPerson,
              }
            );
          }}
          originWhitelist={[""]}
          source={{ html: html ?? "", baseUrl: APP_URL }}
          startInLoadingState={true}
          renderLoading={() => {
            return <SpaceAppSplash appName={route.name} />;
          }}
          injectedJavaScript={`
            
            console = new Object();
            console.log = function() {
              window.ReactNativeWebView.postMessage(JSON.stringify({type: "CONSOLE", log: [...arguments]}));
            };
            console.debug = console.log;
            console.info = console.log;
            console.warn = console.log;
            console.error = console.log;
            `}
        />
      )}
    </View>
  );
}

export function SpaceAppSplash({
  appName,
  sx,
}: {
  appName: string;
  sx?: SxProp;
}) {
  return (
    <View
      sx={{
        position: "absolute",
        zIndex: 10,
        flex: 1,
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        alignItems: "center",
        justifyContent: "center",
        ...sx,
      }}
    >
      <View>
        <MotiView
          delay={200}
          from={{
            opacity: 0,
            scale: 0.9,
          }}
          animate={{
            opacity: 1,
            scale: 1,
          }}
          transition={{
            loop: true,
            type: "timing",
            duration: 1500,
            delay: 100,
          }}
        >
          <SpaceAppIcon appName={appName} size="2xl" />
        </MotiView>
      </View>
    </View>
  );
}
