import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { InteractionManager } from "react-native";
import { useQuery, UseQueryArgs } from "urql";
import { useSpaceContainerId } from "./useSpaceId";
import { CustomResourcesDocument } from "../state/generated/graphql";
import {
  resourceQueryVariables,
  SortDefintion,
} from "../state/utils/resource-queries";

interface UseCustomResourceQueryArgs<T>
  extends Omit<UseQueryArgs<T>, "query" | "variables"> {
  spaceId?: string;
  collectionId?: string;
  resourceQueries?: Record<string, any>;
  pagination?: { limit: number; offset: number };
  sort?: SortDefintion;
}

export function useCustomResourcesQuery<T>({
  spaceId,
  collectionId,
  resourceQueries,
  pagination,
  sort,
  ...queryArgs
}: UseCustomResourceQueryArgs<T>) {
  const variables = useMemo(() => {
    return resourceQueryVariables({
      spaceId,
      collectionId,
      resourceQueries,
      pagination,
      sort,
    });
  }, [spaceId, collectionId, resourceQueries, pagination, sort]);
  return useQuery({
    query: CustomResourcesDocument,
    variables: variables,
    ...queryArgs,
  });
}

// Pagination logic is slightly different, we should watch pagination with new "custom resource" pattern (particularly in chat)
// ...if anything starts looking weird see git history of how pagination was done with useResourceCollection
export function useCustomResourceCollection({
  pageSize = 20,
  resourceQueries = {},
  sort = {},
  spaceId,
}: {
  pageSize?: number;
  resourceQueries?: Record<string, any>;
  sort?: SortDefintion;
  spaceId: string | null;
}) {
  // const spaceId = useSpaceContainerId();
  const [pageNum, setPageNum] = useState(0);
  const [hasMore, setHasMore] = useState(false);
  const [paused, setPaused] = useState(false);

  // useEffect(() => {
  //   InteractionManager.runAfterInteractions(() => {
  //     setPaused(false);
  //   });
  // }, []);

  const rq = useMemo(() => {
    return resourceQueries;
  }, [resourceQueries]);

  const pagination = useMemo(() => {
    return { limit: pageSize, offset: pageSize * pageNum };
  }, [pageNum]);

  const [{ data, error, fetching, operation }] = useCustomResourcesQuery({
    spaceId,
    pagination,
    requestPolicy: "cache-and-network",
    resourceQueries: rq,
    sort,
    pause: paused,
  });

  useEffect(() => {
    if (data) {
      setHasMore(data.resources?.length >= pageSize * (pageNum + 1));
    }
  }, [data]);

  const getMore = useCallback(() => {
    setPageNum((n) => n + 1);
  }, []);

  return {
    resources: data?.resources,
    error,
    fetching,
    getMore,
    hasMore,
  };
}

export function useResourceCollection(
  collectionKey: string,
  pageSize: number = 20
) {
  const sort = useMemo<SortDefintion>(() => {
    return {
      created_at: {
        _direction: "desc",
      },
    };
  }, []);
  const resourceQueries = useMemo(() => {
    return { [collectionKey]: {} };
  }, []);
  return useCustomResourceCollection({
    pageSize,
    resourceQueries,
    sort,
  });
}
