import { nanoid } from "nanoid";
import scheduleResource, {
  ScheduleItemNewEventParams,
  SCHEDULE_RESOURCE_KEY,
} from "../../resources/schedule";

export function createScheduleItemLocal(data: ScheduleItemNewEventParams) {
  const resourceData = scheduleResource.applyEvent(
    undefined,
    "NEW",
    {
      data,
    },
    false
  );

  return {
    id: nanoid(),
    collection_key: SCHEDULE_RESOURCE_KEY,
    data: resourceData,
  };
}
