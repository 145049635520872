import { useCallback } from "react";
import usePersonName from "./usePersonName";

export default function usePersonFirstName() {
  const getName = usePersonName();
  return useCallback(
    (userId: string, currentUserAware: boolean = true) => {
      return getName(userId, currentUserAware)?.split(" ")[0];
    },
    [getName]
  );
}
