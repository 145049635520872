import React, { PropsWithChildren, useEffect, useState } from "react";
import { InteractionManager } from "react-native";

interface Theme {
  primary: string;
}

interface ThemeState {
  theme: Theme;
  setTheme: (updatedTheme: Partial<Theme>) => void;
}

export const THEMES: Record<"spaces" | "apps" | "people", Theme> = {
  spaces: {
    primary: "#ef767a",
  },
  apps: {
    primary: "#2E86AB",
  },
  people: {
    primary: "#79B672",
  },
} as const;

export const DEFAULT_THEME = THEMES["spaces"];

const ThemeContext = React.createContext<ThemeState>({
  theme: DEFAULT_THEME,
  setTheme: () => {},
});

export function ThemeProvider({ children }: PropsWithChildren<{}>) {
  const [theme, setTheme] = useState<Theme>(DEFAULT_THEME);

  return (
    <ThemeContext.Provider
      value={{
        theme,
        setTheme: (partialTheme) => {
          setTheme({ ...theme, ...partialTheme });
        },
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
}

export function SectionTheme({ section }: { section: keyof typeof THEMES }) {
  const { setTheme } = useTheme();

  useEffect(() => {
    InteractionManager.runAfterInteractions(() => {
      setTheme(THEMES[section]);
    });
  }, [section]);
  return null;
}

export function useTheme() {
  return React.useContext(ThemeContext);
}
