import { useCallback, useRef } from "react";

const DOUBLE_PRESS_DELAY = 400;

export default function useDoublePress() {
  const lastPress = useRef(0);
  const lastPressTimeout = useRef<ReturnType<typeof setTimeout>>();
  const doublePressHandler = useCallback(
    ({
      onPress,
      onDoublePress,
    }: {
      onPress?: () => void;
      onDoublePress?: () => void;
    }) => {
      const time = new Date().getTime();
      const delta = time - lastPress.current;

      if (delta < DOUBLE_PRESS_DELAY) {
        if (lastPressTimeout.current) clearTimeout(lastPressTimeout.current);
        onDoublePress && onDoublePress();
      } else {
        lastPressTimeout.current = setTimeout(() => {
          onPress && onPress();
        }, DOUBLE_PRESS_DELAY);
      }
      lastPress.current = time;
    },
    []
  );

  return doublePressHandler;
}
