import { useQuery } from "urql";
import {
  GetResourceCollectionByIdDocument,
  ResourceCollectionsDocument,
} from "../state/generated/graphql";
import { resourceCollectionQueryVariables } from "../state/utils/resource-collection-queries";
import { useSpaceContainerId } from "./useSpaceId";

export function useCollection(id: string) {
  return useQuery({
    query: GetResourceCollectionByIdDocument,
    variables: { id },
  });
}

export function useCollections(collectionKey: string) {
  const spaceId = useSpaceContainerId();
  return useQuery({
    query: ResourceCollectionsDocument,
    variables: resourceCollectionQueryVariables(collectionKey, spaceId),
  });
}
