import graphQlClient from "../state/graphql/client";
import * as Device from "expo-device";

const query = `
  mutation RegisterDeviceToken($token: String = "", $user_id: uuid = "", $host_info: jsonb) {
    insert_user_devices_one(object: {expo_notification_token: $token, user_id: $user_id, host_info: $host_info}, on_conflict: {constraint: user_devices_user_id_expo_notification_token_key, update_columns: []}) {
      id
    }
  }`;

export default async function registerPushToken(userId: string, token: string) {
  const { error } = await graphQlClient
    .mutation(query, { token, user_id: userId, host_info: Device })
    .toPromise();
  if (error) throw new Error(error.message);
}
