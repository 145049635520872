import {
  createClient,
  dedupExchange,
  fetchExchange,
  gql,
  subscriptionExchange,
} from "urql";
import {
  offlineExchange,
  DataFields,
  Cache,
  Variables,
  ResolveInfo,
  FieldInfo,
} from "@urql/exchange-graphcache";
import { devtoolsExchange } from "@urql/devtools";
import supabase from "../../lib/supabase";
import { SubscriptionClient } from "subscriptions-transport-ws";
import Resource from "../../resources/resource";
import { pipe, subscribe, Subscription, filter, map } from "wonka";
// import { simplePagination } from "@urql/exchange-graphcache/extras";
import { v4 as uuidv4 } from "uuid";
import Constants from "expo-constants";

require("../../resources/shopping-list");
require("../../resources/chat");
require("../../resources/page");
require("../../resources/schedule");
require("../../resources/photo");
require("../../resources/le-word");
require("../../resources/notice");
require("../../resources/reminder");
require("../../resources/geo-genius");
require("../../resources/poll");

import { makeAsyncStorage } from "@urql/storage-rn";
import { simplePagination } from "./pagination";
import { reportError } from "../utils/errors";
import {
  AllConnectedProfilesDocument,
  AllSpaceMembersDocument,
  AllUserSpacesDocument,
  ChatListDocument,
  ChatListSubscritionDocument,
  CustomResourcesDocument,
  GetConnectedProfilesDocument,
  GetResourcePermissionsDocument,
  GetSpaceLatestEventTimestampsDocument,
  GetSpaceMembersDocument,
  GetUserProfileDocument,
  GetUserSpacesDocument,
  LogEventFragment as LogEvent,
  ResourceCollectionsDocument,
  ResourceCollectionsSubscriptionDocument,
  ResourceFragmentDoc,
  UnreadCountInfoFragmentDoc,
  UserProfileDocument,
} from "../generated/graphql";
// import schema from "./schema.json";
import {
  evaluateCollectionQuery,
  getLocalSortingFunction,
  getSortedResourceIndex,
} from "../utils/resource-queries";
import { getDeviceIdSync } from "../../utils/device-id";
import { resourceCollectionQueryVariables } from "../utils/resource-collection-queries";
import { dateStringMax } from "../../utils/dates";
import { currentSpaceView } from "../../hooks/useSpaceReadReceipts";

export const storage = makeAsyncStorage({
  dataKey: "graphcache-data", // The AsyncStorage key used for the data (defaults to graphcache-data)
  metadataKey: "graphcache-metadata", // The AsyncStorage key used for the metadata (defaults to graphcache-metadata)
  maxAge: 7, // How long to persist the data in storage (defaults to 7 days)
});

const onLogEventHandlers: Record<string, (events: LogEvent[]) => void> = {};

export function registerLogEventListener(
  handler: (events: LogEvent[]) => void
) {
  const id = uuidv4();
  onLogEventHandlers[id] = handler;
  return () => {
    delete onLogEventHandlers[id];
  };
}

function createSubcriptionClient() {
  return new SubscriptionClient(
    `${Constants.manifest?.extra?.GRAPHQL_WS_PROTOCOL}://${Constants.manifest?.extra?.GRAPHQL_ENDPOINT}`,
    {
      reconnect: true,
      connectionParams: () => ({
        headers: supabase.auth.session()
          ? {
              Authorization: `Bearer ${supabase.auth.session()!.access_token}`,
            }
          : {},
      }),
    }
  );
}

let subscriptionClient = createSubcriptionClient();

supabase.auth.onAuthStateChange((evt, session) => {
  subscriptionClient.close();
  subscriptionClient = createSubcriptionClient();
  if (session === null) {
    storage.clear();
  }
});

const client = createClient({
  url: `${Constants.manifest?.extra?.GRAPHQL_HTTP_PROTOCOL}://${Constants.manifest?.extra?.GRAPHQL_ENDPOINT}`,
  fetchOptions: () => {
    return supabase.auth.session()
      ? {
          headers: {
            Authorization: `Bearer ${supabase.auth.session()!.access_token}`,
          },
        }
      : {};
  },
  exchanges: [
    //...defaultExchanges,
    devtoolsExchange,
    dedupExchange,
    offlineExchange({
      // schema,
      storage,
      keys: {
        resources: ({ collection_key, id }) => {
          if (!id) return null;
          return [collection_key, id].join("|");
        },
        resource_permissions: ({ space_id, resource_key, resource_id }) =>
          [space_id, resource_key, resource_id].join("|"),
        collection_permissions: ({ space_id, collection_id }) =>
          [space_id, collection_id].join("|"),
        resource_collection_links: ({
          collection_id,
          resource_key,
          resource_id,
        }) => [collection_id, resource_key, resource_id].join("|"),
        events: ({ resource_id, space_session, sender_key, order }) =>
          [resource_id, space_session, sender_key, order].join("|"),
        notification_badges: () => null,
        unread_counts: ({ space_id }) => space_id as string,
        apps: (app) => app.name as string,
        space_apps: () => null,
        events_max_fields: () => null,
        events_aggregate_fields: () => null,
        events_aggregate: () => null,
        events_mutation_response: () => null,
      },
      resolvers: {
        Query: {
          resources_query_v1: simplePagination({ offsetArgument: "offset" }),
          resources_query_v2: simplePagination({ offsetArgument: "offset" }),
          resources_by_pk: (parent, args, cache) => {
            const { collection_key, id } = args;
            return {
              __typename: "resources",
              collection_key,
              id,
            };
          },
          resource_collections_by_pk: (parent, args, cache) => {
            const { id } = args;
            return {
              __typename: "resource_collections",
              id,
            };
          },
          resource_collections_by_dev_id: (parent, args, cache) => {
            const dev_id = args?.args?._dev_id;
            if (!dev_id) return null;
            const queryMatch = cache
              .inspectFields(cache.data.queryRootKey)
              .find(
                (field) =>
                  field.fieldName === "resource_collections_by_dev_id" &&
                  field.arguments?.args?._dev_id === dev_id
              );
            if (!queryMatch) return null;
            return cache.resolve(cache.data.queryRootKey, queryMatch.fieldKey);
          },
        },
      },
      updates: {
        Mutation: {
          insert_events_one: handleEventInsert,
          insert_events: handleEventsInsert,
          insert_read_receipts_one: handleReadReceiptInsert,
          delete_collection_permissions: handleCollectionPermissionsDelete,
        },
        Subscription: {
          events: handleEventSubscription,
          resources: updateResourceCacheFromSubscription,
          profiles_by_pk: updateUserProfileCacheFromSubscription,
          spaces: updateSpacesCacheFromSubscription,
          chat_list: updateChatListFromSubscription,
          space_members: updateSpaceMembersCacheFromSubscription,
          profiles: updateProfilesCacheFromSubscription,
          resource_collections: updateResourceCollectionsFromSubscription,
        },
      },
      // TODO: optimistic/offline sharing - (handler for insert_resource_permissions)
      optimistic: {
        insert_events_one: (variables, cache, info) => {
          return {
            __typename: "events",
            id: variables.object.localId,
            user_id: supabase.auth.user()!.id,
            created_at: new Date().toISOString(),
            collection_id: null,
            ...variables.object,
          };
        },
        insert_events: (variables, cache, info) => {
          return {
            returning: variables.objects.map((e) => ({
              __typename: "events",
              id: e.localId,
              user_id: supabase.auth.user()!.id,
              created_at: new Date().toISOString(),
              collection_id: null,
              ...e,
            })),
            __typename: "events_mutation_response",
          };
        },
        insert_read_receipts_one: (variables, cache, info) => {
          return {
            __typename: "read_receipts",
            ...variables.object,
          };
        },
      },
    }),
    fetchExchange,
    subscriptionExchange({
      forwardSubscription: (operation) => subscriptionClient.request(operation),
    }),
  ],
});

function updateResourceCollectionsFromSubscription(
  result: DataFields,
  args: Variables,
  cache: Cache
) {
  const collections = result?.resource_collections as any[];
  if (!collections) return;
  const spacesMap = collections.reduce<Record<string, any>>(
    (spaceCollections, rc) => {
      const permissions = rc.permissions ?? [];
      for (const permission of permissions) {
        if (!spaceCollections[permission.space_id])
          spaceCollections[permission.space_id] = [];
        spaceCollections[permission.space_id].push(rc);
      }
      return spaceCollections;
    },
    {}
  );
  // Update space queries
  for (const [spaceId, spaceCollections] of Object.entries(spacesMap)) {
    // const collectionsLookup = Object.fromEntries(spaceCollections.map(c => [c.id, c]));
    cache.updateQuery(
      {
        query: ResourceCollectionsDocument,
        variables: resourceCollectionQueryVariables("", spaceId),
      },
      () => {
        return { resource_collections: spaceCollections };
      }
    );

    // cache.updateQuery({ query: GetResourcePermissionsDocument }, (data) => {
    //   if (!data) data = { resource_permissions: [] };
    //   permissionKeys.forEach((key) => {
    //     const [space_id, resource_key, resource_id] = key.split("|");
    //     if (
    //       data.resource_permissions.findIndex(
    //         (cache_rp) =>
    //           cache_rp.resource_key === resource_key &&
    //           cache_rp.resource_id === resource_id &&
    //           cache_rp.space_id === space_id
    //       ) === -1
    //     ) {
    //       data.resource_permissions.push({
    //         __typename: "resource_permissions",
    //         resource_id,
    //         resource_key,
    //         space_id,
    //         created_at: new Date().toISOString(),
    //       });
    //     }
    //   });

    //   return data;
    // });
  }
  // Update meta query
  cache.updateQuery(
    {
      query: ResourceCollectionsDocument,
      variables: resourceCollectionQueryVariables("", undefined),
    },
    () => result
  );
}

function updateUserProfileCacheFromSubscription(
  result: DataFields,
  args: Variables,
  cache: Cache
) {
  cache.updateQuery(
    {
      query: GetUserProfileDocument,
      variables: {
        id: args.id,
      },
    },
    () => result
  );
}

function updateProfilesCacheFromSubscription(
  result: DataFields,
  args: Variables,
  cache: Cache
) {
  cache.updateQuery(
    {
      query: GetConnectedProfilesDocument,
    },
    () => result
  );
}

function updateResourceCacheFromSubscription(
  result: DataFields,
  args: Variables,
  cache: Cache
) {
  // Use this to pick up on resources shared with us
  result?.resources?.forEach((r) => {
    const {
      local_id: resource_id,
      resource_permissions,
      collections,
      ...resourceData
    } = r;
    const resource_key = resourceData.collection_key;
    const cachedResource = readResource(cache, resource_id, resource_key);
    const deleted = !resourceData.data;
    // TODO: I think we just want to update non existant resources...event subscription should update other resources
    if (!cachedResource) {
      if (deleted) {
        deleteResource(cache, resource_id, resource_key);
      } else {
        initializeResource(cache, resource_id, resource_key, resourceData);
        const rpSpaces = resource_permissions.map((rp) => rp.space_id);
        const collectionSpaces = collections.flatMap(
          (c) => c?.collection?.permissions?.map((cp) => cp.space_id) ?? []
        );
        const spaces = rpSpaces.concat(collectionSpaces);
        const collectionIds = collections.map((c) => c.id);
        const collectionDevIds = collections.map((c) => c.dev_id);
        trackResource(
          cache,
          resource_id,
          resource_key,
          spaces,
          collectionIds,
          collectionDevIds
        );
      }
    }
  });
}

function updateSpacesCacheFromSubscription(
  result: DataFields,
  args: Variables,
  cache: Cache
) {
  cache.updateQuery(
    {
      query: GetUserSpacesDocument,
    },
    () => result
  );
}

function updateChatListFromSubscription(
  result: DataFields,
  args: Variables,
  cache: Cache
) {
  if (currentSpaceView) {
    result.chat_list = result.chat_list.reduce((items, item) => {
      if (item.id === currentSpaceView) {
        items.push({
          ...item,
          unread_counts: [
            {
              __typename: "unread_counts",
              unread_count: 0,
              space_id: item.id,
            },
          ],
        });
      } else items.push(item);
      return items;
    }, []);
  }

  cache.updateQuery(
    {
      query: ChatListDocument,
    },
    () => result
  );
}

function updateSpaceMembersCacheFromSubscription(
  result: DataFields,
  args: Variables,
  cache: Cache
) {
  cache.updateQuery(
    {
      query: GetSpaceMembersDocument,
    },
    () => result
  );
}

function handleCollectionPermissionsDelete(
  result: DataFields,
  args: Variables,
  cache: Cache,
  info: ResolveInfo
) {
  const collection_id = args["where"]["collection_id"]["_eq"];
  const collection_dev_id = cache.resolve(
    { __typename: "resource_collections", id: collection_id },
    "dev_id"
  );
  const spaceIds = args["where"]["space_id"]["_in"];
  const resourceFields = cache
    .inspectFields(cache.data.queryRootKey)
    .filter((field) => field.fieldName === "resources_query_v2");
  const matches = filterMatchingResourceQueries(
    resourceFields,
    spaceIds,
    [collection_id],
    [collection_dev_id]
  );

  matches.forEach((field) => {
    cache.invalidate(cache.data.queryRootKey, field.fieldName, field.arguments);
  });
}

function handleEventInsert(
  result: DataFields,
  _args: Variables,
  cache: Cache,
  info: ResolveInfo
) {
  const event = result.insert_events_one as Required<LogEvent>;
  updateCacheOnIncomingEvents([event], cache, !info.optimistic);
}

function handleEventsInsert(
  result: DataFields,
  _args: Variables,
  cache: Cache,
  info: ResolveInfo
) {
  const events = result?.insert_events?.returning as Required<LogEvent>[];
  updateCacheOnIncomingEvents(events, cache, !info.optimistic);
}

function handleReadReceiptInsert(
  result: DataFields,
  _args: Variables,
  cache: Cache,
  info: ResolveInfo
) {
  const { space_id, user_id } = result.insert_read_receipts_one;
  cache.writeFragment(UnreadCountInfoFragmentDoc, {
    space_id,
    user_id,
    unread_count: 0,
  });
}

function eventSort(descending: boolean = false) {
  return (a: LogEvent, b: LogEvent) => {
    const direction = descending ? -1 : 1;
    if (a.space_session !== b.space_session)
      return (a.space_session < b.space_session ? -1 : 1) * direction;
    if (a.sender_key !== b.sender_key)
      return (a.sender_key < b.sender_key ? -1 : 1) * direction;
    return (a.order < b.order ? -1 : 1) * direction;
  };
}

function handleEventSubscription(
  result: DataFields,
  args: Variables,
  cache: Cache,
  info: ResolveInfo
) {
  const events = result?.events as Required<LogEvent>[];
  if (!events?.length) return;

  // Use subscription only to apply external events
  // Events generated by this device will be applied by mutation handlers
  // Device id may be undefined, so process all if undefined
  const deviceId = getDeviceIdSync();
  const externalEvents = events.filter(
    (e) => !deviceId || e.sender_key !== deviceId
  );
  if (externalEvents.length)
    updateCacheOnIncomingEvents(externalEvents.slice(), cache, true);
}

function updateCacheOnIncomingEvents(
  events: Required<LogEvent>[],
  cache: Cache,
  fromServer: boolean
) {
  // Read events and partition by resource id
  const resourceEvents = events.reduce<Record<string, LogEvent[]>>(
    (eventsMap, event) => {
      const key = `${event.resource_key}|${event.resource_id}`;
      if (!eventsMap[key]) eventsMap[key] = [];
      eventsMap[key].push(event);
      return eventsMap;
    },
    {}
  );

  for (const [key, value] of Object.entries(resourceEvents)) {
    const [resource_key, resource_id] = key.split("|");
    if (!Resource.canHandleResourceType(resource_key)) {
      console.warn("Encountered unhandable resource type", resource_key);
      continue;
    }
    const cachedResource = readResource(cache, resource_id, resource_key);

    // Early return if we are unaware of this resource there is not a NEW event in the batch to initialize it
    if (!cachedResource && !value.find((e) => e.type === "NEW")) continue;

    const sortedEvents = value.sort(eventSort(false));

    const { data: reducedData, deleted } = Resource.reduceEvents(
      cachedResource?.data,
      sortedEvents,
      fromServer
    );

    if (deleted) {
      deleteResource(cache, resource_id, resource_key);
    } else {
      if (cachedResource) {
        updateResource(cache, resource_id, resource_key, {
          ...cachedResource,
          data: reducedData,
          session_num: sortedEvents[sortedEvents.length - 1].space_session,
          space_id: sortedEvents[sortedEvents.length - 1].space_id,
        });
      } else {
        const session = sortedEvents[sortedEvents.length - 1].space_session;
        const spaceId = sortedEvents[sortedEvents.length - 1].space_id;
        const collectionId =
          sortedEvents[sortedEvents.length - 1].collection_id;
        const collectionDevId = collectionId
          ? (cache.resolve(
              { __typename: "resource_collections", id: collectionId },
              "dev_id"
            ) as string)
          : undefined;
        initializeResource(cache, resource_id, resource_key, {
          data: reducedData,
          session_num: session,
          space_id: sortedEvents[sortedEvents.length - 1].space_id,
        });
        trackResource(
          cache,
          resource_id,
          resource_key,
          [spaceId],
          collectionId ? [collectionId] : [],
          collectionDevId ? [collectionDevId] : []
        );
      }
    }
  }

  // For performance reasons, on optimistic update just update the resource
  if (fromServer) {
    updateChatList(cache, events);
    updateLatestSpaceEvents(cache, events);
  }
}

function addResourcePermission(cache: Cache, permissionKeys: string[]) {
  cache.updateQuery({ query: GetResourcePermissionsDocument }, (data) => {
    if (!data) data = { resource_permissions: [] };
    permissionKeys.forEach((key) => {
      const [space_id, resource_key, resource_id] = key.split("|");
      if (
        data.resource_permissions.findIndex(
          (cache_rp) =>
            cache_rp.resource_key === resource_key &&
            cache_rp.resource_id === resource_id &&
            cache_rp.space_id === space_id
        ) === -1
      ) {
        data.resource_permissions.push({
          __typename: "resource_permissions",
          resource_id,
          resource_key,
          space_id,
          created_at: new Date().toISOString(),
        });
      }
    });

    return data;
  });
}

function addCollectionLinks(cache: Cache, linkKeys: string[]) {
  cache.updateQuery({ query: ResourceCollectionsDocument }, (data) => {
    if (!data) data = { resource_collections: [] };
    linkKeys.forEach((key) => {
      const [collection_id, resource_key, resource_id] = key.split("|");
      const collection = data.resource_collections.find(
        (cache_collection) => cache_collection.id === collection_id
      );
      if (
        collection?.resources.findIndex(
          (cache_link) =>
            cache_link.resource_key === resource_key &&
            cache_link.resource_id === resource_id
        ) === -1
      ) {
        collection?.resources.push({
          __typename: "resource_collection_links",
          resource_id,
          resource_key,
          collection_id,
        });
      }
    });

    return data;
  });
}

function readResource(cache: Cache, resource_id: string, resource_key: string) {
  return cache.readFragment(ResourceFragmentDoc, {
    id: resource_id,
    collection_key: resource_key,
  });
}

function initializeResource(
  cache: Cache,
  resource_id: string,
  resource_key: string,
  resourceData: any // should be fragment of other resource props
) {
  cache.writeFragment(ResourceFragmentDoc, {
    id: resource_id,
    collection_key: resource_key,
    ...resourceData,
    updated_at: resourceData.updated_at || new Date().toISOString(),
    created_at: resourceData.created_at || new Date().toISOString(),
    initialized_at: resourceData.initialized_at || new Date().toISOString(),
    resource_permissions: [
      {
        space_id: resourceData.space_id,
        resource_id,
        resource_key,
        created_at: new Date().toISOString(),
        __typename: "resource_permissions",
      },
    ],
  });
  const linkKey = cache.keyOfEntity({
    __typename: "resources",
    id: resource_id,
    collection_key: resource_key,
  });
  cache.link(cache.data.queryRootKey, "resources", linkKey);
}

function updateResource(
  cache: Cache,
  resource_id: string,
  resource_key: string,
  resourceData: any // should be fragment of other resource props
) {
  const update = {
    ...resourceData,
    id: resource_id,
    collection_key: resource_key,
    created_at: resourceData.created_at || new Date().toISOString(),
    updated_at: resourceData.updated_at || new Date().toISOString(),
  };
  cache.writeFragment(ResourceFragmentDoc, update);
}

function deleteResource(
  cache: Cache,
  resource_id: string,
  resource_key: string
) {
  const allResourceKeyQueries = cache
    .inspectFields(cache.data.queryRootKey)
    .filter(
      (field) =>
        field.fieldName === "resources_query_v2" &&
        !!field.arguments?.where?._or?.some(
          (collection_query) =>
            collection_query?.collection_key?._eq === resource_key
        )
    );

  allResourceKeyQueries.forEach((field) => {
    cache.updateQuery(
      {
        query: CustomResourcesDocument,
        variables: {
          limit: field.arguments?.limit as number,
          offset: field.arguments?.offset as number,
          where: field.arguments?.where,
          args: field.arguments?.args,
          order_by: field.arguments?.order_by,
        },
      },
      (data: { resources: any[] } | null) => {
        if (!data) data = { resources: [] };
        data.resources = data.resources.filter(
          (resource) => resource.id !== resource_id
        );
        return data;
      }
    );
  });

  cache.invalidate({
    __typename: "resources",
    id: resource_id,
    colletion_key: resource_key,
  });
}

function trackResource(
  cache: Cache,
  resource_id: string,
  resource_key: string,
  spaceIds: string[],
  collectionIds: string[],
  collectionDevIds: string[]
) {
  // NOTE: we assume resource has been initialized (have seen failures here if resource init fails with fragment failures as an example)
  const untrackedResource = readResource(cache, resource_id, resource_key);

  const customResourceFields = cache
    .inspectFields(cache.data.queryRootKey)
    .filter(
      (field) =>
        field.fieldName === "resources_query_v2" &&
        field.arguments?.where?._or?.some((collection_query) =>
          evaluateCollectionQuery(collection_query, untrackedResource)
        )
    );

  const queryMatches = filterMatchingResourceQueries(
    customResourceFields,
    spaceIds,
    collectionIds,
    collectionDevIds
  );

  const { paginatedQueries, nonPaginatedQueries } = [...queryMatches].reduce<{
    paginatedQueries: FieldInfo[];
    nonPaginatedQueries: FieldInfo[];
  }>(
    (querySets, query) => {
      if ("limit" in (query.arguments ?? {}))
        querySets.paginatedQueries.push(query);
      else querySets.nonPaginatedQueries.push(query);
      return querySets;
    },
    { paginatedQueries: [], nonPaginatedQueries: [] }
  );
  handlePagniatedResourceQueries(cache, untrackedResource, paginatedQueries);
  handleNonPaginatedResourceQueries(
    cache,
    untrackedResource,
    nonPaginatedQueries
  );
}

function handlePagniatedResourceQueries(
  cache: Cache,
  resource: any,
  queries: FieldInfo[]
) {
  /**
   * We don't know exactly which app each query corresponds to so we group by the parameters (variables * args)
   * so we can be smart about updating the pages correctly with the specified page size.
   */
  const queriesGroupedByType = queries.reduce<Record<string, FieldInfo[]>>(
    (queryMap, query) => {
      const { limit: _limit, ...everythingElse } = query.arguments ?? {};
      const groupKey: string = JSON.stringify(everythingElse);
      if (!(groupKey in queryMap)) queryMap[groupKey] = [];
      queryMap[groupKey].push(query);
      return queryMap;
    },
    {}
  );

  for (const [_querytype, pageQueries] of Object.entries(
    queriesGroupedByType
  )) {
    const pageSize = pageQueries[0].arguments!.limit! as number;
    // Sort matches by offset field for pagination
    const sortedMatches = [...pageQueries].sort(
      (a, b) => a.arguments?.offset - b.arguments?.offset
    );

    let matchFound = false; // Flag for if resource has been inserted in proper place
    let overflow: any[] = []; // Store pagination overflow on insertion
    for (let matchIndex = 0; matchIndex < sortedMatches.length; matchIndex++) {
      const field = sortedMatches[matchIndex];
      const sorter = getLocalSortingFunction(
        field.arguments?.args,
        field.arguments?.order_by
      );
      const isLastPage = matchIndex === sortedMatches.length - 1;
      cache.updateQuery(
        {
          query: CustomResourcesDocument,
          variables: {
            limit: field.arguments?.limit as number,
            offset: field.arguments?.offset as number,
            where: field.arguments?.where,
            args: field.arguments?.args,
            order_by: field.arguments?.order_by,
          },
        },
        (data: { resources: any[] } | null) => {
          if (!data) data = { resources: [] };

          // If we've already matched our resource, move other pages
          if (matchFound) {
            // Append overflow to the front
            data.resources.unshift(...overflow);
            // // Get next overflow over the page size
            // overflow = data.resources.splice(pageSize);
          } else {
            // Start process of updated next pages with overflow
            matchFound = insertResourceIntoQuery(
              resource,
              data,
              sorter,
              isLastPage
            );
          }
          // Get next overflow over the page size
          overflow = data.resources.splice(pageSize);

          return data;
        }
      );

      // If we have a match and nothing to update, stop updating
      if (matchFound && overflow.length === 0) break;
    }
  }
}

function handleNonPaginatedResourceQueries(
  cache: Cache,
  resource: any,
  queries: FieldInfo[]
) {
  for (const field of queries) {
    const sorter = getLocalSortingFunction(
      field.arguments?.args,
      field.arguments?.order_by
    );
    cache.updateQuery(
      {
        query: CustomResourcesDocument,
        variables: {
          limit: field.arguments?.limit as number,
          offset: field.arguments?.offset as number,
          where: field.arguments?.where,
          args: field.arguments?.args,
          order_by: field.arguments?.order_by,
        },
      },
      (data: { resources: any[] } | null) => {
        if (!data) data = { resources: [] };
        insertResourceIntoQuery(resource, data, sorter, true);
        return data;
      }
    );
  }
}

function insertResourceIntoQuery(
  untrackedResource: any,
  data: { resources: any[] },
  sorter: any, // TODO,
  forceInsert: boolean
) {
  // Check if there's a match...if so skip
  const existingIndex = data.resources.findIndex(
    (existingResource) => existingResource.id === untrackedResource?.id
  );
  if (existingIndex === -1) {
    if (sorter) {
      const { sortFunc, sortField } = sorter;
      const extractor = (obj: any, path: string) => obj[path];
      const spliceIndex = getSortedResourceIndex(
        untrackedResource,
        data.resources,
        (a, b) =>
          sortFunc(
            sortField.reduce(extractor, a),
            sortField.reduce(extractor, b)
          ),
        0,
        data.resources.length
      );
      // If force stop or found a matching page, track it and stop processing
      if (forceInsert || spliceIndex < data.resources.length - 1) {
        data.resources.splice(spliceIndex + 1, 0, {
          ...untrackedResource,
          __typename: "resources",
        });
        return true;
      }
    } else {
      data.resources.push({
        ...untrackedResource,
        __typename: "resources",
      });
      return true;
    }
  }
  return false;
}

function filterMatchingResourceQueries(
  fieldInfos: FieldInfo[],
  spaceIds: string[],
  collectionIds: string[],
  collectionDevIds: string[]
) {
  const metaCustomResourceQueries = fieldInfos.filter(
    (field) =>
      !field.arguments?.args?._space_id &&
      !field.arguments?.args?._collection_id &&
      !field.arguments?.args?._collection_dev_id
  );

  const spacesSet = new Set(spaceIds);
  const spaceCustomResourceQueries = fieldInfos.filter(
    (field) =>
      field.arguments?.args?._space_id &&
      spacesSet.has(field.arguments?.args?._space_id)
  );

  const collectionIdsSet = new Set(collectionIds);
  const collectionDevIdsSet = new Set(collectionDevIds);
  const collectionCustomResourceQueries = fieldInfos.filter(
    (field) =>
      (field.arguments?.args?._collection_id &&
        collectionIdsSet.has(field.arguments?.args?._collection_id)) ||
      (field.arguments?.args?._collection_dev_id &&
        collectionDevIdsSet.has(field.arguments?.args?._collection_dev_id))
  );

  return new Set([
    ...metaCustomResourceQueries,
    ...spaceCustomResourceQueries,
    ...collectionCustomResourceQueries,
  ]);
}

function updateLatestSpaceEvents(cache: Cache, newEvents: any[]) {
  if (!newEvents.length) return;
  const latestSpaceEvents = newEvents
    ?.slice()
    ?.sort((a, b) => (new Date(a.created_at) > new Date(b.created_at) ? -1 : 1))
    .reduce<Record<string, LogEvent>>((spacesMap, event) => {
      if (!spacesMap[event.space_id]) spacesMap[event.space_id] = event;
      return spacesMap;
    }, {});

  cache.updateQuery(
    { query: GetSpaceLatestEventTimestampsDocument },
    (data) => {
      if (!data) data = { spaces: [] };
      for (const [spaceId, event] of Object.entries(latestSpaceEvents)) {
        const existingIndex = data.spaces.findIndex(
          (item) => item.id === spaceId
        );

        if (existingIndex !== -1) {
          const prev = data.spaces[existingIndex];
          data.spaces[existingIndex] = {
            ...prev,
            events_aggregate: {
              aggregate: {
                max: {
                  created_at: dateStringMax(
                    event.created_at,
                    prev.events_aggregate?.aggregate?.max?.created_at
                  ),
                  __typename: "events_max_fields",
                },
                __typename: "events_aggregate_fields",
              },
              __typename: "events_aggregate",
            },
          };
        } else {
          data.spaces.push({
            events_aggregate: {
              aggregate: {
                max: {
                  created_at: event.created_at,
                  __typename: "events_max_fields",
                },
                __typename: "events_aggregate_fields",
              },
              __typename: "events_aggregate",
            },
            id: spaceId,
            __typename: "spaces",
          });
        }
      }
      return data;
    }
  );
}

function updateChatList(cache: Cache, newEvents: any[]) {
  if (!newEvents.length) return;
  const latestSpaceEvents = newEvents
    .filter((e) => e.resource_key === "CHAT" && e.type === "NEW")
    ?.sort((a, b) => (new Date(a.created_at) > new Date(b.created_at) ? -1 : 1))
    .reduce<Record<string, LogEvent>>((spacesMap, event) => {
      if (!spacesMap[event.space_id]) spacesMap[event.space_id] = event;
      return spacesMap;
    }, {});

  if (Object.keys(latestSpaceEvents).length) {
    cache.updateQuery(
      {
        query: ChatListDocument,
      },
      (data: { chat_list: any[] } | null) => {
        if (!data) data = { chat_list: [] };

        data.chat_list = data.chat_list
          .reduce<any[]>((items, listItem) => {
            const event = latestSpaceEvents[listItem.id];
            if (event) {
              items.push({
                ...listItem,
                created_at: new Date().toISOString(),
                user_ids: event?.user_id
                  ? [
                      event?.user_id,
                      ...listItem.user_ids.filter(
                        (uid) => uid !== event?.user_id
                      ),
                    ]
                  : listItem.user_ids,
                sender: event?.user_id,
                latest_event: event,
              });
            } else {
              items.push(listItem);
            }
            return items;
          }, [])
          .sort((a, b) =>
            new Date(a.created_at) > new Date(b.created_at) ? -1 : 1
          );

        return data;
      }
    );
  }
}

export function startProfileSubcription(userId: string) {
  return pipe(
    client.subscription(UserProfileDocument, { id: userId }),
    subscribe(({ error, data }) => {
      if (error) {
        reportError(error);
      }
    })
  );
}

export function startSpacesSubscription() {
  return pipe(
    client.subscription(AllUserSpacesDocument),
    subscribe(({ error, data }) => {
      if (error) {
        reportError(error);
      }
    })
  );
}

export function startSpaceMembersSubscription() {
  return pipe(
    client.subscription(AllSpaceMembersDocument),
    subscribe(({ error, data }) => {
      if (error) {
        reportError(error);
      }
    })
  );
}

export function startProfilesSubscription() {
  return pipe(
    client.subscription(AllConnectedProfilesDocument),
    subscribe(({ error, data }) => {
      if (error) {
        reportError(error);
      }
    })
  );
}

export function startResourceCollectionSubscription() {
  return pipe(
    client.subscription(ResourceCollectionsSubscriptionDocument),
    subscribe(({ error, data }) => {
      if (error) {
        reportError(error);
      }
    })
  );
}

export function startChatListSubscription() {
  return pipe(
    client.subscription(ChatListSubscritionDocument),
    subscribe(({ error, data }) => {
      if (error) {
        reportError(error);
      }
    })
  );
}

let eventSubscription: Subscription;
export function startEventSubscription(sinceTimestamp: string) {
  if (eventSubscription) {
    eventSubscription.unsubscribe();
  }
  eventSubscription = pipe(
    client.subscription<{ events: LogEvent[] }>(`
      subscription latestEvents {
        events(where: {created_at: {_gt: "${sinceTimestamp}"}}, order_by: {created_at: asc}) {
          space_id,
          created_at,
          user_id,
          user_key,
          sender_key,
          space_session,
          order,
          data,
          resource_key,
          type,
          resource_id,
          localId
        }
      }
    `),
    subscribe(({ data, error }) => {
      if (error) {
        console.error(error);
      }
      if (!data) return;
      const { events } = data;
      if (events.length === 0) return;
      const latestEvent = events[events.length - 1];
      startEventSubscription(latestEvent.created_at!);
      Object.values(onLogEventHandlers).forEach((handler) => handler(events));
    })
  );
}

let sharedResourcePermissionSubscription: Subscription;
export function startSharedResourceSubscription(sinceTimestamp: string) {
  if (sharedResourcePermissionSubscription) {
    sharedResourcePermissionSubscription.unsubscribe();
  }
  sharedResourcePermissionSubscription = pipe(
    client.subscription(
      gql`
        subscription ResourceShareSubscription($sinceTimestamp: timestamptz!) {
          resources(
            where: {
              _or: [
                { updated_at: { _gt: $sinceTimestamp } }
                {
                  resource_permissions: { created_at: { _gt: $sinceTimestamp } }
                }
                {
                  collections: {
                    collection: {
                      permissions: { created_at: { _gt: $sinceTimestamp } }
                    }
                  }
                }
              ]
              initialized: { _eq: true }
            }
          ) {
            resource_permissions {
              created_at
              resource_id
              resource_key
              space_id
            }
            collections {
              collection {
                id
                dev_id
                permissions {
                  collection_id
                  space_id
                  created_at
                }
              }
            }
            collection_key
            created_at
            data
            local_id
            session_num
            updated_at
            initialized_at
            space_id
          }
        }
      `,
      { sinceTimestamp }
    ),
    subscribe(({ data, error }) => {
      if (error) {
        console.error(error);
      }
      if (!data) return;
      const { resources } = data;
      if (resources.length === 0) return;
      const maxResourcesDate = resources
        .map((r) => r.updated_at)
        .reduce(function (a, b) {
          return new Date(a) > new Date(b) ? a : b;
        }, new Date(0).toISOString());
      const maxResourcePermissionDate = resources
        .flatMap((r) => r.resource_permissions.map((rp) => rp.created_at))
        .reduce(function (a, b) {
          return new Date(a) > new Date(b) ? a : b;
        }, new Date(0).toISOString());
      const maxResourceCollectionDate = resources
        .flatMap((r) =>
          r.collections.flatMap((c) =>
            c.collection.permissions.map((cp) => cp.created_at)
          )
        )
        .reduce(function (a, b) {
          return new Date(a) > new Date(b) ? a : b;
        }, new Date(0).toISOString());
      const maxDate = dateStringMax(
        maxResourcesDate,
        maxResourcePermissionDate,
        maxResourceCollectionDate
      );
      startSharedResourceSubscription(maxDate);
    })
  );
  return sharedResourcePermissionSubscription;
}

export default client;
