import * as MediaLibrary from "expo-media-library";
import { AttachmentState } from "../screens/spaces/ChatScreen";
import supabase, { SUPABASE_URL } from "../lib/supabase";
import * as FileSystem from "expo-file-system";
import {
  ensureDirExists,
  getCacheDirectoryURI,
  getCacheURI,
} from "./file-system";
import { copyAsync } from "expo-file-system";
import { reportError } from "../state/utils/errors";
import {
  generateOptimizedThumbnailURI,
  generateOptimizedImageUploadURI,
} from "./images";
import { Platform } from "react-native";
import {
  queueUploads,
  readUploadQueueFromStorage,
  recordUploadStatuses,
  uploadStatus,
} from "../lib/upload-queue";
import { flushEventOutbox } from "../lib/event-outbox";
import { v4 as uuidv4 } from "uuid";
import { getMp4Conversion } from "./video";
import * as ImageManipulator from "expo-image-manipulator";

const STORAGE_CACHE_KEY = "storage";
const UPLOAD_BUCKET_KEY = "uploads";
export const UPLOAD_CACHE_KEY = `${STORAGE_CACHE_KEY}/${UPLOAD_BUCKET_KEY}`;

export async function cacheUploadFromAssetURI(
  attachmentKey: string,
  assetURI: string
) {
  try {
    // const thumbnailURI = await generateOptimizedThumbnailURI(assetURI);
    const thumbnailURI = assetURI;
    const attachmentCachePath = `${UPLOAD_CACHE_KEY}/${attachmentKey}`;
    const attachmentDir = getCacheDirectoryURI(attachmentCachePath);
    const attachmentFile = getCacheURI(attachmentCachePath);
    // COPYING FILE INTO SAME FILE IS BAD MKAY
    if (thumbnailURI !== attachmentFile) {
      await ensureDirExists(attachmentDir);
      await copyAsync({ from: thumbnailURI, to: attachmentFile });
    }
    return { error: undefined };
  } catch (e) {
    return { error: e };
  }
}

export async function downloadAsync(remoteUri: string) {
  const cacheFile = getCacheURI(`${uuidv4()}.jpeg`);
  const { status, uri } = await FileSystem.downloadAsync(remoteUri, cacheFile, {
    headers: {
      authorization: `Bearer ${supabase.auth.session()!.access_token}`,
      "cache-control": "1209600",
    },
    sessionType: FileSystem.FileSystemSessionType.BACKGROUND,
  });
  if (status < 200 || status > 299) {
    return {
      data: undefined,
      error: "Failed to download this attachment",
    };
  }
  return { data: uri, error: undefined };
}

export async function uriToBase64(localUri: string) {
  try {
    const uri = await FileSystem.readAsStringAsync(localUri, {
      encoding: "base64",
    });
    return { data: uri };
  } catch (e) {
    return { error: e };
  }
}

export async function cacheUploadFromRemoteURI(
  remoteUri: string,
  attachmentPath: string
) {
  const cacheDir = getCacheDirectoryURI(attachmentPath);
  const cacheFile = getCacheURI(attachmentPath);
  await ensureDirExists(cacheDir);
  const { status, uri } = await FileSystem.downloadAsync(remoteUri, cacheFile, {
    headers: {
      authorization: `Bearer ${supabase.auth.session()!.access_token}`,
      "cache-control": "1209600",
    },
    sessionType: FileSystem.FileSystemSessionType.BACKGROUND,
  });
  if (status < 200 || status > 299) {
    return {
      data: undefined,
      error: "Failed to download this attachment",
    };
  }
  return { data: uri, error: undefined };
}

export async function uploadAttachment(attachment: AttachmentState) {
  const {
    id,
    assetId,
    uri: attachmentUri,
    localUri: attachmentLocalUri,
    mediaType,
  } = attachment;
  const attachmentKey = id;
  try {
    // TODO: we might already check this when creating "AttachmentState"
    let uri = attachmentLocalUri ?? attachmentUri;
    if (assetId && (!uri || uri.startsWith("ph://"))) {
      const assetInfo = await MediaLibrary.getAssetInfoAsync(assetId);
      uri = assetInfo.localUri ?? uri;
    }
    // if (attachment.mediaType === "photo") {
    //   uri = await generateOptimizedImageUploadURI(uri);
    // }

    return await _upload(attachmentKey, uri!, mediaType);
  } catch (e) {
    return { data: undefined, error: e };
  }
}

async function _upload(
  attachmentKey: string,
  fileUri: string,
  mediaType: string
) {
  const attachmentPath = `${UPLOAD_BUCKET_KEY}/${attachmentKey}`;
  const url = `${SUPABASE_URL}/storage/v1/object/${attachmentPath}`;

  // TOOD: in future convert on video format rather than platform
  if (mediaType === "video" && Platform.OS === "ios") {
    fileUri = await getMp4Conversion(fileUri);
  }

  // Convert image formats that arent handled by nextjs optimzation
  if (fileUri.toLowerCase().endsWith(".dng")) {
    fileUri = (
      await ImageManipulator.manipulateAsync(fileUri, [], {
        format: ImageManipulator.SaveFormat.JPEG,
        compress: 1,
      })
    ).uri;
  }

  console.log("UPLOAD URI", fileUri);

  const task = FileSystem.createUploadTask(
    url,
    fileUri,
    {
      headers: {
        authorization: `Bearer ${supabase.auth.session()!.access_token}`,
        "cache-control": "1209600",
        "x-upsert": "true",
      },
      httpMethod: "POST",
      sessionType: FileSystem.FileSystemSessionType.BACKGROUND,
      uploadType: FileSystem.FileSystemUploadType.MULTIPART,
      fieldName: "file",
    },
    (data) => {
      // On local: I think there's some bug here where totalByteSent is actally totalByteRemaining (always going down)
      const uploadPct = Math.min(
        Math.floor((data.totalByteSent / data.totalBytesExpectedToSend) * 100),
        100
      );

      recordUploadStatuses({
        [attachmentKey]: {
          key: attachmentKey,
          uploadPct,
          status: uploadPct < 100 ? "pending" : "success",
        },
      });
    }
  );

  const uploadResponse = await task.uploadAsync();
  console.log("UPLOAD RESP", uploadResponse);
  if (!uploadResponse)
    return { data: undefined, error: new Error("Upload Failed") };

  const { body, status } = uploadResponse;

  if (status < 200 || status > 299) {
    const parsedBody = JSON.parse(body);
    return {
      data: undefined,
      error:
        parsedBody.error ||
        parsedBody.message ||
        "an error occurred uploading an attachment",
    };
  }

  return { data: body, error: undefined };
}

async function uploadAndTrack(attachment: AttachmentState) {
  const attachmentKey = attachment.id;
  try {
    const { error: uploadError } = await uploadAttachment(attachment);
    if (uploadError) {
      console.log("IS UPLOAD ERROR", uploadError);
      throw uploadError;
    } else {
      recordUploadStatuses({
        [attachmentKey]: {
          key: attachmentKey,
          uploadPct: 100,
          status: "success",
        },
      });
      flushEventOutbox();
    }
    return attachment.id;
  } catch (e) {
    console.log("GOT ERROR", e);
    recordUploadStatuses({
      [attachmentKey]: {
        key: attachmentKey,
        uploadPct: 0,
        status: "failed",
      },
    });
    reportError(e, { extra: { message: "IMAGE UPLOAD FAILURE" } });
    return undefined;
  }
}

export function uploadAttachments(attachmentsToUpload: AttachmentState[]) {
  // NOTE: THESE OPERATIONS MUST REMAIN SYNC SO WE CAN LOAD IMAGES IMMEDIATELY IN THE UI
  queueUploads(Object.fromEntries(attachmentsToUpload.map((a) => [a.id, a])));

  attachmentsToUpload.forEach((attachment) => {
    uploadAndTrack(attachment);
  });
}

export function getUploadUrl(attachmentKey: string) {
  const remoteURI = supabase.storage
    .from(UPLOAD_BUCKET_KEY)
    .getPublicUrl(attachmentKey).data?.publicURL;
  return remoteURI ?? "";
}

// Upload everything not marked as "success" that is still currently in the queue
export async function flushUploadQueue(attachmentIds?: string[]) {
  if (Platform.OS === "web") return;
  const attachmentsToFlush = Object.entries(await readUploadQueueFromStorage())
    .filter(
      ([id, _attachmentState]) => !attachmentIds || attachmentIds.includes(id)
    )
    .filter(([id, _attachmentState]) => uploadStatus(id)?.status !== "success")
    .map<AttachmentState>(([_id, attachmentState]) => {
      return attachmentState;
    });
  uploadAttachments(attachmentsToFlush);
}
