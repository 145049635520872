import { useCallback } from "react";
import usePersonFirstName from "./usePersonFirstName";

export default function useNoticeFormatter() {
  const getFirstName = usePersonFirstName();
  const fillNames = useCallback(
    (text: string, userIds: string[], currentUserAware: boolean = true) => {
      return userIds.reduce((currText, userId, i) => {
        return currText.replace(
          new RegExp(`%${i}`, "g"),
          getFirstName(userId, currentUserAware)!
        );
      }, text ?? "");
    },
    [getFirstName]
  );
  return fillNames;
}
