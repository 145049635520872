import { Ionicons } from "@expo/vector-icons";
import { ComponentProps } from "react";
import { styled, SxProp } from "dripsy";

const BaseIcon = (props: ComponentProps<typeof Ionicons>) => (
  <Ionicons {...props} />
);

const Icon = styled(BaseIcon)((props: ComponentProps<typeof Ionicons>) => ({
  fontSize: props.size,
  color: props.color,
}));

export default Icon;
