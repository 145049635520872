import { AspenChatMessage } from "../../screens/spaces/ChatScreen";

export function normalizeAttachments(
  attachment: string | string[] | undefined
): AspenChatMessage["attachments"] | undefined {
  return attachment
    ? typeof attachment === "string"
      ? [{ id: attachment, type: "photo" }]
      : attachment.map((a) => {
          if (typeof a === "string") {
            return { id: a, type: "photo" };
          }
          return a;
        })
    : undefined;
}
