/**
 * Learn more about deep linking with React Navigation
 * https://reactnavigation.org/docs/deep-linking
 * https://reactnavigation.org/docs/configuring-links
 */

import { LinkingOptions } from "@react-navigation/native";
import * as Linking from "expo-linking";
import * as Notifications from "expo-notifications";
import supabase from "../lib/supabase";
import { getHashParams } from "../state/utils/url";

import { RootStackParamList } from "../types";
import { navigate } from "./RootNavigation";
import Constants from "expo-constants";
import { reportError } from "../state/utils/errors";

const REST_API_ENDPOINT = Constants.manifest?.extra?.REST_API_ENDPOINT;

const linking: LinkingOptions<any> = {
  prefixes: [Linking.createURL("/")],
  config: {
    screens: {
      Root: {
        path: "",
        screens: {
          Chat: {
            path: "chat",
            screens: {
              "chat-list": { path: "/" },
              conversation: { path: "/:spaceId" },
            },
          },
          "shopping-list": { path: "shopping-list/:spaceId?" },
          Photos: { path: "photos/:spaceId?" },
          Pages: { path: "notes/:spaceId?" },
          polls: { path: "polls/:spaceId?" },
          schedule: { path: "calendar/:spaceId?" },
          reminders: { path: "reminders/:spaceId?" },
          "le-word": { path: "le-word/:spaceId?" },
          "geo-genius": { path: "geo-genius/:spaceId?" },
          profiles: {
            path: "/people",
            screens: {
              profiles: { path: "/" },
              profile: { path: "/:userId" },
            },
          },
        },
      },
      CreateSpace: "CreateSpace",
      EditMobile: "EditMobile",
      EditEmail: "EditEmail",
      Auth: "Auth",
      VerifyMobile: "VerifyMobile",
      CompleteProfile: "CompleteProfile",
      Loading: "Loading",
      Modal: "modal",
      NotFound: "*",
    },
  },
  async getInitialURL() {
    // First, you may want to do the default deep link handling
    // Check if app was opened from a deep link
    const url = await Linking.getInitialURL();
    if (url != null) {
      return url;
    }

    // Handle URL from expo push notifications
    const response = await Notifications.getLastNotificationResponseAsync();
    const notificationUrl = getUrlForNotification(response);
    return notificationUrl || url;
  },
  subscribe(listener) {
    const onReceiveURL = ({ url }: { url: string }) => {
      listener(url);
    };
    // Listen to incoming links from deep linking
    Linking.addEventListener("url", onReceiveURL);

    // Listen to expo push notifications
    const subscription = Notifications.addNotificationResponseReceivedListener(
      (response) => {
        const notificationUrl = getUrlForNotification(response);
        if (!notificationUrl) return;
        // Let React Navigation handle the URL
        listener(notificationUrl);
      }
    );

    return () => {
      // Clean up the event listeners
      Linking.removeEventListener("url", onReceiveURL);
      subscription.remove();
    };
  },
};

function getUrlForNotification(
  response: Notifications.NotificationResponse | null
) {
  const spaceId = response?.notification.request.content.data
    .space_id as string;
  if (spaceId) return Linking.createURL(`/chat/${spaceId}`);

  const resourceKey = response?.notification.request.content.data
    .resource_key as string;
  if (resourceKey === "SCHEDULE") return Linking.createURL("/calendar");
  if (resourceKey === "REMINDER") return Linking.createURL("/reminders");
}

export default linking;

let pendingJoin = "";
linking.subscribe!((url) => {
  const [protocol, ...path] = url.split("/").filter((p) => !!p);
  if (path[0] === "join" && !!path[1]) {
    const joinId = path[1];
    const user = supabase.auth.user();
    if (user) {
      handleJoinRequest(user.id, joinId, true);
    } else {
      pendingJoin = joinId;
    }
  }
});

export async function joinOnAuth(userId: string) {
  if (!pendingJoin) return;
  await handleJoinRequest(userId, pendingJoin, false);
  pendingJoin = "";
}

async function handleJoinRequest(
  userId: string,
  joinId: string,
  shouldNav: boolean
) {
  try {
    const response = await fetch(`${REST_API_ENDPOINT}/api/join`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ join_id: joinId, user_id: userId }),
    });
    const data = await response.json();
    if (response.status === 200) {
      if (shouldNav && data.id) {
        navigate("Space", {
          spaceId: data.id,
        });
      }
    } else {
      reportError(data, { extra: { info: "ERROR ON SPACE JOIN" } });
    }
  } catch (e) {
    reportError(e, { extra: { info: "ERROR ON SPACE JOIN" } });
  }
}
