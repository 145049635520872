import React, {
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useToast } from "./useToast";
import * as Contacts from "expo-contacts";
import { Alert } from "react-native";
import { parsePhoneNumberWithCountryCode } from "../utils/phone";
import { ContactItem, contactToPerson } from "../utils/people";
import * as Linking from "expo-linking";

type ContactsProviderState = [
  ContactItem[],
  Record<string, ContactItem>,
  string | undefined,
  typeof Contacts.requestPermissionsAsync
];

export const ContactsContext = React.createContext<ContactsProviderState>([
  [],
  {},
  undefined,
  Contacts.requestPermissionsAsync,
]);

export function ContactsProvider({ children }: PropsWithChildren<{}>) {
  const [permission, setPermission] = useState<string>();
  const [contacts, setContacts] = useState<ContactItem[]>([]);
  const addToast = useToast();

  useEffect(() => {
    (async () => {
      const { status } = await Contacts.getPermissionsAsync();
      setPermission(status);
    })();
  }, []);

  useEffect(() => {
    if (permission === "granted") {
      (async () => {
        const contacts = await Contacts.getContactsAsync({
          fields: [
            Contacts.Fields.PhoneNumbers,
            Contacts.Fields.Name,
            Contacts.Fields.Image,
          ],
        });
        setContacts(contacts.data.map((c) => contactToPerson(c)));
      })();
    } else {
      setContacts([]);
    }
  }, [permission]);

  const askForPermission = useCallback(async () => {
    const resp = await Contacts.requestPermissionsAsync();
    if (resp.status !== "granted" && !resp.canAskAgain) {
      Alert.alert(
        "Permission needed",
        "Aspen needs access to your contacts to display them. Please navigate to your settings and allow Aspen contacts permissions.",
        [
          {
            text: "OK",
            onPress: () => {
              Linking.openURL("app-settings:");
            },
          },
        ]
      );
    }
    if (resp.status === "granted")
      addToast({
        title: "Contacts successfully synced!",
      });
    setPermission(resp.status);
    return resp;
  }, [addToast]);

  const contactsByPhone = useMemo(() => {
    return contacts.reduce<Record<string, ContactItem>>((map, contact) => {
      contact.data.phoneNumbers?.forEach((phone) => {
        if (phone.digits) {
          const phoneKey = parsePhoneNumberWithCountryCode(
            phone.digits,
            phone.countryCode ?? "US"
          );
          if (phoneKey) {
            map[phoneKey] = contact;
          }
        }
      });
      return map;
    }, {});
  }, [contacts]);

  return (
    <ContactsContext.Provider
      value={[contacts, contactsByPhone, permission, askForPermission]}
    >
      {children}
    </ContactsContext.Provider>
  );
}

export function useContacts() {
  return useContext(ContactsContext);
}
