import { Header } from "@react-navigation/elements";
import { StackActions } from "@react-navigation/native";
import { Button, Column, Row, Text, View } from "../components/basics";
import { usePerson } from "../hooks/usePeople";
import { acceptInvitation, declineInvitation } from "../lib/invitations";
import { ProfileAvatar } from "./PersonAvatar";
import { NativeStackHeaderProps } from "@react-navigation/native-stack";
import { useSpace } from "../hooks/spaces";
import { useUser } from "../hooks/auth";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import React from "react";
import { useSpaceMember } from "../hooks/useSpaceMembers";
import { ProfilesInfoFragment } from "../state/generated/graphql";
import { useSx } from "dripsy";
import { StyleSheet } from "react-native";

export function InvitationMessage({
  spaceName,
  inviter,
  onAccept,
  onDecline,
}: {
  spaceName: string | undefined;
  inviter: ProfilesInfoFragment;
  onAccept: () => void;
  onDecline: () => void;
}) {
  return (
    <View
      sx={{
        padding: "$2",
        backgroundColor: "$white",
        borderRadius: "sm",
        borderWidth: "hairline",
        borderColor: "$coolGray.400",
        boxShadow: "$4",
      }}
    >
      <Row space={2} my={2} width={"100%"}>
        <ProfileAvatar size={40} profile={inviter} />
        <Column>
          <Text sx={{ marginBottom: "$2" }}>
            <Text sx={{ fontWeight: "bold" }}> {inviter.name} </Text>has invited
            you to this group
          </Text>
          <Row space={2}>
            <Button size="sm" colorScheme="$primary" onPress={onAccept}>
              Accept
            </Button>
            <Button size="sm" colorScheme="$secondary" onPress={onDecline}>
              Delete
            </Button>
          </Row>
        </Column>
      </Row>
    </View>
  );
}

export default React.memo(SpacesScreenHeader);

function SpacesScreenHeader({
  navigation,
  route,
  options,
  back,
}: NativeStackHeaderProps) {
  const { spaceId } = route.params;
  const { data: space } = useSpace(spaceId!);
  const sx = useSx();
  const currentUser = useUser();
  const currentUserMembership = useSpaceMember(spaceId, currentUser.id);
  const invitation = currentUserMembership?.invitation;
  const inviter = usePerson(invitation?.inviter_id);
  // Make sure you take note of the status bar height, for some reason this isnt automatically accounted for in the Header component

  return (
    <>
      <Header
        {...options}
        headerBackgroundContainerStyle={sx({
          // borderBottomWidth: "hairline",
          borderBottomColor: "$systemBorder",
        })}
      />
      {invitation && invitation.status === "PENDING" && inviter && space ? (
        <InvitationMessage
          inviter={inviter}
          spaceName={space.display_name}
          onAccept={async () => {
            const { error } = await acceptInvitation(invitation.id);
            if (error) {
              console.error(error);
              throw error;
            }
            navigation.navigate("Space", {
              spaceId: spaceId,
            });
          }}
          onDecline={async () => {
            const { error } = await declineInvitation(invitation.id);
            if (error) {
              console.error(error);
              throw error;
            }

            navigation.dispatch(StackActions.replace("Root"));
          }}
        />
      ) : null}
    </>
  );
}
