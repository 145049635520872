import { nanoid } from "nanoid";
import noteResource, { NoteNewEventParams } from "../../resources/page";

export function createNoteLocal(data: NoteNewEventParams) {
  const resourceData = noteResource.applyEvent(
    undefined,
    "NEW",
    {
      data,
    },
    false
  );

  return {
    id: nanoid(),
    collection_key: "NOTES",
    data: resourceData,
  };
}
