import React, { useState, useRef, useCallback } from "react";
import PropTypes from "prop-types";
import { View, StyleSheet } from "react-native";
import Animated, {
  useAnimatedStyle,
  useSharedValue,
  withSpring,
  runOnJS,
} from "react-native-reanimated";
import { GestureDetector, Gesture } from "react-native-gesture-handler";
import { transformOrigin } from "react-native-redash";

const MAX_ZOOM = 6;
const MIN_ZOOM = 1;

function Zoomable({
  style,
  contentContainerStyle,
  onZoomed = () => {},
  children,
}: React.PropsWithChildren<{
  style?: any;
  contentContainerStyle?: any;
  onZoomed?: (isZoomed: boolean) => void;
}>) {
  // current scale
  const actualScale = useSharedValue(1);
  // holder value for zooming
  const lastScale = useSharedValue(1);
  const isZoomedIn = useRef(false);
  const [panGestureEnabled, setPanGestureEnabled] = useState(false);

  // TODO: name from center...
  // Current image center
  const center = useSharedValue({ x: 0, y: 0 });

  const containerWidth = useRef(0);
  const containerHeight = useRef(0);
  const contentDimensions = useRef({
    width: 1,
    height: 1,
  });

  // Current pan translations
  const translateX = useSharedValue(0);
  const translateY = useSharedValue(0);

  function zoomTo(scale: number, animate?: boolean) {
    let nextScale = scale;
    // Trim zoom
    if (scale > MAX_ZOOM) nextScale = MAX_ZOOM;
    else if (scale < MIN_ZOOM) nextScale = MIN_ZOOM;

    // set with spring if over min/max
    if (nextScale === MAX_ZOOM || nextScale === MIN_ZOOM || animate)
      actualScale.value = withSpring(nextScale, {
        overshootClamping: true,
      });
    else actualScale.value = nextScale;

    if (nextScale > 1) {
      isZoomedIn.current = true;
      setPanGestureEnabled(true);
    } else {
      isZoomedIn.current = false;
      setPanGestureEnabled(false);
    }
  }

  function getContentContainerSize() {
    return {
      width: containerWidth.current,
      height:
        (contentDimensions.current.height * containerWidth.current) /
        contentDimensions.current.width,
    };
  }

  function handlePanEnd() {
    // Check if outside of bounds
    const { width, height } = getContentContainerSize();
    const heightOverflow = (height - containerHeight.current) / 4;
    const minXCenter = 0;
    const maxXCenter = width;
    const minYCenter = -heightOverflow;
    const maxYCenter = height + heightOverflow;

    const newCenterX = Math.min(
      maxXCenter,
      Math.max(minXCenter, center.value.x)
    );
    const newCenterY = Math.min(
      maxYCenter,
      Math.max(minYCenter, center.value.y)
    );
    center.value = { x: newCenterX, y: newCenterY };
  }

  function onDoubleTap(x: number, y: number) {
    if (isZoomedIn.current) zoomTo(1, true);
    else {
      zoomTo(3, true);
      center.value = {
        x,
        y,
      };
      translateX.value = 0;
      translateY.value = 0;
    }
    if (onZoomed) {
      onZoomed(isZoomedIn.current);
    }
  }

  const onLayout = useCallback(
    ({
      nativeEvent: {
        layout: { width, height },
      },
    }) => {
      containerWidth.current = width;
      containerHeight.current = height;
      center.value = { x: width / 2, y: height / 2 };
    },
    []
  );

  const onLayoutContent = useCallback(
    ({
      nativeEvent: {
        layout: { width, height },
      },
    }) => {
      contentDimensions.current = { width, height };
    },
    []
  );

  const animContentContainerStyle = useAnimatedStyle(() => {
    const origin = {
      x:
        -contentDimensions.current.width / 2 +
        center.value.x -
        translateX.value,
      y:
        -contentDimensions.current.height / 2 +
        center.value.y -
        translateY.value,
    };
    return {
      transform: transformOrigin(origin, {
        scale: actualScale.value,
      }),
    };
  });

  const onPinchEnd = (scale: number) => zoomTo(scale);

  const tapGesture = Gesture.Tap()
    .numberOfTaps(2)
    .onEnd(({ x, y }) => {
      runOnJS(onDoubleTap)(x, y);
    });

  const panGesture = Gesture.Pan()
    .onUpdate(({ translationX, translationY }) => {
      translateX.value = translationX / actualScale.value;
      translateY.value = translationY / actualScale.value;
    })
    .onEnd(({ translationX, translationY }) => {
      center.value = {
        x: center.value.x - translateX.value,
        y: center.value.y - translateY.value,
      };
      translateX.value = 0;
      translateY.value = 0;
      runOnJS(handlePanEnd)();
    })
    .minDistance(0)
    .minPointers(1)
    .maxPointers(1)
    .enabled(panGestureEnabled);

  const pinchGesture = Gesture.Pinch()
    .onUpdate(({ scale }) => {
      actualScale.value = lastScale.value * scale;
    })
    .onStart(({ focalX, focalY, scale }) => {
      center.value = { x: focalX, y: focalY };
      lastScale.value = actualScale.value;
      runOnJS(onZoomed)(true);
    })
    .onEnd(({ scale, focalX, focalY }) => {
      actualScale.value = lastScale.value * scale;
      runOnJS(onPinchEnd)(actualScale.value);
      runOnJS(onZoomed)(false);
    });

  return (
    <View
      style={[styles.container, style]}
      onLayout={onLayout}
      collapsable={false}
    >
      <GestureDetector
        gesture={Gesture.Exclusive(
          panGesture,
          Gesture.Race(tapGesture, pinchGesture)
        )}
      >
        <Animated.View
          style={[animContentContainerStyle, contentContainerStyle]}
          onLayout={onLayoutContent}
        >
          {children}
        </Animated.View>
      </GestureDetector>
    </View>
  );
}

Zoomable.propTypes = {
  children: PropTypes.any,
  style: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
    PropTypes.number,
    PropTypes.bool,
  ]),
  contentContainerStyle: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
    PropTypes.number,
    PropTypes.bool,
  ]),
};

export default Zoomable;

const styles = StyleSheet.create({
  container: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
  },
});
