import { AnimatePresence } from "moti";
import React, { useRef, useState } from "react";
import { Text as MotiText } from "moti";
import { View, Text, TextInput } from "./basics";

export default function CodeInput({
  value,
  onChange,
  length = 6,
  disabled = false,
}: {
  value: string;
  onChange: (code: string) => void;
  length: number;
  disabled?: boolean;
}) {
  const hiddenInput = useRef(null);
  return (
    <View sx={{ flexDirection: "row" }}>
      {[...Array(length).keys()].map((_, i) => (
        <View
          key={i}
          sx={{
            width: 35,
            height: 45,
            borderWidth: value.length === i ? 3 : 1,
            borderRadius: 4,
            borderColor: "$primary.400",
            backgroundColor: "$white",
            boxShadow: "md",
            justifyContent: "center",
            marginX: 4,
          }}
        >
          <AnimatePresence key={i}>
            {value[i] && (
              <MotiText
                key={i}
                from={{ translateY: 10, opacity: 0, rotate: "0deg" }}
                animate={{ translateY: 0, opacity: 1, rotate: "0deg" }}
                exit={{
                  translateY: 10,
                  rotate: `${Math.floor(Math.random() * 90 - 45) % 360}deg`,
                  opacity: 0,
                }}
                transition={{
                  type: "timing",
                  duration: 150,
                }}
                exitTransition={{ type: "timing", duration: 300 }}
                style={{ textAlign: "center", fontSize: 20 }}
              >
                {value[i]}
              </MotiText>
            )}
          </AnimatePresence>
        </View>
      ))}
      <TextInput
        autoFocus
        ref={(current) => {
          hiddenInput.current = current;
          current?.focus();
        }}
        onBlur={() => {
          hiddenInput.current?.focus();
        }}
        value={value}
        style={{
          position: "absolute",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          opacity: 0,
          zIndex: 10,
        }}
        maxLength={length}
        autoComplete="sms-otp"
        onChangeText={(text) => onChange(text)}
        keyboardType={"numeric"}
        editable={!disabled}
      />
    </View>
  );
}
