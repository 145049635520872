import {
  Button,
  Center,
  Icon,
  Pressable,
  Text,
  View,
} from "../components/basics";
import * as ImagePicker from "expo-image-picker";
import * as MediaLibrary from "expo-media-library";
import React, {
  memo,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Alert, Platform } from "react-native";
import { RootStackScreenProps } from "../types";
import { AttachmentState } from "./spaces/ChatScreen";
import useChatAttachments from "../hooks/useChatAttachments";
import { Ionicons } from "@expo/vector-icons";
import { v4 as uuidv4 } from "uuid";
import { useNavigation } from "@react-navigation/native";
import { PHOTO_RESOURCE_KEY } from "../resources/photo";
import { uploadAttachments } from "../utils/image-uploads";
import { OutboxItem, queueDelayedEvents } from "../lib/event-outbox";
import { usePersonalSpaceId } from "../hooks/useSpaceId";
import {
  ImageFlatlist,
  ImageListRenderItem,
} from "../components/ImageFlatlist";
import { ActivityIndicator, useSx } from "dripsy";
import { LocalAssetMediaCard } from "../components/MediaCard";
import { NOTICE_RESOURCE_KEY } from "../resources/notice";
import { nanoid } from "nanoid";
import useNoticeFormatter from "../hooks/useNoticeFormatter";
import { useUser } from "../hooks/auth";

const ROW_LENGTH = 3;
const MARGIN_SIZE = 4;
const PAGE_SIZE = 33 * ROW_LENGTH;
const MAX_IMAGES = 20;

async function assetToAttachment(
  asset: MediaLibrary.Asset
): Promise<AttachmentState> {
  const { id: assetId, uri: assetUri } = asset;
  let localUri;
  if (assetId && (!assetUri || assetUri.startsWith("ph://"))) {
    const assetInfo = await MediaLibrary.getAssetInfoAsync(assetId);
    localUri = assetInfo.localUri;
  }
  return {
    id: uuidv4(),
    assetId: asset.id,
    uri: asset.uri,
    localUri,
    mediaType: asset.mediaType as "photo" | "video",
    width: asset.width,
    height: asset.height,
  };
}

function AttachmentSelector({
  selectedAttachments,
  setSelectedAttachments,
  title,
  submitDisabled = false,
  onSubmit,
  onCancel,
}: {
  selectedAttachments: Record<string, MediaLibrary.Asset>;
  setSelectedAttachments: React.Dispatch<
    React.SetStateAction<Record<string, MediaLibrary.Asset>>
  >;
  title: string;
  submitDisabled?: boolean;
  onSubmit?: () => void | Promise<void>;
  onCancel?: () => void;
}) {
  const navigation = useNavigation();

  const [permission, requestPermission] =
    ImagePicker.useMediaLibraryPermissions();

  const manualPermissionNeeded = useMemo(() => {
    return permission && !permission.granted && !permission.canAskAgain;
  }, [permission]);

  const [assets, setAssets] = useState<MediaLibrary.Asset[]>([]);
  const [imagePageLoading, setImagePageLoading] = useState(false);
  const endCursorRef = useRef<string>();
  const hasNextPageRef = useRef<boolean>(true);
  const sx = useSx();

  const [submitRunning, setSubmitRunning] = useState(false);

  useEffect(() => {
    if (permission) {
      if (permission.status !== "granted") {
        requestPermission();
      } else {
        initializeAssets();
      }
    }
  }, [permission?.status]);

  useLayoutEffect(() => {
    navigation.setOptions({
      title,
      headerRight: () => (
        <Button
          variant={"unstyled"}
          onPress={async () => {
            setSubmitRunning(true);
            if (onSubmit) await onSubmit();
            setSubmitRunning(false);
            navigation.goBack();
          }}
          disabled={submitDisabled}
          _textStyles={sx({
            color: "$darkText",
          })}
          _showLoadingSpinner={true}
          isLoading={submitRunning}
        >
          Select
        </Button>
      ),
      headerLeft: () => (
        <Button
          variant={"unstyled"}
          onPress={() => {
            onCancel && onCancel();
            navigation.goBack();
          }}
          _textStyles={sx({
            color: "$darkText",
          })}
        >
          Cancel
        </Button>
      ),
    });
  }, [navigation, selectedAttachments, onSubmit, onCancel, submitDisabled]);

  const loadNextImagePage = useCallback(
    async (reset: boolean = false) => {
      if (hasNextPageRef.current && !imagePageLoading) {
        try {
          setImagePageLoading(true);
          const { assets, endCursor, hasNextPage } =
            await MediaLibrary.getAssetsAsync({
              sortBy:
                Platform.OS === "android" ? "modificationTime" : "creationTime",
              first: PAGE_SIZE,
              after: endCursorRef.current,
              mediaType: [
                MediaLibrary.MediaType.photo,
                MediaLibrary.MediaType.video,
              ],
            });
          if (reset) {
            setAssets([...assets]);
          } else {
            setAssets((prev) => [...prev, ...assets]);
          }
          endCursorRef.current = endCursor;
          hasNextPageRef.current = hasNextPage;
        } finally {
          setImagePageLoading(false);
        }
      }
    },
    [imagePageLoading]
  );

  const initializeAssets = useCallback(() => {
    endCursorRef.current = undefined;
    hasNextPageRef.current = true;
    setImagePageLoading(false);
    loadNextImagePage(true);
  }, [loadNextImagePage]);

  const handleAssetPress = useCallback(
    (asset: MediaLibrary.Asset) => {
      const isSelected = asset.id in selectedAttachments;
      if (isSelected) {
        setSelectedAttachments((prev) => {
          const { [asset.id]: _, ...remainingImages } = prev;
          return remainingImages;
        });
      } else {
        if (Object.keys(selectedAttachments).length >= MAX_IMAGES) {
          Alert.alert(
            "Too many attachments",
            `Aspen supports at most ${MAX_IMAGES} attachments per message.`,
            [
              {
                text: "OK",
              },
            ]
          );
          return;
        }
        setSelectedAttachments((prev) => ({ ...prev, [asset.id]: asset }));
      }
    },
    [selectedAttachments]
  );

  useEffect(() => {
    const listener = MediaLibrary.addListener((event) => {
      initializeAssets();
    });
    return () => {
      listener.remove();
    };
  }, []);

  const renderImageTile: ImageListRenderItem<MediaLibrary.Asset> = useCallback(
    ({ index, item, size }) => {
      const isSelected = item.id in selectedAttachments;
      return (
        <MemoMediaTile
          item={item}
          index={index}
          handleAssetPress={handleAssetPress}
          isSelected={isSelected}
          size={size}
        />
      );
    },
    [selectedAttachments]
  );

  const onImageListEndReached = useCallback(
    async () => await loadNextImagePage(),
    [loadNextImagePage]
  );

  return manualPermissionNeeded ? (
    <Center m={6}>
      <Text sx={{ textAlign: "center" }}>
        Aspen would like to access your photos. This lets you share photos from
        your library. This permission can be adjusted in your settings.
      </Text>
    </Center>
  ) : (
    <View sx={{ flex: 1 }}>
      {permission?.accessPrivileges === "limited" && (
        <Button
          onPress={async () => {
            await MediaLibrary.presentPermissionsPickerAsync();
          }}
          variant="ghost"
        >
          Select more
        </Button>
      )}
      <ImageFlatlist
        data={assets}
        extraData={selectedAttachments}
        renderItem={renderImageTile}
        keyExtractor={(item) => item.id}
        numColumns={ROW_LENGTH}
        onEndReached={onImageListEndReached}
        onEndReachedThreshold={0.5}
      />
      {imagePageLoading && (
        <View>
          <ActivityIndicator size="small" color="#0000ff" />
        </View>
      )}
    </View>
  );
}

function MediaTile({
  index,
  item,
  handleAssetPress,
  size,
  isSelected,
}: {
  index: number;
  item: MediaLibrary.Asset;
  handleAssetPress: any;
  size: number;
  isSelected: boolean;
}) {
  const imageRowStart = index % ROW_LENGTH === 0;
  const firstRow = index < 3;
  const asset = item;
  return (
    <Pressable
      onPress={() => {
        handleAssetPress(asset);
      }}
      style={({ pressed }) => pressed && { opacity: 0.8 }}
      key={asset.id}
      sx={{
        mr: MARGIN_SIZE,
        ml: imageRowStart ? MARGIN_SIZE : 0,
        mt: firstRow ? MARGIN_SIZE : 0,
        mb: MARGIN_SIZE,
        height: size,
        width: size,
        flex: 1,
        overflow: "hidden",
      }}
    >
      <LocalAssetMediaCard asset={asset} size={size} sx={{ flex: 1 }} />
      {isSelected && (
        <View
          sx={{
            position: "absolute",
            bottom: 4,
            left: 4,
            width: 32,
            height: 32,
            backgroundColor: "$gray.200",
            borderRadius: 100,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Icon as={Ionicons} name="checkmark-outline" color="$blue.500" />
        </View>
      )}
    </Pressable>
  );
}

export const MemoMediaTile = memo(MediaTile);

// TODO: Fixup photo picker after space thing
export function PhotoPickerScreen({
  route,
}: RootStackScreenProps<"PhotoPicker">) {
  const noticeFormatter = useNoticeFormatter();
  const currentUser = useUser();
  const [selectedImages, setSelectedImages] = useState<
    Record<string, MediaLibrary.Asset>
  >({});
  const personalSpaceId = usePersonalSpaceId();
  const spaceId = route.params?.spaceId ?? personalSpaceId;

  const onSubmit = useCallback(async () => {
    const attachments = await Promise.all(
      Object.values(selectedImages).map((asset) => assetToAttachment(asset))
    );
    uploadAttachments(attachments);
    const delayedEvents: OutboxItem[] = attachments.map((attachment) => ({
      eventPayload: {
        resource_key: PHOTO_RESOURCE_KEY,
        type: "NEW",
        data: {
          key: attachment.id,
          type: attachment.mediaType,
        },
        resource_id: attachment.id,
        space_id: spaceId,
      },
      dependencies: [attachment.id], // maybe we want to wait for all to upload before sending event? Would fit better with sending notice
    }));
    if (currentUser) {
      const noticeId = nanoid();
      const noticeText = `%0 uploaded ${attachments.length} photo${
        attachments.length === 1 ? "" : "s"
      }`;
      const noticeUserIds = [currentUser.id];
      delayedEvents.push({
        eventPayload: {
          resource_key: NOTICE_RESOURCE_KEY,
          type: "NEW",
          data: {
            text_user_ids: noticeUserIds,
            text: noticeText,
            static_text: noticeFormatter(noticeText, noticeUserIds, false),
            metadata: { noticeKey: "UPLOAD", resourceKey: "PHOTOS" },
          },
          resource_id: noticeId,
          space_id: spaceId,
        },
        dependencies: attachments.map((a) => a.id),
      });
    }

    queueDelayedEvents(delayedEvents);
  }, [selectedImages, spaceId]);

  return (
    <AttachmentSelector
      selectedAttachments={selectedImages}
      setSelectedAttachments={setSelectedImages}
      title="Select photos"
      submitDisabled={Object.keys(selectedImages).length === 0}
      onSubmit={onSubmit}
    />
  );
}

export function ChatAttachmentPickerScreen({
  navigation,
  route,
}: RootStackScreenProps<"AttachmentPicker">) {
  const { spaceId } = route.params;

  // TODO: can we get rid of spaceId here?
  const { stagedAttachments, stageAttachments } = useChatAttachments(spaceId);

  const [initialized, setInitialized] = useState(false);

  const [selectedAttachments, setSelectedAttachments] = useState<
    Record<string, MediaLibrary.Asset>
  >({});

  useEffect(() => {
    if (!initialized && stagedAttachments?.length) {
      setSelectedAttachments(
        Object.fromEntries(
          stagedAttachments.map((a) => [
            a.assetId,
            { id: a.assetId, uri: a.uri },
          ])
        )
      );
      setInitialized(true);
    }
  }, [stagedAttachments]);

  const submitSelection = useCallback(async () => {
    const attachmentsToUpload: AttachmentState[] = [];
    for (const [id, image] of Object.entries(selectedAttachments)) {
      const attachmentMatch = stagedAttachments.find(
        (attachment) => attachment.assetId === id
      );
      if (attachmentMatch) {
        attachmentsToUpload.push(attachmentMatch);
      } else {
        if (image) {
          const attachment = await assetToAttachment(image);
          attachmentsToUpload.push(attachment);
        }
      }
    }
    stageAttachments(attachmentsToUpload);
  }, [selectedAttachments, stagedAttachments]);

  return (
    <AttachmentSelector
      selectedAttachments={selectedAttachments}
      setSelectedAttachments={setSelectedAttachments}
      title="Select attachments"
      submitDisabled={Object.keys(selectedAttachments).length === 0}
      onSubmit={submitSelection}
    />
  );
}
