import Constants from "expo-constants";
import { parsePhoneNumber } from "../utils/phone";
import supabase from "./supabase";

const BASE_URL = Constants.manifest?.extra?.TWILIO_BASE_URL;

export const sendSmsVerification = async (phoneNumber: string) => {
  const parsedPhone = parsePhoneNumber(phoneNumber);
  if (!parsedPhone) throw new Error("Invalid phone number");
  try {
    const data = JSON.stringify({
      to: parsedPhone,
      channel: "sms",
    });

    const response = await fetch(`${BASE_URL}/start-verify`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: data,
    });
    return await response.json();
  } catch (error) {
    return {
      success: false,
      error: error instanceof Error ? error.message : error,
    };
  }
};

export const checkVerification = async (phoneNumber: string, code: string) => {
  try {
    const data = JSON.stringify({
      to: phoneNumber,
      code,
      verification_code: code,
      user_id: supabase.auth.user()!.id,
    });

    const response = await fetch(`${BASE_URL}/check-verify`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: data,
    });
    return await response.json();
  } catch (error) {
    return {
      success: false,
      message: error instanceof Error ? error.message : error,
    };
  }
};
