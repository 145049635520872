import { useNavigation } from "@react-navigation/native";
import { View, Text, Pressable, FlatList } from "../components/basics";
import React from "react";
import DRIPSY_THEME from "../constants/DripsyTheme";
import SpaceAppIcon from "../components/SpaceAppIcon";
import { BottomSheetFlatList } from "@gorhom/bottom-sheet";
import { useSx } from "dripsy";
import { Platform } from "react-native";

const TILE_SIZE = 100;
// const NUM_COLUMNS = Dimensions.get("window").width / (TILE_SIZE * 1.25);
const NUM_COLUMNS = 3;

function AppTile({
  app,
  title,
  size,
}: {
  app: string;
  title: string;
  size: number;
}) {
  const navigation = useNavigation();
  return (
    <View sx={{ width: size, height: size, padding: "$2" }}>
      <Pressable
        onPress={() => {
          navigation.navigate(app);
        }}
        style={({ hovered, pressed }) =>
          pressed
            ? { backgroundColor: DRIPSY_THEME.colors.$gray["300"] }
            : hovered
            ? { backgroundColor: DRIPSY_THEME.colors.$gray["100"] }
            : { backgroundColor: "transparent" }
        }
        sx={{
          flex: 1,
          borderRadius: "md",
        }}
      >
        <View
          sx={{
            flex: 1,
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <SpaceAppIcon appName={app} size="lg" />
          <Text
            sx={{ fontWeight: "medium", textAlign: "center", marginTop: "$3" }}
          >
            {title}
          </Text>
        </View>
      </Pressable>
    </View>
  );
}

// Try to keep alphabetical
const apps = [
  { app: "Chat", title: "Chat" },
  { app: "le-word", title: "Le Word" },
  { app: "geo-genius", title: "Geo Genius" },
  { app: "Pages", title: "Notes" },
  { app: "Photos", title: "Photos" },
  { app: "polls", title: "Polls" },
  { app: "reminders", title: "Reminders" },
  { app: "schedule", title: "Schedule" },
  { app: "profiles", title: "People" },
  // { app: "groups", title: "Groups" },
  { app: "shopping-list", title: "Shopping List" },
];

const FlatListSafe = Platform.OS === "web" ? FlatList : BottomSheetFlatList;

export default function AppsScreen(props) {
  const Sx = useSx();
  return (
    <FlatListSafe
      style={Sx({
        paddingX: "$6",
        flex: 1,
      })}
      contentContainerStyle={{ alignItems: "center" }}
      estimatedItemSize={100}
      numColumns={NUM_COLUMNS}
      data={apps}
      keyExtractor={(app) => app.app}
      renderItem={({ item }) => (
        <AppTile {...item} key={item.app} size={TILE_SIZE} />
      )}
      {...props}
    />
  );
}
