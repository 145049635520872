import React, { useCallback, useMemo, useRef, useState } from "react";
import {
  View,
  Text,
  IconButton,
  Pressable,
  Avatar,
} from "../components/basics";
import { useToast } from "../hooks/useToast";
import * as ImagePicker from "expo-image-picker";
import { uploadAvatarImage } from "../utils/avatars";
import { v4 as uuidv4 } from "uuid";
import { ProfilesInfoFragment } from "../state/generated/graphql";
import useProfileAvatarUri from "../hooks/useProfileAvatarUri";
import PersonAvatar from "./PersonAvatar";

export default function AvatarPicker({
  profile,
  onAvatarChange,
}: {
  pickerText: string;
  onAvatarChange?: (key: string) => void;
  profile?: ProfilesInfoFragment;
}) {
  const [imagePath, setImagePath] = useState<string>();
  const [permission, requestPermission] =
    ImagePicker.useMediaLibraryPermissions();

  const manualPermissionNeeded = useMemo(() => {
    return permission && !permission.granted && !permission.canAskAgain;
  }, [permission]);
  const uploadingImage = useRef<string>();
  const addToast = useToast();
  const size = 120;
  const avatarUri = useProfileAvatarUri(profile, size);
  const alt = true;
  const onImageSelect = useCallback(
    async (image) => {
      const avatarKey = uuidv4();
      uploadingImage.current = avatarKey;
      setImagePath(image.uri);
      // TODO: queue the image upload in case goes offline
      uploadAvatarImage(avatarKey, image.uri).then(({ error }) => {
        if (error && error.avatarKey === uploadingImage.current) {
          addToast({
            title: "Image upload error",
            description: "Failed to upload your profile image",
            variant: "ERROR",
          });
          setImagePath(undefined);
          onAvatarChange && onAvatarChange("");
        }
      });
      onAvatarChange && onAvatarChange(avatarKey);
    },
    [uploadingImage, addToast]
  );
  const pickImage = useCallback(async () => {
    if (!permission) return;
    if (
      permission.status === "undetermined" ||
      (permission.status === "denied" && permission.canAskAgain)
    ) {
      await requestPermission();
    }
    if (permission.status === "granted") {
      let result = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        allowsEditing: true,
        quality: 1,
      });

      if (!result.cancelled) {
        onImageSelect(result);
      }
    }
  }, [onImageSelect, permission, addToast]);

  if (!(imagePath || avatarUri))
    return (
      <Pressable
        sx={{
          width: size,
          height: size,
          borderRadius: size,
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "$gray.200",
        }}
        onPress={pickImage}
      >
        <Text sx={{ color: "$aspenDark" }}>Add Photo</Text>
      </Pressable>
    );
  return (
    <View sx={{ width: size, height: size }}>
      <Avatar
        source={
          imagePath || avatarUri ? { uri: imagePath || avatarUri } : undefined
        }
        size={size}
      />
      <IconButton
        icon="camera"
        variant="unstyled"
        size="md"
        onPress={pickImage}
        _containerStyles={{
          position: "absolute",
          right: 0,
          top: 0,
          backgroundColor: "$gray.200",
          borderRadius: 50,
        }}
        _iconStyles={{ color: "$black", padding: 0, margin: 0 }}
      />
    </View>
  );
}
