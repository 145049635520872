import DRIPSY_THEME from "./DripsyTheme";

export const baseStyle = {
  _container: {
    borderRadius: "xs",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  _text: {
    paddingX: "$1",
  },
  _web: {
    _disabled: {
      cursor: "not-allowed",
    },
    _loading: {
      cursor: "not-allowed",
    },
    cursor: "pointer",
    userSelect: "none",
  },
  _stack: {
    space: "1.5",
    alignItems: "center",
  },
  _loading: {
    opacity: 0.4,
  },
  _disabled: {
    opacity: 0.4,
  },
  _spinner: {
    size: "small",
    focusable: false,
  },
};

function variantGhost({ colorScheme }: Record<string, any>) {
  return {
    _text: {
      color: `${colorScheme}.600`,
    },
    _icon: {
      color: `${colorScheme}.600`,
    },
    _spinner: {
      color: DRIPSY_THEME.colors[colorScheme]["600"],
    },
    _container: {
      backgroundColor: "transparent",
    },
    _containerHover: {
      backgroundColor: DRIPSY_THEME.colors[colorScheme]["50"], // was 600.alpha:10
    },
    _containerPressed: {
      backgroundColor: DRIPSY_THEME.colors[colorScheme]["100"], // was 600.alpha:20
    },
  };
}

function variantOutline({ colorScheme }: Record<string, any>) {
  return {
    _text: {
      color: `${colorScheme}.600`,
    },
    _icon: {
      color: `${colorScheme}.600`,
    },
    _spinner: {
      color: DRIPSY_THEME.colors[colorScheme]["600"],
    },
    // consider pulling out border styles on their own?
    _container: {
      backgroundColor: "transparent",
      borderWidth: 1,
      borderStyle: "solid",
      borderColor: DRIPSY_THEME.colors.$muted["300"],
    },
    _containerHover: {
      borderWidth: 1,
      borderStyle: "solid",
      backgroundColor: DRIPSY_THEME.colors[colorScheme]["50"], // was 600.alpha:10
      borderColor: DRIPSY_THEME.colors.$muted["300"],
    },
    _containerPressed: {
      borderWidth: 1,
      borderStyle: "solid",
      backgroundColor: DRIPSY_THEME.colors[colorScheme]["100"], // was 600.alpha:20
      borderColor: DRIPSY_THEME.colors.$muted["300"],
    },
  };
}

function variantSolid({ colorScheme }: Record<string, any>) {
  return {
    _text: {
      color: "$white",
    },
    _icon: {
      color: "$white",
    },
    _spinner: {
      color: DRIPSY_THEME.colors.$white,
    },
    _container: {
      backgroundColor: DRIPSY_THEME.colors[colorScheme]["500"],
    },
    _containerHover: {
      backgroundColor: DRIPSY_THEME.colors[colorScheme]["700"],
    },
    _containerPressed: {
      backgroundColor: DRIPSY_THEME.colors[colorScheme]["800"],
    },
  };
}

function variantSubtle({ colorScheme }: Record<string, any>) {
  return {
    _text: {
      color: `${colorScheme}.900`,
    },
    _icon: {
      color: `${colorScheme}.900`,
    },
    _spinner: {
      color: DRIPSY_THEME.colors[colorScheme]["900"],
    },
    _container: {
      backgroundColor: DRIPSY_THEME.colors[colorScheme]["100"],
    },
    _containerHover: {
      backgroundColor: DRIPSY_THEME.colors[colorScheme]["200"],
    },
    _containerPressed: {
      backgroundColor: DRIPSY_THEME.colors[colorScheme]["300"],
    },
  };
}
function variantUnstyled({ colorScheme }: Record<string, any>) {
  return {
    _text: {},
    _icon: { color: colorScheme || "$muted.900" },
    _spinner: {},
    _container: {},
    _containerHover: {},
    _containerPressed: {},
  };
}

export const variants = {
  ghost: variantGhost,
  outline: variantOutline,
  solid: variantSolid,
  subtle: variantSubtle,
  unstyled: variantUnstyled,
};

export const buttonSizes = {
  lg: {
    _container: {
      px: "$4",
      py: "$3",
    },
    _text: {
      fontSize: "md",
    },
    _icon: {
      fontSize: "lg",
    },
    _spinner: {
      size: "small",
    },
  },
  md: {
    _container: {
      px: "$3",
      py: "$3",
    },
    _text: {
      fontSize: "sm",
    },
    _icon: {
      fontSize: "md",
    },
    _spinner: {
      size: "small",
    },
  },
  sm: {
    _container: {
      px: "$2",
      py: "$2",
    },
    _text: {
      fontSize: "xs",
    },
    _icon: {
      fontSize: "sm",
    },
    _spinner: {
      size: "small",
    },
  },
  xs: {
    _container: {
      px: "$3",
      py: "$2",
    },
    _text: {
      fontSize: "2xs",
    },
    _icon: {
      fontSize: "xs",
    },
    _spinner: {
      size: "small",
    },
  },
};

export const iconButtonSizes = {
  lg: {
    _container: {
      p: "$4",
    },
    _icon: {
      size: 24,
    },
  },
  md: {
    _container: {
      p: "$2",
    },
    _icon: {
      size: "lg",
    },
  },
  sm: {
    _container: {
      p: "$2",
    },
    _icon: {
      size: "md",
    },
  },
  xs: {
    _container: {
      p: "$1",
    },
    _icon: {
      size: "sm",
    },
  },
};
