import {
  BottomSheetFooter,
  BottomSheetModal,
  BottomSheetModalProvider,
} from "@gorhom/bottom-sheet";
import React, {
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from "react";
import { Dimensions } from "react-native";
import { Button, View } from "../components/basics";
import { ResourceShareList } from "../components/ResourceShareBottomSheet";
import { GEO_GENIUS_RESOURCE_KEY } from "../resources/geo-genius";
import { LE_WORD_RESOURCE_KEY } from "../resources/le-word";
import { PAGES_RESOURCE_KEY } from "../resources/page";
import { POLL_RESOURCE_KEY } from "../resources/poll";
import { SCHEDULE_RESOURCE_KEY } from "../resources/schedule";
import { createNewSpaces, SpaceCreationParams } from "../state/atoms/spaces";
import { parseNotePreview } from "../utils/apps/notes";
import { formatNoticeDate } from "../utils/apps/schedule";
import { useUser } from "./auth";
import { usePushNoticeAdmin } from "./usePushNotice";
import useResource from "./useResource";
import useShareResource from "./useShareResource";
import { useToast } from "./useToast";

const ResourceShareContext = React.createContext(
  (resourceId: string, resourceKey: string) => {}
);

export function useResourceShareDialog() {
  return useContext(ResourceShareContext);
}

const FOOTER_HEIGHT = 80;
const INITIAL_SNAP_INDEX = -1;

export const ResourceShareModalProvider: React.FC = ({ children }) => {
  const [resourceId, setResourceId] = useState<string>();
  const [resourceKey, setResourceKey] = useState<string>();

  const onResourceShare = useCallback(
    (resourceId: string, resourceKey: string) => {
      setResourceId(resourceId);
      setResourceKey(resourceKey);
      bottomSheetModalRef.current?.present();
    },
    []
  );

  // ref
  const bottomSheetModalRef = useRef<BottomSheetModal>(null);

  // variables
  const snapPointValues = useMemo(() => [50, 85], []);
  const snapPoints = useMemo(
    () => snapPointValues.map((v) => `${v}%`),
    [snapPointValues]
  );

  const [snapIndex, setSnapIndex] = useState(INITIAL_SNAP_INDEX);
  const handleSheetChanges = useCallback((index: number) => {
    if (index === -1) {
      setResourceId(undefined);
      setResourceKey(undefined);
    }
    setSnapIndex(index);
  }, []);

  const [selectedSpaces, setSelectedSpaces] = useState<Set<string>>(new Set());
  const [mockSpaces, setMockSpaces] = useState<SpaceCreationParams[]>();
  function handleSelectionChange(
    selectedSpaces: Set<string>,
    mockSpaces: SpaceCreationParams[]
  ) {
    setSelectedSpaces(selectedSpaces);
    setMockSpaces(mockSpaces);
  }

  const currentUser = useUser();
  const [{ data: resource, fetching: resourceLoading }] = useResource(
    resourceKey,
    resourceId
  );
  const [isSharing, setIsSharing] = useState(false);
  const shareResource = useShareResource();
  const addToast = useToast();
  const pushNoticeAdmin = usePushNoticeAdmin();

  const handleResourceShare = useCallback(async () => {
    if (!resourceId || !resourceKey) return;
    if (!selectedSpaces?.size && !mockSpaces?.length) return;
    try {
      setIsSharing(true);
      const { error: resourceShareError } = await shareResource(
        resourceId,
        resourceKey,
        [...selectedSpaces]
      );

      if (resourceShareError) {
        addToast({
          title: "Share failure",
          description:
            "The request to share this resource could not be completed.",
          variant: "ERROR",
        });
        setIsSharing(false);
        return;
      }

      const newSpaceParams = mockSpaces.map((s) => ({
        ...s,
        permissions: [{ resource_id: resourceId, resource_key: resourceKey }],
      }));
      const { data: newSpaces, error: spaceCreationError } =
        await createNewSpaces(newSpaceParams);

      if (resourceShareError) {
        addToast({
          title: "Share failure",
          description:
            "The request to share this resource could not be completed.",
          variant: "ERROR",
        });
        setIsSharing(false);
        return;
      }
      // TODO: push notices in bulk
      const resourceData = resource?.resources_by_pk?.data;
      if (currentUser && resourceData) {
        if (resourceKey === SCHEDULE_RESOURCE_KEY) {
          for (const spaceId of selectedSpaces) {
            pushNoticeAdmin(
              `%0 shared an event "${resourceData.title}" on ${formatNoticeDate(
                new Date(resourceData.start_date_utc),
                resourceData.all_day
              )}`,
              [currentUser.id],
              { resourceId, resourceKey, noticeKey: "SHARE" },
              spaceId
            );
          }
        } else if (resourceKey === PAGES_RESOURCE_KEY) {
          for (const spaceId of selectedSpaces) {
            const { title } = parseNotePreview(resourceData.rawText);
            pushNoticeAdmin(
              `%0 shared a note "${title}"`,
              [currentUser.id],
              { resourceId, resourceKey, noticeKey: "SHARE" },
              spaceId
            );
          }
        } else if (resourceKey === POLL_RESOURCE_KEY) {
          for (const spaceId of selectedSpaces) {
            const { title } = resourceData;
            pushNoticeAdmin(
              `%0 shared a poll${title ? `: "${title}"` : ""}`,
              [currentUser.id],
              { resourceId, resourceKey, noticeKey: "SHARE" },
              spaceId
            );
          }
        } else if (resourceKey === GEO_GENIUS_RESOURCE_KEY) {
          for (const spaceId of selectedSpaces) {
            let variableMessage: string;
            const { distance } = resourceData;
            if (distance < 100) {
              variableMessage = "pinpointed today’s Geo Genius!";
            } else if (distance <= 1000) {
              variableMessage = "came close on today’s Geo Genius";
            } else if (distance <= 7000) {
              variableMessage = "missed today's Geo Genius";
            } else {
              variableMessage = "made a wild guess in today's Geo Genius";
            }
            pushNoticeAdmin(
              `🌎 %0 ${variableMessage} (${
                resourceData.distance.toString().split(".")[0]
              }km)`,
              [currentUser.id],
              { resourceId, resourceKey, noticeKey: "SHARE" },
              spaceId
            );
          }
        } else if (resourceKey === LE_WORD_RESOURCE_KEY) {
          if (["WON", "LOST"].includes(resourceData.result)) {
            const guessCount = resourceData.numGuesses;
            const optionallyPluralizedGuess =
              guessCount === 1 ? "guess" : "guesses";
            for (const spaceId of selectedSpaces) {
              pushNoticeAdmin(
                resourceData.result === "WON"
                  ? `%0 got Le Word in ${resourceData.numGuesses} ${optionallyPluralizedGuess}`
                  : "%0 couldn't guess Le Word today",
                [currentUser.id],
                { resourceId, resourceKey, noticeKey: "SHARE" },
                spaceId
              );
            }
          }
        }
      }
      addToast({
        description: "Shared successfully",
        variant: "SUCCESS",
      });
      bottomSheetModalRef.current?.dismiss();
    } catch {
      addToast({
        title: "Share failure",
        description:
          "The request to share this resource could not be completed.",
        variant: "ERROR",
      });
    } finally {
      setIsSharing(false);
    }
  }, [
    selectedSpaces,
    resourceId,
    resourceKey,
    resource,
    currentUser,
    mockSpaces,
    shareResource,
    pushNoticeAdmin,
    addToast,
  ]);

  const renderFooter = useCallback(
    (props) => (
      <BottomSheetFooter {...props}>
        <View
          sx={{
            paddingX: 12,
            backgroundColor: "#fff",
            borderTopColor: "$gray.200",
            borderTopWidth: 1,
            height: FOOTER_HEIGHT,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            onPress={handleResourceShare}
            isLoading={isSharing}
            disabled={
              selectedSpaces?.size === 0 && (mockSpaces?.length ?? 0) === 0
            }
            sx={{ width: "100%" }}
          >
            Share
          </Button>
        </View>
      </BottomSheetFooter>
    ),
    [selectedSpaces, mockSpaces, isSharing, handleResourceShare]
  );

  // Waiting for resolution here: https://github.com/gorhom/react-native-bottom-sheet/issues/1008
  const paddingBottom =
    FOOTER_HEIGHT +
    Dimensions.get("window").height *
      ((snapPointValues[snapPointValues.length - 1] -
        snapPointValues[snapIndex]) /
        100);

  return (
    <BottomSheetModalProvider>
      <ResourceShareContext.Provider value={onResourceShare}>
        {children}
        <BottomSheetModal
          ref={bottomSheetModalRef}
          index={0}
          snapPoints={snapPoints}
          onChange={handleSheetChanges}
          style={{
            shadowColor: "#000",
            shadowOffset: {
              width: 0,
              height: 10,
            },
            shadowOpacity: 0.51,
            shadowRadius: 13.16,

            elevation: 20,
          }}
          footerComponent={renderFooter}
        >
          {resourceId && resourceKey ? (
            <ResourceShareList
              resourceId={resourceId}
              resourceKey={resourceKey}
              onSelectionChange={handleSelectionChange}
              flatListProps={{
                contentContainerStyle: { paddingBottom: paddingBottom },
              }}
            />
          ) : null}
        </BottomSheetModal>
      </ResourceShareContext.Provider>
    </BottomSheetModalProvider>
  );
};
