import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { useToast } from "../hooks/useToast";
import { View, Text, Button } from "../components/basics";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { checkVerification, sendSmsVerification } from "../lib/twilio";
import { RootStackParamList } from "../types";
import { useUserAccount } from "../hooks/auth";
import { useNavigation } from "@react-navigation/native";
import { signOut } from "../lib/supabase";
import { parsePhoneNumberFriendly } from "../utils/phone";
import CodeInput from "../components/CodeInput";

export default function VerifyMobileScreen({
  route,
}: NativeStackScreenProps<RootStackParamList, "VerifyMobile">) {
  const { phone, redirect } = route.params;
  const [code, setCode] = useState("");
  const addToast = useToast();
  const [isVerifying, setIsVerifying] = useState(false);
  const [resendingCode, setResendingCode] = useState(false);
  const [inputError, setInputError] = useState(false);
  const [_currentAccount, refreshUser] = useUserAccount();
  const navigation = useNavigation();
  const CODE_LENGTH = 6;
  let hiddenInput = useRef(null);

  const submitCode = useCallback(async () => {
    try {
      setIsVerifying(true);
      const { success, message } = await checkVerification(phone, code);
      console.log(phone);
      console.log(code);
      console.log(success);
      console.log(message);
      if (!success) throw new Error(message);

      const { error } = await refreshUser();
      console.log(code);
      if (error) {
        signOut();
        addToast({
          variant: "ERROR",

          description:
            "Sorry, something unexpected occured. Please try signing in again.",
          position: "bottom",
        });
      }

      if (redirect === "BACK") {
        navigation.goBack();
      }

      addToast({
        description: "Your phone number was updated.",
      });
    } catch (e) {
      console.error(e);
      setInputError(true);
      addToast({
        variant: "ERROR",
        description: "There was an error verifying your mobile number.",
        position: "bottom",
      });
    } finally {
      setIsVerifying(false);
    }
  }, [addToast, phone, code, navigation]);

  const resendCode = useCallback(async () => {
    setResendingCode(true);
    try {
      const { success, error } = await sendSmsVerification(phone);
      if (!success) {
        throw new Error("Verification flow unsuccessful");
      }
    } catch (e) {
      console.error(e);
      addToast({
        description: "There was an error sending your new code.",
        position: "bottom",
        variant: "ERROR",
      });
    } finally {
      setResendingCode(false);
    }
  }, [phone, addToast]);

  useEffect(() => {
    if (code.length === CODE_LENGTH) {
      submitCode();
    }
  }, [code]);

  useEffect(() => {
    if (inputError) {
      setTimeout(() => {
        setCode("");
        setInputError(false);
      }, 500);
    }
  }, [inputError]);

  return (
    <View
      sx={{
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "white",
        paddingBottom: 200,
      }}
    >
      <Button
        variant="unstyled"
        sx={{ position: "absolute", left: 0, top: 40 }}
        leftIcon={"arrow-back"}
        onPress={() => navigation.goBack()}
      >
        Change Phone
      </Button>
      <View sx={{ marginBottom: 50 }}>
        <Text
          style={{
            fontSize: 30,
            fontWeight: "500",
            marginBottom: 5,
            textAlign: "center",
          }}
        >
          {parsePhoneNumberFriendly(phone)}
        </Text>
        <Text style={{ textAlign: "center" }}>
          We've sent you a message with the code
        </Text>
      </View>
      <CodeInput value={code} onChange={setCode} length={CODE_LENGTH} />
      <Button variant="ghost" isLoading={resendingCode} onPress={resendCode}>
        Resend Code
      </Button>
    </View>
  );
}
