import { useEffect, useState } from "react";
import AsyncStorage from "@react-native-async-storage/async-storage";
import * as Updates from "expo-updates";
import { storage } from "../state/graphql/client";
import linkPreviewCache from "../state/caches/link-preview-cache";

async function clearData() {
  await storage.clear();
  await linkPreviewCache.clearAll();
}

export default function useUpdateCheck() {
  const [updateHandled, setUpdateHandled] = useState(false);

  useEffect(() => {
    async function handleUpdate() {
      const appVersion = Updates.updateId ?? "";
      const storageKey = "client-update-version";
      const cacheVersion = await AsyncStorage.getItem(storageKey);
      if (appVersion && appVersion !== cacheVersion) {
        await clearData();
        await AsyncStorage.setItem(storageKey, appVersion);
      }
      setUpdateHandled(true);
    }
    handleUpdate();
  }, []);

  return updateHandled;
}
