import { ScheduleItem } from "../../resources/schedule";
import {
  format,
  differenceInCalendarDays,
  addMinutes,
  getMinutes,
  startOfMinute,
  setMinutes,
} from "date-fns";

export function formatDurationText(event: ScheduleItem) {
  const startDate = new Date(event.start_date_utc);
  const endDate = new Date(event.end_date_utc);
  if (event.all_day) return format(startDate, "E, MMM d, yyyy");
  const differentDays = differenceInCalendarDays(endDate, startDate) !== 0;
  if (differentDays) {
    const formatString = "E, MMM d, yyyy, p";
    return `${format(startDate, formatString)}\nto ${format(
      endDate,
      formatString
    )}`;
  }
  return `${format(startDate, "E, MMM d, yyyy")}\n${format(
    startDate,
    "p"
  )} to ${format(endDate, "p")}`;
}

export function sortScheduleItems(itemA: ScheduleItem, itemB: ScheduleItem) {
  if (itemA.all_day !== itemB.all_day) return itemA.all_day ? -1 : 1;
  if (itemA.all_day && itemB.all_day) return itemA.title < itemB.title ? -1 : 1;
  return new Date(itemA.start_date_utc) < new Date(itemB.start_date_utc)
    ? -1
    : 1;
}

export function formatNoticeDate(date: Date, allDay: boolean) {
  return format(date, `MMM d, yyyy${allDay ? "" : ` 'at' p`}`);
}

export function roundToNextMinuteInterval(date: Date, interval: number) {
  var roundedMinutes = Math.floor(getMinutes(date) / interval) * interval;
  return addMinutes(setMinutes(startOfMinute(date), roundedMinutes), interval);
}
