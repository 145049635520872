import { useRoute } from "@react-navigation/native";
import { createContext, useContext } from "react";
import { useUser } from "./auth";
import { usePerson } from "./usePeople";

export const SpaceIdContext = createContext<string | undefined>(undefined);

export function useEventPushSpaceId() {
  const containerSpaceId = useSpaceContainerId();
  const personalSpaceId = usePersonalSpaceId();

  return containerSpaceId ?? personalSpaceId;
}

export function useSpaceContainerId() {
  const route = useRoute();
  const { spaceId } = route.params ?? {};
  return spaceId;
}

export function usePersonalSpaceId() {
  const currentUser = useUser();
  const currentProfile = usePerson(currentUser?.id);
  const personalSpaceId = currentProfile?.personal_space?.id as
    | string
    | undefined;
  return personalSpaceId;
}
