import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { RootStackParamList } from "../types";
import SimpleSpaceListItem from "../components/SimpleSpaceListItem";
import {
  Button,
  FlatList,
  Row,
  Icon,
  Divider,
  Spinner,
  View,
  Text,
  Pressable,
} from "../components/basics";
import { useSpaces } from "../hooks/spaces";
import { useResourcePermissions } from "../hooks/useResourcePermissions";
import { ListRenderItem } from "react-native";
import CenteredSpinner from "../components/basics/CenteredSpinner";
import { SpaceInfoFragment } from "../state/generated/graphql";
import { useCallback, useMemo, useState } from "react";
import { SafeAreaView } from "dripsy";
import useShareResource from "../hooks/useShareResource";
import ResourceCard from "../components/ResourceCard";
import useResource from "../hooks/useResource";
import { useUser } from "../hooks/auth";
import { SCHEDULE_RESOURCE_KEY } from "../resources/schedule";
import { formatNoticeDate } from "../utils/apps/schedule";
import { PAGES_RESOURCE_KEY } from "../resources/page";
import { parseNotePreview } from "../utils/apps/notes";
import { GEO_GENIUS_RESOURCE_KEY } from "../resources/geo-genius";
import { useToast } from "../hooks/useToast";
import { LE_WORD_RESOURCE_KEY } from "../resources/le-word";
import { usePushNoticeAdmin } from "../hooks/usePushNotice";
import { useChatList } from "../hooks/useChatList";
import { POLL_RESOURCE_KEY } from "../resources/poll";
import { useResourceShareDialog } from "../hooks/useResourceShare";

export default function ResourceAttributionModalScreen({
  route,
  navigation,
}: NativeStackScreenProps<RootStackParamList, "ResourceAttributionModal">) {
  const { resourceId, resourceKey } = route.params;
  const { data: spaces, loading } = useSpaces();
  const [{ data: chatList }] = useChatList();

  const currentUser = useUser();
  // const filteredSpaces = useMemo(() => {
  //   const externalSpaces = spaces.filter(
  //     (s) => s.private_space_owner_id !== currentUser!.id
  //   );
  //   if (!spaceIdSet) return externalSpaces;
  //   return externalSpaces.filter((s) => spaceIdSet.has(s.id));
  // }, [spaceIds, spaceIdSet, currentUser]);

  // const orderedSpaces = useMemo(() => {
  //   const chatIndices = new Map(chatList?.chat_list.map((cl, i) => [cl.id, i]));
  //   return filteredSpaces.slice().sort((a, b) => {
  //     const aIndex = chatIndices.get(a.id) ?? chatIndices.size;
  //     const bIndex = chatIndices.get(b.id) ?? chatIndices.size;
  //     let res = 0;
  //     if (!res) res = aIndex - bIndex;
  //     if (!res) res = a.id - b.id;
  //     return res;
  //   });
  // }, [filteredSpaces, chatList]);
  const showResourceShareDialog = useResourceShareDialog();

  const { data } = useResourcePermissions(resourceKey, resourceId);
  const [{ data: resource, fetching: resourceLoading }] = useResource(
    resourceKey,
    resourceId
  );
  const filteredSpaces = useMemo(() => {
    const ids = new Set(
      ...(data?.resource_permissions
        ? [data?.resource_permissions?.map((rp) => rp.space_id)]
        : [])
    );
    return spaces?.filter((s) => ids.has(s.id)) ?? [];
  }, [data?.resource_permissions, spaces]);
  const renderItem: ListRenderItem<SpaceInfoFragment> = ({ item }) => {
    return <SimpleSpaceListItem space={item} />;
  };

  if (loading) return <CenteredSpinner text="Loading spaces" />;

  return (
    <SafeAreaView sx={{ flex: 1 }}>
      <Row justifyContent="center" alignItems="center" m={2}>
        {resource?.resources_by_pk ? (
          <ResourceCard resource={resource.resources_by_pk} />
        ) : (
          <Spinner />
        )}
      </Row>
      <Pressable
        sx={{ justifyContent: "center", alignItems: "center", m: 2 }}
        onPress={() => {
          navigation.goBack();
          showResourceShareDialog(resourceId, resourceKey);
        }}
      >
        <Text sx={{ fontSize: 16, color: "$primary.500" }}>
          Share with others <Icon name="share-outline" />
        </Text>
      </Pressable>
      <Divider my={2} />
      <FlatList
        data={filteredSpaces}
        renderItem={renderItem}
        keyExtractor={(item) => item.id}
      />
    </SafeAreaView>
  );
}
