import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { SafeAreaView } from "dripsy";
import { RootStackParamList } from "../types";
import { Button, Text, View } from "../components/basics";
import EmojiSelector, { Categories } from "react-native-emoji-selector";
import { useCallback, useLayoutEffect, useState } from "react";
import useSharedNavigationValue from "../hooks/useSharedNavigationValue";

export default function EmojiPickerScreen({
  route,
  navigation,
}: NativeStackScreenProps<RootStackParamList, "EmojiPicker">) {
  const [emoji, setEmoji] = useState<string>();
  const { id } = route.params;

  const [_sharedEmojiState, setSharedEmojiState] = useSharedNavigationValue(id);

  const handleSelect = useCallback(async () => {
    setSharedEmojiState(emoji);
    navigation.goBack();
  }, [navigation, setSharedEmojiState, emoji]);

  const handleCancel = useCallback(() => {
    navigation.goBack();
  }, [navigation]);

  useLayoutEffect(() => {
    navigation.setOptions({
      headerRight: () => (
        <Button variant={"unstyled"} onPress={handleSelect}>
          Select
        </Button>
      ),
      headerLeft: () => (
        <Button variant={"unstyled"} onPress={handleCancel}>
          Cancel
        </Button>
      ),
    });
  }, [navigation, emoji]);

  return (
    <SafeAreaView
      sx={{
        flex: 1,
        backgroundColor: "#fff",
        alignItems: "center",
        justifyContent: "center",
        padding: "$2",
      }}
    >
      <View
        sx={{
          width: 96,
          height: 96,
          margin: 24,
          borderWidth: 2,
          borderRadius: 12,
          borderColor: "$gray.300",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Text sx={{ fontSize: 64, backgroundColor: "transparent" }}>
          {emoji}
        </Text>
      </View>
      <EmojiSelector
        onEmojiSelected={(emoji) => setEmoji(emoji)}
        showSearchBar={true}
        showTabs={false}
        showHistory={true}
        showSectionTitles={false}
        category={Categories.all}
        theme={"#d1d5db"}
      />
    </SafeAreaView>
  );
}
