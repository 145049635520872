import { cardInfo } from "./ResourceCard";
import { View, Icon, Text } from "./basics";
import { POLL_RESOURCE_KEY } from "../resources/poll";
import { PAGES_RESOURCE_KEY } from "../resources/page";
import { REMINDER_RESOURCE_KEY } from "../resources/reminder";
import { format } from "date-fns";
import { SCHEDULE_RESOURCE_KEY } from "../resources/schedule";

export default function ResourceAttachmentForChat({
  resource,
  size = 100,
}: {
  resource: any;
  size: number;
}) {
  const { icon, title, snippet } = cardInfo(resource);
  if (resource.collection_key === POLL_RESOURCE_KEY)
    return <PollAttachment size={size} resource={resource} />;
  if (resource.collection_key === PAGES_RESOURCE_KEY)
    return <NoteAttachment size={size} resource={resource} />;
  if (
    resource.collection_key === REMINDER_RESOURCE_KEY ||
    resource.collection_key === SCHEDULE_RESOURCE_KEY
  )
    return <EventAttachment size={size} resource={resource} />;
  if (resource.collection_key === SCHEDULE_RESOURCE_KEY)
    return (
      <View
        sx={{
          height: size,
          width: size,
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "$white",

          borderRadius: "md",
          boxShadow: "sm",
        }}
        key={resource.id}
      >
        <Icon size={"2xl"} color={"$black"} name={icon} />
        <Text sx={{ color: "$black", fontSize: 12, mt: "$1" }}>{title}</Text>
      </View>
    );
}

function PollAttachment({ resource, size }: { resource: any; size: number }) {
  const { icon, title, snippet } = cardInfo(resource);

  return (
    <View
      sx={{
        height: size,
        width: size,
        ...attachmentContainerStyle,
      }}
      key={resource.id}
    >
      <Text sx={{ mb: "$2" }}>
        <Icon size={"md"} color={"$black"} name={icon} /> Poll
      </Text>
      {resource.data.options.map(({ value, id }) => (
        <Text sx={{ width: "100%", fontSize: 12 }} numberOfLines={1} key={id}>
          {`- ${value}`}
        </Text>
      ))}
    </View>
  );
}

function NoteAttachment({ resource, size }: { resource: any; size: number }) {
  const { icon, title, snippet } = cardInfo(resource);

  return (
    <View
      sx={{
        height: size,
        width: size,
        ...attachmentContainerStyle,
      }}
      key={resource.id}
    >
      <Text sx={{ mb: "$2" }}>
        <Icon size={"md"} color={"$black"} name={icon} /> Note
      </Text>
      <Text sx={{ width: "100%", fontSize: 12 }} numberOfLines={4}>
        {resource.data.rawText}
      </Text>
    </View>
  );
}

function EventAttachment({ resource, size }: { resource: any; size: number }) {
  const { icon, title, snippet } = cardInfo(resource);
  const startDate = new Date(resource.data.start_date_utc);
  const formattedMonth = format(startDate, "MMM");
  const formattedDay = format(startDate, "d");
  const formattedTime = format(startDate, "h:mm aaa");

  return (
    <View
      sx={{
        height: size,
        width: size,
        ...attachmentContainerStyle,
      }}
      key={resource.id}
    >
      <Text sx={{ mb: "$2" }}>
        <Icon size={"md"} color={"$black"} name={icon} />
      </Text>
      <Text
        sx={{
          width: "100%",
          fontSize: 16,
          textAlign: "center",
          mb: "$1",
          fontWeight: "medium",
        }}
        numberOfLines={1}
      >
        {title}
      </Text>
      <Text
        sx={{
          width: "100%",
          fontSize: 16,
          textAlign: "center",
        }}
      >
        {formattedTime} {formattedMonth} {formattedDay}
      </Text>
    </View>
  );
}

const attachmentContainerStyle = {
  justifyContent: "flex-start",
  alignItems: "flex-start",
  padding: "$2",
  backgroundColor: "$white",
  boxShadow: "sm",
  borderRadius: "xs",
};
