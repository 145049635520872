import { CombinedError, OperationResult } from "urql";
import {
  CreateCollectionDocument,
  CreateCollectionWithDevIdDocument,
  GetResourceCollectionByDevIdDocument,
} from "../state/generated/graphql";
import client from "../state/graphql/client";

export async function createCollection(
  spaceId: string | undefined,
  name: string,
  devId?: string
) {
  if (devId) {
    const result = await client
      .mutation(CreateCollectionWithDevIdDocument, {
        space_id: spaceId,
        name,
        dev_id: devId,
      })
      .toPromise();
    if (result.error) return handleCreationError(result);
    return result;
  }
  const result = await client
    .mutation(CreateCollectionDocument, {
      space_id: spaceId,
      name,
    })
    .toPromise();
  if (result.error) return handleCreationError(result);
  return result;
}

function handleCreationError<T extends OperationResult>(result: T) {
  if (result.error?.graphQLErrors[0]?.extensions.code === "not-supported") {
    return { ...result, error: undefined };
  }
  return result;
}

export async function getCollectionByDevId(devId: string) {
  const resp = client.readQuery(GetResourceCollectionByDevIdDocument, {
    dev_id: devId,
  });
  if (!resp?.data?.resource_collections_by_dev_id) {
    return await client
      .query(
        GetResourceCollectionByDevIdDocument,
        {
          dev_id: devId,
        },
        { requestPolicy: "network-only" }
      )
      .toPromise();
  }
  return resp;
}
