import React, {
  ComponentProps,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  View,
  Image,
  Progress,
  Icon,
  Text,
  Pressable,
  Spinner,
  Column,
  Center,
} from "../components/basics";
import {
  UPLOAD_CACHE_KEY,
  cacheUploadFromRemoteURI,
} from "../utils/image-uploads";
import { ImageURISource, Platform } from "react-native";
import { getCacheURI } from "../utils/file-system";
import useUploadQueue from "../hooks/useUploadQueue";
import { readUploadQueueState, UploadMetadata } from "../lib/upload-queue";
import DRIPSY_THEME from "../constants/DripsyTheme";
import { FallbackImage, FallbackImageSource } from "./FallbackImage";

interface ChatImageProps extends ComponentProps<typeof Image> {
  attachmentKey: string;
  source: ImageURISource;
  height?: number;
  width?: number;
  maxHeight?: number;
  maxWidth?: number;
}

export function ChatImage(props: ChatImageProps) {
  const {
    source: { uri },
    attachmentKey,
  } = props;
  const isWeb = Platform.OS === "web";

  /**
   * Generally we
   * 1) If attachment is in the queue (ie uploading) display from queue in that state
   * 2) If not, check if its in cache, if so display that
   * 3) If we get a cache miss, try the network
   */
  const queueURI = useRef<string | undefined>(
    readUploadQueueState(attachmentKey)?.uri
  );
  const sources = useMemo<FallbackImageSource[]>(() => {
    const remoteSrc = { src: { uri } };
    const queueSrc = { src: { uri: queueURI.current } };
    return isWeb
      ? [remoteSrc]
      : !!queueURI.current
      ? [queueSrc, remoteSrc]
      : [remoteSrc];
  }, [isWeb, uri]);

  const queue = useUploadQueue();
  const queueStatus = useMemo<UploadMetadata | undefined>(() => {
    return queue[attachmentKey];
  }, [queue, attachmentKey]);

  return (
    <View>
      <FallbackImage
        {...props}
        // opacity={queueStatus && queueStatus.status !== "success" ? 0.5 : 1}
        sources={sources}
      />
      {/* {queueStatus?.status === "pending" && (
        <View
          sx={{
            position: "absolute",
            bottom: 1,
            width: "100%",
          }}
        >
          <Progress size="sm" value={queueStatus.uploadPct} mx={2} />
        </View>
      )} */}
      {queueStatus?.status === "failed" && (
        <View
          sx={{
            position: "absolute",
            bottom: 2,
            left: 2,
          }}
        >
          <Icon size="lg" name="alert-circle-outline" color="red" />
        </View>
      )}
    </View>
  );
}
