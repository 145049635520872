import { useEffect, useMemo } from "react";
import { useQuery } from "urql";
import { GetSpaceLatestEventTimestampsDocument } from "../state/generated/graphql";

export default function useSpaceLatestEventTimestamp(spaceId: string) {
  const [{ data }, reexecuteQuery] = useQuery({
    query: GetSpaceLatestEventTimestampsDocument,
  });

  const spaceMap = useMemo(() => {
    return Object.fromEntries(
      data?.spaces.map(
        (s) =>
          [s.id, s.events_aggregate.aggregate?.max?.created_at] as [
            string,
            string | undefined
          ]
      ) ?? []
    );
  }, [data]);

  return spaceMap[spaceId];
}
