import { useMemo, useState } from "react";
import { useRecoilValue } from "recoil";
import { pendingResourcesState } from "../state/atoms/pending-resources";
import { evaluateCollectionQuery } from "../state/utils/resource-queries";

export default function usePendingResources({
  spaceId,
  resourceQueries = {},
}: {
  spaceId?: string;
  resourceQueries?: Record<string, any>;
}) {
  // Object param causing infite loops
  const [resourceQueriesState, _] = useState(resourceQueries);
  const pendingResources = useRecoilValue(pendingResourcesState);
  const filteredResources = useMemo(() => {
    const resourceQuery = {
      _or: Object.entries(resourceQueries).map(([resource_key, filters]) => ({
        collection_key: { _eq: resource_key },
        ...filters,
      })),
    };
    return pendingResources.filter(
      (r) =>
        (!spaceId || r.space_id === spaceId) &&
        resourceQuery._or.some((collection_query) =>
          evaluateCollectionQuery(collection_query, r)
        )
    );
  }, [spaceId, resourceQueriesState, pendingResources]);
  return filteredResources;
}

export function useMergedResources(
  committedResources: any[] | undefined,
  pendingResources: any[] | undefined
) {
  return useMemo(() => {
    const pendingResourcesList = pendingResources ?? [];
    const commitedResourcesList = committedResources ?? [];
    const commitedResourceIds = new Set(commitedResourcesList.map((r) => r.id));
    const pendingResourcesToDisplay = pendingResourcesList.filter(
      (r) => !commitedResourceIds.has(r.id)
    );
    return [...pendingResourcesToDisplay, ...commitedResourcesList];
  }, [committedResources, pendingResources]);
}
