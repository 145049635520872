import { atom, AtomEffect } from "recoil";
import { AspenChatMessage } from "../../screens/spaces/ChatScreen";
import AsyncStorage from "@react-native-async-storage/async-storage";
import {
  addPendingResourceChangeListener,
  addPendingResourceRemoveListener,
  removePendingResourceChangeListener,
  removePendingResourceRemoveListener,
} from "../../lib/pending-resources";

const PENDING_RESOURCES_KEY = "pending_resources";

// const localStorageEffect: AtomEffect<any[]> = ({ setSelf, onSet }) => {
//   AsyncStorage.getItem(PENDING_RESOURCES_KEY).then((savedValue) => {
//     if (savedValue != null) {
//       setSelf(JSON.parse(savedValue));
//     } else {
//       AsyncStorage.setItem(PENDING_RESOURCES_KEY, JSON.stringify([]));
//       setSelf([]);
//     }
//   });

//   onSet((newValue, _, isReset) => {
//     isReset
//       ? AsyncStorage.removeItem(PENDING_RESOURCES_KEY)
//       : AsyncStorage.setItem(PENDING_RESOURCES_KEY, JSON.stringify(newValue));
//   });
// };

// export const pendingResourcesState = atom<any[]>({
//   key: "pending_resources",
//   default: [],
//   effects_UNSTABLE: [localStorageEffect],
// });

const pendingResourcesEffect: AtomEffect<any[]> = ({ setSelf }) => {
  AsyncStorage.getItem(PENDING_RESOURCES_KEY).then((savedValue) => {
    if (savedValue != null) {
      setSelf(JSON.parse(savedValue));
    }
  });
  const enqueueListener = (resources: any[]) => {
    const updates = new Set(resources.map((r) => r.resource_id));
    setSelf((prev) => [
      ...prev.filter((r) => !updates.has(r.id)),
      ...resources,
    ]);
  };
  const dequeueListener = (resourceIds: string[]) => {
    const updates = new Set(resourceIds);
    setSelf((prev) => [...prev.filter((r) => !updates.has(r.id))]);
  };

  addPendingResourceChangeListener(enqueueListener);
  addPendingResourceRemoveListener(dequeueListener);

  return () => {
    removePendingResourceChangeListener(enqueueListener);
    removePendingResourceRemoveListener(dequeueListener);
  };
};

export const pendingResourcesState = atom<any[]>({
  key: "pending_resources",
  default: [],
  effects_UNSTABLE: [pendingResourcesEffect],
});
