import { useNavigation } from "@react-navigation/native";
import { Menu } from "native-base";
import React, {
  useMemo,
  ComponentProps,
  useState,
  useCallback,
  useEffect,
} from "react";
import supabase, { signOut } from "../lib/supabase";
import { useMutation } from "urql";
import {
  Button,
  View,
  Text,
  FlatList,
  Pressable,
  Modal,
  Column,
  IconButton,
} from "../components/basics";
import { useToast } from "../hooks/useToast";
import SpacesFeedItem from "../components/SpacesFeedItem";
import {
  createMaterialTopTabNavigator,
  MaterialTopTabBarProps,
} from "@react-navigation/material-top-tabs";
import { useUser } from "../hooks/auth";
import usePeople from "../hooks/usePeople";
import AvatarPicker from "../components/AvatarPicker";
import PersonAvatar from "../components/PersonAvatar";
import { reportError } from "../state/utils/errors";
import {
  ChatListItemFragment,
  ProfileInfoFragment,
  UpdateProfileImageDocument,
  UpdateProfileNameDocument,
} from "../state/generated/graphql";
import { DeleteProfileDocument } from "../state/generated/graphql";
import { ListRenderItem } from "react-native";
import { useConnection } from "../hooks/useConnections";
import { useChatList } from "../hooks/useChatList";
import { useSpaceMembersBySpace } from "../hooks/useSpaceMembers";
import { getOrCreateStaticSpace } from "../utils/space";
import StyledTextInput from "../components/basics/StyledTextInput";
const Tab = createMaterialTopTabNavigator();

interface DeleteAccountModalProps extends ComponentProps<typeof Modal> {
  user: ProfileInfoFragment;
}

function DeleteAccountModal(props: DeleteAccountModalProps) {
  const { user, onClose } = props;
  const [deleteAccountResult, deleteAccount] = useMutation(
    DeleteProfileDocument
  );
  const addToast = useToast();
  const handleDeleteAccount = useCallback(async () => {
    const response = await deleteAccount({ id: user.id });
    if (response.error) {
      reportError(response.error.message);
      addToast({
        title:
          "Could not delete this account. Please try again or contact support@aspen.cloud",
        position: "bottom",
        variant: "ERROR",
      });
    } else {
      signOut();
      addToast({
        title: "Account successfully deleted. Sorry to see you go!",
        position: "bottom",
        variant: "ERROR",
      });
    }
  }, [onClose, addToast]);
  return (
    <Modal {...props}>
      <Modal.Content>
        <Modal.CloseButton />
        <Modal.Header>Delete account</Modal.Header>
        <Modal.Body>
          <Column space="md">
            <Text>Are you sure you want to delete your account?</Text>
            <Text>This action cannot be undone.</Text>
            <View sx={{ alignSelf: "flex-end", flexDirection: "row" }}>
              <Button
                variant="ghost"
                colorScheme="$blueGray"
                onPress={() => {
                  onClose();
                }}
              >
                Cancel
              </Button>
              <Button
                onPress={handleDeleteAccount}
                isLoading={deleteAccountResult.fetching}
                colorScheme="$danger"
                sx={{ ml: "$1" }}
              >
                Delete
              </Button>
            </View>
          </Column>
        </Modal.Body>
      </Modal.Content>
    </Modal>
  );
}

const CLAIM_FRIENDLY_NAMES: Record<string, string> = {
  PHONE: "Phone",
  EMAIL: "Email",
};

function ClaimItem({ claim, onPress }: { claim: any; onPress: () => void }) {
  return (
    <View
      sx={{
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "row",
        my: 3,
      }}
    >
      <Text sx={{ fontSize: "md" }}>{CLAIM_FRIENDLY_NAMES[claim.type]}</Text>
      <View sx={{ flexGrow: 1 }}>
        <Pressable onPress={onPress}>
          <Text sx={{ textAlign: "right", fontSize: 16 }}>{claim.value}</Text>
        </Pressable>
      </View>
    </View>
  );
}

function InfoTab({ route }) {
  const { userId, type } = route.params;
  const people = usePeople();
  const userProfile = people.get(userId);
  const currentUser = useUser();
  const isPersonalProfile = useMemo(() => {
    return currentUser && userProfile && userProfile?.id === currentUser.id;
  }, [userProfile, currentUser]);

  const navigation = useNavigation();
  const [deleteAccountModalOpen, setDeleteAccountModalOpen] = useState(false);
  const onCloseModal = () => setDeleteAccountModalOpen(false);
  const claims = useMemo(() => {
    return userProfile?.claims ?? {};
  }, [userProfile]);

  if (type === "CONTACT") return <></>;

  return (
    <>
      <View
        sx={{
          flex: 1,
          paddingLeft: 40,
          paddingRight: 40,
        }}
      >
        {/* <ClaimItem
          claim={claims["PHONE"] ?? { type: "PHONE", value: undefined }}
          onPress={() => {
            if (!isPersonalProfile) return;
            navigation.navigate("EditMobile", {});
          }}
        />
        <Divider marginY={4} />
        <ClaimItem
          claim={claims["EMAIL"] ?? { type: "EMAIL", value: undefined }}
          onPress={() => {
            if (!isPersonalProfile) return;
            navigation.navigate("EditEmail");
          }}
        /> */}

        {isPersonalProfile && (
          <View
            sx={{
              flex: 1,
              flexDirection: "column",
              justifyContent: "flex-end",
            }}
          >
            <Button
              sx={{ alignSelf: "center", maxWidth: 400, marginY: "$10" }}
              leftIcon="warning-outline"
              colorScheme="$danger"
              variant={"ghost"}
              onPress={() => setDeleteAccountModalOpen(true)}
            >
              Delete Account
            </Button>
          </View>
        )}
      </View>
      <DeleteAccountModal
        isOpen={deleteAccountModalOpen}
        onClose={onCloseModal}
        user={currentUser!}
      />
    </>
  );
}

function PersonalProfileHeader({ profile }) {
  const [_updateProfileImageResult, updateProfileImage] = useMutation(
    UpdateProfileImageDocument
  );
  const [_updateProfileNameResult, updateProfileName] = useMutation(
    UpdateProfileNameDocument
  );
  const [draftProfileName, setDraftProfileName] = useState(profile.name);
  const [showUpdateNameInput, setShowUpdateNameInput] = useState(false);
  const [deleteAccountModalOpen, setDeleteAccountModalOpen] = useState(false);
  const addToast = useToast();

  const handleUpdateName = useCallback(async () => {
    if (draftProfileName !== profile.name) {
      const response = await updateProfileName({
        id: profile.id,
        name: draftProfileName,
      });
      if (response.error)
        addToast({
          variant: "ERROR",
          description: "Error updating your name.",
        });
    }
    setShowUpdateNameInput(false);
    setDraftProfileName(draftProfileName);
  }, [profile, draftProfileName, addToast]);

  return (
    <View
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        flexShrink: 0,
        width: "100%",
      }}
    >
      <Menu
        placement="bottom right"
        trigger={({ ref, ...triggerProps }) => {
          return (
            <View
              ref={ref}
              sx={{ alignSelf: "flex-end", margin: "$2" }}
              {...triggerProps}
            >
              <IconButton
                size={"lg"}
                colorScheme="$gray"
                icon="menu-outline"
                {...triggerProps}
              />
            </View>
          );
        }}
      >
        <Menu.Item
          onPress={() => {
            signOut();
          }}
        >
          Sign Out
        </Menu.Item>
        <Menu.Item onPress={() => setDeleteAccountModalOpen(true)}>
          Delete Account
        </Menu.Item>
      </Menu>
      <View
        sx={{ width: "100%", justifyContent: "center", alignItems: "center" }}
      >
        <AvatarPicker
          profile={profile.data}
          pickerText="Update Image"
          onAvatarChange={async (key) => {
            const profileUpdateResp = await updateProfileImage({
              id: supabase.auth.user()?.id,
              image_path: key,
            });

            if (profileUpdateResp.error) {
              reportError(profileUpdateResp.error);
              addToast({
                variant: "ERROR",
                title: "Upload failed",
                description: "Not able to upload image at this time",
              });
            }
          }}
        />
      </View>
      <View sx={{ marginY: 16, alignItems: "center" }}>
        {showUpdateNameInput ? (
          <>
            <StyledTextInput
              value={draftProfileName}
              autoFocus
              textContentType="name"
              onChangeText={(text) => setDraftProfileName(text)}
              sx={{ width: "80%", marginBottom: 0 }}
              textInputSx={{
                textAlign: "center",
                fontSize: "3xl",
                width: "100%",
              }}
            />
            <View sx={{ flexDirection: "row", mt: "$2" }}>
              <Button
                rightIcon="close-outline"
                variant="ghost"
                _textStyles={{ color: "$gray.500" }}
                _iconStyles={{ color: "$gray.500" }}
                onPress={() => {
                  setShowUpdateNameInput(false);
                  setDraftProfileName(profile.name);
                }}
              >
                Cancel
              </Button>
              <Button
                rightIcon="checkmark"
                variant="ghost"
                onPress={handleUpdateName}
                sx={{ marginRight: "$3" }}
                disabled={
                  draftProfileName === "" || draftProfileName.length <= 3
                }
              >
                Save
              </Button>
            </View>
          </>
        ) : (
          <>
            <Text sx={{ fontSize: "3xl" }}>{profile.name}</Text>
            <Button
              variant="ghost"
              onPress={() => setShowUpdateNameInput(true)}
            >
              Edit Name
            </Button>
          </>
        )}
      </View>
      <DeleteAccountModal
        isOpen={deleteAccountModalOpen}
        onClose={() => setDeleteAccountModalOpen(false)}
        user={profile}
      />
    </View>
  );
}

export default function PersonScreen({
  state,
  descriptors,
  navigation,
  route,
  ...props
}: MaterialTopTabBarProps & { userId: string; type: "PROFILE" | "CONTACT" }) {
  const { userId, type } = route.params;
  const connection = useConnection(userId, type);
  const currentUser = useUser();
  const isPersonalProfile = useMemo(() => {
    return (
      currentUser &&
      connection &&
      connection.key === "PROFILE" &&
      connection.id === currentUser.id
    );
  }, [connection, currentUser]);

  const [{ data: chatList }] = useChatList();
  const spaceMembersBySpace = useSpaceMembersBySpace();
  const filteredChats = useMemo(() => {
    if (!chatList?.chat_list) return [];
    return chatList.chat_list.filter((item) =>
      spaceMembersBySpace
        .get(item.id)
        ?.find((member) => member.user_id === userId)
    );
  }, [chatList, spaceMembersBySpace]);
  const profilesStackState = navigation.getState();

  const profileOnlyStack = profilesStackState.routes[0].name === "profile";

  useEffect(() => {
    if (profileOnlyStack)
      navigation.reset({
        index: 1,
        routes: [
          { name: "profiles" },
          { name: "profile", params: route.params },
        ],
      });
  }, [profileOnlyStack, navigation]);

  const renderItem: ListRenderItem<ChatListItemFragment> = useCallback(
    ({ item: space }) => (
      <SpacesFeedItem
        onPress={() => {
          navigation.navigate("Chat", {
            screen: "conversation",
            params: { spaceId: space.id },
          });
        }}
        space={space}
      />
    ),
    []
  );

  return (
    <View
      sx={{
        alignItems: "stretch",
        flexDirection: "column",
        maxWidth: 500,
        flex: 1,
        overflow: "hidden",
      }}
    >
      {isPersonalProfile ? (
        <PersonalProfileHeader profile={connection} />
      ) : (
        <>
          <View
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              flexShrink: 0,
              width: "100%",
            }}
          >
            <View
              sx={{
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <PersonAvatar
                person={connection}
                size={240}
                sx={{ borderRadius: 0, width: "100%" }}
              />
            </View>
            <View sx={{ marginY: 16 }}>
              <Text sx={{ fontSize: "4xl" }}>{connection?.name}</Text>
            </View>
          </View>
          <View
            sx={{
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-evenly",
            }}
          >
            <Button
              leftIcon="chatbox"
              onPress={async () => {
                const spaceId = await getOrCreateStaticSpace([
                  userId,
                  currentUser?.id,
                ]);
                navigation.navigate("conversation", {
                  spaceId,
                });
              }}
            >
              Message
            </Button>
          </View>
        </>
      )}
      <View sx={{ flex: 1, paddingX: "$3" }}>
        <FlatList
          data={filteredChats}
          keyExtractor={(item) => item.id}
          renderItem={renderItem}
        />
      </View>
    </View>
  );
}
