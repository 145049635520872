import {
  BaseRouter,
  CommonNavigationAction,
  Router,
} from "@react-navigation/native";
import { nanoid } from "nanoid/non-secure";
import { MDActionType, MDNavigationState, MDRouterOptions } from "./types";

export const MasterDetailActions = {
  navigateTo(name: string, params?: object): MDActionType {
    return { type: "NAVIGATE", payload: { name, params } };
  },
  goBack(): MDActionType {
    return { type: "GO_BACK" };
  },
  setShouldSplitView(shouldSplit: boolean): MDActionType {
    return { type: "SET_SPLIT_VIEW", payload: { shouldSplit } };
  },
};

export default function MasterDetailRouter(
  options: MDRouterOptions
): Router<MDNavigationState, CommonNavigationAction | MDActionType> {
  return {
    ...BaseRouter,
    type: "master-detail",

    shouldActionChangeFocus(action) {
      return action.type === "NAVIGATE";
    },

    getStateForRouteNamesChange(
      state,
      { routeNames, routeParamList, routeKeyChanges }
    ) {
      const routes = state.routes.filter(
        (route) =>
          routeNames.includes(route.name) &&
          !routeKeyChanges.includes(route.name)
      );

      if (routes.length === 0) {
        const initialRouteName =
          options.initialRouteName !== undefined &&
          routeNames.includes(options.initialRouteName)
            ? options.initialRouteName
            : routeNames[0];

        routes.push({
          key: `${initialRouteName}-${nanoid()}`,
          name: initialRouteName,
          params: routeParamList[initialRouteName],
        });
      }

      return {
        ...state,
        routeNames,
        routes,
        index: Math.min(state.index, routes.length - 1),
      };
    },

    getInitialState({ routeNames, routeParamList, routeGetIdList }) {
      const initialRouteName =
        options.initialState?.name ??
        (options.initialRouteName !== undefined &&
          routeNames.includes(options.initialRouteName))
          ? options.initialRouteName
          : routeNames[0];

      const routes = options.initialState?.routes ?? [
        {
          key: `${initialRouteName}-${nanoid()}`,
          name: initialRouteName,
          params: routeParamList[initialRouteName],
        },
      ];

      return {
        stale: false,
        type: "master-detail",
        key: `detail-${nanoid()}`,
        index: 0,
        isOpen: true,
        isSplitView: options.isSplitView,
        routeNames,
        routes,
      };
    },

    getRehydratedState(
      partialState,
      { routeNames, routeParamList, routeGetIdList }
    ) {
      if (partialState.stale === false) {
        return partialState;
      }

      const initialState = this.getInitialState({
        routeNames,
        routeParamList,
        routeGetIdList,
      });

      if (partialState.routes.length === 0) {
        return initialState;
      }

      const resumedState = {
        ...initialState,
        routes: partialState.routes.map((partialRoute) => ({
          ...partialRoute,
          key: partialRoute.key ?? `${partialRoute.name}-${nanoid()}`,
        })),
        index: 0,
      };

      return resumedState;
    },

    getStateForRouteFocus(state, key) {
      const index = state.routes.findIndex((r) => r.key === key);

      if (index === -1 || index === state.index) {
        return state;
      }

      return {
        ...state,
        isOpen: !state.isSplitView,
        index,
        routes: state.routes.slice(0, index + 1),
      };
    },

    getStateForAction(state, action, options) {
      switch (action.type) {
        case "NAVIGATE": {
          if (
            action.payload.name !== undefined &&
            !state.routeNames.includes(action.payload.name)
          ) {
            return null;
          }
          // TODO handle pushing and popping of history
          return {
            ...state,
            isOpen: false,
            routes: [
              {
                key: `${action.payload.name}-${nanoid()}`,
                name: action.payload.name,
                params: action.payload.params,
              },
            ],
          };
        }

        case "GO_BACK":
          // TODO handle desktop history
          return { ...state, isOpen: true };
        case "SET_SPLIT_VIEW":
          return { ...state, isSplitView: action.payload.shouldSplit };
        default:
          return BaseRouter.getStateForAction(state, action);
      }
    },

    actionCreators: MasterDetailActions,
  };
}
