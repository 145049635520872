import * as FileSystem from "expo-file-system";

export async function ensureDirExists(dir: string) {
  const dirInfo = await FileSystem.getInfoAsync(dir);
  if (!dirInfo.exists) {
    await FileSystem.makeDirectoryAsync(dir, { intermediates: true });
  }
}

export function getCacheURI(cacheKey: string) {
  return `${FileSystem.cacheDirectory}${cacheKey}`;
}

export function getCacheDirectoryURI(cacheKey: string) {
  const dirPath = cacheKey.slice(0, cacheKey.lastIndexOf("/") + 1);
  return getCacheURI(dirPath);
}
