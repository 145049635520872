import { Pressable as DripsyPressable } from "dripsy";
import { ComponentProps } from "react";

/**
 * Here we can add basic touch behaviors for each platform
 * E.g. Animated opacity on iOS and ripple on Android
 */
export default function Pressable(
  props: ComponentProps<typeof DripsyPressable>
) {
  return <DripsyPressable {...props}>{props.children}</DripsyPressable>;
}
