import { useSx } from "dripsy";
import { MotiView } from "moti";
import { MotiPressable, MotiPressableProps } from "moti/interactions";
import React, { useContext, useEffect, useMemo } from "react";
import { useCallback, useState } from "react";
import { Dimensions, Platform } from "react-native";
import { View, Text, Pressable, Icon } from "../basics";
import * as Clipboard from "expo-clipboard";
import { AspenChatMessage } from "../../screens/spaces/ChatScreen";
import SpaceAppIcon from "../SpaceAppIcon";
import { useBottomTabBarHeight } from "@react-navigation/bottom-tabs";
import { useHeaderHeight } from "@react-navigation/elements";

const PICKER_HEIGHT = 60;
const REACTIONS = ["❤️", "👍", "👎", "😂", "👆", "👏"];
const SHIFT_PADDING = 10;
const TOUCH_PADDING = 20;
export function ReactionPicker({
  onClose,
  onSelect,
  verticalPosition,
  currentMessage,
  onCreationSelect,
  selected = [],
}: {
  onClose: () => void;
  onSelect: (reaction: string) => void;
  verticalPosition: number;
  selected?: string[];
  currentMessage?: AspenChatMessage;
  onCreationSelect: (key: string, payload: string) => void;
}) {
  const sx = useSx();
  const headerHeight = useHeaderHeight();
  const tabBarHeight = useBottomTabBarHeight();
  const [shift, setShift] = useState<"up" | "down">();
  const [menuHeight, setMenuHeight] = useState(0);
  const shiftedVerticalPosition = verticalPosition - headerHeight;
  const reactionPickerTop =
    shift === "up"
      ? shiftedVerticalPosition -
        TOUCH_PADDING -
        PICKER_HEIGHT -
        menuHeight -
        SHIFT_PADDING
      : shift === "down"
      ? shiftedVerticalPosition + TOUCH_PADDING
      : shiftedVerticalPosition - TOUCH_PADDING - PICKER_HEIGHT;
  const optionsTop =
    shift === "up"
      ? shiftedVerticalPosition - TOUCH_PADDING - menuHeight
      : shift === "down"
      ? shiftedVerticalPosition + TOUCH_PADDING + PICKER_HEIGHT + SHIFT_PADDING
      : shiftedVerticalPosition + TOUCH_PADDING;

  return (
    <View
      sx={{
        flex: 1,
        position: "absolute",
        left: 0,
        top: 0,
        bottom: 0,
        right: 0,
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        zIndex: 10,
        elevation: 10,
        shadowRadius: 5,
        shadowColor: "$black",
        shadowOpacity: 0.5,
        shadowOffset: { width: 1, height: 1 },
      }}
    >
      <Pressable
        sx={{
          flex: 1,
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 5,
        }}
        onPress={() => {
          onClose();
        }}
      ></Pressable>
      <MotiView
        from={{ translateY: 10, scale: 0.9 }}
        animate={{ translateY: 0, scale: 1.0 }}
        transition={{
          type: "spring",
          stiffness: 200,
        }}
        style={sx({
          height: PICKER_HEIGHT,
          position: "absolute",
          top: reactionPickerTop,
          zIndex: 10,
          elevation: Platform.OS === "android" ? 10 : 0,
        })}
        onLayout={(e) => {
          const { y } = e.nativeEvent.layout;
          if (y < 0) {
            setShift("down");
          }
        }}
      >
        <View
          sx={{
            padding: "$1",
            flexDirection: "row",
            alignSelf: "center",
            alignItems: "center",
            backgroundColor: "$gray.100",
            borderRadius: 15,
          }}
        >
          {REACTIONS.map((emoji, i) => (
            <MotiPressable
              key={emoji}
              style={sx({
                marginRight: "$1",
                padding: "$2",
                borderRadius: 10,
                backgroundColor: selected.includes(emoji)
                  ? "$blue.100"
                  : "transparent",
              })}
              onPress={() => {
                onSelect(emoji);
              }}
              from={{ translateY: -10, scale: 0.5 }}
              animate={{ translateY: 0, scale: 1.0 }}
              transition={{
                type: "spring",
                stiffness: 200,
                delay: 50 * (i + 1),
              }}
            >
              <Text sx={{ fontSize: 24 }}>{emoji}</Text>
            </MotiPressable>
          ))}
        </View>
      </MotiView>
      <MotiView
        from={{ translateY: 10, scale: 0.9 }}
        animate={{ translateY: 0, scale: 1.0 }}
        transition={{
          type: "spring",
          stiffness: 200,
        }}
        style={sx({
          position: "absolute",
          top: optionsTop,
          zIndex: 10,
          elevation: Platform.OS === "android" ? 10 : 0,
        })}
        onLayout={(e) => {
          const { y, height } = e.nativeEvent.layout;
          setMenuHeight(height);
          const menuBottom = y + height;
          const viewBottom =
            Dimensions.get("window").height - headerHeight - tabBarHeight;
          if (menuBottom > viewBottom) {
            setShift("up");
          }
        }}
      >
        <View
          sx={{
            flexDirection: "column",
            backgroundColor: "$gray.100",
            borderRadius: 15,
          }}
        >
          {currentMessage?.text ? (
            <MenuOption
              text="Copy text"
              onPress={async () => {
                await Clipboard.setStringAsync(currentMessage.text);
                onClose();
              }}
              icon={"copy-outline"}
            />
          ) : null}
          {currentMessage?.text ? (
            <MenuOption
              text="Create note"
              onPress={() => {
                onCreationSelect("notes", currentMessage.text);
                onClose();
              }}
              icon={<SpaceAppIcon appName="notes" />}
            />
          ) : null}
          {currentMessage?.text ? (
            <MenuOption
              text="Create event"
              onPress={() => {
                onCreationSelect("schedule", currentMessage.text);
                onClose();
              }}
              icon={<SpaceAppIcon appName="schedule" />}
            />
          ) : null}
          {currentMessage?.text ? (
            <MenuOption
              text="Create reminder"
              onPress={() => {
                onCreationSelect("reminders", currentMessage.text);
                onClose();
              }}
              icon={<SpaceAppIcon appName="reminders" />}
            />
          ) : null}
        </View>
      </MotiView>
    </View>
  );
}

function MenuOption({
  text,
  onPress,
  icon,
}: {
  icon: string | React.ReactNode;
  text: string;
  onPress?: MotiPressableProps["onPress"];
}) {
  const sx = useSx();
  return (
    <MotiPressable
      onPress={onPress}
      style={sx({
        flexDirection: "row",
        alignItems: "center",
        py: 2,
        px: 4,
      })}
      animate={useMemo(
        () =>
          ({ hovered, pressed }) => {
            "worklet";

            return {
              opacity: hovered || pressed ? 0.5 : undefined,
            };
          },
        []
      )}
    >
      <View
        sx={{
          width: 30,
          height: 30,
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {typeof icon === "string" ? (
          <Icon size="xl" name={icon} sx={{ color: "$aspenDark" }} />
        ) : (
          icon
        )}
      </View>
      <Text sx={{ fontSize: 16, ml: 2 }}>{text}</Text>
    </MotiPressable>
  );
}

type MessageMenuHandler = (
  verticalPosition: number,
  reactionPickerconfig: {
    onSelect: (reaction: string) => void;
    selected?: string[];
  },
  menuConfig: {
    currentMessage: AspenChatMessage | undefined;
    onCreationSelect: (key: string, payload: string) => void;
  }
) => void;

const MessageMenuContext = React.createContext<MessageMenuHandler>(() => {});

export function MessageMenuProvider({ children }) {
  const [isShown, setIsShown] = useState(false);
  const [verticalPosition, setVerticalPosition] = useState(0);
  const [selectHandler, setSelectHandler] = useState<
    (reaction: string) => void
  >(() => {});
  const [selectedReactions, setSelectedReactions] = useState([]);
  const [currentMessage, setCurrentMessage] = useState<AspenChatMessage>();
  const [onCreationSelect, setOnCreationSelect] = useState<
    (key: string, payload: string) => void
  >(() => {});
  const openMenu = useCallback(
    (
      verticalPosition,
      { onSelect, selected },
      { currentMessage, onCreationSelect }
    ) => {
      setIsShown(true);
      setSelectHandler(() => onSelect);
      setVerticalPosition(verticalPosition);
      setSelectedReactions(selected ?? []);
      setCurrentMessage(currentMessage);
      setOnCreationSelect(() => onCreationSelect);
    },
    []
  );

  return (
    <MessageMenuContext.Provider value={openMenu}>
      {children}
      {isShown && (
        <ReactionPicker
          onSelect={(reaction) => {
            selectHandler(reaction);
            setIsShown(false);
          }}
          selected={selectedReactions}
          verticalPosition={verticalPosition}
          onClose={() => {
            setIsShown(false);
          }}
          currentMessage={currentMessage}
          onCreationSelect={onCreationSelect}
        />
      )}
    </MessageMenuContext.Provider>
  );
}

export function useMessageMenu() {
  return useContext(MessageMenuContext);
}
