import { styled, Image as DripsyImage } from "dripsy";
import { Platform } from "react-native";
// import FastImage from "react-native-fast-image";

const Image =
  Platform.OS === "web"
    ? DripsyImage
    : styled(require("react-native-fast-image"))();

export default Image;
