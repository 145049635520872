import { nanoid } from "nanoid";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { runOnJS } from "react-native-reanimated";
import {
  Text,
  View,
  IconButton,
  KeyboardAvoidingView,
} from "../components/basics";
import { MotiView, AnimatePresence, MotiText } from "moti";
import { useSx } from "dripsy";
import {
  GestureDetector,
  Gesture,
  Directions,
} from "react-native-gesture-handler";

const ToastContext = React.createContext((toast: Toast) => {});

const TOAST_TIMEOUT = 4000;

interface Toast {
  title?: string;
  description?: string;
  variant?: "SUCCESS" | "ERROR" | "INFO" | "WARNING";
  emoji?: string;
  position?: "top" | "bottom";
}

interface InternalToast extends Toast {
  id: string;
}

const variant_info = {
  SUCCESS: {
    icon: "checkmark-circle",
    color: "$aspenGreen",
    textColor: "$black",
    emoji: "🎉",
  },
  ERROR: {
    icon: "close-circle",
    color: "$danger.200",
    textColor: "$black",

    emoji: "❗",
  },
  INFO: {
    icon: "information-circle",
    color: "$aspenBlue",
    textColor: "$black",

    emoji: "💡",
  },
  WARNING: {
    icon: "alert-circle",
    color: "$aspenYellow",
    textColor: "$black",
    emoji: "⚠️",
  },
  DEFAULT: {
    icon: "",
    color: "$aspenYellow",
    textColor: "$black",
    emoji: null,
  },
};

export const ToastContextProvider: React.FC = ({ children }) => {
  const [toasts, setToasts] = useState<InternalToast[]>([]);
  const toastTimeouts = useRef(new Map());
  useEffect(() => {
    return () => {
      toastTimeouts.current.forEach(clearTimeout);
    };
  }, []);

  const showToast = useCallback(
    (toast: Toast) => {
      const toastId = nanoid();
      setToasts((prev) => [...prev, { id: toastId, ...toast }]);
      const timeout = setTimeout(() => {
        setToasts((prev) => prev.filter((toast) => toast.id !== toastId));
      }, TOAST_TIMEOUT);
      toastTimeouts.current.set(toastId, timeout);
    },
    [setToasts]
  );
  const dismissToast = useCallback(
    (id) => {
      setToasts((prev) => prev.filter((toast) => toast.id !== id));
      clearTimeout(toastTimeouts.current.get(id));
    },
    [toastTimeouts]
  );

  return (
    <ToastContext.Provider value={showToast}>
      {children}
      <KeyboardAvoidingView
        behavior={"height"}
        style={{
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          position: "absolute",
        }}
        pointerEvents={"box-none"}
      >
        <AnimatePresence>
          {toasts.map((t) => (
            // <MotiText
            //   from={{ opacity: 1, translateY: -10 }}
            //   animate={{ opacity: 1, translateY: 0 }}
            //   exit={{ opacity: 1, translateY: -10 }}
            //   transition={{ type: "timing", duration: 300 }}
            //   exitTransition={{ type: "timing", duration: 300 }}
            //   key={t.id}
            //   style={{ color: "red", padding: 5 }}
            // >
            //   {t.description}
            // </MotiText>
            <ToastMessage
              toast={t}
              key={t.id}
              onDismiss={() => dismissToast(t.id)}
            />
          ))}
        </AnimatePresence>
      </KeyboardAvoidingView>
    </ToastContext.Provider>
  );
};

function ToastMessage({
  toast,
  onDismiss,
}: {
  toast: InternalToast;
  onDismiss: () => void;
}) {
  const sx = useSx();

  const variant = toast.variant
    ? variant_info[toast.variant]
    : variant_info["DEFAULT"];
  const emoji = toast.emoji ?? variant.emoji;
  const isBottomToast = !toast.position || toast.position === "bottom";

  const offset = isBottomToast ? { bottom: 25 } : { top: 50 };

  const swipeGestures = useMemo(() => {
    return Gesture.Fling()
      .onEnd(() => runOnJS(onDismiss)())
      .direction(Directions.UP || Directions.DOWN);
  }, [onDismiss]);
  return (
    <GestureDetector gesture={swipeGestures}>
      <MotiView
        from={{
          opacity: 0,
          scale: 0.1,
        }}
        animate={{
          opacity: 1,
          scale: 1,
        }}
        exit={{
          opacity: 0,
          scale: 0.1,
        }}
        transition={{ type: "timing", duration: 300 }}
        exitTransition={{ type: "timing", duration: 300 }}
        style={sx({
          position: "absolute",
          ...offset,
          padding: "$3",
          flexDirection: "row",
          alignItems: "center",
          alignSelf: "center",
          justifyContent: "center",
          backgroundColor: variant.color,
          borderRadius: 6,
          maxWidth: 300,
          // zIndex: 1000,
          // elevation: 1000,
          boxShadow: "md",
        })}
        key={toast.id}
      >
        {!!emoji && (
          <View
            sx={{
              alignItems: "center",
              justifyContent: "center",
              marginRight: "$3",
              flexShrink: 0,
            }}
          >
            <Text sx={{ fontSize: 24 }}>{emoji}</Text>
          </View>
        )}
        <View sx={{ flexShrink: 1 }}>
          {!!toast.title && (
            <Text
              sx={{
                fontWeight: "bold",
                fontSize: 14,
                color: variant.textColor,
              }}
            >
              {toast.title}
            </Text>
          )}
          {!!toast.description && (
            <Text sx={{ color: variant.textColor }}>{toast.description}</Text>
          )}
        </View>
        <IconButton
          icon="close"
          variant="unstyled"
          _iconStyles={{ color: "$gray.400" }}
          onPress={onDismiss}
        />
      </MotiView>
    </GestureDetector>
  );
}

export function useToast() {
  return useContext(ToastContext);
}
