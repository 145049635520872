import {
  BottomSheetModal,
  BottomSheetFlatList,
  BottomSheetBackdrop,
} from "@gorhom/bottom-sheet";
import { BottomSheetModalMethods } from "@gorhom/bottom-sheet/lib/typescript/types";
import { useCallback, useEffect, useRef } from "react";
import { FlatList, Text, View } from "./basics";
import { observable } from "@legendapp/state";
import { useObserve } from "@legendapp/state/react";
import { useSpaces } from "../hooks/spaces";
import { ActivityIndicator } from "dripsy";
import SpacesFeedItem from "./SpacesFeedItem";
import { ChatListItemFragment } from "../state/generated/graphql";
import { ListRenderItem } from "@shopify/flash-list";
import { useChatList } from "../hooks/useChatList";
import { BottomTabNavigationProp } from "@react-navigation/bottom-tabs";
import { Platform } from "react-native";

const FlatListSafe = Platform.OS === "web" ? FlatList : BottomSheetFlatList;

const SNAP_POINTS = ["66%"];

export const spaceFilterSelectorState = observable<{
  isOpen: boolean;
  navigator: BottomTabNavigationProp<any, any>;
}>({
  isOpen: false,
  navigator: null,
});

export function toggleSpaceFilterSelector() {
  spaceFilterSelectorState.set((prev) => ({ ...prev, isOpen: !prev.isOpen }));
}

export function closeSpaceFilterSelector() {
  spaceFilterSelectorState.assign({ isOpen: false });
}

export function openSpaceFilterSelector() {
  spaceFilterSelectorState.assign({ isOpen: true });
}

export function bindSpaceFilterNavigator(navigator) {
  spaceFilterSelectorState.assign({ navigator });
}

export default function SpaceFilterSelector() {
  const bottomSheetRef = useRef<BottomSheetModalMethods>(null);
  const [{ data, fetching }] = useChatList();
  const navigation = spaceFilterSelectorState.get().navigator;

  useObserve(() => {
    const shown = spaceFilterSelectorState.get().isOpen;
    if (shown) {
      bottomSheetRef.current?.present();
    } else {
      bottomSheetRef.current?.close();
    }
  });

  const navState = navigation?.getState();

  const selectedSpaceId: string | undefined =
    navState?.routes[navState.index].params?.spaceId;

  const renderItem: ListRenderItem<ChatListItemFragment> = useCallback(
    ({ item: space }) => (
      <SpacesFeedItem
        onPress={() => {
          closeSpaceFilterSelector();

          navigation.setParams({
            spaceId: selectedSpaceId === space.id ? undefined : space.id,
          });
        }}
        space={space}
        sx={{
          padding: "$2",
          backgroundColor:
            selectedSpaceId === space.id ? "$primary.100" : "transparent",
        }}
      />
    ),
    [navigation, selectedSpaceId]
  );
  return (
    <BottomSheetModal
      index={0}
      ref={bottomSheetRef}
      snapPoints={SNAP_POINTS}
      onChange={(index) => {
        if (index === -1) {
          closeSpaceFilterSelector();
        } else {
          openSpaceFilterSelector();
        }
      }}
      backdropComponent={({ animatedIndex, animatedPosition, style }) => (
        <BottomSheetBackdrop
          style={style}
          animatedIndex={animatedIndex}
          animatedPosition={animatedPosition}
          disappearsOnIndex={-1}
        />
      )}
    >
      <Text sx={{ textAlign: "center", fontSize: 16, padding: "$3" }}>
        Filter by group
      </Text>
      {fetching && <ActivityIndicator />}
      {data && (
        <View sx={{ flex: 1 }}>
          <FlatListSafe
            data={data.chat_list}
            style={{ flex: 1 }}
            keyExtractor={(item) => item.id}
            renderItem={renderItem}
          />
        </View>
      )}
    </BottomSheetModal>
  );
}
