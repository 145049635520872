import client from "../state/graphql/client";
import { pipe, subscribe, Subscription } from "wonka";
import Constants from "expo-constants";
import {
  CustomResourcesDocument,
  GetConnectedProfilesDocument,
  GetResourcePermissionsDocument,
  GetSpaceMembersDocument,
  GetUserSpacesDocument,
  ResourceCollectionsDocument,
} from "../state/generated/graphql";
import { Platform } from "react-native";
import { pushEventQueue } from "./push-event";
import { reportError } from "../state/utils/errors";
import { resourceQueryVariables } from "../state/utils/resource-queries";
import * as RootNavigation from "../navigation/RootNavigation";
import { createCollection, getCollectionByDevId } from "./collections";
import { resourceCollectionQueryVariables } from "../state/utils/resource-collection-queries";
import { GetSpaceMemberProfilesDocument } from "../state/generated/graphql";
import supabase from "../lib/supabase";
import * as Linking from "expo-linking";

const APP_ORIGIN = Constants.manifest?.extra?.APPS_ORIGIN;

interface ParameterizedSubscription {
  subscription: Subscription;
  parameters: any;
}

const resourceSubscriptions = new Map<Window, ParameterizedSubscription>();
const collectionSubscriptions = new Map<Window, ParameterizedSubscription>();
const profilesSubscriptions = new Map<Window, Subscription>();
let permissionsSubscription: Subscription | undefined;
let spacesSubscription: Subscription | undefined;
let spaceMembersSubscription: Subscription | undefined;
let spaceIdSubscription: Subscription | undefined;

export function postWindowMessage(target: Window, msg: any) {
  try {
    target.postMessage(JSON.stringify(msg), "*");
  } catch (e) {
    reportError(e);
  }
}

export function unsubscribeWinResources(win: Window): void {
  resourceSubscriptions.get(win)?.subscription?.unsubscribe();
  resourceSubscriptions.delete(win);
  collectionSubscriptions.get(win)?.subscription?.unsubscribe();
  collectionSubscriptions.delete(win);
  profilesSubscriptions.get(win)?.unsubscribe();
  profilesSubscriptions.delete(win);
  permissionsSubscription?.unsubscribe();
  permissionsSubscription = undefined;
  spacesSubscription?.unsubscribe();
  spacesSubscription = undefined;
  spaceMembersSubscription?.unsubscribe();
  spaceMembersSubscription = undefined;
  spaceIdSubscription?.unsubscribe();
  spaceIdSubscription = undefined;
}

function handleResourceRequest(win: Window, message: any, spaceId?: string) {
  const { resourceQueries, pagination, sort, collectionId, devCollectionId } =
    message;
  return startResourcesQuery(win, {
    resourceQueries,
    spaceId,
    collectionId,
    devCollectionId,
    pagination,
    sort,
  });
}

// Would be nice to tell the listener to enter a loading state
// We can maybe add another message, but that might get race-conditiony
export function invalidateSpaceQueries(win: Window, spaceId?: string) {
  invalidateResourcesQuery(win, spaceId);
  invalidateCollectionsQuery(win, spaceId);
}

function invalidateResourcesQuery(win: Window, spaceId?: string) {
  const sub = resourceSubscriptions.get(win);
  if (!sub) return;
  const { parameters, subscription } = sub;
  subscription?.unsubscribe();
  parameters.spaceId = spaceId;
  startResourcesQuery(win, parameters);
}

function startResourcesQuery(win: Window, params: any) {
  if (resourceSubscriptions.has(win)) {
    resourceSubscriptions.get(win)?.subscription?.unsubscribe();
  }
  const vars = resourceQueryVariables(params);

  const subscription = pipe(
    client.query(CustomResourcesDocument, vars, {
      requestPolicy: "cache-and-network",
    }),
    subscribe((response) => {
      postWindowMessage(win, {
        type: "RESOURCES",
        response,
      });
    })
  );
  resourceSubscriptions.set(win, { subscription, parameters: params });
  return subscription;
}

function handlePermissionsRequest(win: Window, message: any) {
  // Reuse subscription if possible
  // Just re-run query if we are already subscribed
  if (permissionsSubscription) {
    client
      .query(GetResourcePermissionsDocument, {})
      .toPromise()
      .then((response) => {
        postWindowMessage(win, {
          type: "RESOURCE_PERMISSIONS",
          response,
        });
      });
  } else {
    const subscription = pipe(
      client.query(GetResourcePermissionsDocument, {}),
      subscribe((response) => {
        postWindowMessage(win, {
          type: "RESOURCE_PERMISSIONS",
          response,
        });
      })
    );

    permissionsSubscription = subscription;
  }

  return permissionsSubscription;
}

function handleSpacesRequest(win: Window, message: any) {
  // Reuse subscription if possible
  // Just re-run query if we are already subscribed
  if (spacesSubscription) {
    client
      .query(GetUserSpacesDocument, {})
      .toPromise()
      .then((response) => {
        postWindowMessage(win, {
          type: "SPACES",
          response,
        });
      });
  } else {
    const subscription = pipe(
      client.query(GetUserSpacesDocument, {}),
      subscribe((response) => {
        postWindowMessage(win, {
          type: "SPACES",
          response,
        });
      })
    );

    spacesSubscription = subscription;
  }
  return spacesSubscription;
}

function handleSpaceMembershipRequest(win: Window, message: any) {
  // Reuse subscription if possible
  // Just re-run query if we are already subscribed
  if (spaceMembersSubscription) {
    client
      .query(GetSpaceMembersDocument, {})
      .toPromise()
      .then((response) => {
        postWindowMessage(win, {
          type: "SPACE_MEMBERS",
          response,
        });
      });
  } else {
    const subscription = pipe(
      client.query(GetSpaceMembersDocument, {}),
      subscribe((response) => {
        postWindowMessage(win, {
          type: "SPACE_MEMBERS",
          response,
        });
      })
    );
    spaceMembersSubscription = subscription;
  }

  return spacesSubscription;
}

export function postSpaceId(win: Window, spaceId?: string) {
  postWindowMessage(win, {
    type: "SPACE_ID",
    response: { spaceId },
  });
}

function handleCollectionRequest(win: Window, message: any, spaceId?: string) {
  const { resourceKey } = message;
  const params = { resourceKey, spaceId };
  startCollectionsQuery(win, params);
}

function invalidateCollectionsQuery(win: Window, spaceId?: string) {
  const sub = collectionSubscriptions.get(win);
  if (!sub) return;
  const { parameters, subscription } = sub;
  subscription?.unsubscribe();
  parameters.spaceId = spaceId;
  startCollectionsQuery(win, parameters);
}

function startCollectionsQuery(win: Window, params: any) {
  if (collectionSubscriptions.has(win)) {
    collectionSubscriptions.get(win)?.unsubscribe();
  }
  const collectionVars = resourceCollectionQueryVariables(params);
  // TODO: move to query
  const subscription = pipe(
    client.query(ResourceCollectionsDocument, collectionVars, {
      requestPolicy: "cache-and-network",
    }),
    subscribe((response) => {
      postWindowMessage(win, {
        type: "COLLECTIONS",
        response,
      });
    })
  );
  collectionSubscriptions.set(win, subscription);
  return subscription;
}

function handleProfilesRequest(win: Window, message: any) {
  if (profilesSubscriptions.has(win)) {
    profilesSubscriptions.get(win)?.unsubscribe();
  }
  const subscription = pipe(
    client.query(GetConnectedProfilesDocument),
    subscribe((resp) => {
      postWindowMessage(win, {
        type: "PROFILES",
        response: resp,
      });
    })
  );

  profilesSubscriptions.set(win, subscription);
  return subscription;
}

export function handleMessage(
  win: Window,
  containerSpaceId: string | undefined,
  eventPushTarget: string | undefined,
  event: MessageEvent,
  navigation: any,
  reactBridgeMethods: {
    onShareResource: (resourceId: string, resourceKey: string) => void;
    onPressProfile: ({ uid }: { uid: string }) => void;
  }
) {
  if (Platform.OS === "web" && event.origin !== APP_ORIGIN) return;
  const data = Platform.OS === "web" ? event.data : event.nativeEvent.data;
  const message = JSON.parse(data);
  if (message.type === "CONSOLE") {
    console.log(...message.log);
  }
  if (message.type === "ROUTE_CHANGE") {
    navigation.setParams({
      appRoute: message.payload.path,
      appQuery: message.payload.query,
    });
  }
  if (message.type === "RESOURCE_REQUEST") {
    handleResourceRequest(win, message, containerSpaceId);
  }
  if (message.type === "COLLECTION_REQUEST") {
    handleCollectionRequest(win, message, containerSpaceId);
  }
  if (message.type === "PERMISSIONS_REQUEST") {
    handlePermissionsRequest(win, message);
  }
  if (message.type === "SPACES_REQUEST") {
    handleSpacesRequest(win, message);
  }
  if (message.type === "SPACE_MEMBERS_REQUEST") {
    handleSpaceMembershipRequest(win, message);
  }
  if (message.type === "SPACE_ID_REQUEST") {
    postSpaceId(win, containerSpaceId);
  }
  if (message.type === "CREATE_COLLECTION") {
    createCollection(
      eventPushTarget,
      message.payload.name,
      message.payload.devId
    ).then((resp) => {
      postWindowMessage(win, {
        _request_id: message._request_id,
        response: resp,
      });
    });
  }
  if (message.type === "PUSH_EVENT") {
    pushEventQueue([
      {
        resource_key: message.resourceKey,
        collection_id: message.collectionId,
        ...message.data,
        space_id: message.spaceId ?? eventPushTarget,
      },
    ]);
  }
  if (message.type === "PUSH_EVENTS") {
    pushEventQueue(
      message.events.map((e) => ({ ...e, space_id: eventPushTarget }))
    );
  }
  if (message.type === "SHARE_RESOURCE") {
    const { resourceId, resourceKey } = message;
    reactBridgeMethods.onShareResource(resourceId, resourceKey);
  }
  if (message.type === "PEEK_PROFILE") {
    const { userId } = message;
    reactBridgeMethods.onPressProfile({ uid: userId });
  }
  if (message.type === "SHARE_COLLECTION") {
    const { collectionId, spaceIds } = message;
    RootNavigation.navigate("CollectionShareModal", {
      collectionId,
      spaceIds,
    });
  }
  if (message.type === "FOLLOW_COLLECTIONS") {
    const { resourceKey } = message;
    RootNavigation.navigate("FollowCollectionsModal", { resourceKey });
  }
  if (message.type === "SHARE_COLLECTION_BY_DEV_ID") {
    const { collectionDevId, spaceIds } = message;
    getCollectionByDevId(collectionDevId).then((resp) => {
      if (resp?.data?.resource_collections_by_dev_id) {
        RootNavigation.navigate("CollectionShareModal", {
          collectionId: resp.data.resource_collections_by_dev_id.id,
          spaceIds,
        });
      }
    });
  }
  if (message.type === "IMPORT_RESOURCES") {
    const { resourceKey } = message;
    RootNavigation.navigate("ResourceImportModal", {
      resourceKey,
      spaceId: containerSpaceId,
    });
  }
  if (message.type === "IMPORT_COLLECTIONS_BY_DEV_IDS") {
    const { collectionDevIds } = message;
    RootNavigation.navigate("CollectionImportModal", {
      collectionDevIds,
      spaceId: containerSpaceId,
    });
  }
  if (message.type === "GET_SPACE_MEMBER_PROFILES") {
    // uPDATE this
    const { spaceId } = message;
    client
      .query(
        GetSpaceMemberProfilesDocument,
        spaceId
          ? {
              space_id: { _eq: spaceId },
            }
          : {},
        { requestPolicy: "cache-and-network" }
      )
      .toPromise()
      .then((result) => {
        const profiles = result.data?.profiles.map((profile) => ({
          ...profile,
          profile_image_path: supabase.storage
            .from("avatars")
            .getPublicUrl(profile.profile_image_path).data?.publicURL,
        }));
        postWindowMessage(win, {
          type: "SPACE_MEMBER_PROFILES",
          data: { ...result, data: { profiles } },
        });
      });
  }
  if (message.type === "GET_CURRENT_USER") {
    postWindowMessage(win, {
      type: "CURRENT_USER",
      data: { id: supabase.auth.user()?.id },
    });
  }
  if (message.type === "PROFILES_REQUEST") {
    handleProfilesRequest(win, message);
  }
  if (message.type === "OPEN_LINK") {
    if (message.payload?.url) {
      Linking.openURL(message.payload.url);
    }
  }
  if (message.type === "VIEW_RESOURCE_ATTRIBUTIONS") {
    const { resourceKey, resourceId } = message;
    RootNavigation.navigate("ResourceAttributionModal", {
      resourceKey,
      resourceId,
    });
  }
}
