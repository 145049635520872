import { Sx } from "dripsy";
import supabase from "../lib/supabase";
import { optimizedImagePath } from "../utils/images";
import { Text, Image, View } from "./basics";
import CacheImage from "./CacheImage";

export interface CoverPhoto {
  type: "image" | "emoji";
  value: string;
}

export function CoverPhotoView({
  size,
  coverPhoto,
  rounded = false,
  sx,
}: {
  size: number;
  coverPhoto: CoverPhoto | undefined;
  rounded?: boolean;
  sx?: Sx;
}) {
  switch (coverPhoto?.type) {
    case "emoji":
      return (
        <View
          sx={{
            width: size,
            height: size,
            alignItems: "center",
            justifyContent: "center",
            ...sx,
            boxShadow: "none",
          }}
        >
          <Text
            sx={{
              fontSize: Math.min(size * 0.8, 80),
            }}
          >
            {coverPhoto.value}
          </Text>
        </View>
      );
    case "image":
      const publicUrl = supabase.storage
        .from("cover-photos")
        .getPublicUrl(coverPhoto.value).data?.publicURL;
      const uri = publicUrl
        ? optimizedImagePath(publicUrl, { w: 640 })
        : publicUrl;
      return (
        <View sx={{ ...sx, overflow: "visible" }}>
          <Image
            source={{ uri }}
            sx={{
              borderRadius: rounded ? size / 2 : undefined,
              height: size,
              width: size,
              ...sx,
              overflow: "hidden",
            }}
          />
        </View>
      );
    default:
      return null;
  }
}
