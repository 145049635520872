import { useNavigation } from "@react-navigation/native";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { Header } from "@react-navigation/elements";
import {
  Button,
  TextInput,
  View,
  Text,
  Pressable,
  Icon,
} from "../../components/basics";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  SafeAreaView,
  useSafeAreaInsets,
} from "react-native-safe-area-context";
import { useUser } from "../../hooks/auth";
import { RootStackParamList } from "../../types";
import SpaceMemberSelector, {
  SelectedMemberItem,
} from "../../components/SpaceMemberSelector";
import {
  generateExternalInvitations,
  generateProfileInvitations,
} from "../../lib/invitations";
import { parsePhoneNumber } from "../../utils/phone";
import { createNewSpace, SpaceCreationParams } from "../../state/atoms/spaces";
import { useHeaderHeight } from "@react-navigation/elements";
import usePeople from "../../hooks/usePeople";
import useSharedNavigationValue from "../../hooks/useSharedNavigationValue";
import { ProfileItem } from "../../utils/people";
import { useToast } from "../../hooks/useToast";
import { reportError } from "../../state/utils/errors";
import { useSx } from "dripsy";
import { getOrCreateStaticSpace } from "../../utils/space";

export default function NewChatScreen({
  navigation,
}: NativeStackScreenProps<RootStackParamList, "CreateSpace">) {
  const currentUser = useUser();
  const [isCreatingSpace, setIsCreatingSpace] = useState(false);
  const people = usePeople();
  const [memberSelections, setMemberSelections] = useState<
    SelectedMemberItem[]
  >([
    {
      key: "PROFILE",
      id: currentUser!.id,
      name: people.get(currentUser!.id)?.name,
      data: people.get(currentUser!.id)!,
    },
  ]);

  const handleCreateStaticSpace = useCallback(async () => {
    try {
      setIsCreatingSpace(true);

      const profileSubmissions = memberSelections.filter(
        (s) => s.key === "PROFILE"
      ) as ProfileItem[];
      const userIds = profileSubmissions.map((user) => user.id);

      const spaceId = await getOrCreateStaticSpace(userIds);
      if (spaceId)
        navigation.navigate("conversation", {
          spaceId,
        });
    } catch (e) {
      reportError(e);
    } finally {
      setIsCreatingSpace(false);
    }
  }, [memberSelections, currentUser]);
  const sx = useSx();
  const membersToDisplay = useMemo(
    () =>
      memberSelections.filter((selection) => selection.id !== currentUser?.id),
    [memberSelections, currentUser]
  );

  useEffect(() => {
    navigation.setOptions({
      headerLeft: () => (
        <Button
          variant="unstyled"
          _textStyles={{ color: "$primary.600" }}
          onPress={navigation.goBack}
        >
          Cancel
        </Button>
      ),
      title: "New Chat",
      headerRight: () => (
        <Button
          variant="ghost"
          onPress={handleCreateStaticSpace}
          disabled={membersToDisplay.length === 0}
        >
          Finish
        </Button>
      ),
    });
  }, [navigation, membersToDisplay, handleCreateStaticSpace]);
  return (
    <SafeAreaView style={{ flex: 1 }} edges={["bottom"]}>
      {/* <Header
        headerLeft={() => (
          <Button
            variant="unstyled"
            _textStyles={{ color: "$primary.600" }}
            onPress={navigation.goBack}
          >
            Cancel
          </Button>
        )}
        title="New Chat"
        headerRight={() => (
          <Button
            variant="ghost"
            onPress={handleCreateStaticSpace}
            disabled={membersToDisplay.length === 0}
          >
            Finish
          </Button>
        )}
        headerStatusBarHeight={10}
        headerShadowVisible
      /> */}

      <SpaceMemberSelector
        data={membersToDisplay}
        aspenUsersOnly
        onAdd={(selection) => {
          if (selection.key === "PROFILE" && selection.id === currentUser?.id)
            return;
          setMemberSelections((selections) => [...selections, selection]);
        }}
        onRemove={(selection) => {
          if (selection.key === "PROFILE" && selection.id === currentUser?.id)
            return;
          setMemberSelections((selections) =>
            selections.filter(
              (s) => !(s.id === selection.id && s.key === selection.key)
            )
          );
        }}
        OptionalTopComponent={() => (
          <Pressable
            sx={{
              width: "100%",
              paddingY: "$3",
              paddingX: "$3",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
            onPress={() => {
              navigation.navigate("create-space");
            }}
          >
            <View sx={{ flexDirection: "row", alignItems: "center" }}>
              <Icon
                name="people-circle-outline"
                size={26}
                color={"$coolGray.500"}
                sx={{ marginRight: "$2" }}
              />
              <Text sx={{ color: "$coolGray.500", justifyContent: "center" }}>
                Create new group
              </Text>
            </View>
            <Icon
              name="chevron-forward-outline"
              size={24}
              color={"$coolGray.400"}
              sx={{ marginRight: "$2" }}
            />
          </Pressable>
        )}
      />
    </SafeAreaView>
  );
}
