import { FlatList } from "dripsy";
import { useConnectionQuery } from "../../hooks/useConnections";
import { useSortedSpaceMembers } from "../../hooks/useSpaceMembers";
import React, { useState } from "react";
import { phoneToPerson } from "../../utils/people";
import {
  Icon,
  StyledTextInput,
  Text,
  View,
  Image,
} from "../../components/basics";
import { SpaceMemberRow } from "./SpaceEditScreen";
import { useProfilePeekDrawer } from "../../components/chat/ProfilePeekDrawer";

export function getPersonFromMember(connectionQuery, member) {
  const profile = connectionQuery("profiles_by_id", member?.user_id);
  if (profile) return profile;
  const phone =
    member?.invitation?.external_metadata?.type === "PHONE"
      ? member?.invitation?.external_metadata?.value
      : undefined;
  if (phone) {
    const contact = phone
      ? connectionQuery("contacts_by_phone", phone)
      : undefined;
    if (contact) return contact;
    return phoneToPerson(phone);
  }
  return undefined;
}

export default function ManageMembersScreen({ route }) {
  const { spaceId } = route.params;
  const sortedMembers = useSortedSpaceMembers(spaceId);
  const [query, setQuery] = useState("");
  const connectionQuery = useConnectionQuery();
  const hydratedMembers = sortedMembers
    .map((member) => ({
      member,
      person: getPersonFromMember(connectionQuery, member),
    }))
    .filter(({ member, person }) =>
      person.name.toLowerCase().includes(query.toLowerCase())
    );

  const { onPressPerson, closeBottomSheet } = useProfilePeekDrawer();

  function RenderSpaceMemberRow({ item: { member }, index }) {
    return (
      <SpaceMemberRow
        member={member}
        index={index}
        onPress={() => onPressPerson({ member })}
        sx={{
          borderBottomWidth: "hairline",
          borderBottomColor: "$coolGray.300",
        }}
        showCaret={false}
      />
    );
  }

  return (
    <View sx={{ height: "100%" }}>
      <View sx={{ height: 50, justifyContent: "center" }}>
        <StyledTextInput
          sx={{
            borderRadius: 24,
            paddingX: "$3",
            marginX: "$5",
            mb: 3,
            height: "auto",
          }}
          value={query}
          onChangeText={(text) => setQuery(text)}
          textInputSx={{ paddingY: "$2", fontSize: 16 }}
          placeholder="Find someone..."
          rightIcon={() => (
            <Image
              source={require("../../assets/images/logo.png")}
              sx={{
                width: 24,
                height: 24,
              }}
            />
          )}
          leftIcon={() => <Icon name="search" size={18} />}
        />
      </View>
      <FlatList
        data={hydratedMembers}
        renderItem={RenderSpaceMemberRow}
        onScroll={closeBottomSheet}
        ListEmptyComponent={
          <View
            sx={{
              flex: 1,
            }}
          >
            <View
              sx={{
                alignItems: "center",
                padding: 20,
              }}
            >
              <Text
                sx={{
                  textAlign: "center",
                  fontSize: "sm",
                  color: "$gray.500",
                }}
              >
                No group members founds
              </Text>
            </View>
          </View>
        }
      />
    </View>
  );
}
