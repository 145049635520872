import supabase, { SUPABASE_URL } from "../lib/supabase";
import { blobToBase64, generateOptimizedImageUploadURI } from "./images";
import * as FileSystem from "expo-file-system";

type Base64Image = string;

const AVATAR_BUCKET_KEY = "avatars";

export async function getAvatarImage(imagePath: string): Promise<Base64Image> {
  const { data, error } = await supabase.storage
    .from("avatars")
    .download(imagePath);

  if (error) {
    throw error;
  }
  const base64 = await blobToBase64(data!);
  return base64;
}

export async function uploadAvatarImage(avatarKey: string, fileUri: string) {
  const avatarPath = `${AVATAR_BUCKET_KEY}/${avatarKey}`;
  const url = `${SUPABASE_URL}/storage/v1/object/${avatarPath}`;
  const uploadUri = await generateOptimizedImageUploadURI(fileUri);

  // Manually uploading (as opposed to using subabase lib) to take advantage of background work
  const { body, status } = await FileSystem.uploadAsync(url, uploadUri, {
    headers: {
      authorization: `Bearer ${supabase.auth.session()!.access_token}`,
      "cache-control": "1209600",
      "x-upsert": "true",
    },
    httpMethod: "POST",
    sessionType: FileSystem.FileSystemSessionType.BACKGROUND,
    uploadType: FileSystem.FileSystemUploadType.MULTIPART,
    fieldName: "file",
  });
  if (status < 200 || status > 299) {
    return {
      data: undefined,
      error: {
        avatarKey,
        message:
          JSON.parse(body).error?.message ||
          "an error occurred uploading an attachment",
      },
    };
  }

  return { data: body, error: undefined };
}
