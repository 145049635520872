import React, {
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import * as Notifications from "expo-notifications";
import { Subscription } from "expo-modules-core";
import { Platform } from "react-native";

export const NotificationsContext = React.createContext({
  notifications: [] as Notifications.Notification[],
  clearNotification: (notificationKey: string) => {},
});

export function useNotifications() {
  return useContext(NotificationsContext);
}

export function NotificationsProvider({ children }: PropsWithChildren<{}>) {
  const [notifications, setNotifications] = useState<
    Notifications.Notification[]
  >([]);
  const notificationListener = useRef<Subscription>();
  const responseListener = useRef<Subscription>();

  useEffect(() => {
    if (Platform.OS !== "web") {
      Notifications.getPresentedNotificationsAsync().then((notifs) =>
        setNotifications(notifs)
      );
    }
    notificationListener.current =
      Notifications.addNotificationReceivedListener((notification) => {
        setNotifications((prev) => [notification, ...prev]);
      });

    responseListener.current =
      Notifications.addNotificationResponseReceivedListener((response) => {
        setNotifications((prev) =>
          prev.filter(
            (notif) =>
              notif.request.identifier !==
              response.notification.request.identifier
          )
        );
      });

    return () => {
      Notifications.removeNotificationSubscription(
        notificationListener.current!
      );
      Notifications.removeNotificationSubscription(responseListener.current!);
    };
  }, []);

  const clearNotification = useCallback(
    async (notificationId: string) => {
      try {
        await Notifications.dismissNotificationAsync(notificationId);
        setNotifications(
          notifications.filter(
            (notif) => notif.request.identifier !== notificationId
          )
        );
      } catch (err) {
        console.error(err);
      }
    },
    [notifications]
  );

  return (
    <NotificationsContext.Provider value={{ notifications, clearNotification }}>
      {children}
    </NotificationsContext.Provider>
  );
}
