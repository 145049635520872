import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { RootStackParamList } from "../types";
import {
  Button,
  FlatList,
  IconButton,
  Text,
  TextInput,
  View,
} from "../components/basics";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { SafeAreaView } from "dripsy";
import { useUser } from "../hooks/auth";
import { useCollections } from "../hooks/useCollections";
import useShareCollection from "../hooks/useShareCollection";
import { useToast } from "../hooks/useToast";
import { useAllPeople, useConnections } from "../hooks/useConnections";
import PersonAvatar, { ProfileAvatar } from "../components/PersonAvatar";
import { getOrCreateStaticSpace, getSpaceIdForMembers } from "../utils/space";
import { getCollectionByDevId } from "../utils/collections";
import { MakeAccessRequest } from "../utils/access-requests";
import { ListRenderItemInfo } from "react-native";

export default function FollowCollectionsModalScreen({
  route,
  navigation,
}: NativeStackScreenProps<RootStackParamList, "FollowCollectionsModal">) {
  const { resourceKey } = route.params;
  const devIdSuffixes = {
    GEO_GENIUS: "GEO_GENIUS_GAMES",
    LE_WORD: "LE_WORD_GAMES",
  };
  const devIdSuffix = devIdSuffixes[resourceKey];

  const [query, setQuery] = useState("");

  const updateCollectionPermissions = useShareCollection();
  const addToast = useToast();

  const connections = useConnections();
  const allPeople = useAllPeople();
  const [showAllPeople, setShowAllPeople] = useState(false);
  const [followRequests, setFollowRequests] = useState<Set<string>>(new Set());
  const [initialLoadedCollections, setInitialLoadedCollections] =
    useState(null);
  const [unfollowRequests, setUnfollowRequests] = useState<Set<string>>(
    new Set()
  );
  const listToShow = useMemo(
    () => (showAllPeople ? (query === "" ? [] : allPeople) : connections),
    [connections, allPeople, showAllPeople, query]
  );
  const user = useUser();
  const filteredConnections = useMemo(
    () =>
      listToShow.filter(
        (connection) =>
          connection.key === "PROFILE" &&
          connection.id !== user?.id &&
          connection.name.toLowerCase().includes(query.toLowerCase())
      ),
    [query, listToShow, user]
  );
  const [{ data: collections, fetching: collectionsLoading }] =
    useCollections(resourceKey);

  const filteredCollections = collections?.resource_collections.filter((rc) =>
    rc.dev_id.includes(devIdSuffix)
  );

  const uidToCollectionMap = filteredCollections?.reduce((prev, curr) => {
    const ownerId = curr.dev_id.split("|")[0];
    return { ...prev, [ownerId]: curr };
  }, {});

  useEffect(() => {
    if (initialLoadedCollections == null && uidToCollectionMap != null)
      setInitialLoadedCollections(uidToCollectionMap);
  }, [initialLoadedCollections, uidToCollectionMap]);

  const sortedPeople = useMemo(() => {
    if (initialLoadedCollections == null) return [];
    return filteredConnections?.sort((a, b) => {
      const isFollowingA =
        !!initialLoadedCollections && !!initialLoadedCollections[a.id];
      const isFollowingB =
        !!initialLoadedCollections && !!initialLoadedCollections[b.id];
      if (!isFollowingA && isFollowingB) return -1;

      if (isFollowingA && !isFollowingB) return 1;
      return 0;
    });
  }, [filteredConnections, initialLoadedCollections]);

  const followList = useMemo(
    () =>
      sortedPeople?.map((connection) => {
        const isFollowing =
          !!uidToCollectionMap && !!uidToCollectionMap[connection.id];
        const isLoading =
          (!isFollowing && followRequests.has(connection.id)) ||
          (isFollowing && unfollowRequests.has(connection.id));
        return {
          ...connection,
          isFollowing,
          isLoading,
        };
      }),
    [sortedPeople, uidToCollectionMap, followRequests, unfollowRequests]
  );

  const handleFollow = useCallback(
    async (uid: string) => {
      if (!user) return;
      const uidArray = [user?.id, uid];
      setFollowRequests((prevSet) => new Set(prevSet?.add(uid)));
      setUnfollowRequests((prevSet) => {
        prevSet.delete(uid);
        return new Set(prevSet);
      });
      try {
        const staticSpace = await getOrCreateStaticSpace(uidArray);
        const metadata = { collection_dev_id: `${uid}|${devIdSuffix}` };
        const resp = await MakeAccessRequest(
          user?.id,
          uid,
          staticSpace,
          resourceKey,
          metadata,
          "LEADERBOARD"
        );
      } finally {
      }
    },
    [user, resourceKey, devIdSuffix, filteredConnections]
  );

  const [handleUnfollowRunningFor, setHandleUnfollowRunningFor] =
    useState<string>();

  const handleUnfollow = useCallback(
    async (uid: string) => {
      setUnfollowRequests((prevSet) => new Set(prevSet?.add(uid)));
      setFollowRequests((prevSet) => {
        prevSet.delete(uid);
        return new Set(prevSet);
      });
      const spaceId = getSpaceIdForMembers([user?.id, uid]);
      const resp = await getCollectionByDevId(`${uid}|${devIdSuffix}`);
      const cid = resp?.data?.resource_collections_by_dev_id?.id;
      if (!cid) {
        setUnfollowRequests((prevSet) => {
          prevSet.delete(uid);
          return new Set(prevSet);
        });
        setFollowRequests((prevSet) => prevSet?.add(uid));

        return;
      }
      try {
        const { error, data } = await updateCollectionPermissions(
          cid,
          [],
          [spaceId]
        );
        if (error) {
          addToast({
            description:
              "The request to unfollow this collection could not be completed.",
            variant: "ERROR",
          });
        }
      } catch (e) {
        addToast({
          description:
            "The request to unfollow this collection could not be completed.",
          variant: "ERROR",
        });
      } finally {
      }
    },
    [user, setFollowRequests]
  );

  const renderItem = useCallback(
    ({ item }: ListRenderItemInfo<typeof personList[0]>) => {
      const { name, isFollowing, isLoading, id, image, data } = item;

      return (
        <View
          sx={{
            flexDirection: "row",
            width: "100%",
            justifyContent: "space-between",
            alignItems: "center",
            paddingY: "$2",
            paddingLeft: "$4",
            paddingRight: "$3",
          }}
        >
          <View sx={{ flexDirection: "row", alignItems: "center" }}>
            <PersonAvatar person={item} size={50} />
            <Text sx={{ marginLeft: "$4" }}>{name}</Text>
          </View>
          <View
            sx={{
              width: 90,
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            {isFollowing ? (
              <Button
                variant="outline"
                onPress={() => handleUnfollow(id)}
                sx={buttonStyles}
                isLoading={isLoading}
                _showLoadingSpinner={true}
              >
                Remove
              </Button>
            ) : (
              <Button
                onPress={() => handleFollow(id)}
                sx={buttonStyles}
                isLoading={isLoading}
                _showLoadingSpinner={true}
              >
                Add
              </Button>
            )}
          </View>
        </View>
      );
    },
    [handleFollow, handleUnfollow, followRequests, handleUnfollowRunningFor]
  );

  const keyExtractor = useCallback((item: typeof personList[0]) => item.id, []);

  if (!connections || !user) return null;
  return (
    <SafeAreaView sx={{ flex: 1 }}>
      <View
        sx={{
          paddingY: "$2",
          paddingX: "$4",
          flexDirection: "column",
          backgroundColor: "$gray.100",
        }}
      >
        <View sx={{ flexDirection: "row" }}>
          <TextInput
            value={query}
            onChangeText={setQuery}
            sx={{ flexGrow: 1 }}
            placeholder="Search for..."
          />
          <IconButton
            _iconStyles={{ color: "$darkText" }}
            icon="close"
            onPress={() => {
              setQuery("");
            }}
          />
        </View>
        <View
          sx={{
            width: "100%",
            paddingY: "$1",
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          <Button
            size="sm"
            variant={showAllPeople ? "outline" : "solid"}
            sx={{ borderRadius: 50, paddingY: "$1" }}
            _textStyles={{ fontSize: "sm" }}
            onPress={() => setShowAllPeople(false)}
          >
            Friends in my spaces
          </Button>
          <Button
            size="sm"
            variant={showAllPeople ? "solid" : "outline"}
            sx={{ borderRadius: 50, marginLeft: "$4", paddingY: "$1" }}
            _textStyles={{ fontSize: "sm" }}
            onPress={() => setShowAllPeople(true)}
          >
            Everyone on Aspen
          </Button>
        </View>
      </View>

      <FlatList
        data={followList}
        renderItem={renderItem}
        keyExtractor={keyExtractor}
        extraData={query}
        estimatedItemSize={65}
        keyboardDismissMode="on-drag"
        keyboardShouldPersistTaps="always"
        ListEmptyComponent={
          <View
            sx={{
              alignSelf: "center",
              marginTop: "$4",
            }}
          >
            <Text sx={{ color: "$gray.500", textAlign: "center" }}>
              {query !== ""
                ? "No results"
                : showAllPeople
                ? "Start searching to find friends using Aspen"
                : "You haven't connected with anyone yet...\nTry searching Everyone on Aspen"}
            </Text>
          </View>
        }
      />
    </SafeAreaView>
  );
}

const buttonStyles = { borderRadius: 50, paddingY: "$1" };
