//import { Heading } from "native-base";
import { Text, styled, Sx } from "dripsy";
import React from "react";
import { Children, Component, ComponentProps } from "react";

const Heading = React.forwardRef<Component<ComponentProps<typeof Text>, any, any>>((
    { sx, children, ...props }: ComponentProps<typeof Text>,
    ref
) => {
    return <Text
        ref={ref}
        sx={{ fontWeight: "bold", fontSize: "2xl", ...sx }}
        {...props}>
        {children}
    </Text>
});

export default Heading;