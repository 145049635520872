export type StringTransform = "datetime";
export interface TransformParameter {
  type: StringTransform;
}
export interface SortParameter {
  _direction: "asc" | "desc";
  _transform?: TransformParameter;
}

export type SortDefintion = {
  [key: string]: SortDefintion | SortParameter;
};

export function evaluateCollectionQuery(query: any, resource: any) {
  const { collection_key, ...filters } = query;
  const collectionKeyMatch = collection_key?._eq === resource.collection_key;
  const filterMatch = evaluateFieldFilters(resource, filters);
  return collectionKeyMatch && filterMatch;
}

function evaluateFieldFilters(resource: any, filters: Record<string, any>) {
  return Object.entries(filters).every(([field, predicates]) => {
    const predicateEntries = Object.entries(predicates) as [string, any][];
    return predicateEntries.every(([predicateKey, predicateValue]) =>
      evaluateFilterPredicate(predicateKey, predicateValue, resource[field])
    );
  });
}

function evaluateFilterPredicate(
  predicateKey: string,
  predicateValue: any,
  resourceValue: any
) {
  switch (predicateKey) {
    case "_eq":
      return predicateValue === resourceValue;
    case "_has_key":
      return (
        predicateValue in (resourceValue ?? {}) &&
        resourceValue[predicateValue] != undefined
      );
    default:
      return false;
  }
}

function parseResourceFilters(resourceFilters: Record<string, any>) {
  return {
    _or: Object.entries(resourceFilters).map(([resource_key, filters]) => ({
      collection_key: { _eq: resource_key },
      ...filters,
    })),
  };
}

function parseTopSortDefinition(sortDefinition: SortDefintion) {
  let args = {};
  let order_by = [];
  if (sortDefinition) {
    const sortEntries = Object.entries(sortDefinition);
    const dataEntry = sortEntries.find((sort) => sort[0] === "data");
    if (dataEntry) {
      // FOR NOW ONLY SUPPORTING SINGLE SORT VALUE
      const [key, sortVal] = dataEntry;
      args = parseSortForArgs(sortVal as SortDefintion);
    } else {
      for (const [key, sortVal] of sortEntries) {
        order_by.push(parseSortForOrderBy(key, sortVal));
      }
    }
  }

  return { args, order_by };
}

function parseSortForArgs(dataSortDefinition: SortDefintion) {
  if (!dataSortDefinition) return {};
  return recursivelyGeneratePath([], dataSortDefinition);
}

function recursivelyGeneratePath(
  currentPath: string[],
  sortValue: SortDefintion | SortParameter
): {
  _order_asc_desc?: string;
  _order_by_path?: string;
  _order_by_transform?: string;
} {
  if (sortValue._direction) {
    return {
      _order_asc_desc: sortValue._direction,
      _order_by_path: currentPath.join("."),
      _order_by_transform: sortValue._transform?.type,
    };
  }

  // FOR NOW ONLY SUPPORTING SINGLE SORT VALUE
  const firstSort = Object.entries(sortValue)[0];
  if (!firstSort) return {};
  const [key, sortVal] = firstSort;
  currentPath.push(key);
  return recursivelyGeneratePath(currentPath, sortVal);
}

interface ResourceQueryArgs {
  _order_by_path?: string;
  _order_asc_desc?: string;
  _order_by_transform?: string;
}

function parseSortForOrderBy(sortKey: string, sortParam: SortParameter) {
  return {
    [sortKey]: sortParam._direction === "asc" ? "asc" : "desc",
  };
}

export function getLocalSortingFunction(
  queryArgs?: ResourceQueryArgs,
  orderBy?: Record<string, any>[]
):
  | { sortField: string[]; sortFunc: (a: string, b: string) => number }
  | undefined {
  if (queryArgs) {
    if (!!queryArgs._order_by_path) {
      return {
        sortField: `data.${queryArgs._order_by_path}`.split("."),
        sortFunc: getSortFunc(
          queryArgs._order_asc_desc ?? "asc",
          queryArgs._order_by_transform
        ),
      };
    }
  }
  if (orderBy) {
    // TODO: we may not want to assume just first sort here
    const firstSortValue = orderBy[0];
    if (firstSortValue) {
      const [sortField, sortDirection] = Object.entries(firstSortValue)[0];
      if (sortField) {
        const transform =
          sortField === "created_at" || sortField === "updated_at"
            ? "datetime"
            : undefined;
        return {
          sortField: sortField.split("."),
          sortFunc: getSortFunc(sortDirection, transform),
        };
      }
    }
  }

  return undefined;
}

export function getSortedResourceIndex(
  element: any,
  array: any[],
  comparer: (a: string, b: string) => number,
  start: number,
  end: number
): number {
  if (array.length === 0) return -1;

  start = start || 0;
  end = end || array.length;
  var pivot = (start + end) >> 1;

  var c = comparer(element, array[pivot]);
  if (end - start <= 1) return c == -1 ? pivot - 1 : pivot;

  if (c < 0)
    return getSortedResourceIndex(element, array, comparer, start, pivot);
  if (c > 0)
    return getSortedResourceIndex(element, array, comparer, pivot, end);
  return pivot;
}

function getSortFunc(direction: string, transform?: string) {
  return (a: string, b: string) => {
    const aVal = applyTransform(a, transform);
    const bVal = applyTransform(b, transform);

    if (aVal < bVal) return direction === "asc" ? -1 : 1;
    if (aVal > bVal) return direction === "asc" ? 1 : -1;
    return 0;
  };
}

function applyTransform(value: string, transform: string | undefined) {
  switch (transform) {
    case "datetime":
      return new Date(value);
    default:
      return value;
  }
}

// NOTE: IF YOU CHANGE THIS YOU MUST UPDATE HOW OUR CACHE PARSES THE WHERE CLAUSE, which should be:
// 1) Get all queries where a resource type query is matched
// 2) Check the permisssions to make sure that query should be updated
export function resourceQueryVariables({
  spaceId,
  collectionId,
  devCollectionId,
  resourceQueries = {},
  pagination,
  sort = {},
}: {
  spaceId?: string;
  collectionId?: string;
  devCollectionId?: string;
  resourceQueries?: Record<string, any>;
  pagination?: { limit: number; offset: number };
  sort?: SortDefintion;
}) {
  const resourceQuery = parseResourceFilters(resourceQueries);
  const { args: sortArgs, order_by } = parseTopSortDefinition(sort);

  return {
    args: {
      _space_id: spaceId,
      _collection_id: collectionId,
      _collection_dev_id: devCollectionId,
      ...sortArgs,
    },
    where: {
      ...resourceQuery,
      data: { _is_null: false },
    },
    order_by,
    ...(pagination ? pagination : {}),
  };
}
