import { Ionicons } from "@expo/vector-icons";
import { ActivityIndicator, Sx } from "dripsy";
import React, { ComponentProps, PropsWithoutRef } from "react";
import { PressableProps, _Text, _View } from "react-native";
import {
  baseStyle as baseButtonStyle,
  variants as buttonVariants,
  iconButtonSizes,
} from "../../constants/ButtonThemes";
import Text from "./Text";
import Pressable from "./Pressable";
import Icon from "./Icon";

export interface IconButtonProps extends PressableProps {
  disabled: boolean;
  variant?: "unstyled" | "solid" | "outline" | "ghost" | "subtle";
  size?: "xs" | "sm" | "md" | "lg";
  colorScheme?: string;
  icon: ComponentProps<typeof Ionicons>["name"];
  isLoading?: boolean;
  _showLoadingSpinner?: boolean;
  _iconStyles?: Sx;
  _containerStyles?: Sx;
  _spinnerStyles?: Sx;
  _isDisabledStyles?: Sx;
  _isLoadingStyles?: Sx;
  sx?: Sx;
}

function IconButton({
  onPress,
  icon,
  isLoading,
  disabled = false,
  colorScheme = "$primary",
  variant = "ghost",
  size = "md",
  _showLoadingSpinner = true,
  _iconStyles,
  _containerStyles,
  _spinnerStyles,
  _isDisabledStyles,
  _isLoadingStyles,
  sx,
  ...props
}: PropsWithoutRef<IconButtonProps>) {
  const sizeStyles = iconButtonSizes[size];
  const colorStyles = buttonVariants[variant]({ colorScheme });
  const defaultLoadingStyles = isLoading ? baseButtonStyle._loading : undefined;
  const defaultDisabledStyles = disabled
    ? baseButtonStyle._disabled
    : undefined;
  const buttonContent =
    isLoading && _showLoadingSpinner ? (
      <ActivityIndicator
        // the spinner has `size` and `color` props which need to be passed
        // directly, not through `sx` or `style`
        {...baseButtonStyle._spinner}
        //pass color and size as prop here...
        sx={{
          ..._spinnerStyles,
        }}
      />
    ) : (
      <>
        <Icon
          name={icon}
          {...sizeStyles._icon}
          {...colorStyles._icon}
          sx={{ ..._iconStyles }}
        />
      </>
    );
  return (
    <Pressable
      onPress={onPress}
      disabled={disabled || isLoading}
      // considering removing hover effects for icon button... they really don't make much of an impression
      style={({ hovered, pressed }) =>
        pressed
          ? colorStyles._containerPressed
          : hovered
          ? colorStyles._containerHover
          : colorStyles._container
      }
      // remainder of the style props.
      sx={{
        ...baseButtonStyle._container,
        ...sizeStyles._container,
        ..._containerStyles,
        ...sx,
        ...defaultLoadingStyles,
        ...defaultDisabledStyles,
        ..._isDisabledStyles,
        ..._isLoadingStyles,
      }}
      {...props}
    >
      {buttonContent}
    </Pressable>
  );
}

export default IconButton;
