import { nanoid } from "nanoid";
import { NOTICE_RESOURCE_KEY } from "../resources/notice";
import useNoticeFormatter from "./useNoticeFormatter";
import { usePushEvent, usePushEventAdmin } from "./usePushEvent";

export function useNoticeConstructor() {
  const noticeFormatter = useNoticeFormatter();
  return (
    text: string,
    userIds: string[] = [],
    metadata: Record<string, string> | undefined = undefined
  ) => {
    return {
      resource_key: NOTICE_RESOURCE_KEY,
      type: "NEW",
      data: {
        text_user_ids: userIds,
        text,
        static_text: noticeFormatter(text, userIds, false),
        metadata,
      },
      resource_id: nanoid(),
    };
  };
}

export default function usePushNotice() {
  const pushEvent = usePushEvent();
  const noticeConstructor = useNoticeConstructor();
  return (
    text: string,
    userIds: string[] = [],
    metadata: Record<string, string> | undefined = undefined
  ) => {
    return pushEvent(noticeConstructor(text, userIds, metadata));
  };
}

export function usePushNoticeAdmin() {
  const pushEvent = usePushEventAdmin();
  const noticeFormatter = useNoticeFormatter();
  return (
    text: string,
    userIds: string[] = [],
    metadata: Record<string, string> | undefined = undefined,
    spaceId: string | undefined = undefined
  ) => {
    return pushEvent({
      resource_key: NOTICE_RESOURCE_KEY,
      type: "NEW",
      data: {
        text_user_ids: userIds,
        text,
        static_text: noticeFormatter(text, userIds, false),
        metadata,
      },
      resource_id: nanoid(),
      space_id: spaceId,
    });
  };
}
