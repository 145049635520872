import { useNavigation } from "@react-navigation/native";
import {
  View,
  Text,
  Divider,
  Image,
  KeyboardAvoidingView,
  FlatList,
} from "../components/basics";
import React, { useCallback, useMemo, useState } from "react";
import { ListRenderItem, Platform } from "react-native";
import { AnimatedFlatList } from "../components/animated-flat-list";
import { PersonSectionItem } from "../components/MemberItems";
import { TabScreenProps } from "../components/NavDrawer";
import Colors from "../constants/Colors";
import { useConnections } from "../hooks/useConnections";
import { Connection, Person } from "../utils/people";
import { useContacts } from "../hooks/useContacts";
import { useUser } from "../hooks/auth";
import StyledTextInput from "../components/basics/StyledTextInput";
import { TAB_BAR_HEIGHT } from "./MainScreen";
import { useSafeAreaInsets } from "react-native-safe-area-context";

function PeopleListItem({ item }: { item: Person | "SEPARATOR" }) {
  if (item === "SEPARATOR") return <Divider my="2" bgColor="gray.300" />;
  return <PeopleListProfileItem person={item} />;
  return null;
}

export function PeopleListProfileItem({ person }: { person: Person }) {
  const navigation = useNavigation();

  const onPress = useCallback(() => {
    navigation.navigate("profile", {
      userId: person.id,
      type: person.key,
    });
  }, [navigation, person]);

  return (
    <PersonSectionItem
      person={person}
      onPress={onPress}
      // avatarProps={isCurrentUser ? { size: 75 } : {}}
    />
  );
}

export default function PeopleScreen({
  scrollHandler,
  verticalOffset = { bottom: 0, top: 0 },
}: TabScreenProps) {
  const connections = useConnections();
  const currentUser = useUser();
  const insets = useSafeAreaInsets();
  const [
    _contacts,
    _contactsByPhone,
    contactPermission,
    askForContactPermission,
  ] = useContacts();
  const [query, setQuery] = useState("");
  const sortedConnections = useMemo(() => {
    return connections
      .filter(
        (c) =>
          c.key === "PROFILE" &&
          c.name.toLowerCase().includes(query.toLowerCase())
      )
      .sort((a, b) => {
        if (a.id === currentUser!.id) return -1;
        if (b.id === currentUser!.id) return 1;
        return a.name < b.name ? -1 : 1;
      });
  }, [connections, currentUser, query]);

  const renderItem: ListRenderItem<Connection> = ({ item }) => {
    return <PeopleListItem item={item} />;
  };

  // TODO: add button to sync contacts

  return (
    <View sx={{ height: "100%", backgroundColor: "white" }}>
      <FlatList
        data={sortedConnections}
        keyExtractor={(item, index) => JSON.stringify(item)}
        onScroll={scrollHandler}
        // contentContainerStyle={{
        //   paddingTop: Platform.OS != "ios" ? verticalOffset.top : 0,
        //   paddingBottom: Platform.OS != "ios" ? verticalOffset.bottom : 0,
        // }}
        // contentInset={verticalOffset}
        // contentOffset={{
        //   x: 0,
        //   y: Platform.OS === "ios" ? -verticalOffset.top : 0,
        // }}
        // progressViewOffset={verticalOffset.top}
        renderItem={renderItem}
        ListEmptyComponent={
          <View
            sx={{
              paddingTop: verticalOffset.top ?? 0,
              flex: 1,
            }}
          >
            <View
              sx={{
                alignItems: "center",
                padding: 20,
              }}
            >
              <Text
                sx={{
                  textAlign: "center",
                  fontSize: "sm",
                  color: "$gray.500",
                  // fontStyle: "italic",
                }}
              >
                No results
              </Text>
            </View>
            {/* {contactPermission !== "granted" && Platform.OS !== "web" && (
              <Button
                variant={"outline"}
                sx={{ m: "$2" }}
                onPress={async () => {
                  await askForContactPermission();
                }}
              >
                Import contacts from phone
              </Button>
            )} */}
          </View>
        }
      />
      <KeyboardAvoidingView
        // style={{ borderWidth: 1 }}
        behavior={Platform.OS === "ios" ? "position" : "height"}
        keyboardVerticalOffset={TAB_BAR_HEIGHT + insets.bottom}
      >
        <View
          sx={{
            position: "absolute",
            bottom: 0,
            width: "100%",
            backgroundColor: "transparent",
            paddingY: "$1",
            alignItems: "center",
          }}
        >
          <StyledTextInput
            placeholder="Search for someone..."
            sx={{
              marginBottom: "$2",
              height: 36,
              marginX: "$4",
              borderRadius: 80,
              paddingY: 0,
              width: 300,
              boxShadow: "$4",
            }}
            textInputSx={{ fontSize: 16 }}
            value={query}
            onChangeText={(text) => setQuery(text)}
            leftIcon={() => (
              <Image
                source={require("../assets/images/logo.png")}
                sx={{
                  width: 24,
                  height: 24,
                  marginLeft: "$2",
                }}
              />
            )}
          />
        </View>
      </KeyboardAvoidingView>
    </View>
  );
}
