// TODO: this is not a queue, should rename

import AsyncStorage from "@react-native-async-storage/async-storage";
import { AttachmentState } from "../screens/spaces/ChatScreen";

export type AttachmentUploadingState = "success" | "failed" | "pending";

export interface UploadMetadata {
  uploadPct: number;
  key: string;
  status: AttachmentUploadingState;
}

export const UPLOAD_QUEUE_KEY = "attachment_queue";
let local_queue: Record<string, AttachmentState> = {};

// Load stored info into memory
export function loadUploadQueueIntoMemory() {
  return readUploadQueueFromStorage().then((data) => {
    local_queue = data;
  });
}

export function pushToUploadQueue(items: Record<string, AttachmentState>) {
  local_queue = { ...local_queue, ...items };
  backupToStorage();
}

export function popFromUploadQueue(keys: string[]) {
  keys.forEach((k) => delete local_queue[k]);
  backupToStorage();
}

// TODO: need some way to lock this
async function backupToStorage() {
  await AsyncStorage.setItem(UPLOAD_QUEUE_KEY, JSON.stringify(local_queue));
}

export function readUploadQueueKeys() {
  return Object.keys(local_queue);
}

export function readUploadQueue() {
  return local_queue;
}

export function readUploadQueueState(id: string): AttachmentState | undefined {
  return local_queue[id];
}

export async function readUploadQueueFromStorage(): Promise<
  Record<string, AttachmentState>
> {
  return JSON.parse((await AsyncStorage.getItem(UPLOAD_QUEUE_KEY)) ?? "{}");
}

export function queueUploads(uploads: Record<string, AttachmentState>) {
  pushToUploadQueue(uploads);
  recordUploadStatuses(
    Object.fromEntries(
      Object.entries(uploads).map(([k, v]) => [
        k,
        { key: k, uploadPct: 0, status: "pending" },
      ])
    )
  );
}

export function deleteFromUploadQueueBulk(attachmentKeys: string[]) {
  popFromUploadQueue(attachmentKeys);
  untrackUploadStatuses(attachmentKeys);
}

export function deleteFromUploadQueue(attachmentKey: string) {
  deleteFromUploadQueueBulk([attachmentKey]);
}

// Track statuses locally
let localUploadStatuses: Record<string, UploadMetadata> = {};

export function uploadStatus(attachmentId: string): UploadMetadata | undefined {
  return localUploadStatuses[attachmentId];
}

export function uploadStatuses() {
  return localUploadStatuses;
}

export function recordUploadStatuses(statuses: Record<string, UploadMetadata>) {
  localUploadStatuses = { ...localUploadStatuses, ...statuses };
  for (const status of Object.values(statuses)) {
    updateImageUploadStatusListeners(status);
  }
}

export function untrackUploadStatuses(attachmentId: string[]) {
  for (const id of attachmentId) {
    delete localUploadStatuses[id];
    updateImageUploadDequeueListeners(id);
  }
}

// Listen for upload status changes
let imageUploadStatusListeners: ((item: UploadMetadata) => void)[] = [];
export function addImageUploadStatusListener(
  listener: (item: UploadMetadata) => void
) {
  imageUploadStatusListeners.push(listener);
}
export function updateImageUploadStatusListeners(item: UploadMetadata) {
  imageUploadStatusListeners.forEach((callback) => callback(item));
}
export function removeImageUploadStatusListener(
  listener: (item: UploadMetadata) => void
) {
  imageUploadStatusListeners = imageUploadStatusListeners.filter(
    (callback) => callback !== listener
  );
}

// Listen for dequeues
let onImageUploadDequeueListeners: ((item: string) => void)[] = [];
export function updateImageUploadDequeueListeners(itemId: string) {
  onImageUploadDequeueListeners.forEach((callback) => callback(itemId));
}
export function addImageUploadDequeueListener(
  listener: (itemId: string) => void
) {
  onImageUploadDequeueListeners.push(listener);
}
export function removeImageUploadDequeueListener(
  listener: (itemId: string) => void
) {
  onImageUploadDequeueListeners = onImageUploadDequeueListeners.filter(
    (callback) => callback !== listener
  );
}
