import { differenceInCalendarDays, format } from "date-fns";

export function dateStringMax(dateString0: string, ...dateStrings: string[]) {
  return dateStrings.reduce(function (a, b) {
    return new Date(a || 0) > new Date(b || 0) ? a : b;
  }, dateString0);
}

export function formatMessageTime(date: Date) {
  const now = new Date();
  const daysDiff = differenceInCalendarDays(now, date);
  if (daysDiff === 0) {
    return format(date, "h:mm a");
  } else if (daysDiff <= 7) {
    return format(date, "ccc");
  }
  return format(date, "M/d/yy");
}

export function dateMax(date0: Date, ...dates: Date[]) {
  return dates.reduce((a, b) => (a > b ? a : b), date0);
}

export function formatRelativeDate(date: Date) {
  const now = new Date();

  const daysDiff = differenceInCalendarDays(date, now);
  if (daysDiff === 0) {
    return "Today";
  } else if (daysDiff > 0 && daysDiff <= 7) {
    return format(date, "ccc");
  }
  return format(date, "MMM d");
}
