import { useNavigation } from "@react-navigation/native";
import {
  View,
  Text,
  Button,
  Icon,
  TextInput,
  Image,
} from "../../components/basics";
import { MotiText, MotiView } from "moti";
import React, { useCallback, useState } from "react";
import supabase, { signInWithEmail } from "../../lib/supabase";
import { parsePhoneNumber } from "../../utils/phone";
import StyledTextInput from "../../components/basics/StyledTextInput";
import { useToast } from "../../hooks/useToast";

export default function SignInScreen() {
  const navigation = useNavigation();
  const [signInMethod, setSignInMethod] = useState<
    "email" | "phone" | "password" | null
  >("email");
  const addToast = useToast();
  const [showPasswordField, setShowPasswordField] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [phone, setPhone] = useState("");
  const [countryCode, setCountryCode] = useState("1");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSignIn = useCallback(async () => {
    const isEmail = signInMethod === "email";

    let credential = isEmail
      ? email.trim()
      : countryCode.trim() + phone.match(/\d+/g)?.join("");
    setIsSubmitting(true);
    try {
      if (!isEmail) {
        const phoneCredential = parsePhoneNumber(credential);
        if (!phoneCredential) {
          addToast({
            description: `Sorry, that's not a valid phone number.`,
            position: "top",
            variant: "ERROR",
          });
          throw new Error(`${credential} is not a valid phone number`);
        }
        credential = phoneCredential;
      }

      if (showPasswordField) {
        await signInWithEmail(credential, password);
        navigation.navigate("Root");
      } else {
        const resp = await supabase.auth.signIn(
          isEmail ? { email: credential } : { phone: credential },
          { shouldCreateUser: false }
        );
        if (resp.error) {
          throw new Error(resp.error.message);
        }
        if (isEmail) {
          navigation.navigate("EmailSignInMessage", {
            email: credential,
          });
        } else {
          navigation.navigate("ConfirmSMSCode", {
            phone: credential,
          });
        }
      }
    } catch (err) {
      if (err instanceof Error) {
        if (err.message === "Signups not allowed for otp")
          addToast({
            description: `Your ${
              isEmail ? "email" : "phone number"
            } was not recognized.`,
            position: "bottom",
            variant: "ERROR",
          });
        if (
          err.message ===
          "For security purposes, you can only request this once every 60 seconds"
        )
          addToast({
            description: `For security purposes, you can only sign in with the same ${
              isEmail ? "email" : "phone number"
            } once every 60 seconds`,
            position: "bottom",
            variant: "ERROR",
          });
        else
          addToast({
            description: "An unknown error occured",
            position: "bottom",
            variant: "ERROR",
          });
      }
    } finally {
      setIsSubmitting(false);
    }
  }, [email, phone, countryCode, signInMethod, password, showPasswordField]);
  return (
    <View
      sx={{
        flex: 1,
        alignItems: "center",
        justifyContent: "flex-start",
        backgroundColor: "white",
        paddingBottom: 200,
      }}
    >
      <MotiView
        style={{
          flexDirection: "row",
          alignItems: "center",
          marginTop: 100,
        }}
        from={{ opacity: 0, scale: 0.85 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ type: "timing" }}
      >
        <Image
          source={require("../../assets/images/logo.png")}
          sx={{ width: 60, height: 60 }}
        />
        <MotiText
          style={{
            fontSize: 36,
            fontWeight: "500",
            marginBottom: 5,
            textAlign: "center",
            marginLeft: 12,
          }}
        >
          Aspen Spaces
        </MotiText>
      </MotiView>

      <MotiView
        style={{ alignItems: "stretch", width: 250, display: "flex" }}
        delay={1000}
        from={{ opacity: 0, translateY: 30 }}
        animate={{ opacity: 1, translateY: 0 }}
        transition={{ type: "timing" }}
      >
        <View
          sx={{
            flexDirection: "row",
            marginTop: 50,
            marginBottom: 4,
          }}
        >
          <Text
            sx={{
              width: 50,
              color: signInMethod === "phone" ? "$darkText" : "$gray.400",
              fontWeight: "bold",
            }}
            onPress={() => setSignInMethod("phone")}
          >
            Phone
          </Text>
          <Text
            sx={{
              width: 50,
              color: signInMethod === "email" ? "$darkText" : "$gray.400",
              fontWeight: "bold",
            }}
            onPress={() => setSignInMethod("email")}
          >
            Email
          </Text>
        </View>
        {signInMethod === "phone" ? (
          <>
            <StyledTextInput
              onChangeText={(text) => setPhone(text)}
              value={phone}
              autoFocus
              placeholder="--- --- ----"
              keyboardType={"phone-pad"}
              leftIcon={({ onFocus }) => (
                <>
                  <Text sx={{ fontSize: 18, ml: "$2" }}>+</Text>
                  <TextInput
                    onChangeText={(text) => setCountryCode(text)}
                    value={countryCode}
                    sx={{
                      width: 30,
                      fontSize: 18,
                    }}
                    defaultValue={"1"}
                    maxLength={3}
                    onFocus={onFocus}
                    keyboardType={"phone-pad"}
                  />
                  <View
                    sx={{
                      height: "50%",
                      borderLeftWidth: 1,
                      borderColor: "$primary.400",
                    }}
                  />
                </>
              )}
            />
            <View
              sx={{
                backgroundColor: "$danger.200",
                p: "$2",
                mb: "$2",
                borderRadius: 4,
              }}
            >
              <Text>
                Due to a service outage, only email sign in is available at this
                time
              </Text>
            </View>
          </>
        ) : (
          <StyledTextInput
            onChangeText={(text) => setEmail(text)}
            autoCapitalize={"none"}
            autoComplete="email"
            autoFocus
            value={email}
            keyboardType={"email-address"}
            placeholder={"email@example.com"}
          />
        )}
        {showPasswordField && signInMethod === "email" && (
          <StyledTextInput
            onChangeText={(text) => setPassword(text)}
            autoCapitalize={"none"}
            autoComplete="password"
            secureTextEntry={!showPassword}
            value={password}
            placeholder={"password"}
            rightIcon={() => (
              <Icon
                size={24}
                color={"$gray.400"}
                onPress={() => setShowPassword(!showPassword)}
                name={!showPassword ? "eye-outline" : "eye-off-outline"}
              />
            )}
          />
        )}
        {signInMethod === "email" && (
          <Text
            onPress={() => setShowPasswordField(!showPasswordField)}
            sx={{
              mb: "$4",
              color: "$primary.600",
              textDecorationLine: "underline",
              textAlign: "center",
            }}
          >{`Sign in ${showPasswordField ? "without" : "with"} password`}</Text>
        )}
        <Button
          disabled={signInMethod === "phone"}
          isLoading={isSubmitting}
          onPress={handleSignIn}
          sx={{ marginBottom: "5%" }}
          _textStyles={{ fontSize: 18 }}
        >
          Sign In
        </Button>
        <View
          sx={{ alignItems: "center", marginTop: "$8", marginBottom: "$8" }}
        >
          <View
            sx={{
              borderTopWidth: 1,
              height: 0,
              width: 150,
              borderColor: "$gray.300",
            }}
          />
          <Text
            sx={{
              marginBottom: "3%",
              backgroundColor: "$white",
              color: "$gray.500",
              marginTop: -10,
              width: 25,
              textAlign: "center",
              zIndex: 10,
            }}
          >
            or
          </Text>
        </View>
        <Button
          onPress={() => {
            navigation.navigate("SignUp");
          }}
          variant={"outline"}
          _textStyles={{ fontSize: 18 }}
        >
          Create Account
        </Button>
      </MotiView>
    </View>
  );
}
