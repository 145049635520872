export function resourceCollectionQueryVariables(
  resourceKey: string,
  spaceId?: string
) {
  return {
    where: {
      permissions: spaceId ? { space_id: { _eq: spaceId } } : undefined,
    },
  };
}
