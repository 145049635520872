import { Image } from "react-native";
import Constants from "expo-constants";
import * as ImageManipulator from "expo-image-manipulator";

export const IMAGE_THUMBNAIL_SIZE = 256;
export const IMAGE_UPLOAD_SIZE = 1200;
export const imageSizesReadonly = [16, 32, 48, 64, 96, 128, 256, 384] as const; // Default Vercel sizes: https://nextjs.org/docs/api-reference/next/image#image-sizes + https://nextjs.org/docs/api-reference/next/image#device-sizes
export const imageSizes = [...imageSizesReadonly];
interface OptimizationProps {
  w: typeof imageSizesReadonly[number];
  q?: number;
}

const IMAGE_OPTIMIZATION_BASE_URL =
  Constants.manifest?.extra?.IMAGE_OPTIMIZATION_BASE_URL;

export async function blobToBase64(blob: Blob): Promise<string> {
  return new Promise((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      if (!reader.result) throw new Error("Could not convert blob!");
      resolve(reader.result as string);
    };
    reader.readAsDataURL(blob);
  });
}

export function optimizedImagePath(url: string, props: OptimizationProps) {
  const propDefaults = {
    q: 100,
  };
  const uriProps = {
    ...propDefaults,
    ...props,
  };
  const urlEncodedProps = Object.entries(uriProps)
    .map(([prop, val]) => `&${prop}=${encodeURIComponent(val)}`)
    .join("");
  return `${IMAGE_OPTIMIZATION_BASE_URL}/_next/image?url=${encodeURIComponent(
    url
  )}${urlEncodedProps}`;
}

export async function getSizeAsync(uri: string) {
  return new Promise<{ width: number; height: number }>((resolve, reject) => {
    Image.getSize(
      uri,
      (width, height) => resolve({ width, height }),
      (err) => reject(err)
    );
  });
}

export function calculateResize(
  { height, width }: { height: number; width: number },
  {
    maxHeight,
    maxWidth,
  }: {
    maxHeight: number;
    maxWidth: number;
  }
) {
  const maxResizeRatio = Math.min(maxHeight / height, maxWidth / width);

  // dont resize image up if not necessary
  const newHeight = Math.min(height, height * maxResizeRatio);
  const newWidth = Math.min(width, width * maxResizeRatio);

  return { height: newHeight, width: newWidth };
}

async function generateOptimizedImageUri(uri: string, size: number) {
  const imageSize = await getSizeAsync(uri);
  const resizeDims = calculateResize(imageSize, {
    maxHeight: size,
    maxWidth: size,
  });
  const { uri: resizedURI } = await ImageManipulator.manipulateAsync(
    uri,
    [{ resize: resizeDims }],
    {
      format: ImageManipulator.SaveFormat.JPEG,
      compress: 0.8,
    }
  );

  return resizedURI;
}

export function generateOptimizedThumbnailURI(uri: string) {
  return generateOptimizedImageUri(uri, IMAGE_THUMBNAIL_SIZE);
}

export async function generateOptimizedImageUploadURI(uri: string) {
  return generateOptimizedImageUri(uri, IMAGE_UPLOAD_SIZE);
}
