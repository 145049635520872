import { stringifyVariables } from "@urql/core";
import {
  Resolver,
  Variables,
  NullArray,
} from "@urql/exchange-graphcache/dist/types";

export type MergeMode = "before" | "after";

export interface PaginationParams {
  offsetArgument?: string;
  limitArgument?: string;
  mergeMode?: MergeMode;
}

export const simplePagination = ({
  offsetArgument = "skip",
  limitArgument = "limit",
  mergeMode = "after",
}: PaginationParams = {}): Resolver<any, any, any> => {
  const compareArgs = (
    fieldArgs: Variables,
    connectionArgs: Variables
  ): boolean => {
    for (const key in connectionArgs) {
      if (key === offsetArgument || key === limitArgument) {
        continue;
      } else if (!(key in fieldArgs)) {
        return false;
      }

      const argA = fieldArgs[key];
      const argB = connectionArgs[key];

      if (
        typeof argA !== typeof argB || typeof argA !== "object"
          ? argA !== argB
          : stringifyVariables(argA) !== stringifyVariables(argB)
      ) {
        return false;
      }
    }

    for (const key in fieldArgs) {
      if (key === offsetArgument || key === limitArgument) {
        continue;
      }
      if (!(key in connectionArgs)) return false;
    }

    return true;
  };

  return (_parent, fieldArgs, cache, info) => {
    const { parentKey: entityKey, fieldName } = info;

    const allFields = cache.inspectFields(entityKey);
    const fieldInfosForField = allFields.filter(
      (info) => info.fieldName === fieldName
    );
    if (fieldInfosForField.length === 0) {
      return undefined;
    }

    // Sort before processing, so we get proper page ordering
    const fieldInfos = fieldInfosForField
      .filter((info) => {
        const { arguments: args } = info;
        return args !== null && compareArgs(fieldArgs, args);
      })
      .sort(
        (a, b) => a.arguments[offsetArgument] - b.arguments[offsetArgument]
      );
    const size = fieldInfos.length;
    const visited = new Set();
    let result: NullArray<string> = [];
    let prevOffset: number | null = null;

    const expectedLength = fieldArgs[limitArgument] + fieldArgs[offsetArgument];

    for (let i = 0; i < size; i++) {
      const { fieldKey, arguments: args } = fieldInfos[i];
      if (args === null || !compareArgs(fieldArgs, args)) {
        continue;
      }

      const links = (cache.resolve(entityKey, fieldKey) ?? []) as string[];
      const currentOffset = args[offsetArgument];

      // if (
      //   links === null ||
      //   links.length === 0 ||
      //   typeof currentOffset !== "number"
      // ) {
      //   continue;
      // }

      const tempResult: NullArray<string> = [];

      for (let j = 0; j < links.length; j++) {
        const link = links[j];
        if (visited.has(link)) continue;
        tempResult.push(link);
        visited.add(link);
      }

      if (
        (!prevOffset || currentOffset > prevOffset) ===
        (mergeMode === "after")
      ) {
        result = [...result, ...tempResult];
      } else {
        result = [...tempResult, ...result];
      }

      // only return expected page length of results
      if (expectedLength && result.length >= expectedLength) {
        result = result.slice(0, expectedLength);
        break;
      }

      prevOffset = currentOffset;
    }

    const hasCurrentPage = cache.resolve(entityKey, fieldName, fieldArgs);
    if (hasCurrentPage) {
      return result;
    } else if (!(info as any).store.schema) {
      return undefined;
    } else {
      info.partial = true;
      return result;
    }
  };
};
