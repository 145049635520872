import React, {
  ComponentProps,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  View,
  Image,
  Icon,
  Text,
  Pressable,
  Spinner,
  Column,
  Center,
} from "./basics";
import { ImageURISource, Platform } from "react-native";
import { Image as RNImage } from "dripsy";
import DRIPSY_THEME from "../constants/DripsyTheme";
import { calculateResize } from "../utils/images";

export interface FallbackImageSource {
  src: ImageURISource;
}

type FallbackImageProps = Omit<
  ComponentProps<typeof Image>,
  "source" | "onLoad"
> & {
  sources: FallbackImageSource[];
  onLoad?: (imgSrc: FallbackImageSource) => void;
  height?: number;
  width?: number;
  maxHeight?: number;
  maxWidth?: number;
};

const MemoImage = React.memo(Image);
const MemoRNImage = React.memo(RNImage);

//TODO: loading component, failed component
export function FallbackImage(props: FallbackImageProps) {
  const { sources } = props;
  const sourceIndex = useRef(0);
  const [imgSrc, setImgSrc] = useState<FallbackImageSource>(
    sources[sourceIndex.current]
  );
  const [imgDimensions, setImgDimensions] = useState({
    height: props?.height ?? 256,
    width: props?.width ?? 256,
  });

  // Increment counter to trigger image refresh
  const [counter, setCounter] = useState(0);

  // In case we unmount before download starts (no need to kick off extra work)
  const componentIsMounted = useRef(false);
  useEffect(() => {
    componentIsMounted.current = true;
    return () => {
      componentIsMounted.current = false;
    };
  }, []);

  const trySource = useCallback((srcIndex: number) => {
    if (componentIsMounted.current) {
      sourceIndex.current = srcIndex;
      const newSource = sources[srcIndex];
      if (newSource) {
        setImgSrc(newSource);
        setCounter((c) => c + 1);
      }
    }
  }, []);

  const [imageStatus, setImageStatus] = useState<
    "pending" | "success" | "failure" | undefined
  >();
  const imageLoading = imageStatus === "pending";
  const imageFailed = imageStatus === "failure";

  const onLoad = useCallback(
    (e) => {
      setImageStatus("success");
      props.onLoad && props.onLoad(imgSrc);

      if (props.height && props.width) return;

      const maxHeight = props.maxHeight;
      const maxWidth = props.maxWidth;

      const naturalDimensions = e.nativeEvent.source
        ? e.nativeEvent.source
        : e.nativeEvent;

      const resizedDimensions = calculateResize(naturalDimensions, {
        maxHeight,
        maxWidth,
      });
      setImgDimensions(
        maxHeight && maxWidth ? resizedDimensions : naturalDimensions
      );
    },
    [imgSrc, props.onLoad]
  );

  const onLoadStart = useCallback(() => {
    setImageStatus("pending");
    props.onLoadStart && props.onLoadStart();
  }, [props.onLoadStart]);

  const onError = useCallback(
    (e) => {
      const nextSourceIndex = sourceIndex.current + 1;
      if (nextSourceIndex === sources.length) {
        setImageStatus("failure");
        props.onError && props.onError(e);
      } else {
        trySource(nextSourceIndex);
      }
    },
    [props.onError]
  );

  const imageKey = useMemo(
    () => `${imgSrc.src.uri}-${counter}`,
    [imgSrc.src.uri, counter]
  );

  const retry = useCallback(() => trySource(0), []);

  return (
    <View sx={{}}>
      {/* TODO: check fast image fallback to handle this */}
      {imgSrc.src.uri?.startsWith("ph://") ? (
        <MemoRNImage
          {...props}
          key={imageKey}
          source={imgSrc.src}
          onLoad={onLoad}
          onLoadStart={onLoadStart}
          onError={onError}
          sx={{
            ...props.sx,
            ...imgDimensions,
          }}
        />
      ) : (
        <MemoImage
          {...props}
          key={imageKey}
          source={imgSrc.src}
          onLoad={onLoad}
          onLoadStart={onLoadStart}
          onError={onError}
          sx={{
            ...props.sx,
            ...imgDimensions,
          }}
        />
      )}

      {imageLoading && (
        <Center
          position="absolute"
          left="0"
          top="0"
          bg="gray.50"
          w="full"
          h="full"
        >
          {/* <DelayedSpinner /> */}
        </Center>
      )}
      {imageFailed && (
        <View
          sx={{
            position: "absolute",
            left: 0,
            top: 0,
            height: "100%",
            width: "100%",
          }}
        >
          <BrokenImage onPress={retry} />
        </View>
      )}
    </View>
  );
}

function BrokenImage({ onPress }: { onPress?: () => void | Promise<void> }) {
  const isWeb = Platform.OS === "web";
  return (
    <Pressable
      style={({ pressed }) => ({
        backgroundColor: pressed
          ? DRIPSY_THEME.colors.$gray["100"]
          : DRIPSY_THEME.colors.$gray["50"],
      })}
      sx={{
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
      }}
      onPress={onPress}
    >
      <Text sx={{ textAlign: "center" }}>
        {isWeb ? "Click" : "Tap"} to retry
      </Text>
      <Icon name="image-outline" size="2xl" />
    </Pressable>
  );
}

// Prevents flickering on on load
function DelayedSpinner() {
  const [canShowSpinner, setCanShowSpinner] = useState(false);
  useEffect(() => {
    const timeout = setTimeout(() => {
      setCanShowSpinner(true);
    }, 1000);
    return () => {
      clearTimeout(timeout);
    };
  }, []);
  if (!canShowSpinner) return null;
  return (
    <Column>
      <Spinner />
      <Text sx={{ textAlign: "center" }}>Loading</Text>
    </Column>
  );
}
