import { useSx } from "dripsy";
import { MotiView } from "moti";
import React from "react";

function Skeleton({ sx = {} }) {
  const sxWrapper = useSx();
  return (
    <MotiView
      style={sxWrapper({
        backgroundColor: "$gray.400",
        borderRadius: "md",
        ...sx,
      })}
      from={{ opacity: 0.3 }}
      animate={{ opacity: 0.6 }}
      transition={{ type: "timing", duration: 2000, loop: true }}
    />
  );
}

export default Skeleton;
