import { addHours } from "date-fns";
import ResourceCollection from "./resource";

export interface Poll {
  title: string;
  options: { id: string; value: string }[];
  votes: Record<string, string[]>;
  anonymous: boolean;
  vote_count: number; // 0 --> inifinte
  duration: number;
  expiry?: string;
  // close_time
}

export interface PollNewEventParams {
  title: string;
  options: { id: string; value: string }[];
  anonymous?: boolean;
  vote_count?: number; // 0 -> infinite
  duration?: number;
}

export const POLL_RESOURCE_KEY = "POLL";

function pollExpired(resource: any) {
  return resource.expiry ? new Date(resource.expiry) < new Date() : false;
}

function optionExists(resource: any, option_id: string) {
  return resource.options.findIndex((o) => o.id === option_id) !== -1;
}

function canUserVote(resource: any, user_id: string) {
  // Can always vote if 0 votes (unlimited) or 1 vote (toggle)
  if (!resource.vote_count || resource.vote_count < 2) return true;
  return (
    Object.values(resource.votes)
      .flatMap((user) => user)
      .filter((user) => user === user_id).length < resource.vote_count
  );
}

const pollResource = new ResourceCollection<
  Poll,
  {
    NEW: PollNewEventParams;
    VOTE: {
      option_id: string;
    };
    REMOVE_VOTE: {
      option_id: string;
    };
    ADD_OPTION: {
      option_id: string;
      value: string;
    };
    REMOVE_OPTION: {
      option_id: string;
    };
  }
>(POLL_RESOURCE_KEY, {
  NEW: (_resource, { data }) => {
    const duration = data.duration ?? 0;
    return {
      title: data.title ?? "",
      options: data.options ?? [],
      votes: {},
      anonymous: data.anonymous ?? false,
      vote_count:
        data.vote_count || data.vote_count === 0 ? data.vote_count : 1,
      duration,
      expiry: duration
        ? addHours(new Date(), duration).toISOString()
        : undefined,
    };
  },
  VOTE: (resource, { data, user_id }) => {
    const votes = { ...(resource.votes ?? {}) };
    const { option_id } = data;

    if (pollExpired(resource)) return resource;

    // if cant vote, no op
    if (!canUserVote(resource, user_id)) return resource;

    // If not an option, no op
    if (!optionExists(resource, option_id)) return resource;

    // Remove current vote before adding
    if (resource.vote_count === 1) {
      const currentVote = Object.entries(votes).filter(
        (v) => !!v[1].find((uid) => uid === user_id)
      );
      if (currentVote.length) {
        const [currentVoteOption] = currentVote[0];
        votes[currentVoteOption] = votes[currentVoteOption].filter(
          (uid) => uid !== user_id
        );
        if (!votes[currentVoteOption].length) delete votes[currentVoteOption];
      }
    }

    if (!votes[option_id]) votes[option_id] = [];
    votes[option_id] = [...votes[option_id], user_id];

    const newData = { ...resource, votes };
    return newData;
  },
  REMOVE_VOTE: (resource, { data, user_id }) => {
    const votes = { ...(resource.votes ?? {}) };
    const { option_id } = data;

    if (pollExpired(resource)) return resource;

    // If no votes for option, no op
    if (!votes[option_id]) return resource;

    // If user has no votes on option, no op
    const matchIndex = votes[option_id].findIndex((user) => user === user_id);
    if (matchIndex === -1) return resource;
    votes[option_id] = votes[option_id].splice(matchIndex, 1);
    return { ...resource, votes };
  },
  ADD_OPTION: (resource, { data }) => {
    const options = [...(resource.options ?? [])];
    const { option_id, value } = data;

    if (pollExpired(resource)) return resource;

    if (optionExists(resource, option_id)) return resource;

    if (!value?.trim()) return resource;

    options.push({ id: option_id, value });

    return { ...resource, options };
  },
  REMOVE_OPTION: (resource, { data }) => {
    const options = [...(resource.options ?? [])];
    const votes = { ...(resource.votes ?? {}) };
    const { option_id } = data;

    if (pollExpired(resource)) return resource;

    if (!optionExists(resource, option_id)) return resource;

    delete votes[option_id];

    return {
      ...resource,
      options: options.filter((x) => x.id !== option_id),
      votes,
    };
  },
});

export default pollResource;
