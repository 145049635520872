import { BottomTabHeaderProps } from "@react-navigation/bottom-tabs";
import { IconButton, View, Text, SafeAreaView, Button } from "./basics";
import SpaceAppIcon from "./SpaceAppIcon";
import { Header } from "@react-navigation/elements";
import { useSpace } from "../hooks/spaces";
import SpaceFilterSelector, {
  bindSpaceFilterNavigator,
  toggleSpaceFilterSelector,
} from "./SpaceFilterSelector";
import { useFocusEffect } from "@react-navigation/native";

export default function SpaceAppHeader({
  options,
  route,
  navigation,
}: BottomTabHeaderProps) {
  const { title } = options;
  const { spaceId } = route.params ?? {};
  const { name: appName } = route;
  const hideFilter = !!["geo-genius", "le-word"].includes(appName);
  const { data: space } = useSpace(spaceId);
  /*
   * Hooks don't work in Headers so we need to find another way to show space name
   */

  useFocusEffect(() => {
    bindSpaceFilterNavigator(navigation);
  });

  return (
    <Header
      headerTitleAlign="center"
      headerLeft={() => (
        <View sx={{ width: 50, flexShrink: 0, alignItems: "center" }}>
          <SpaceAppIcon appName={appName} />
        </View>
      )}
      headerTitle={() => (
        <View
          sx={{
            flexGrow: 1,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {appName !== "le-word" && (
            <Text
              sx={{
                textAlign: "center",
                fontSize: 18,
                fontWeight: "medium",
              }}
            >
              {title}
            </Text>
          )}
          {spaceId ? (
            <Button
              onPress={() => {
                navigation.navigate("Chat", {
                  screen: "conversation",
                  params: { spaceId },
                });
              }}
              rightIcon="log-out-outline"
              variant="unstyled"
              _containerStyles={{
                paddingX: 0,
                paddingY: 0,
                paddingBottom: "$1",
              }}
              _textStyles={{ color: "$coolGray.500" }}
              _iconStyles={{ color: "$coolGray.500" }}
            >
              {space?.display_name ?? "Filtered"}
            </Button>
          ) : null}
        </View>
      )}
      headerRight={() =>
        hideFilter ? null : (
          <View sx={{ width: 50, flexShrink: 0, alignItems: "center" }}>
            <IconButton
              icon={"filter" + (spaceId ? "" : "-outline")}
              colorScheme="$gray"
              variant="ghost"
              onPress={() => {
                toggleSpaceFilterSelector();
              }}
            />
          </View>
        )
      }
    />
  );
}
