import { Session } from "@supabase/supabase-js";
import React, {
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from "react";
import supabase from "../lib/supabase";
import * as Sentry from "sentry-expo";
import { Platform } from "react-native";
import { joinOnAuth } from "../navigation/LinkingConfiguration";

export const SessionContext = React.createContext<Session | null>(null);

export function SessionProvider({ children }: PropsWithChildren<{}>) {
  const [session, setSession] = useState<Session | null>(
    supabase.auth.session()
  );

  console.log("sesh", session);

  useEffect(() => {
    supabase.auth.onAuthStateChange((_, sesh) => {
      if (sesh) {
        if (Platform.OS === "web") {
          Sentry.Browser.setUser({ id: sesh.user?.id });
        } else {
          Sentry.Native.setUser({ id: sesh.user?.id });
          if (sesh.user) joinOnAuth(sesh.user.id);
        }
      }
      setSession(sesh);
    });
  }, []);

  return (
    <SessionContext.Provider value={session}>
      {children}
    </SessionContext.Provider>
  );
}

export default function useSession() {
  return useContext(SessionContext);
}
