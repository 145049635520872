import AsyncStorage from "@react-native-async-storage/async-storage";
import { v4 as uuidv4 } from "uuid";

const STORAGE_KEY = "device-session-id";

let deviceId: string;

export async function getDeviceId() {
  if (deviceId) return deviceId;
  let storedSessionId = await AsyncStorage.getItem(STORAGE_KEY);
  if (!storedSessionId) {
    deviceId = uuidv4();
    await AsyncStorage.setItem(STORAGE_KEY, deviceId);
  } else {
    deviceId = storedSessionId;
  }
  return deviceId;
}

export function getDeviceIdSync() {
  return deviceId ? deviceId : undefined;
}
