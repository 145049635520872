import { useEffect } from "react";
import { pushReadReceipt } from "../state/utils/unread-messages";
import { useUser } from "./auth";
import useSpaceLatestEventTimestamp from "./useSpaceLatestEventTimestamp";

export let currentSpaceView: string | undefined = undefined;

export function useSpaceReadReceipts(spaceId: string) {
  const currentUser = useUser();
  const latestEventTimestamp = useSpaceLatestEventTimestamp(spaceId);

  // When we enter the space, load the latest event and push read receipt
  // Should trigger on load and cache updates
  useEffect(() => {
    if (latestEventTimestamp && currentUser?.id && spaceId) {
      pushReadReceipt(spaceId, currentUser.id, latestEventTimestamp);
    }
  }, [latestEventTimestamp, currentUser?.id, spaceId]);

  // In case the latestEventTimestamp is behind, push current timestamp on space entry
  useEffect(() => {
    if (currentUser?.id && spaceId) {
      pushReadReceipt(spaceId, currentUser.id, new Date().toISOString());
    }
  }, []);

  useEffect(() => {
    currentSpaceView = spaceId;
    return () => {
      currentSpaceView = undefined;
    };
  }, [spaceId]);
}
