import AsyncStorage from "@react-native-async-storage/async-storage";
import { createClient } from "@supabase/supabase-js";
import { parsePhoneNumber } from "../utils/phone";
import Constants from "expo-constants";

export const SUPABASE_URL = Constants.manifest?.extra?.SUPABASE_API_URL;
export const SUPABASE_KEY = Constants.manifest?.extra?.SUPABASE_ANON_KEY;
const supabase = createClient(SUPABASE_URL, SUPABASE_KEY, {
  localStorage: AsyncStorage,
  autoRefreshToken: true,
});
export default supabase;

// NOTE: redirecting to prod app
const authRedirect = "https://aspen.cloud/profile-confirmed";

export async function signInWithEmail(email: string, password: string) {
  const { user, error } = await supabase.auth.signIn({
    email: email,
    password: password,
  });
  if (error) {
    throw new Error(error.message);
  }
  return user;
}

export async function signUp(email: string) {
  const { user, error } = await supabase.auth.signIn(
    {
      email: email,
    },
    {
      redirectTo: authRedirect,
      shouldCreateUser: true,
    }
  );
  if (error) throw new Error(error.message);

  return user;
}

export async function signInWithPhone(phone: string, password: string) {
  const parsedPhone = parsePhoneNumber(phone);
  if (!parsedPhone) throw new Error("Invalid phone number");
  const { user, error } = await supabase.auth.signIn({
    phone: parsedPhone,
    password: password,
  });
  if (error) throw new Error(error.message);
  return user;
}

export async function signOut() {
  const { error } = await supabase.auth.signOut();
  if (error) throw new Error(error.message);
}

export async function sendPhoneVerification(phone: string) {
  const parsedPhone = parsePhoneNumber(phone);
  if (!parsedPhone) throw new Error("Invalid phone number");
  const { data, error } = await supabase.auth.api.sendMobileOTP(parsedPhone);
  if (error) throw new Error(error.message);
}

export async function updateUserEmail(email: string) {
  const { user, error } = await supabase.auth.update({
    email,
  });
  if (error) throw new Error(error.message);
}
