import { View } from "../components/basics";
import { Keyboard, Platform, TouchableWithoutFeedback } from "react-native";

const DismissKeyboardView: React.FC = ({ children }) => {
  return (
    <TouchableWithoutFeedback
      onPress={() => {
        if (Platform.OS !== "web") Keyboard.dismiss();
      }}
    >
      <View sx={{flex: 1}}>{children}</View>
    </TouchableWithoutFeedback>
  );
};

export default DismissKeyboardView;
