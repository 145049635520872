import React from "react";
import { Ionicons } from "@expo/vector-icons";

export function getIconForApp(
  appName: string
): React.ComponentProps<typeof Ionicons>["name"] {
  switch (appName) {
    case "Chat":
      return "chatbubble";
    case "ShoppingList":
    case "shopping-list":
      return "cart";
    case "Photos":
      return "images-outline";
    case "Pages":
      return "document-text-outline";
    case "schedule":
    case "Schedule":
      return "calendar-outline";
    case "le-word":
      return "game-controller-outline";
    case "LeWordLeaderboard":
    case "le-word-leaderboard":
      return "trophy-outline";
    case "videochat":
    case "Videochat":
    case "VideoChat":
      return "videocam-outline";
    case "Reminders":
    case "reminders":
      return "notifications-outline";
    case "geo-genius":
      return "globe-outline";
    case "Polls":
    case "polls":
      return "bar-chart-outline";
    default:
      return "help-buoy";
  }
}

export function getTitleForApp(appName: string): string {
  switch (appName) {
    case "Chat":
      return "Conversations";
    case "ShoppingList":
    case "shopping-list":
      return "Shopping List";
    case "Photos":
      return "Photos";
    case "Pages":
      return "Notes";
    case "schedule":
    case "Schedule":
      return "Calendar";
    case "le-word":
      return "Le Word";
    case "LeWordLeaderboard":
    case "le-word-leaderboard":
      return "Le Word";
    case "videochat":
    case "Videochat":
    case "VideoChat":
      return "Video Chat";
    case "Reminders":
    case "reminders":
      return "Reminders";
    case "geo-genius":
      return "Geo Genius";
    case "Polls":
    case "polls":
      return "Polls";
    default:
      return "App";
  }
}
