import AsyncStorage from "@react-native-async-storage/async-storage";
import { Cache } from "react-native-cache";

const webAppCache = new Cache({
  namespace: "space-web-apps",
  policy: {
    maxEntries: 50000,
    stdTTL: 86400, // 1 day, could set to 0 if we want no expiry
  },
  backend: AsyncStorage,
});

export default webAppCache;
