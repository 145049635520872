import { useCallback, useEffect } from "react";
import { useRecoilState } from "recoil";
import { sharedValuesState } from "../state/atoms/shared-values";

// NOTE: Manual eviction is required, if not could cause memory leaks
// TODO: improve default value, currently will start as undefined
export default function useSharedNavigationValue<T>(
  id: string,
  defaultValue?: T
): [T, (val: T) => void, () => void] {
  const [values, setValues] = useRecoilState(sharedValuesState);
  const value = values[id] as T;
  const setValue = useCallback(
    (val: T) => {
      setValues((prev) => ({ ...prev, [id]: val }));
    },
    [id]
  );
  const evict = useCallback(() => {
    setValues((prev) => {
      const { [id]: _val, ...next } = prev;
      return next;
    });
  }, [id]);

  useEffect(() => {
    if (!(id in values) && defaultValue) {
      setValue(defaultValue);
    }
  }, []);

  return [value, setValue, evict];
}
