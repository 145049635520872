import { Text, Row, Column, Pressable } from "../components/basics";
import React, { ComponentProps, useMemo } from "react";
import { SpaceMembersInfoFragment } from "../state/generated/graphql";
import PersonAvatar, { PersonAvatarProps } from "./PersonAvatar";
import DRIPSY_THEME from "../constants/DripsyTheme";
import { useConnection, useConnectionQuery } from "../hooks/useConnections";
import { Person, phoneToPerson } from "../utils/people";
import { StyleSheet } from "react-native";

export function MemberSectionItem({
  member,
  onPress,
  direction = "row",
}: {
  direction?: "row" | "column";
  member: SpaceMembersInfoFragment;
  onPress?: () => void | Promise<void>;
}) {
  return member.invitation?.status !== "PENDING" ? (
    <ActiveMemberItem direction={direction} member={member} onPress={onPress} />
  ) : (
    <PendingMemberItem
      direction={direction}
      member={member}
      onPress={onPress}
    />
  );
}

function ActiveMemberItem({
  member,
  onPress,
  direction,
}: {
  member: SpaceMembersInfoFragment;
  onPress?: () => void | Promise<void>;
  direction?: "row" | "column";
}) {
  const person = useConnection(member.user_id, "PROFILE");
  return (
    <PersonSectionItem
      person={person}
      onPress={onPress}
      direction={direction}
    />
  );
}

function PendingNameText({ value }: { value: string }) {
  return (
    <Column>
      <Text sx={{ fontSize: "md", textAlign: "center" }}>{value}</Text>
      <Text sx={{ fontSize: "sm", textAlign: "center", fontStyle: "italic" }}>
        Pending
      </Text>
    </Column>
  );
}

function PendingMemberItem({
  member,
  onPress,
  direction,
}: {
  member: SpaceMembersInfoFragment;
  onPress?: () => void | Promise<void>;
  direction?: "row" | "column";
}) {
  const connectionQuery = useConnectionQuery();
  const person = useMemo(() => {
    const profile = connectionQuery("profiles_by_id", member.user_id);
    if (profile) return profile;
    const phone =
      member?.invitation?.external_metadata?.type === "PHONE"
        ? member?.invitation?.external_metadata?.value
        : undefined;
    if (phone) {
      const contact = phone
        ? connectionQuery("contacts_by_phone", phone)
        : undefined;
      if (contact) return contact;
      return phoneToPerson(phone);
    }

    return undefined;
  }, [member, connectionQuery]);

  if (person) {
    return (
      <PersonSectionItem
        direction={direction}
        person={person}
        textOverride={<PendingNameText value={person.name} />}
        onPress={onPress}
      />
    );
  }
  return null;
}

export const SectionItemWrapper: React.FC<
  ComponentProps<typeof Pressable> & { direction?: "row" | "column" }
> = ({ children, direction = "row", sx, ...props }) => {
  return (
    <Pressable
      sx={{
        display: "flex",
        // marginTop: "$2",
        // paddingRight: "$2",
        flexDirection: "row",
        ...sx,
      }}
      {...props}
    >
      <Row
        direction={direction}
        space="3"
        alignItems={"center"}
        marginLeft={direction === "row" ? "2" : undefined}
        flex={1}
      >
        {children}
      </Row>
    </Pressable>
  );
};

interface PersonSectionItemProps extends ComponentProps<typeof Pressable> {
  person: Person;
  textOverride?: React.ReactNode;
  textProps?: ComponentProps<typeof Text>;
  avatarProps?: Omit<PersonAvatarProps, "person">;
  direction?: "row" | "column";
  shortenName?: boolean;
}

export const PersonSectionItem: React.FC<PersonSectionItemProps> = ({
  person,
  textOverride,
  textProps,
  avatarProps,
  shortenName = false,
  ...props
}) => {
  return (
    <SectionItemWrapper
      style={({ pressed }) => ({
        opacity: pressed ? 0.7 : 1,
      })}
      sx={{
        // margin: 8,
        // marginBottom: 0,
        // marginTop: 8,
        paddingY: "$2",
        paddingLeft: "$1",
        backgroundColor: "$white",
        borderColor: "$coolGray.200",
        borderBottomWidth: "hairline",
        // borderRadius: 8,
        ...(props.direction === "column"
          ? { minWidth: 90 }
          : props.direction === "row"
          ? { height: 72 }
          : {}),
      }}
      {...props}
    >
      <PersonAvatar size={40} person={person} />
      {textOverride ?? (
        <Text
          numberOfLines={2}
          {...textProps}
          sx={{
            fontSize: "md",
            textAlign: "center",
          }}
        >
          {person?.name ?? "N/A"}
        </Text>
      )}
    </SectionItemWrapper>
  );
};
