// import { Avatar } from "native-base";
import { Sx } from "dripsy";
import { PropsWithChildren } from "react";
import { ImageSourcePropType } from "react-native";
import { View, Image, Text } from "../basics";

export default function Avatar({
  source,
  size = 50,
  sx,
  children,
}: PropsWithChildren<{
  source: ImageSourcePropType;
  size: number;
  sx?: Sx;
}>) {
  const borderRadiusStyle =
    sx?.borderRadius != undefined
      ? {
          borderRadius: sx.borderRadius,
        }
      : {};
  return (
    <View
      sx={{
        width: size,
        height: size,
        borderRadius: size,
        position: "relative",
        ...sx,
      }}
    >
      <View
        sx={{
          position: "absolute",
          flex: 1,
          zIndex: 1,
          justifyContent: "center",
          alignItems: "center",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
        }}
      >
        {children}
      </View>
      <Image
        sx={{
          position: "absolute",
          zIndex: 2,
          flex: 1,
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          borderRadius: size,
          overflow: "hidden",
          ...borderRadiusStyle,
        }}
        source={source}
        key={source}
      />
    </View>
  );
}
