import React, { useContext, useEffect, useState } from "react";
import { getDeviceId } from "../utils/device-id";

export const DeviceSessionContext = React.createContext<string | undefined>(
  undefined
);

export function useDeviceSessionIdLoader() {
  const [sessionId, setSessionId] = useState<string>();
  useEffect(() => {
    (async () => {
      const deviceId = await getDeviceId();
      setSessionId(deviceId);
    })();
  }, []);
  return sessionId;
}

export function useDeviceSessionId() {
  return useContext(DeviceSessionContext);
}
