import "react-native-gesture-handler";
import * as Sentry from "sentry-expo";
import { StatusBar } from "expo-status-bar";
import { DripsyProvider } from "dripsy";
import { ColorModeOptions, extendTheme, NativeBaseProvider } from "native-base";
import React, { useEffect } from "react";
import { SafeAreaProvider } from "react-native-safe-area-context";
import { RecoilRoot } from "recoil";
import { Provider as GraphqlProvider } from "urql";
import * as Notifications from "expo-notifications";

import useCachedResources from "./hooks/useCachedResources";
import useColorScheme from "./hooks/useColorScheme";
import Navigation from "./navigation";
import client from "./state/graphql/client";
import { LinearGradient } from "expo-linear-gradient";
import { enableScreens } from "react-native-screens";
import { LogBox, Platform } from "react-native";
import { NotificationsProvider } from "./hooks/useNotifications";
import { ActionSheetProvider } from "@expo/react-native-action-sheet";
import { SessionProvider } from "./hooks/useSession";
import { UserAccountProvider } from "./hooks/auth";
import { ThemeProvider } from "./hooks/theme";
import { flushUploadQueue } from "./utils/image-uploads";
import useUpdateCheck from "./hooks/useUpdateCheck";
import { DeviceSessionContext, useDeviceSessionIdLoader } from "./hooks/device";
import DRIPSY_THEME from "./constants/DripsyTheme";
import { flushEventOutbox } from "./lib/event-outbox";
import { loadUploadQueueIntoMemory } from "./lib/upload-queue";
import { GestureHandlerRootView } from "react-native-gesture-handler";
import { ToastContextProvider } from "./hooks/useToast";
import { ContactsProvider } from "./hooks/useContacts";
import { enableLegendStateReact } from "@legendapp/state/react";
import { Audio } from "expo-av";

enableLegendStateReact();

enableScreens();
LogBox.ignoreLogs(["timer"]);
// https://github.com/GeekyAnts/NativeBase/issues/4273 ignoring native base warnings (temp until fixed on their end)
LogBox.ignoreLogs([
  "When server rendering, you must wrap your application in an",
]);

Sentry.init({
  dsn: "https://3cde7e24bf33485fa0a312c744af7c7d@o417382.ingest.sentry.io/6253348",
  enableInExpoDevelopment: false,
  // debug: __DEV__,
  environment: __DEV__ ? "development" : "production",
});

const config: ColorModeOptions = {
  useSystemColorMode: false,
  initialColorMode: "light",
};

// extend the theme
const customTheme = extendTheme({ config });

type DripType = typeof DRIPSY_THEME;

declare module "dripsy" {
  interface DripsyCustomTheme extends DripType {}
}

export default function App() {
  const isLoadingComplete = useCachedResources();
  const updateHandled = useUpdateCheck();
  const deviceSessionId = useDeviceSessionIdLoader();
  const colorScheme = useColorScheme();

  // Related: https://github.com/expo/expo/issues/7485
  useEffect(() => {
    Audio.setAudioModeAsync({ playsInSilentModeIOS: true });
  });

  useEffect(() => {
    let outboxInterval: NodeJS.Timer | null = null;
    loadUploadQueueIntoMemory().then(() => {
      flushUploadQueue().then(() => {
        outboxInterval = setInterval(() => {
          flushEventOutbox();
        }, 1000 * 5);
      });
    });

    return () => {
      if (outboxInterval) clearInterval(outboxInterval);
    };
  }, []);

  const appReady = isLoadingComplete && updateHandled && deviceSessionId;

  if (!appReady) {
    return null;
  } else {
    return (
      <DeviceSessionContext.Provider value={deviceSessionId}>
        <ContactsProvider>
          <NotificationsProvider>
            <SessionProvider>
              <UserAccountProvider>
                <GraphqlProvider value={client}>
                  <RecoilRoot>
                    <GestureHandlerRootView style={{ flex: 1 }}>
                      <DripsyProvider theme={DRIPSY_THEME}>
                        <NativeBaseProvider
                          theme={customTheme}
                          config={{
                            dependencies: {
                              "linear-gradient": LinearGradient,
                            },
                          }}
                        >
                          <ActionSheetProvider>
                            <SafeAreaProvider>
                              <ToastContextProvider>
                                <Navigation colorScheme={colorScheme} />
                                <StatusBar
                                  backgroundColor="#FFF"
                                  style="dark"
                                  translucent={true}
                                />
                              </ToastContextProvider>
                            </SafeAreaProvider>
                          </ActionSheetProvider>
                        </NativeBaseProvider>
                      </DripsyProvider>
                    </GestureHandlerRootView>
                  </RecoilRoot>
                </GraphqlProvider>
              </UserAccountProvider>
            </SessionProvider>
          </NotificationsProvider>
        </ContactsProvider>
      </DeviceSessionContext.Provider>
    );
  }
}
