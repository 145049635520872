import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import {
  createNativeStackNavigator,
  NativeStackHeaderProps,
  NativeStackScreenProps,
} from "@react-navigation/native-stack";
import SpaceAppIcon from "../components/SpaceAppIcon";
import { BackButton, Spinner, IconButton, Image } from "../components/basics";
import React, { useEffect, useMemo, useState } from "react";
import { InteractionManager, Platform } from "react-native";
import SpacesScreenHeader from "../components/SpacesScreenHeader";
import { useSpace } from "../hooks/spaces";

import useColorScheme from "../hooks/useColorScheme";
import useHandleSpaceNotifications from "../hooks/useHandleSpaceNotifications";
import useIsLargeScreen from "../hooks/useIsLargeScreen";
import { SpaceIdContext } from "../hooks/useSpaceId";
import { pushReadReceipt } from "../state/utils/unread-messages";
import {
  RootStackParamList,
  SpacesTabParamList,
  SpaceStackParamList,
} from "../types";
import ChatScreen from "./spaces/ChatScreen";
import PagesScreen from "./spaces/PagesScreen";
import PhotosScreen from "./spaces/PhotosScreen";
import SpaceEditScreen from "./spaces/SpaceEditScreen";
import CenteredSpinner from "../components/basics/CenteredSpinner";
import Icon from "../components/basics/Icon";
import WebSpaceApp from "./spaces/WebSpaceApp";
import { useUser } from "../hooks/auth";
import { useSpaceMember } from "../hooks/useSpaceMembers";
import { useSpaceReadReceipts } from "../hooks/useSpaceReadReceipts";
import { ChatStack } from "./ChatScreen";
import { useNavigation } from "@react-navigation/native";

const BottomTab = createBottomTabNavigator<SpacesTabParamList>();

const Stack = createNativeStackNavigator();

export default React.memo(SpaceScreen);
const MemoMainScreen = React.memo(SpaceMainScreen);
function SpaceScreen({
  route,
}: NativeStackScreenProps<RootStackParamList, "Space">) {
  const isLargeScreen = useIsLargeScreen();
  const { spaceId } = route.params;
  const { loading, data: space } = useSpace(spaceId);
  const navigation = useNavigation();
  const chatStackState = navigation.getState();
  const conversationOnlyStack =
    chatStackState.routes[0].name === "conversation";
  useEffect(() => {
    if (conversationOnlyStack)
      navigation.reset({
        index: 1,
        routes: [
          { name: "chat-list" },
          { name: "conversation", params: route.params },
        ],
      });
  }, [conversationOnlyStack, navigation]);
  useHandleSpaceNotifications(spaceId);
  useSpaceReadReceipts(spaceId);

  useEffect(() => {
    navigation.setOptions({
      title: space?.display_name,
    });
  }, [space]);

  return (
    <SpaceIdContext.Provider value={spaceId}>
      <ChatScreen spaceId={spaceId} />
    </SpaceIdContext.Provider>
  );

  if (loading || !space)
    return (
      <CenteredSpinner text="Loading Space" spinnerProps={{ size: "lg" }} />
    );

  return (
    <SpaceIdContext.Provider value={spaceId}>
      <Stack.Navigator
        screenOptions={{
          header: (props: NativeStackHeaderProps) => (
            <SpacesScreenHeader {...props} />
          ),
        }}
      >
        {space && space.apps && space.apps.length > 0 ? (
          <Stack.Screen
            name="SpaceMain"
            component={MemoMainScreen}
            initialParams={{ spaceId }}
            options={({ route, navigation }) => ({
              headerShown: true,
              headerLeft: () => (isLargeScreen ? null : <BackButton />),
              headerRight: () => (
                <IconButton
                  onPress={() => {
                    navigation.navigate("SpaceEdit", {
                      spaceId: route.params?.spaceId,
                    });
                  }}
                  size="lg"
                  icon="settings-outline"
                  variant="unstyled"
                  _iconStyles={{ color: "$darkText" }}
                />
              ),
            })}
          />
        ) : null}
        <Stack.Screen
          name="SpaceEdit"
          navigationKey={space ? "pre-space" : "loaded"}
          component={SpaceEditScreen}
          initialParams={{ spaceId }}
          options={({ navigation }) => ({
            title: `Space settings`,
            headerLeft: () => (isLargeScreen ? null : <BackButton />),
          })}
        />
      </Stack.Navigator>
    </SpaceIdContext.Provider>
  );
}

function SpaceMainScreen({
  route,
  navigation,
}: NativeStackScreenProps<SpaceStackParamList, "SpaceMain">) {
  const colorScheme = useColorScheme();
  const { spaceId } = route.params;
  const { data: space } = useSpace(spaceId);
  const isLargescreen = useIsLargeScreen();
  useHandleSpaceNotifications(spaceId);
  useSpaceReadReceipts(spaceId);

  useEffect(() => {
    navigation.setOptions({
      title: space?.display_name,
    });
  }, [space]);

  const appNames: Set<string> = useMemo(() => {
    return new Set(
      space?.apps?.map((spaceApp) => {
        const appName = spaceApp?.app?.name ?? spaceApp?.app_name;
        return appName;
      })
    );
  }, [space]);

  const [shouldRender, setShouldRender] = useState(false);

  useEffect(() => {
    InteractionManager.runAfterInteractions(() => {
      setShouldRender(true);
    });
  }, []);

  return <ChatStack spaceId={spaceId} />;

  return space && shouldRender ? (
    <>
      <BottomTab.Navigator
        initialRouteName={"Chat"}
        screenOptions={{
          tabBarHideOnKeyboard: false,
          headerShown: false,
          tabBarLabelPosition: isLargescreen ? "beside-icon" : "below-icon",
        }}
      >
        {appNames.has("Chat") && (
          <BottomTab.Screen
            name="Chat"
            component={ChatScreen}
            initialParams={{ spaceId: space.id }}
            options={{
              title: "Chat",
              tabBarIcon: ({ focused }) => (
                <SpaceAppIcon focused={focused} appName="chat" />
              ),
            }}
          />
        )}
        {appNames.has("ShoppingList") && (
          <BottomTab.Screen
            name="shopping-list"
            component={WebSpaceApp}
            initialParams={{ spaceId: space.id }}
            options={{
              title: "Shopping List",
              tabBarIcon: ({ focused }) => (
                <SpaceAppIcon focused={focused} appName="shopping-list" />
              ),
            }}
          />
        )}
        {appNames.has("Photos") && (
          <BottomTab.Screen
            name="Photos"
            component={PhotosScreen}
            initialParams={{ spaceId: space.id }}
            options={{
              title: "Photos",
              tabBarIcon: ({ focused }) => (
                <SpaceAppIcon focused={focused} appName="photos" />
              ),
            }}
          />
        )}
        {appNames.has("Pages") && (
          <BottomTab.Screen
            name="Pages"
            component={PagesScreen}
            initialParams={{ spaceId: space.id }}
            options={{
              title: "Notes",
              tabBarIcon: ({ focused }) => (
                <SpaceAppIcon focused={focused} appName="notes" />
              ),
            }}
          />
        )}
        {appNames.has("Schedule") && (
          <BottomTab.Screen
            name="schedule"
            component={WebSpaceApp}
            initialParams={{ spaceId: space.id }}
            options={{
              title: "Schedule",
              tabBarIcon: ({ focused }) => (
                <SpaceAppIcon focused={focused} appName="schedule" />
              ),
            }}
          />
        )}
        {appNames.has("VideoChat") && (
          <BottomTab.Screen
            name="videochat"
            component={WebSpaceApp}
            initialParams={{ spaceId: space.id }}
            options={{
              title: "Video Chat",
              tabBarIcon: ({ focused }) => (
                <SpaceAppIcon focused={focused} appName="videochat" />
              ),
            }}
          />
        )}

        {appNames.has("Recipes") && (
          <BottomTab.Screen
            name="recipes"
            component={WebSpaceApp}
            initialParams={{ spaceId: space.id }}
            options={{
              title: "Recipes",
              tabBarIcon: ({ color }) => (
                <Icon name="beaker-outline" size={"2xl"} color={color} />
              ),
            }}
          />
        )}
        {appNames.has("LeWordLeaderboard") && (
          <BottomTab.Screen
            name="le-word"
            component={WebSpaceApp}
            initialParams={{ spaceId: space.id }}
            options={{
              title: "Le Word",
              tabBarIcon: ({ focused }) => (
                <SpaceAppIcon focused={focused} appName="le-word" />
              ),
            }}
          />
        )}
        {appNames.has("Reminders") && (
          <BottomTab.Screen
            name="reminders"
            component={WebSpaceApp}
            initialParams={{ spaceId: space.id }}
            options={{
              title: "Reminders",
              tabBarIcon: ({ focused }) => (
                <SpaceAppIcon focused={focused} appName="reminders" />
              ),
            }}
          />
        )}
        {appNames.has("Polls") && (
          <BottomTab.Screen
            name="polls"
            component={WebSpaceApp}
            initialParams={{ spaceId: space.id }}
            options={{
              title: "Polls",
              tabBarIcon: ({ focused }) => (
                <SpaceAppIcon focused={focused} appName="polls" />
              ),
            }}
          />
        )}
      </BottomTab.Navigator>
    </>
  ) : (
    <Spinner flex="1" accessibilityLabel="Loading space" />
  );
}
