import { v4 as uuidv4 } from "uuid";
import {
  DeleteInvitationDocument,
  InsertInvitationsDocument,
  UpdateInvitationStatusDocument,
} from "../state/generated/graphql";
import client from "../state/graphql/client";

type ExternalInviteType = "EMAIL" | "PHONE";

export interface InvitationCreationInfo {
  inviter_id: string;
  status: string;
  external_metadata?: Record<string, any>;
  user_id?: string;
}

export async function inviteToSpace(
  spaceId: string,
  inviterId: string,
  profileIds: string[],
  externalInvites: { type: ExternalInviteType; value: string }[]
) {
  const profileInvitations = profileIds.map((pid) => ({
    user_id: pid,
    space_id: spaceId,
    status: "PENDING",
    inviter_id: inviterId,
  }));
  const externalInvitations = externalInvites.map((invite) => ({
    external_metadata: invite,
    space_id: spaceId,
    status: "PENDING",
    inviter_id: inviterId,
  }));

  return await client
    .mutation(InsertInvitationsDocument, {
      invitations: [...profileInvitations, ...externalInvitations],
    })
    .toPromise();
}

export function generateProfileInvitations(
  inviterId: string,
  profileIds: string[],
  spaceId?: string
) {
  return profileIds.map((pid) => ({
    user_id: pid,
    space_id: spaceId,
    status: "PENDING",
    inviter_id: inviterId,
  }));
}

export function generateExternalInvitations(
  inviterId: string,
  externalInvites: { type: ExternalInviteType; value: string }[],
  spaceId?: string
) {
  return externalInvites.map((invite) => ({
    external_metadata: invite,
    space_id: spaceId,
    status: "PENDING",
    inviter_id: inviterId,
  }));
}

export async function acceptInvitation(invitationId: string) {
  return await client
    .mutation(UpdateInvitationStatusDocument, {
      id: invitationId,
      status: "ACCEPTED",
    })
    .toPromise();
}

export async function declineInvitation(invitationId: string) {
  return await client
    .mutation(UpdateInvitationStatusDocument, {
      id: invitationId,
      status: "DECLINED",
    })
    .toPromise();
}

export async function deleteInvitation(invitationId: string) {
  return await client
    .mutation(DeleteInvitationDocument, {
      id: invitationId,
    })
    .toPromise();
}
