import React, { useCallback, useMemo, useState } from "react";
import { BubbleProps as ChatBubbleProps } from "react-native-gifted-chat";
import { usePushEvent } from "../../hooks/usePushEvent";
import { View, Text, Pressable, Icon, Spinner } from "../basics";
import { isSameUser, isSameDay } from "react-native-gifted-chat/lib/utils";
import { useUser } from "../../hooks/auth";
import { AspenChatMessage } from "../../screens/spaces/ChatScreen";
import MessageMedia from "./MessageMedia";
import useDoublePress from "../../hooks/useDoublePress";
import { CHAT_RESOURCE_KEY } from "../../resources/chat";
import { MotiView } from "moti";
import { useMessageMenu } from "./MessageMenu";
import { MessageText } from "./MessageText";
import { format } from "date-fns";
import Lottie from "lottie-react-native";
import { Platform } from "react-native";
import {
  cancelDelayedEvents,
  clearRetryNotifStatus,
  flushEventOutbox,
} from "../../lib/event-outbox";
import { flushUploadQueue } from "../../utils/image-uploads";
import { evict } from "../../lib/pending-resources";
import { deleteFromUploadQueueBulk } from "../../lib/upload-queue";
import useUploadQueue from "../../hooks/useUploadQueue";
import {
  ActionSheetOptions,
  useActionSheet,
} from "@expo/react-native-action-sheet";
import { useReactionDrawer } from "./ReactionDrawer";

export default React.memo(Bubble);

type BubbleProps = ChatBubbleProps<AspenChatMessage> & {
  onCreationSelect: (key: string, payload: string) => void;
};

type MessageBlockPosition = "start" | "middle" | "end" | "alone";

const BORDER_RADIUS = "lg";

function Bubble(props: BubbleProps) {
  const { currentMessage, nextMessage, position, onCreationSelect } = props;
  const pushEvent = usePushEvent();
  const currentUser = useUser();
  const [lastPressed, setLastPressed] = useState<string | null>(null);

  const reactions = useMemo(() => {
    return Object.entries(props.currentMessage?.reactions ?? {}).filter(
      ([_emoji, user_ids]) => user_ids.length > 0
    );
  }, [props.currentMessage]);

  const doublePressHandler = useDoublePress();
  const onMessageDoublePress = useCallback(() => {
    if (!props.currentMessage?.pending) {
      const likes =
        (props.currentMessage?.reactions &&
          props.currentMessage?.reactions["LIKE"]) ??
        [];
      const likedByUser = likes.includes(currentUser!.id);
      if (!likedByUser) setLastPressed("LIKE");
      console.log(likedByUser ? "REMOVE_REACTION" : "ADD_REACTION");
      pushEvent({
        resource_key: CHAT_RESOURCE_KEY,
        type: likedByUser ? "REMOVE_REACTION" : "ADD_REACTION",
        data: { type: "LIKE", user_id: currentUser!.id },
        resource_id: props.currentMessage!._id as string,
      });
    }
  }, [pushEvent, props.currentMessage]);

  const openMenu = useMessageMenu();
  const openReactionDrawer = useReactionDrawer();

  const addReaction = useCallback(
    (reaction: string) => {
      setLastPressed(reaction);
      pushEvent({
        resource_key: CHAT_RESOURCE_KEY,
        type: "ADD_REACTION",
        data: {
          type: reaction === "❤️" ? "LIKE" : reaction,
          user_id: currentUser!.id,
        },
        resource_id: props.currentMessage!._id as string,
      });
    },
    [currentUser, currentMessage?._id]
  );

  const removeReaction = useCallback(
    (reaction: string) => {
      setLastPressed(null);
      pushEvent({
        resource_key: CHAT_RESOURCE_KEY,
        type: "REMOVE_REACTION",
        data: {
          type: reaction === "❤️" ? "LIKE" : reaction,
          user_id: currentUser!.id,
        },
        resource_id: props.currentMessage!._id as string,
      });
    },
    [currentUser]
  );

  const selectedReactions = useMemo(
    () =>
      reactions
        .filter(([_emoji, userIds]) => userIds.includes(currentUser.id))
        .map(([emoji]) => emoji),
    [reactions]
  );

  const onMessageLongPress = useCallback(
    (e) => {
      openMenu(
        e.nativeEvent.pageY,
        {
          onSelect: (emoji) => {
            const reaction = emoji === "❤️" ? "LIKE" : emoji;
            if (selectedReactions.includes(reaction)) {
              removeReaction(reaction);
            } else {
              addReaction(reaction);
            }
          },
          selected: selectedReactions.map((reaction) =>
            reaction === "LIKE" ? "❤️" : reaction
          ),
        },
        {
          currentMessage: props.currentMessage,
          onCreationSelect: onCreationSelect,
        }
      );
    },
    [openMenu, selectedReactions, props.currentMessage, onCreationSelect]
  );

  const onReactionLongPress = useCallback(
    (reaction) => {
      openReactionDrawer(currentMessage, reaction);
    },
    [currentMessage]
  );

  const blockPosition: MessageBlockPosition = useMemo(() => {
    const { previousMessage, currentMessage, nextMessage } = props;
    const connectedAbove =
      isSameUser(currentMessage, previousMessage) &&
      isSameDay(currentMessage, previousMessage);
    const connectedBelow =
      isSameUser(currentMessage, nextMessage) &&
      isSameDay(currentMessage, nextMessage);
    if (connectedAbove && connectedBelow) return "middle";
    if (connectedBelow) return "start";
    if (connectedAbove) return "end";
    return "alone";
  }, [props.currentMessage, props.previousMessage, props.nextMessage]);

  const formattedTime = useMemo(() => {
    return format(currentMessage?.createdAt!, "h:mm aa");
  }, [currentMessage?.createdAt]);

  const isMediaOnlyMessage = currentMessage?.text?.length === 0;

  const uploadQueue = useUploadQueue();

  const { showActionSheetWithOptions } = useActionSheet();
  const defaultActionSheetOptions: ActionSheetOptions = {
    options: ["Retry", "Delete", "Close"],
    destructiveButtonIndex: 1,
    cancelButtonIndex: 2,
  };

  const sendFailed = useMemo(() => {
    const failedAttachments =
      currentMessage?.attachments?.filter(
        (attachment) => uploadQueue[attachment.id]?.status === "failed"
      ) ?? [];
    return failedAttachments.length > 0;
  }, [currentMessage?.attachments, uploadQueue]);

  const handleErrorMessageLongPress = useCallback(() => {
    if (!sendFailed) return;
    showActionSheetWithOptions(defaultActionSheetOptions, (buttonIndex) => {
      switch (buttonIndex) {
        case 0:
          if (currentMessage) {
            clearRetryNotifStatus(currentMessage._id as string);
            flushUploadQueue(
              currentMessage?.attachments?.map((attachment) => attachment.id) ??
                []
            );
            flushEventOutbox();
          }

          break;
        case 1:
          if (currentMessage) {
            cancelDelayedEvents([currentMessage._id as string]);
            flushEventOutbox();
          }
          break;
        default:
          break;
      }
    });
  }, [currentMessage, sendFailed]);

  return (
    <MotiView
      from={{ translateY: 10 }}
      animate={{ translateY: 0 }}
      transition={{
        type: "timing",
        duration: 250,
      }}
      style={{
        maxWidth: "85%",
        flexDirection: "column",
      }}
    >
      <MessageMedia
        position={props.position}
        currentMessage={props.currentMessage}
        onDoublePress={onMessageDoublePress}
        onLongPress={onMessageLongPress}
      />
      <Pressable
        sx={{
          flexDirection: "column",
          alignSelf: position === "right" ? "flex-end" : "flex-start",
          paddingY: isMediaOnlyMessage ? "$1" : 6,
          paddingX: isMediaOnlyMessage ? "$1" : "$3",
          borderRadius: BORDER_RADIUS,
          borderBottomRightRadius:
            position === "right" &&
            (blockPosition === "middle" ||
              blockPosition === "start" ||
              reactions.length > 0)
              ? "xs"
              : BORDER_RADIUS,
          borderBottomLeftRadius:
            position === "left" &&
            (blockPosition === "middle" ||
              blockPosition === "start" ||
              reactions.length > 0)
              ? "xs"
              : BORDER_RADIUS,
          borderTopRightRadius:
            position === "right" &&
            (blockPosition === "middle" || blockPosition === "end")
              ? "xs"
              : BORDER_RADIUS,
          borderTopLeftRadius:
            position === "left" &&
            (blockPosition === "middle" || blockPosition === "end")
              ? "xs"
              : BORDER_RADIUS,
          backgroundColor: isMediaOnlyMessage
            ? "transparent"
            : props.position === "left"
            ? "$gray.100"
            : "$primary.600",
          justifyContent: "flex-start",
          alignItems: "flex-start",
        }}
        onPress={() =>
          doublePressHandler({
            onDoublePress: onMessageDoublePress,
          })
        }
        onLongPress={onMessageLongPress}
      >
        {!!props.currentMessage && !!props.currentMessage.text && (
          <MessageText {...props} />
        )}
        {/* Show timestamp and icon ALWAYS on the left. On the right we replace it with error/retry text if necessary */}
        {(!sendFailed || position === "left") && (
          <View
            sx={{
              flexDirection: position === "left" ? "row" : "row-reverse",
              alignItems: "center",
              alignSelf: "stretch",
            }}
          >
            {currentMessage?.user._id !== currentUser.id &&
            (blockPosition === "end" || blockPosition === "alone") ? (
              <Text
                sx={{
                  color:
                    position === "left" || isMediaOnlyMessage
                      ? "$gray.400"
                      : "$primary.50",
                  marginRight: "$4",
                  fontSize: "xs",
                }}
              >
                {currentMessage?.user.name}
              </Text>
            ) : null}
            {position == "right" && (
              <Icon
                sx={{
                  marginLeft: "$2",
                  width: 10,
                  color: isMediaOnlyMessage ? "$gray.400" : "$primary.50",
                }}
                size={11}
                name={
                  props.currentMessage?.received
                    ? "checkmark-outline"
                    : "ellipse-outline"
                }
              />
            )}

            <Text
              sx={{
                color:
                  position === "left" || isMediaOnlyMessage
                    ? "$gray.400"
                    : "$primary.50",
                fontSize: "2xs",
              }}
            >
              {formattedTime}
            </Text>
          </View>
        )}
      </Pressable>
      {sendFailed && position == "right" && (
        <Pressable
          sx={{ flexDirection: "row", justifyContent: "flex-end", mb: "$1" }}
          onPress={handleErrorMessageLongPress}
        >
          <Text sx={{ fontSize: "2xs", color: "$danger.600", mr: "$1" }}>
            {"Failed to send. Press for more options."}
          </Text>
          <Icon size={12} name="alert-circle-outline" color="$danger.600" />
        </Pressable>
      )}
      {reactions.length > 0 ? (
        <View
          sx={{
            paddingY: 4,
            flexDirection: "row",
            justifyContent: position === "right" ? "flex-end" : "flex-start",
          }}
        >
          {reactions.map(([emoji, userIds], i) => {
            const isSelected = userIds.includes(currentUser.id);
            return (
              <Pressable
                key={emoji}
                sx={{
                  marginRight: position === "right" ? 0 : "$1",
                  marginLeft: position === "left" ? 0 : "$1",
                  padding: "$1",
                  backgroundColor: isSelected ? "$primary.500" : "$gray.100",
                  borderRadius: 4,
                  flexDirection: "row",
                  alignItems: "center",
                }}
                onPress={(e) => {
                  if (selectedReactions.includes(emoji)) {
                    removeReaction(emoji);
                  } else {
                    addReaction(emoji);
                  }
                  e.stopPropagation();
                }}
                onLongPress={(e) => {
                  onReactionLongPress(emoji);
                  e.stopPropagation();
                }}
              >
                {isSelected && lastPressed === emoji && (
                  <Lottie
                    source={require("../../assets/animations/bubble-explosion.json")}
                    resizeMode="cover"
                    style={{
                      width: 200,
                      position: "absolute",
                      zIndex: 10,
                      left: Platform.OS === "web" ? -75 : -25,
                    }}
                    autoPlay
                    loop={false}
                    onAnimationFinish={() => {
                      setLastPressed(null);
                    }}
                  />
                )}
                <Text sx={{ marginX: "$1", fontSize: 12 }}>
                  {emoji === "LIKE" ? "❤️" : emoji}
                </Text>
                <Text
                  sx={{
                    color: isSelected ? "$primary.50" : "$gray.800",
                    fontSize: 10,
                  }}
                >
                  {userIds.length}
                </Text>
              </Pressable>
            );
          })}
        </View>
      ) : null}
    </MotiView>
  );
}
