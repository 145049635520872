import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { RootStackParamList } from "../types";
import React from "react";
import AuthMessage from "../components/AuthMessage";

export default function EmailSignInMessageScreen({
  route,
}: NativeStackScreenProps<RootStackParamList, "EmailSignInMessage">) {
  return (
    <AuthMessage
      title="Hello again!"
      message={"To sign in to your account, please enter the code sent to"}
      email={route.params?.email}
      page="SIGN_IN"
    />
  );
}
