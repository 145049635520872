import { useHeaderHeight } from "@react-navigation/elements";
import { nanoid } from "nanoid";
import {
  Divider,
  Icon,
  IconButton,
  KeyboardAvoidingView,
  Pressable,
  Row,
  Spinner,
  Text,
  View,
} from "../../components/basics";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { usePushEvent } from "../../hooks/usePushEvent";
import { useCustomResourceCollection } from "../../hooks/useResourceCollection";
import { ListRenderItem, Platform, ScrollView, Alert } from "react-native";
import { PAGES_RESOURCE_KEY } from "../../resources/page";
import usePushNotice from "../../hooks/usePushNotice";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { SpacesTabParamList } from "../../types";
import { useUser } from "../../hooks/auth";
import { SwipeListView } from "react-native-swipe-list-view";
import CenteredSpinner from "../../components/basics/CenteredSpinner";
import { formatMessageTime } from "../../utils/dates";
import SpaceAppWrapper from "../../components/SpaceAppWrapper";
import { ResourceFragment } from "../../state/generated/graphql";
import { parseNotePreview } from "../../utils/apps/notes";
import { useSx } from "dripsy";
import DRIPSY_THEME from "../../constants/DripsyTheme";
import { useResourceShareDialog } from "../../hooks/useResourceShare";
import {
  actions,
  RichEditor,
  RichToolbar,
} from "react-native-pell-rich-editor";
import { MaterialCommunityIcons } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import { useSpacesMap } from "../../hooks/spaces";
import {
  useCurrentUserMemberships,
  useSpaceMembers,
} from "../../hooks/useSpaceMembers";
import { SpaceIcon } from "../../components/SimpleSpaceListItem";

function PageScreen({
  note: noteResource,
  onBack,
}: {
  note?: any;
  onBack: () => void;
}) {
  const [isEditing, setIsEditing] = useState(false);
  const [hasEdit, setHasEdit] = useState(false);
  const [editText, setEditText] = useState(noteResource?.data.rawText ?? "");
  const currentUser = useUser();
  const navigation = useNavigation();

  function handleUnsavedNavigation(onDiscard: () => void) {
    Alert.alert(
      "Discard changes?",
      "You have unsaved changes. Are you sure to discard them and leave the screen?",
      [
        { text: "Don't leave", style: "cancel", onPress: () => {} },
        {
          text: "Discard",
          style: "destructive",
          onPress: onDiscard,
        },
      ]
    );
  }

  // TODO: this doesn't seem to be working properly possibly due to our custom navigators
  useEffect(() => {
    const beforeRemoveHandler = (e) => {
      if (!hasEdit) return navigation.dispatch(e.data.action);
      e.preventDefault();
      handleUnsavedNavigation(() => navigation.dispatch(e.data.action));
    };
    const unsubscribe = navigation.addListener(
      "beforeRemove",
      beforeRemoveHandler
    );
    return () => {
      unsubscribe();
    };
  }, [hasEdit, navigation]);

  const handleBack = useCallback(() => {
    if (!hasEdit) return onBack();
    handleUnsavedNavigation(onBack);
  }, [onBack, hasEdit]);

  const pushEvent = usePushEvent();
  const pushNotice = usePushNotice();

  const updateNote = useCallback(
    (newNote: string) => {
      if (hasEdit) {
        if (!noteResource) {
          const id = nanoid();
          pushEvent({
            resource_key: PAGES_RESOURCE_KEY,
            type: "NEW",
            data: { rawText: newNote },
            resource_id: id,
          });
          const { title } = parseNotePreview(newNote);
          pushNotice(`%0 created a note "${title}"`, [currentUser!.id], {
            resourceId: id,
            resourceKey: PAGES_RESOURCE_KEY,
            noticeKey: "NEW",
          });
        } else {
          pushEvent({
            resource_key: PAGES_RESOURCE_KEY,
            type: "EDIT",
            data: { rawText: newNote },
            resource_id: noteResource.id,
          });
        }
      }
    },
    [pushEvent, noteResource, hasEdit]
  );

  const onSaveButtonPress = useCallback(() => {
    updateNote(editText);
    setHasEdit(false);
    setIsEditing(false);
  }, [editText, updateNote]);

  const onCancelButtonPress = useCallback(() => {
    setEditText(noteResource?.data.rawText ?? "");
    richText.current?.setContentHTML(noteResource?.data.rawText ?? "");
    setHasEdit(false);
    setIsEditing(false);
  }, [noteResource]);

  const headerHeight = useHeaderHeight();

  const sx = useSx();

  const richText = useRef();
  let scrollRef = useRef();

  let handleCursorPosition = useCallback((scrollY) => {
    // Positioning scroll bar
    scrollRef.current?.scrollTo({ y: scrollY - 30, animated: true });
  }, []);

  const handleInput = useCallback(() => {
    setHasEdit(true);
  }, []);

  const handleChange = useCallback((descriptionText) => {
    setEditText(descriptionText);
  }, []);

  const [selectionBolded, setSelectionBolded] = useState(false);
  const [selectionItalic, setSelectionItalic] = useState(false);
  const [selectionUnderline, setSelectionUnderline] = useState(false);
  const [selectionFontSize, setSelectionFontSize] = useState(3);

  const editorInitializedCallback = useCallback(() => {
    richText.current?.registerToolbar(function (items: any[]) {
      setSelectionBolded(items.includes("bold"));
      setSelectionItalic(items.includes("italic"));
      setSelectionUnderline(items.includes("underline"));
      const fontSizeItem = items.find(
        (item) => typeof item === "object" && item.type === "fontSize"
      );
      setSelectionFontSize(
        fontSizeItem?.value ? parseInt(fontSizeItem.value) : 3
      );
    });
  }, []);

  const { data: spacesMap } = useSpacesMap();

  const MAX_ATTRIBUTIONS = 3;
  const attributionsOverflow =
    noteResource &&
    Math.max(noteResource.resource_permissions.length - MAX_ATTRIBUTIONS, 0);
  const trimmedAttributions = noteResource
    ? noteResource.resource_permissions.slice(0, MAX_ATTRIBUTIONS)
    : [];

  return (
    <KeyboardAvoidingView
      behavior={Platform.OS === "ios" ? "padding" : "height"}
      keyboardVerticalOffset={headerHeight}
      flex={1}
    >
      <ScrollView
        contentContainerStyle={{ flexGrow: 1 }}
        style={sx({ height: "100%", backgroundColor: "$gray.100" })}
      >
        <Row justifyContent="space-between" py={2} mx={4}>
          <Pressable
            onPress={handleBack}
            style={({ pressed }) =>
              pressed && {
                backgroundColor: DRIPSY_THEME.colors.$gray["200"],
              }
            }
            sx={{
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              py: 2,
            }}
          >
            <Icon name="arrow-back" />
            <Text>Back</Text>
          </Pressable>
          <Pressable
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
            onPress={() => {
              navigation.navigate("ResourceAttributionModal", {
                resourceId: noteResource.id,
                resourceKey: noteResource.collection_key,
              });
            }}
          >
            {trimmedAttributions.map((rp) => {
              const space = spacesMap.get(rp.space_id);
              return (
                <View
                  key={`${rp.space_id}|${rp.resource_id}|${rp.resource_key}`}
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    mr: 2,
                  }}
                >
                  <SpaceIcon space={space} size={30} />
                </View>
              );
            })}
            {!!attributionsOverflow && (
              <Text sx={{ ml: 4 }}>+{attributionsOverflow}</Text>
            )}
          </Pressable>
        </Row>
        <RichEditor
          ref={richText}
          onChange={handleChange}
          onCursorPosition={handleCursorPosition}
          // Adding padding here interferes with the editor and may cut content off
          containerStyle={{ flexGrow: 1 }}
          style={{
            marginHorizontal: 8,
            marginBottom: 8,
            flexGrow: 1,
          }}
          autoCapitalize="on"
          onInput={handleInput}
          firstFocusEnd={false}
          initialContentHTML={editText}
          disabled={!isEditing}
          onMessage={(msg) => {
            if (msg.type === "EDITOR_ENABLED") {
              setIsEditing(true);
            }
          }}
          editorInitializedCallback={editorInitializedCallback}
        />
      </ScrollView>

      {isEditing && (
        <Row justifyContent={"space-between"} backgroundColor="#efefef">
          <IconButton
            onPress={onCancelButtonPress}
            colorScheme={"$danger"}
            icon="close-circle-outline"
            _iconStyles={{
              fontSize: "3xl",
            }}
            _containerStyles={{ borderRadius: 50 }}
          />
          <RichToolbar
            editor={richText}
            actions={[
              actions.setBold,
              actions.setItalic,
              actions.setUnderline,
              actions.insertLink,
              "decreaseFontSize",
              "increaseFontSize",
            ]}
            iconMap={{
              [actions.setBold]: ({ tintColor }) => (
                <ToolbarIcon
                  iconProps={{
                    name: "format-bold",
                    color: selectionBolded ? "black" : tintColor,
                  }}
                  containerProps={{
                    sx: selectionBolded
                      ? { borderBottomWidth: 2, borderBottomColor: "black" }
                      : {},
                  }}
                />
              ),
              [actions.setItalic]: ({ tintColor }) => (
                <ToolbarIcon
                  iconProps={{
                    name: "format-italic",
                    color: selectionItalic ? "black" : tintColor,
                  }}
                  containerProps={{
                    sx: selectionItalic
                      ? { borderBottomWidth: 2, borderBottomColor: "black" }
                      : {},
                  }}
                />
              ),
              [actions.setUnderline]: ({ tintColor }) => (
                <ToolbarIcon
                  iconProps={{
                    name: "format-underline",
                    color: selectionUnderline ? "black" : tintColor,
                  }}
                />
              ),
              ["decreaseFontSize"]: ({ tintColor }) => (
                <ToolbarIcon
                  iconProps={{
                    name: "format-font-size-decrease",
                    color: selectionFontSize < 3 ? "black" : tintColor,
                  }}
                  containerProps={{
                    sx:
                      selectionFontSize < 3
                        ? {
                            borderBottomWidth: 2,
                            borderBottomColor: "black",
                          }
                        : {},
                  }}
                />
              ),
              ["increaseFontSize"]: ({ tintColor }) => (
                <ToolbarIcon
                  iconProps={{
                    name: "format-font-size-increase",
                    color: selectionFontSize > 3 ? "black" : tintColor,
                  }}
                  containerProps={{
                    sx:
                      selectionFontSize > 3
                        ? { borderBottomWidth: 2, borderBottomColor: "black" }
                        : {},
                  }}
                />
              ),
            }}
            decreaseFontSize={() => {
              richText.current.sendAction("decreaseFontSize", "result");
            }}
            increaseFontSize={() => {
              richText.current.sendAction("increaseFontSize", "result");
            }}
          />

          <IconButton
            onPress={onSaveButtonPress}
            colorScheme={"$success"}
            icon="checkmark-circle-outline"
            _iconStyles={{
              fontSize: "3xl",
            }}
            _containerStyles={{ borderRadius: 50 }}
          />
        </Row>
      )}
    </KeyboardAvoidingView>
  );
}

function ToolbarIcon({
  iconProps,
  containerProps = {},
}: {
  iconProps: React.ComponentProps<typeof MaterialCommunityIcons>;
  containerProps?: React.ComponentProps<typeof View>;
}) {
  return (
    <View
      {...containerProps}
      sx={{
        width: "100%",
        alignItems: "center",
        ...(containerProps?.sx ?? {}),
      }}
    >
      <MaterialCommunityIcons size={24} {...iconProps} />
    </View>
  );
}

export default function PagesScreen({
  navigation,
  route,
}: NativeStackScreenProps<SpacesTabParamList, "Pages">) {
  const pushEvent = usePushEvent();
  const { resourceId, spaceId, newPage } = route.params ?? {};
  const {
    resources: notes,
    error,
    fetching,
    hasMore,
    getMore,
  } = useCustomResourceCollection({
    resourceQueries: {
      [PAGES_RESOURCE_KEY]: {},
    },
    sort: {
      updated_at: {
        _direction: "desc",
      },
      // TODO: something wrong with tiebreak sorting here...
      // id: {
      //   _direction: "asc",
      // },
    },
    spaceId,
  });

  const showResourceShareDialog = useResourceShareDialog();

  const [initialLoad, setInitialLoad] = useState(false);
  useEffect(() => {
    if (notes || error) setInitialLoad(true);
  }, [notes, error]);

  const createNote = useCallback(() => {
    navigation.setParams({ newPage: true });
  }, []);

  const deleteNote = useCallback(
    async (resourceId: string) => {
      await pushEvent({
        resource_key: PAGES_RESOURCE_KEY,
        type: "REMOVE",
        data: {},
        resource_id: resourceId,
      });
    },
    [pushEvent]
  );

  const closeRow = (rowMap, rowKey) => {
    if (rowMap[rowKey]) {
      rowMap[rowKey].closeRow();
    }
  };

  const deleteRow = (rowMap, rowKey) => {
    closeRow(rowMap, rowKey);
    deleteNote(rowKey);
  };

  const shareRow = (rowMap, rowKey) => {
    closeRow(rowMap, rowKey);
    showResourceShareDialog(rowKey, PAGES_RESOURCE_KEY);
  };

  const renderHiddenItem = (data, rowMap) => (
    <Row
      alignItems="center"
      flex={1}
      justifyContent="flex-end"
      bg="gray.200"
      paddingRight={2}
    >
      <IconButton
        onPress={() => deleteRow(rowMap, data.item.id)}
        icon="trash-outline"
        colorScheme="$danger"
        _iconStyles={{ fontSize: "xl" }}
      />
      <IconButton
        onPress={() => shareRow(rowMap, data.item.id)}
        icon="share-outline"
        _iconStyles={{ fontSize: "xl" }}
      />
    </Row>
  );

  const { data: spacesMap } = useSpacesMap();
  const currentMemberships = useCurrentUserMemberships();

  const extraData = useMemo(() => {
    return {
      spacesMap,
      currentMemberships,
    };
  }, [spacesMap, currentMemberships]);

  const renderItem: ListRenderItem<ResourceFragment> = ({ item }) => {
    const notePreview = parseNotePreview(item?.data?.rawText);
    const MAX_ATTRIBUTIONS = 3;
    const attributionsOverflow = Math.max(
      item.resource_permissions.length - MAX_ATTRIBUTIONS,
      0
    );
    const trimmedAttributions = item.resource_permissions.slice(
      0,
      MAX_ATTRIBUTIONS
    );
    // TODO: try moving updated time to one line below the snippet and just put attributions on the right
    return (
      <Pressable
        onPress={() => navigation.setParams({ resourceId: item.id })}
        style={({ pressed, hovered }) => ({
          backgroundColor: pressed
            ? DRIPSY_THEME.colors.$gray["200"]
            : hovered
            ? DRIPSY_THEME.colors.$gray["100"]
            : DRIPSY_THEME.colors.$white,
        })}
        sx={{
          py: "$3",
          px: 16,
          // height: 70,
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <View sx={{ flexShrink: 1 }}>
          <Text
            numberOfLines={1}
            ellipsizeMode="tail"
            sx={{
              fontSize: "md",
              // fontWeight: "medium",
            }}
          >
            {notePreview.title.replace(/^\s*#+/, "").trim()}
          </Text>
          {/* {!!notePreview.snippet && (
            <Text
              numberOfLines={2}
              ellipsizeMode="tail"
              sx={{ color: "$coolGray.500" }}
            >
              {notePreview.snippet}
            </Text>
          )} */}
          <Text sx={{ color: "$coolGray.500" }} numberOfLines={1}>
            {formatMessageTime(new Date(item.updated_at))}
            {notePreview.snippet && " · "}
            {notePreview.snippet ?? ""}
          </Text>
        </View>
        <View
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          {trimmedAttributions.map((rp) => {
            const space = extraData.spacesMap.get(rp.space_id);
            return (
              <View
                key={`${rp.space_id}|${rp.resource_id}|${rp.resource_key}`}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  mr: 2,
                }}
              >
                <SpaceIcon space={space} size={30} />
              </View>
            );
          })}
          {!!attributionsOverflow && (
            <Text sx={{ ml: 4 }}>+{attributionsOverflow}</Text>
          )}
        </View>
      </Pressable>
    );
  };

  if (!initialLoad) return <CenteredSpinner text="Loading Notes" />;

  if (resourceId || newPage) {
    const note = resourceId
      ? notes?.find((n) => n.id === resourceId)
      : undefined;
    if (note) {
      return (
        <PageScreen
          note={note}
          onBack={() =>
            navigation.setParams({ resourceId: undefined, newPage: false })
          }
        />
      );
    } else if (newPage) {
      return (
        <PageScreen
          note={undefined}
          onBack={() =>
            navigation.setParams({ resourceId: undefined, newPage: false })
          }
        />
      );
    }
  }

  return (
    <>
      <SwipeListView
        useFlatList
        data={notes}
        keyExtractor={(item) => item.id}
        renderItem={renderItem}
        renderHiddenItem={renderHiddenItem}
        rightOpenValue={-90}
        previewRowKey={"0"}
        previewOpenValue={-40}
        previewOpenDelay={3000}
        contentContainerStyle={{ paddingBottom: 96 }}
        onEndReached={() => {
          if (hasMore && !fetching) getMore();
        }}
        onEndReachedThreshold={0.1}
        ItemSeparatorComponent={() => (
          <View
            sx={{
              width: "100%",
              borderBottomWidth: "hairline",
              borderColor: "$coolGray.200",
            }}
          />
        )}
        extraData={extraData}
      />
      {fetching && (
        <View sx={{ alignItems: "center", justifyContent: "center" }}>
          <Spinner my={8} />
        </View>
      )}

      <IconButton
        icon="add-outline"
        _iconStyles={{ fontSize: "3xl", color: "$black" }}
        variant="solid"
        _containerStyles={{
          position: "absolute",
          width: 64,
          height: 64,
          bottom: 0,
          right: 0,
          borderRadius: 50,
          padding: "$4",
          alignItems: "center",
          justifyContent: "center",
          margin: "$4",
          boxShadow: "md",
          backgroundColor: "$aspenGreen",
        }}
        onPress={createNote}
      />
    </>
  );
}
