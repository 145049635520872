import {
  StackActions,
  useNavigation,
  useTheme,
} from "@react-navigation/native";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  Dimensions,
  Platform,
  Keyboard,
  Modal,
  ModalProps,
} from "react-native";
import {
  BubbleProps,
  GiftedChat,
  IMessage,
  Send,
} from "react-native-gifted-chat";
import { usePushEvent, usePushEvents } from "../../hooks/usePushEvent";
import supabase from "../../lib/supabase";
import { SpacesTabParamList } from "../../types";
import { v4 as uuidv4 } from "uuid";
import {
  View,
  Image,
  PasteInput,
  Icon,
  Text,
  Pressable,
  ScrollView,
  Button,
  IconButton,
  KeyboardAvoidingView,
  TextInput,
  DateTimePicker,
  Input,
} from "../../components/basics";
import usePersonName from "../../hooks/usePersonName";
import { nanoid } from "nanoid";
import { useCustomResourceCollection } from "../../hooks/useResourceCollection";
import { ChatMessage, CHAT_RESOURCE_KEY } from "../../resources/chat";
import useNoticeFormatter from "../../hooks/useNoticeFormatter";
import { useBottomTabBarHeight } from "@react-navigation/bottom-tabs";
import { MessageText } from "../../components/chat/MessageText";
import Bubble from "../../components/chat/Bubble";
import useChatAttachments from "../../hooks/useChatAttachments";
import usePeople, { usePerson } from "../../hooks/usePeople";
import SpaceAppWrapper from "../../components/SpaceAppWrapper";
import { queueDelayedEvents } from "../../lib/event-outbox";
import Message from "../../components/chat/Message";
import usePendingResources, {
  useMergedResources,
} from "../../hooks/usePendingResources";
import { normalizeAttachments } from "../../utils/apps/chat";
import { captureCameraImage } from "../../utils/media";
import { ProfileAvatar } from "../../components/PersonAvatar";
import { NOTICE_RESOURCE_KEY } from "../../resources/notice";
import SystemMessage from "../../components/chat/SystemMessage";
import { MessageMenuProvider } from "../../components/chat/MessageMenu";
import { SafeAreaView, useDripsyTheme, useSx } from "dripsy";
import { InputToolbar } from "../../components/chat/InputToolbar";
import { Ionicons } from "@expo/vector-icons";
import DRIPSY_THEME from "../../constants/DripsyTheme";
import { POLL_RESOURCE_KEY } from "../../resources/poll";
import { useNoticeConstructor } from "../../hooks/usePushNotice";
import { useUser } from "../../hooks/auth";
import { navigate } from "../../navigation/RootNavigation";
import useSharedNavigationValue from "../../hooks/useSharedNavigationValue";
import { PAGES_RESOURCE_KEY } from "../../resources/page";
import BottomSheet, {
  BottomSheetModal,
  BottomSheetModalProvider,
  BottomSheetScrollView,
} from "@gorhom/bottom-sheet";
import SpaceAppIcon from "../../components/SpaceAppIcon";
import { MotiView } from "moti";
import Animated, {
  useSharedValue,
  useAnimatedStyle,
} from "react-native-reanimated";
import { createPollLocal } from "../../utils/resources/polls";
import { createNoteLocal } from "../../utils/resources/notes";
import * as chrono from "chrono-node";
import {
  ReminderNewEventParams,
  REMINDER_RESOURCE_KEY,
} from "../../resources/reminder";
import { createReminderLocal } from "../../utils/resources/reminders";
import {
  ScheduleItemNewEventParams,
  SCHEDULE_RESOURCE_KEY,
} from "../../resources/schedule";
import { addHours } from "date-fns";
import { createScheduleItemLocal } from "../../utils/resources/schedule";
import { ToastContextProvider, useToast } from "../../hooks/useToast";
import { useSpace } from "../../hooks/spaces";
import { TAB_BAR_HEIGHT } from "../MainScreen";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import { ReactionDrawerProvider } from "../../components/chat/ReactionDrawer";
import { Image as RNImage } from "react-native";
import { getSizeAsync } from "../../utils/images";
import { useSpaceMember, useSpaceMembers } from "../../hooks/useSpaceMembers";
import { useProfilePeekDrawer } from "../../components/chat/ProfilePeekDrawer";
import { InvitationMessage } from "../../components/SpacesScreenHeader";
import { acceptInvitation, declineInvitation } from "../../lib/invitations";

// const chrono = require("chrono-node/dist/locales/en");
const windowHeight = Dimensions.get("window").height;

export interface AspenChatMessage extends IMessage {
  attachments?: { id: string; type: "photo" | "video" }[];
  reactions?: Record<string, string[]>;
  isPending?: boolean;
  metadata?: Record<string, string>;
}

export interface AttachmentState {
  id: string;
  assetId?: string; // Attachemnts from image picker will have assetId
  uri: string;
  localUri?: string;
  width?: number;
  height?: number;
  mediaType: "photo" | "video";
}

interface ResourceAttachment {
  resource: any;
  event: any;
}

const PAGE_SIZE = 20;
export default function ChatScreen({ spaceId }: { spaceId: string }) {
  const useImagePickerRef = useRef(false);
  const [attachmentPreviewLoading, setAttachmentPreviewLoading] =
    useState(false);
  const theme = useTheme();
  const tabBarHeight = useBottomTabBarHeight();

  const people = usePeople();

  const getName = usePersonName();

  const navigation = useNavigation();

  // const { spaceId } = route.params;

  const {
    stagedAttachments,
    stageAttachments,
    unstageAttachment,
    resetAttachments,
    isQueueingAttachments,
    queueAttachments,
  } = useChatAttachments(spaceId);

  const currentUser = useUser();

  // useMessageReader(spaceId);

  const pushEvent = usePushEvent();
  const pushEvents = usePushEvents();
  const noticeConstructor = useNoticeConstructor();

  const noticeFormatter = useNoticeFormatter();

  const chatParams = useMemo(() => {
    return {
      spaceId,
      resourceQueries: { [CHAT_RESOURCE_KEY]: {}, [NOTICE_RESOURCE_KEY]: {} },
      sort: {
        created_at: {
          _direction: "desc",
        },
        id: {
          _direction: "asc",
        },
      },
      pageSize: PAGE_SIZE,
    };
  }, [spaceId]);

  const {
    resources: commitedChatResources,
    error,
    getMore,
    fetching,
    hasMore,
  } = useCustomResourceCollection(chatParams);

  const pendingChatResources = usePendingResources({
    resourceQueries: {
      [CHAT_RESOURCE_KEY]: {},
    },
    spaceId,
  });

  // On teardown, clear global attachment data
  useEffect(() => {
    return () => {
      resetAttachments();
    };
  }, []);

  useEffect(() => {
    if (error) {
      console.error(error);
    }
  }, [error]);

  const resources = useMergedResources(
    commitedChatResources,
    pendingChatResources
  );

  const [resourceAttachments, setResourceAttachments] = useState<
    ResourceAttachment[]
  >([]);

  const { data: space } = useSpace(spaceId);
  const spaceMembers = useSpaceMembers(space?.id);
  const currentUserMembership = useSpaceMember(spaceId, currentUser?.id);
  const invitation = currentUserMembership?.invitation;
  const inviter = usePerson(invitation?.inviter_id);

  function chatResourceToMessage(chatResource: any): AspenChatMessage {
    const data = chatResource.data as ChatMessage;
    const isNotice = data.is_notice;
    const rawText: string = data.text;
    const text = isNotice
      ? noticeFormatter(rawText, data.text_user_ids ?? [])
      : rawText;
    const normalizedAttachments = normalizeAttachments(data.attachment);
    const totalReactions = data.reactions
      ? Object.keys(data.reactions).reduce(
          (prev, reaction) => prev + data.reactions[reaction].length,
          0
        )
      : 0;
    const msg: AspenChatMessage = {
      _id: chatResource.id,
      text: text,
      createdAt: new Date(chatResource.created_at!),
      system: isNotice,
      received: !data.is_local,
      user: {
        _id: data.sender_id,
        name: getName(data.sender_id),
        space_membership: spaceMembers?.find(
          (member) => data.sender_id === member.user_id
        ),
        avatar: () => {
          const profile = people.get(data.sender_id);
          return (
            <View sx={{ marginBottom: totalReactions > 0 ? "$8" : 0 }}>
              <ProfileAvatar size={36} profile={profile} />
            </View>
          );
        },
      },
      attachments: normalizedAttachments,
      reactions: data.reactions,
      isPending: chatResource._is_pending,
    };
    return msg;
  }

  function noticeResourceToMessage(noticeResource: any): AspenChatMessage {
    const data = noticeResource.data;
    const text = noticeFormatter(data.text, data.text_user_ids ?? []);

    const msg: AspenChatMessage = {
      _id: noticeResource.id,
      text: text,
      createdAt: new Date(noticeResource.created_at!),
      system: true,
      user: { _id: "_SYSTEM" },
      metadata: data.metadata,
    };
    return msg;
  }

  const messages = useMemo<AspenChatMessage[]>(() => {
    const mappedMessages = (resources as any[]).reduce<AspenChatMessage[]>(
      (messages, r) => {
        if (r.collection_key === CHAT_RESOURCE_KEY)
          messages.push(chatResourceToMessage(r));
        if (r.collection_key === NOTICE_RESOURCE_KEY)
          messages.push(noticeResourceToMessage(r));
        return messages;
      },
      []
    );
    return mappedMessages;
  }, [resources]);

  const handlePoll = useCallback(
    (msg: AspenChatMessage) => {
      const rawOptions = msg.text.replace("/poll", "").trim().split(", ");
      const options = rawOptions.map((o) => ({ id: nanoid(), value: o }));
      const pollId = nanoid();
      const noticeData = noticeConstructor(
        `%0 created a poll`,
        [currentUser.id],
        { resourceId: pollId, resourceKey: POLL_RESOURCE_KEY, noticeKey: "NEW" }
      );
      pushEvents([
        {
          resource_key: POLL_RESOURCE_KEY,
          type: "NEW",
          data: { options },
          resource_id: pollId,
        },
        noticeData,
      ]);
    },
    [noticeConstructor, currentUser?.id, pushEvents]
  );

  const handleSend = useCallback(
    async (msg: AspenChatMessage) => {
      const resource_id = nanoid();
      const messageData = stagedAttachments.length
        ? {
            text: msg.text,
            attachment: stagedAttachments.map((attachment) => ({
              id: attachment.id,
              type: attachment.mediaType,
              height: attachment.height,
              width: attachment.width,
            })),
          }
        : {
            text: msg.text,
          };
      const messageEventPayload = {
        resource_key: CHAT_RESOURCE_KEY,
        type: "NEW",
        data: messageData,
        resource_id,
        space_id: spaceId,
      };
      const resourcePayloads = [];
      for (const ra of resourceAttachments) {
        if (ra.resource.collection_key === POLL_RESOURCE_KEY) {
          const noticeData = noticeConstructor(
            `%0 created a poll`,
            [currentUser.id],
            {
              resourceId: ra.resource.id,
              resourceKey: POLL_RESOURCE_KEY,
              noticeKey: "NEW",
            }
          );
          resourcePayloads.push(
            {
              resource_key: POLL_RESOURCE_KEY,
              type: "NEW",
              data: ra.event,
              resource_id: ra.resource.id,
            },
            noticeData
          );
        } else if (ra.resource.collection_key === PAGES_RESOURCE_KEY) {
          const noticeData = noticeConstructor(
            `%0 created a note`,
            [currentUser.id],
            {
              resourceId: ra.resource.id,
              resourceKey: PAGES_RESOURCE_KEY,
              noticeKey: "NEW",
            }
          );
          resourcePayloads.push(
            {
              resource_key: PAGES_RESOURCE_KEY,
              type: "NEW",
              data: ra.event,
              resource_id: ra.resource.id,
            },
            noticeData
          );
        } else if (ra.resource.collection_key === REMINDER_RESOURCE_KEY) {
          const noticeData = noticeConstructor(
            `%0 created a reminder`,
            [currentUser.id],
            {
              resourceId: ra.resource.id,
              resourceKey: REMINDER_RESOURCE_KEY,
              noticeKey: "NEW",
            }
          );
          resourcePayloads.push(
            {
              resource_key: REMINDER_RESOURCE_KEY,
              type: "NEW",
              data: ra.event,
              resource_id: ra.resource.id,
            },
            noticeData
          );
        } else if (ra.resource.collection_key === SCHEDULE_RESOURCE_KEY) {
          const noticeData = noticeConstructor(
            `%0 created an event`,
            [currentUser.id],
            {
              resourceId: ra.resource.id,
              resourceKey: SCHEDULE_RESOURCE_KEY,
              noticeKey: "NEW",
            }
          );
          resourcePayloads.push(
            {
              resource_key: SCHEDULE_RESOURCE_KEY,
              type: "NEW",
              data: ra.event,
              resource_id: ra.resource.id,
            },
            noticeData
          );
        }
      }
      if (stagedAttachments.length) {
        try {
          await queueAttachments(stagedAttachments);
          const attachmentKeys = messageEventPayload.data.attachment
            ? (typeof messageEventPayload.data.attachment === "string"
                ? [messageEventPayload.data.attachment]
                : messageEventPayload.data.attachment
              ).filter((x) => x)
            : undefined;
          queueDelayedEvents([
            {
              dependencies:
                attachmentKeys?.map((attachment) => attachment.id) ?? [],
              eventPayload: messageEventPayload,
            },
          ]);
          pushEvents(resourcePayloads);
        } catch (e) {
          throw e;
        }
      } else {
        if (msg.text) {
          pushEvents([...resourcePayloads, messageEventPayload]);
        } else {
          pushEvents(resourcePayloads);
        }
      }
      setResourceAttachments([]);
      resetAttachments();
    },
    [
      pushEvent,
      stagedAttachments,
      queueAttachments,
      resourceAttachments,
      resetAttachments,
    ]
  );

  const onSend = useCallback(
    async (messages: AspenChatMessage[] = []) => {
      const [msg] = messages;
      if (!msg) return;
      const words = msg.text.trim().split(" ");
      if (words[0].startsWith("/") && words[0].toLowerCase() === "/poll") {
        handlePoll(msg);
      } else {
        await handleSend(msg);
      }
    },
    [handleSend, handlePoll]
  );

  const pickImage = useCallback(async () => {
    // If we are currently using the image picker, open image picker passing along current attachment data
    if (useImagePickerRef.current) {
      navigation.navigate("AttachmentPicker", {
        spaceId,
      });

      // If not (ie camera), clear attachment data and open image picker
    } else {
      resetAttachments();
      useImagePickerRef.current = true;
      navigation.navigate("AttachmentPicker", {
        spaceId,
      });
    }
  }, [resetAttachments]);

  const captureImage = useCallback(async () => {
    try {
      setAttachmentPreviewLoading(true);
      const result = await captureCameraImage();
      setAttachmentPreviewLoading(false);
      if (!result) throw new Error("Failed to capture image.");
      if (!result.cancelled) {
        const attachments: AttachmentState[] = [
          {
            id: uuidv4(),
            uri: result.uri,
            mediaType: "photo",
            width: result.width,
            height: result.height,
          },
        ];
        useImagePickerRef.current = false;
        stageAttachments(attachments);
      }
    } catch (e) {
      setAttachmentPreviewLoading(false);

      // Should only run on Android
      // https://docs.expo.dev/versions/latest/sdk/imagepicker/#imagepickergetpendingresultasync
      if (!result) {
        const attachments: AttachmentState[] = [
          {
            id: uuidv4(),
            uri: result.uri,
            mediaType: "photo",
            width: result.width,
            height: result.height,
          },
        ];
        useImagePickerRef.current = false;
        stageAttachments(attachments);
      }
    }
  }, [stageAttachments]);

  const bubbleRenderer = useCallback(
    (props: BubbleProps<AspenChatMessage>) => (
      <Bubble
        {...props}
        onCreationSelect={(key, payload) => {
          setSelectedResourceCreator({ key, initialValue: payload });
        }}
      />
    ),
    []
  );
  const sendRenderer = useCallback(
    (props) => (
      <Send
        {...props}
        disabled={
          (!props.text &&
            !stagedAttachments?.length &&
            !resourceAttachments.length) ||
          isQueueingAttachments
        }
        textStyle={{
          color: useDripsyTheme().theme.colors.$aspenPurple[600],
        }}
        sendButtonProps={{
          ...props.sendButtonProps,
          onPress: () => {
            const { onSend, text } = props;
            if (onSend) {
              onSend({ text: text?.trim() }, true);
            }
          },
        }}
      >
        <View
          sx={{
            height: "100%",
            width: 40,
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Icon
            name="send"
            color={DRIPSY_THEME.colors.$primary[600]}
            size={24}
          />
        </View>
      </Send>
    ),
    [stagedAttachments?.length, isQueueingAttachments, resourceAttachments]
  );
  const composerRenderer = useCallback(({ text, onTextChanged, onSend }) => {
    return (
      <View
        sx={{
          flex: 1,
          height: "100%",
          alignItems: "center",
          flexDirection: "row",
          width: "100%",
          flexGrow: 1,
          backgroundColor: "$pink",
        }}
      >
        <PasteInput
          sx={{
            width: "100%",
            padding: 8,
            position: "absolute",
            alignSelf: "stretch",
            bottom: 5,
            left: 0,
            right: 0,
            maxHeight: Math.floor(windowHeight * 0.3),
            zIndex: 100,
            backgroundColor: "$gray.100",
            borderRadius: 4,
            fontSize: 16,
          }}
          placeholder="Say something..."
          disableCopyPaste={false}
          onPaste={(err, files) => {
            const asyncAttachments: AttachmentState[] = files.map(
              async (file) => {
                const size = await getSizeAsync(file.uri);
                return {
                  id: uuidv4(),
                  uri: file.uri,
                  mediaType: "photo",
                  ...size,
                };
              }
            );
            Promise.all(asyncAttachments).then(stageAttachments);
          }}
          multiline={true}
          blurOnSubmit={false}
          underlineColorAndroid="transparent"
          keyboardType="default"
          disableFullscreenUI={true}
          textContentType="none"
          value={text}
          onChangeText={onTextChanged}
          onKeyPress={
            Platform.OS === "web"
              ? (e) => {
                  //@ts-ignore on web certain event props are available that arent in TS def
                  if (e.key === "Enter" && !e.shiftKey) {
                    e.preventDefault();
                    //@ts-ignore onSend still passed in as prop, even tho not in TS def
                    if (onSend) {
                      onSend({ text: text?.trim() }, true);
                    }
                  }
                }
              : undefined
          }
        />
      </View>
    );
  }, []);
  const messageTextRenderer = useCallback(
    (props) => <MessageText {...props} />,
    []
  );
  const systemMessageRenderer = useCallback(
    (props) => <SystemMessage {...props} />,
    []
  );
  const messageRenderer = useCallback((props) => <Message {...props} />, []);
  const listViewProps = useRef({
    keyboardDismissMode: "on-drag",
    keyboardShouldPersistTaps: "handled",
    initialNumToRender: PAGE_SIZE,
  });
  const user = useRef({
    _id: supabase.auth.user()!.id,
  });
  const toolbarRenderer = useCallback(
    (props) => (
      <InputToolbar
        {...props}
        color="black"
        onImageButtonPress={() => {
          openBottomSheet();
          Keyboard.dismiss();
        }}
        onCameraButtonPress={captureImage}
        attachments={stagedAttachments}
        resourceAttachments={resourceAttachments}
        onResourceAttachmentDelete={(id: string) =>
          setResourceAttachments((prev) =>
            prev.filter((ra) => ra.resource.id !== id)
          )
        }
        onAttachmentDelete={unstageAttachment}
        attachmentUploading={attachmentPreviewLoading}
        isBottomSheetOpen={currentSnapIndex.value !== -1}
      />
    ),
    [
      pickImage,
      captureImage,
      stagedAttachments,
      resourceAttachments,
      unstageAttachment,
      attachmentPreviewLoading,
    ]
  );

  const resourceTypes = [
    { key: "notes", title: "Notes", icon: "notes" },
    { key: "photos", title: "Photos", icon: "photos" },
    { key: "polls", title: "Polls", icon: "polls" },
    { key: "reminders", title: "Reminders", icon: "reminders" },
    { key: "schedule", title: "Schedule", icon: "schedule" },
  ];
  const [selectedResourceCreator, setSelectedResourceCreator] = useState<
    | {
        key: string;
        initialValue: string;
      }
    | undefined
  >();

  const bottomSheetRef = useRef<BottomSheet>(null);
  const sheetSnapPoints = useMemo(() => ["25%", "66%"], []);
  const currentSnapIndex = useSharedValue<number>(-1);
  const insets = useSafeAreaInsets();

  const { onPressPerson, closeBottomSheet: closeProfilePeekBottomSheet } =
    useProfilePeekDrawer();
  function closeBottomSheet() {
    bottomSheetRef.current?.close();
  }
  function openBottomSheet() {
    if (currentSnapIndex.value === -1) {
      closeProfilePeekBottomSheet();
      bottomSheetRef.current?.snapToIndex(0);
    } else {
      closeBottomSheet();
    }
  }
  const animatedChatContainerStyle = useAnimatedStyle(() => {
    const height = `${
      100 -
      (currentSnapIndex.value === -1
        ? 0
        : Number(sheetSnapPoints[currentSnapIndex.value].slice(0, -1)))
    }%`;
    return {
      height,
    };
  });

  const handleBottomSheetResourcePress = useCallback(
    (resourceType: string) => {
      if (resourceType === "photos") {
        pickImage();
      } else {
        setSelectedResourceCreator({ key: resourceType, initialValue: "" });
      }
    },
    [pickImage, closeBottomSheet]
  );

  useEffect(() => {
    const handleKeyboardShow = () => {
      closeBottomSheet();
      closeProfilePeekBottomSheet();
    };
    const showSubscription = Keyboard.addListener(
      "keyboardWillShow",
      handleKeyboardShow
    );

    return () => showSubscription.remove();
  }, []);

  return (
    <>
      {selectedResourceCreator?.key === "polls" ? (
        <PollCreationModal
          onCreate={(creationInfo) =>
            setResourceAttachments((prev) => [...prev, creationInfo])
          }
          onDismiss={() => setSelectedResourceCreator(undefined)}
          initialValue={selectedResourceCreator?.initialValue}
        />
      ) : null}
      {selectedResourceCreator?.key === "notes" ? (
        <NoteCreationModal
          onCreate={(creationInfo) =>
            setResourceAttachments((prev) => [...prev, creationInfo])
          }
          onDismiss={() => setSelectedResourceCreator(undefined)}
          initialValue={selectedResourceCreator?.initialValue}
        />
      ) : null}
      {selectedResourceCreator?.key === "reminders" ? (
        <ReminderCreationModal
          onCreate={(creationInfo) =>
            setResourceAttachments((prev) => [...prev, creationInfo])
          }
          onDismiss={() => setSelectedResourceCreator(undefined)}
          initialValue={selectedResourceCreator?.initialValue}
        />
      ) : null}
      {selectedResourceCreator?.key === "schedule" ? (
        <EventCreationModal
          onCreate={(creationInfo) =>
            setResourceAttachments((prev) => [...prev, creationInfo])
          }
          onDismiss={() => setSelectedResourceCreator(undefined)}
          initialValue={selectedResourceCreator?.initialValue}
        />
      ) : null}

      {messages.length === 0 && !fetching && (
        <View
          style={[
            {
              justifyContent: "center",
              alignItems: "center",
              top: 100,
              left: 0,
              right: 0,
              position: "absolute",
            },
          ]}
        >
          <Image
            source={
              theme.dark
                ? require("../../assets/images/no-messages-dark.png")
                : require("../../assets/images/no-messages.png")
            }
            resizeMode={"contain"}
            sx={{
              height: 200,
              width: 300,
              flex: 1,
              justifyContent: "center",
              alignItems: "center",
            }}
            accessibilityLabel="empty-chat-message"
          />
        </View>
      )}
      {invitation && invitation.status === "PENDING" && inviter && space ? (
        <View
          sx={{
            position: "absolute",
            top: 10,
            left: 15,
            right: 15,
            zIndex: 30,
          }}
        >
          <InvitationMessage
            inviter={inviter}
            spaceName={space?.name}
            onAccept={async () => {
              const { error } = await acceptInvitation(invitation.id);
              if (error) {
                console.error(error);
                throw error;
              }
              navigation.navigate("conversation", {
                spaceId: spaceId,
              });
            }}
            onDecline={async () => {
              const { error } = await declineInvitation(invitation.id);
              if (error) {
                console.error(error);
                throw error;
              }

              navigation.dispatch(StackActions.replace("Root"));
            }}
          />
        </View>
      ) : null}
      <ReactionDrawerProvider>
        <MessageMenuProvider>
          <Animated.View style={[animatedChatContainerStyle]}>
            <GiftedChat
              messages={messages}
              onSend={onSend}
              wrapInSafeArea={false}
              loadEarlier={hasMore}
              infiniteScroll={true}
              isLoadingEarlier={fetching}
              onLoadEarlier={getMore}
              user={user.current}
              renderUsernameOnMessage={true}
              bottomOffset={
                Platform.OS === "ios" ? TAB_BAR_HEIGHT + insets.bottom : 0
              }
              renderInputToolbar={toolbarRenderer}
              renderBubble={bubbleRenderer}
              renderSend={sendRenderer}
              renderComposer={composerRenderer}
              renderMessageText={messageTextRenderer}
              renderSystemMessage={systemMessageRenderer}
              renderMessage={messageRenderer}
              alwaysShowSend={true}
              listViewProps={listViewProps.current}
              messagesContainerStyle={{ paddingHorizontal: 4 }}
              onPressAvatar={(user) => {
                Keyboard.dismiss();
                onPressPerson({ member: user.space_membership });
              }}
            />
          </Animated.View>

          <BottomSheet
            ref={bottomSheetRef}
            index={-1}
            snapPoints={sheetSnapPoints}
            enablePanDownToClose={true}
            onAnimate={(fromIndex, toIndex) => {
              currentSnapIndex.value = toIndex;
            }}
          >
            <View sx={{ ml: 16, mb: "$4" }}>
              <Text sx={{ fontWeight: "bold", fontSize: 16 }}>Create</Text>
            </View>
            <ScrollView horizontal>
              <View
                sx={{
                  flexDirection: "row",
                  flexWrap: "wrap",
                  alignItems: "center",
                }}
              >
                {resourceTypes.map((rt) => (
                  <View
                    sx={{
                      width: 70,
                      justifyContent: "center",
                      alignItems: "center",
                      marginBottom: "$2",
                    }}
                    key={rt.key}
                  >
                    <Pressable
                      style={({ pressed }) => ({
                        backgroundColor: pressed
                          ? DRIPSY_THEME.colors.$gray["200"]
                          : undefined,
                      })}
                      sx={{
                        p: 3,
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      onPress={() => handleBottomSheetResourcePress(rt.key)}
                    >
                      <SpaceAppIcon appName={rt.icon} size={"md"} />
                      <Text sx={{ marginTop: "$1", fontSize: 12 }}>
                        {rt.title}
                      </Text>
                    </Pressable>
                  </View>
                ))}
              </View>
            </ScrollView>
          </BottomSheet>
        </MessageMenuProvider>
      </ReactionDrawerProvider>
    </>
  );
}

interface ResourceCreationModalProps extends ModalProps {
  onCreate: (creationInfo: ResourceAttachment) => void;
  initialValue?: string;
}

function PollCreationModal(props: ResourceCreationModalProps) {
  return (
    <CreationModal {...props}>
      <PollCreationModalBody {...props} />
    </CreationModal>
  );
}

function PollCreationModalBody(props: ResourceCreationModalProps) {
  const [draftOption, setDraftOption] = useState("");
  const [draftPrompt, setDraftPrompt] = useState("");
  const [pollOptions, setPollOptions] = useState<string[]>([]);
  const sx = useSx();
  const pollInput = useRef();
  const submitOption = useCallback(() => {
    setPollOptions((prevOptions) => [...prevOptions, draftOption]);
    setDraftOption("");
  }, [draftOption, pollInput]);

  const handleCreatePoll = useCallback(() => {
    const eventData = {
      title: draftPrompt,
      options: pollOptions.map((option) => ({
        id: nanoid(),
        value: option,
      })),
    };
    const resource = createPollLocal(eventData);
    props.onCreate({
      resource,
      event: eventData,
    });
    setPollOptions([]);
    props.onDismiss && props.onDismiss();
  }, [pollOptions, props.onDismiss, props.onCreate, draftPrompt]);

  return (
    <>
      <View
        sx={{
          backgroundColor: "$primary.600",
          paddingY: "$3",
          paddingX: "$3",
          borderTopLeftRadius: "sm",
          borderTopRightRadius: "sm",
        }}
      >
        <Text sx={{ color: "$white" }}>New Poll</Text>
      </View>
      <Text
        sx={{
          paddingX: "$2",
          paddingTop: "$2",
          fontWeight: "semibold",
          fontSize: "xs",
          color: "$gray.500",
        }}
      >
        Prompt
      </Text>
      <View
        sx={{
          flexDirection: "row",
          paddingLeft: "$3",
          paddingRight: "$1",
          paddingY: "$1",
          marginTop: "$2",
          marginX: "$2",
          // borderWidth: 1,
          // borderRadius: 50,
          // borderColor: "$gray.300",
          backgroundColor: "$white",
        }}
      >
        <TextInput
          value={draftPrompt}
          autoFocus
          onChangeText={(value) => setDraftPrompt(value)}
          returnKeyType="done"
          placeholder="e.g. What should we watch?"
          sx={{ flex: 1 }}
        />
      </View>
      <Text
        sx={{
          paddingX: "$2",
          paddingTop: "$2",
          fontWeight: "semibold",
          fontSize: "xs",
          color: "$gray.500",
        }}
      >
        Options
      </Text>
      <ScrollView
        sx={{ flexGrow: 0, paddingY: "$2", maxHeight: 160 }}
        contentContainerStyle={sx({
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "center",
          paddingBottom: "$3",
          marginX: 2,
        })}
      >
        {pollOptions.map((option, index) => (
          <View
            key={index}
            sx={{
              flexDirection: "row",
              alignItems: "center",
              borderWidth: 1,
              borderColor: "$gray.300",
              borderRadius: 50,
              maxWidth: "90%",
              padding: 1,
              margin: 2,
            }}
          >
            <Text sx={{ marginLeft: "$3", maxWidth: "80%" }} numberOfLines={1}>
              {option}
            </Text>
            <IconButton
              variant="unstyled"
              size="sm"
              icon="close"
              sx={{ paddingY: 4 }}
              _iconStyles={{ color: "$gray.300" }}
              onPress={() =>
                setPollOptions((options) => {
                  options.splice(index, 1);
                  return [...options];
                })
              }
            />
          </View>
        ))}
      </ScrollView>
      <View
        sx={{
          flexDirection: "row",
          paddingLeft: "$3",
          paddingRight: "$1",
          paddingY: "$1",
          marginBottom: "$1",
          marginX: "$2",
          borderWidth: 1,
          borderRadius: 50,
          borderColor: "$gray.300",
          backgroundColor: "$white",
        }}
      >
        <TextInput
          value={draftOption}
          autoFocus
          onChangeText={(value) => setDraftOption(value)}
          onSubmitEditing={submitOption}
          returnKeyType="done"
          placeholder="New option"
          sx={{ flex: 1 }}
        />
        <IconButton
          sx={{ borderRadius: "sm", padding: 0 }}
          _iconStyles={{ fontSize: "3xl" }}
          icon="add-circle"
          disabled={draftOption === ""}
          onPress={submitOption}
        />
      </View>
      <DefaultCreationModalActions
        onClose={props.onDismiss}
        onCreate={handleCreatePoll}
        createDisabled={pollOptions.length === 0}
      />
    </>
  );
}

function NoteCreationModal(props: ResourceCreationModalProps) {
  const [draftNote, setDraftNote] = useState(props.initialValue);

  return (
    <CreationModal {...props}>
      <View
        sx={{
          backgroundColor: "$primary.600",
          paddingY: "$3",
          paddingX: "$3",
          borderTopLeftRadius: "sm",
          borderTopRightRadius: "sm",
        }}
      >
        <Text sx={{ color: "$white" }}>New Note</Text>
      </View>
      <TextInput
        multiline
        autoFocus
        placeholder="Type here"
        value={draftNote}
        onChangeText={(text) => setDraftNote(text)}
        sx={{
          backgroundColor: "white",
          padding: "$3",
          mt: "$2",
          borderBottomWidth: 1,
          minHeight: 75,
          maxHeight: 150,
          overflow: "scroll",
          borderBottomColor: "$gray.300",
        }}
      />
      <DefaultCreationModalActions
        onClose={props.onDismiss}
        createDisabled={draftNote === ""}
        onCreate={() => {
          const eventData = {
            rawText: draftNote,
          };
          const resource = createNoteLocal(eventData);
          props.onCreate({
            resource,
            event: eventData,
          });
          setDraftNote("");
          props.onDismiss && props.onDismiss();
        }}
      />
    </CreationModal>
  );
}

function ReminderCreationModal(props: ResourceCreationModalProps) {
  return (
    <CreationModal {...props}>
      <ReminderCreationModalBody {...props} />
    </CreationModal>
  );
}

function ReminderCreationModalBody(props: ResourceCreationModalProps) {
  const [mode, setMode] = useState<"input" | "confirmation">("input");
  const [reminderInfo, setReminderInfo] = useState<ReminderNewEventParams>();
  function handleClose() {
    props.onDismiss && props.onDismiss();
    setMode("input");
    setReminderInfo(undefined);
  }
  const addToast = useToast();
  return (
    <View>
      <View
        sx={{
          backgroundColor: "$primary.600",
          paddingY: "$3",
          paddingX: "$3",
          borderTopLeftRadius: "sm",
          borderTopRightRadius: "sm",
          justifyContent: "space-between",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <Text sx={{ color: "$white" }}>New Reminder</Text>
      </View>
      {mode === "input" && (
        <ReminderCreationForm
          onSubmit={(text) => {
            const date = chrono.parseDate(text, new Date()) ?? new Date();
            const parseResults = chrono.parse(text);
            const parsed = parseResults[0] ?? { text: "" };
            const updatedText = text.replace(parsed.text, "").trim();
            const eventData = {
              title: updatedText,
              description: "",
              date_utc: date.toISOString(),
              is_recurring: false,
              recurring_rate: "NEVER",
            };
            setReminderInfo(eventData);
            setMode("confirmation");
          }}
          onClose={handleClose}
          initialMagicValue={props.initialValue}
        />
      )}
      {mode === "confirmation" && reminderInfo && (
        <ReminderConfirmationForm
          reminderInfo={reminderInfo}
          onSubmit={(confirmedInfo) => {
            const eventData = confirmedInfo;
            const resource = createReminderLocal(eventData);
            props.onCreate({
              resource,
              event: eventData,
            });
            handleClose();
          }}
          onClose={handleClose}
        />
      )}
    </View>
  );
}

function ReminderCreationForm(props: {
  onSubmit: (value: string) => void;
  onClose: () => void;
  initialMagicValue: string | undefined;
}) {
  const [magicValue, setMagicValue] = useState(props.initialMagicValue ?? "");
  return (
    <View>
      <TextInput
        value={magicValue}
        autoFocus
        onChangeText={(text) => setMagicValue(text)}
        returnKeyType="done"
        onSubmitEditing={() => props.onSubmit(magicValue)}
        placeholder="Call Dan at 3pm tomorrow"
        sx={{
          flexDirection: "row",
          padding: "$3",
          margin: "$2",
          borderWidth: 1,
          borderRadius: 50,
          borderColor: "$primary.300",
          backgroundColor: "$white",
        }}
      />
      <DefaultCreationModalActions
        createText="Continue"
        onCreate={() => {
          props.onSubmit(magicValue);
        }}
        createDisabled={magicValue === ""}
        onClose={props.onClose}
      />
    </View>
  );
}

function ReminderConfirmationForm(props: {
  onSubmit: (confirmedInfo: ReminderNewEventParams) => void;
  onClose: () => void;
  reminderInfo: ReminderNewEventParams;
}) {
  const [title, setTitle] = useState(props.reminderInfo.title);
  const [date, setDate] = useState(new Date(props.reminderInfo.date_utc));
  const onDateChange = (event: any, selectedDate: any) => {
    setDate(selectedDate);
  };
  const onTimeChange = (event: any, selectedDate: any) => {
    setDate(selectedDate);
  };
  return (
    <View>
      <TextInput
        value={title}
        onChangeText={(text) => setTitle(text)}
        placeholder="Call Dan at 3pm tomorrow"
        sx={{
          flexDirection: "row",
          padding: "$3",
          margin: "$2",
          borderWidth: 1,
          borderRadius: 50,
          borderColor: "$primary.300",
          backgroundColor: "$white",
        }}
      />
      <View
        sx={{
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <DateTimePicker
          testID="dateTimePicker"
          value={date}
          mode={"date"}
          style={{ width: 100 }}
          onChange={onDateChange}
        />
        <DateTimePicker
          testID="dateTimePicker"
          value={date}
          mode={"time"}
          style={{ width: 100 }}
          onChange={onTimeChange}
        />
      </View>
      <DefaultCreationModalActions
        onCreate={() => {
          const confirmedInfo = {
            ...props.reminderInfo,
            title,
            date_utc: date.toISOString(),
          };
          props.onSubmit(confirmedInfo);
          props.onClose();
        }}
        createDisabled={title === ""}
        onClose={props.onClose}
      />
    </View>
  );
}

function EventCreationModal(props: ResourceCreationModalProps) {
  const [mode, setMode] = useState<"input" | "confirmation">("input");
  const [eventInfo, setEventInfo] = useState<ScheduleItemNewEventParams>();
  function handleClose() {
    props.onDismiss && props.onDismiss();
    setMode("input");
    setEventInfo(undefined);
  }
  return (
    <CreationModal {...props}>
      <View
        sx={{
          backgroundColor: "$primary.600",
          paddingY: "$3",
          paddingX: "$3",
          borderTopLeftRadius: "sm",
          borderTopRightRadius: "sm",
          justifyContent: "space-between",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <Text sx={{ color: "$white" }}>New Event</Text>
      </View>
      {mode === "input" && (
        <EventCreationForm
          onSubmit={(text) => {
            const date = chrono.parseDate(text, new Date()) ?? new Date();
            const parseResults = chrono.parse(text);
            const parsed = parseResults[0] ?? { text: "" };
            const updatedText = text.replace(parsed.text, "").trim();
            const eventData = {
              title: updatedText,
              description: "",
              start_date_utc: date.toISOString(),
              end_date_utc: addHours(date, 1).toISOString(),
              all_day: false,
              reminder_offset_minutes: 30,
            };
            setEventInfo(eventData);
            setMode("confirmation");
          }}
          onClose={handleClose}
          initialMagicValue={props.initialValue}
        />
      )}
      {mode === "confirmation" && eventInfo && (
        <EventConfirmationForm
          eventInfo={eventInfo}
          onSubmit={(confirmedInfo) => {
            const eventData = confirmedInfo;
            const resource = createScheduleItemLocal(eventData);
            props.onCreate({
              resource,
              event: eventData,
            });
            handleClose();
          }}
          onClose={handleClose}
        />
      )}
    </CreationModal>
  );
}

function EventCreationForm(props: {
  onSubmit: (value: string) => void;
  onClose: () => void;
  initialMagicValue: string | undefined;
}) {
  const [magicValue, setMagicValue] = useState(props.initialMagicValue ?? "");
  return (
    <View>
      <TextInput
        value={magicValue}
        autoFocus
        onChangeText={(text) => setMagicValue(text)}
        placeholder="Backyard BBQ at 7pm on Fri"
        returnKeyType="done"
        onSubmitEditing={() => props.onSubmit(magicValue)}
        sx={{
          flexDirection: "row",
          padding: "$3",
          margin: "$2",
          borderWidth: 1,
          borderRadius: 50,
          borderColor: "$primary.300",
          backgroundColor: "$white",
        }}
      />
      <DefaultCreationModalActions
        createText="Continue"
        createDisabled={magicValue === ""}
        onCreate={() => {
          props.onSubmit(magicValue);
        }}
        onClose={props.onClose}
      />
    </View>
  );
}

function EventConfirmationForm(props: {
  onSubmit: (confirmedInfo: ScheduleItemNewEventParams) => void;
  onClose: () => void;
  eventInfo: ScheduleItemNewEventParams;
}) {
  const [title, setTitle] = useState(props.eventInfo.title);
  const [startDate, setStartDate] = useState(
    new Date(props.eventInfo.start_date_utc)
  );
  const [endDate, setEndDate] = useState(
    new Date(props.eventInfo.end_date_utc)
  );
  const onStartDateTimeChange = (event: any, selectedDate: any) => {
    setStartDate(selectedDate);
  };
  const onEndDateTimeChange = (event: any, selectedDate: any) => {
    setEndDate(selectedDate);
  };
  return (
    <View>
      <TextInput
        value={title}
        onChangeText={(text) => setTitle(text)}
        placeholder="Backyard BBQ at 7pm on Fri"
        sx={{
          flexDirection: "row",
          padding: "$3",
          margin: "$2",
          borderWidth: 1,
          borderRadius: 50,
          borderColor: "$primary.300",
          backgroundColor: "$white",
        }}
      />
      <View
        sx={{
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          mx: 12,
          my: 2,
        }}
      >
        <Text sx={{ fontWeight: "bold" }}>Start:</Text>
        <View
          sx={{
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <DateTimePicker
            value={startDate}
            mode={"date"}
            style={{ width: 100, marginLeft: 4 }}
            onChange={onStartDateTimeChange}
          />
          <DateTimePicker
            value={startDate}
            mode={"time"}
            style={{ width: 100, marginLeft: 4 }}
            onChange={onStartDateTimeChange}
          />
        </View>
      </View>
      <View
        sx={{
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "center",
          mx: 12,
          my: 2,
        }}
      >
        <Text sx={{ fontWeight: "bold" }}>End:</Text>
        <View
          sx={{
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <DateTimePicker
            value={endDate}
            mode={"date"}
            style={{ width: 100, marginLeft: 4 }}
            onChange={onEndDateTimeChange}
          />
          <DateTimePicker
            value={endDate}
            mode={"time"}
            style={{ width: 100, marginLeft: 4 }}
            onChange={onEndDateTimeChange}
          />
        </View>
      </View>

      <DefaultCreationModalActions
        onCreate={() => {
          const confirmedInfo = {
            ...props.eventInfo,
            title,
            start_date_utc: startDate.toISOString(),
            end_date_utc: endDate.toISOString(),
          };
          props.onSubmit(confirmedInfo);
          props.onClose();
        }}
        createDisabled={title === ""}
        onClose={props.onClose}
      />
    </View>
  );
}

function CreationModal(props: ModalProps) {
  return (
    <Modal {...props} transparent animationType="fade">
      <View
        sx={{
          position: "absolute",
          height: "100%",
          width: "100%",
          backgroundColor: "$aspenDark",
          opacity: 0.5,
        }}
      />
      <KeyboardAvoidingView
        behavior={"height"}
        style={{ height: "100%" }}
        keyboardVerticalOffset={-100}
      >
        <ToastContextProvider>
          <MotiView
            style={{
              justifyContent: "center",
              alignItems: "center",
              flex: 1,
              marginBottom: 150,
            }}
            // from={{ translateY: 20 }}
            // animate={{ translateY: 0 }}
            // transition={{ type: "timing" }}
          >
            <View
              sx={{
                maxWidth: "75%",
                backgroundColor: "$white",
                borderRadius: "sm",
                justifyContent: "flex-start",
              }}
            >
              {props.children}
            </View>
          </MotiView>
        </ToastContextProvider>
      </KeyboardAvoidingView>
    </Modal>
  );
}

function DefaultCreationModalActions(props: {
  onCreate?: () => void;
  createDisabled?: boolean;
  createText?: string;
  onClose?: () => void;
  closeText?: string;
}) {
  return (
    <View sx={{ flexDirection: "row" }}>
      <Button
        variant="ghost"
        sx={{
          borderRadius: 0,
          borderBottomLeftRadius: "sm",
          // borderTopWidth: 1,
          // borderColor: "$gray.300",
          width: "50%",
          paddingY: "$4",
        }}
        _textStyles={{ color: "$gray.500" }}
        onPress={() => {
          props.onClose && props.onClose();
        }}
      >
        {props.closeText ?? "Close"}
      </Button>
      <Button
        variant="ghost"
        sx={{
          borderRadius: 0,
          borderBottomRightRadius: "sm",
          // borderTopWidth: 1,
          // borderLeftWidth: 1,
          // borderColor: "$gray.300",
          width: "50%",
          paddingY: "$4",
        }}
        disabled={props.createDisabled ?? false}
        _textStyles={{ fontWeight: "bold" }}
        onPress={() => {
          props.onCreate && props.onCreate();
        }}
      >
        {props.createText ?? "Attach"}
      </Button>
    </View>
  );
}
