import { useMemo } from "react";
import { useRecoilValue } from "recoil";
import {
  spaceMembersBySpace,
  spaceMembersState,
} from "../state/atoms/space_members";
import { SpaceMembersInfoFragment } from "../state/generated/graphql";
import { useUser } from "./auth";
import usePeople from "./usePeople";

export function useSpaceMembers(spaceId: string | undefined) {
  const spaceMembersMap = useSpaceMembersBySpace();
  return useMemo(() => {
    if (!spaceId) return undefined;
    return spaceMembersMap.get(spaceId);
  }, [spaceMembersMap, spaceId]);
}

export function useSpaceMemberLookup(
  spaceId: string | undefined
): Map<string, SpaceMembersInfoFragment> {
  const spaceMembersMap = useSpaceMembersBySpace();
  return useMemo(() => {
    if (!spaceId) return new Map();
    return new Map(
      [...(spaceMembersMap.get(spaceId)?.values() ?? [])].map((sm) => [
        sm.user_id,
        sm,
      ])
    );
  }, [spaceId, spaceMembersMap]);
}

export function useSpaceMembersBySpace() {
  return useRecoilValue(spaceMembersBySpace);
}

export function useAllSpaceMembers() {
  return useRecoilValue(spaceMembersState);
}

export function useCurrentUserMemberships() {
  const allMemberships = useAllSpaceMembers();
  const currentUser = useUser();
  return useMemo(() => {
    return allMemberships.filter((sm) => sm.user_id === currentUser?.id);
  }, [allMemberships, currentUser]);
}

export function useSpaceMember(
  spaceId: string | undefined,
  userId: string | undefined
) {
  const spaceMembers = useSpaceMembers(spaceId);
  const member = useMemo(() => {
    const membersList = spaceMembers ?? [];
    return membersList.find((sm) => userId && sm.user_id === userId);
  }, [spaceMembers, userId]);
  return member;
}

export function useSortedSpaceMembers(spaceId) {
  const spaceMembers = useSpaceMembers(spaceId) ?? [];
  const currentUser = useUser();
  const people = usePeople();
  const getName = (member: SpaceMembersInfoFragment) =>
    member.user_id ? people.get(member.user_id)?.name : undefined;
  const getPhone = (member: SpaceMembersInfoFragment) =>
    member.invitation?.status === "PENDING" &&
    member.invitation?.external_metadata
      ? member.invitation?.external_metadata?.value
      : undefined;

  return useMemo(() => {
    return [...spaceMembers].sort((a, b) => {
      const aName = getName(a);
      const aPhone = getPhone(a);
      const bName = getName(b);
      const bPhone = getPhone(b);
      if (a.user_id === currentUser?.id) return -1;
      if (b.user_id === currentUser?.id) return 1;

      if (aName && bName) return aName < bName ? -1 : 1;
      if (aName && !bName) return -1;
      if (!aName && bName) return 1;

      return (aPhone ?? "") < (bPhone ?? "") ? -1 : 1;
    });
  }, [spaceMembers, currentUser, people]);
}
