import { Sx } from "dripsy";
import { ComponentProps, useCallback, useState } from "react";
import { getIconForApp } from "../utils/apps";
import { View, Icon, Image } from "./basics";

function iconNameCleaner(name: string) {
  switch (name.toLowerCase()) {
    case "pages":
    case "notes":
      return "notes";
    case "photos":
      return "photos";
    case "polls":
      return "polls";
    case "chat":
      return "chat";
    case "shopping-list":
    case "shoppinglist":
      return "shopping-list";
    case "calendar":
    case "schedule":
      return "schedule";
    case "reminders":
    case "reminder":
      return "reminders";
    case "profiles":
      return "people";
    default:
      return name;
  }
}

function getIconUri(appName: string) {
  return `https://www.aspen.cloud/_next/image?url=%2Fapp-icons%2F${appName}.png&w=3840&q=100`;
}

interface SpaceAppIconProps extends ComponentProps<typeof Image> {
  appName: string;
  size?: "sm" | "md" | "lg";
  focused?: boolean;
}

const imageSizes: Record<string, Sx> = {
  sm: { width: 24, height: 24 },
  md: { width: 36, height: 36 },
  lg: { width: 48, height: 48 },
  xl: { width: 72, height: 72 },
  "2xl": { width: 96, height: 96 },
};

function SpaceAppIcon({
  appName,
  size = "sm",
  focused = true,
}: SpaceAppIconProps) {
  const [showFallbackIcon, setShowFallbackIcon] = useState(false);
  const cleanAppName = iconNameCleaner(appName);
  const onError = useCallback(() => setShowFallbackIcon(true), []);
  return showFallbackIcon ? (
    <View
      sx={{
        ...imageSizes[size],
        alignItems: "center",
        justifyContent: "center",
        opacity: focused ? 1 : 0.5,
      }}
    >
      <Icon
        size="xl"
        name={getIconForApp(appName)}
        sx={{ color: "$aspenDark" }}
      />
    </View>
  ) : (
    <Image
      source={{
        uri: getIconUri(cleanAppName),
      }}
      onError={onError}
      sx={{ ...imageSizes[size], opacity: focused ? 1 : 0.5 }}
    />
  );
}

export default SpaceAppIcon;
