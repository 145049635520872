// type EventHandlers<R, E> =
//   | { REMOVE: () => void }
//   | { NEW: <T>(resource: null, event_data: T) => R }
//   | Record<keyof E, <EK extends keyof E>(resource: R, eventData: E[EK]) => R>;

import { LogEventFragment as LogEvent } from "../state/generated/graphql";

type EventHandlers<R, E> = Record<
  keyof E,
  <EK extends keyof E>(
    resource: R,
    event: Required<LogEvent> & { data: E[EK] },
    fromServer: boolean
  ) => R
>;

export default class Resource<
  T,
  E extends { REMOVE?: undefined } & { NEW?: any } & Record<string, any>
> {
  static resources: Record<string, Resource<any, any>> = {};
  constructor(
    readonly collectionKey: string,
    readonly events: EventHandlers<T, E>
  ) {
    Resource.resources[collectionKey] = this;
  }

  static lookupResourceByCollectionKey(
    key: string
  ): Resource<any, any> | undefined {
    return Resource.resources[key];
  }

  static canHandleResourceType(collectionKey: string) {
    return collectionKey in Resource.resources;
  }

  static reduceEvents(
    resourceData: any,
    events: Required<LogEvent>[],
    fromServer: boolean
  ): { data: any; deleted: boolean } {
    let reducedData = resourceData;
    let deleted = false;
    for (const event of events) {
      const resourceUpdate = Resource._reduceEvent(
        reducedData,
        event,
        fromServer
      );
      if (resourceUpdate.deleted) {
        deleted = true;
        break;
      }
      reducedData = resourceUpdate.data;
    }
    return { data: reducedData, deleted };
  }

  private static _reduceEvent(
    resourceData: any,
    event: Required<LogEvent>,
    fromServer: boolean
  ): { data: any; deleted: boolean } {
    if (event.type === "REMOVE") return { data: undefined, deleted: true };
    const resourceReducer = this.lookupResourceByCollectionKey(
      event.resource_key
    );

    return {
      data: resourceReducer!.applyEvent(
        resourceData,
        event.type,
        event,
        fromServer
      ),
      deleted: false,
    };
  }

  applyEvent<ET extends keyof E>(
    resourceData: T,
    eventType: keyof Required<E>,
    event: Required<LogEvent> & { data: E[ET] },
    fromServer: boolean
  ) {
    return this.events[eventType](resourceData, event, fromServer);
  }

  createEvent<EventType extends keyof E>(
    eventType: EventType,
    data: E[EventType]
  ) {}
}
