import { useNavigation } from "@react-navigation/native";
import { useSpace } from "../hooks/spaces";
import { usePerson } from "../hooks/usePeople";
import { acceptInvitation, declineInvitation } from "../lib/invitations";
import {
  InvitationInfoFragment,
  SpaceInfoFragment,
} from "../state/generated/graphql";
import { Button, View, Text } from "./basics";

export default function SpaceFeedInvitationCard({
  spaceId,
  invitation,
}: {
  spaceId: string;
  invitation: InvitationInfoFragment;
}) {
  const { loading, data: space } = useSpace(spaceId);
  const inviter = usePerson(invitation.inviter_id);
  const navigation = useNavigation();
  if (loading) return null;
  return (
    <View
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: "$secondary.400",
        marginX: "$8",
        borderBottomLeftRadius: "sm",
        borderBottomRightRadius: "sm",
      }}
    >
      {!!inviter && (
        <View
          sx={{
            flexDirection: "row",
            paddingY: "$2",
            alignItems: "center",
          }}
        >
          <Text>Invited by</Text>
          <Button
            variant="unstyled"
            _containerStyles={{ paddingY: 0, paddingX: 0 }}
            _textStyles={{
              fontWeight: "bold",
            }}
            onPress={() => {
              navigation.navigate("UserProfile", {
                userId: inviter?.id!,
                type: "PROFILE",
              });
            }}
          >
            {inviter?.name}
          </Button>
        </View>
      )}
      <View
        sx={{
          flexDirection: "row",
          width: "100%",
          justifyContent: "space-evenly",
        }}
      >
        <Button
          colorScheme="$success"
          sx={{ marginY: "$1", paddingY: "$1", width: "40%" }}
          onPress={async () => {
            const { error } = await acceptInvitation(invitation.id);
            if (error) {
              console.error(error);
              throw error;
            }
            navigation.navigate("Space", {
              spaceId: space.id,
            });
          }}
        >
          Accept
        </Button>
        <Button
          colorScheme="$secondary"
          sx={{ marginY: "$1", paddingY: "$1", width: "40%" }}
          onPress={async () => {
            const { error } = await declineInvitation(invitation.id);
            if (error) {
              console.error(error);
              throw error;
            }
          }}
        >
          Delete
        </Button>
      </View>
    </View>
  );
}
