import ResourceCollection from "./resource";

interface Note {
  rawText: string;
}

export interface NoteNewEventParams {
  rawText: string;
}

export const PAGES_RESOURCE_KEY = "NOTES";

const noteResource = new ResourceCollection<
  Note,
  {
    NEW: NoteNewEventParams;
    EDIT: { rawText: string };
  }
>(PAGES_RESOURCE_KEY, {
  NEW: (_resource, { data }) => ({
    rawText: data.rawText || "",
  }),
  EDIT: (resource, { data }) => {
    return {
      ...resource,
      rawText: data.rawText,
    };
  },
});

export default noteResource;
