import { startOfDay } from "date-fns";
import { rrulestr } from "rrule";

interface ReminderResource {
  id: string;
  data: any;
}

interface ReminderView {
  resource: ReminderResource;
  displayDate: Date;
}

export function filterAndSetupRemindersForDisplay(
  reminderDefinitions: any[]
): ReminderView[] {
  const maxExceptionDates: Record<string, Date> = {};
  const acceptedParentDates: Record<string, Date> = {};
  const unfilteredRecurrences = reminderDefinitions.reduce(
    (reminderObjs, rd) => {
      if (rd.data.is_recurring) {
        // When reading a recurring reminder definition, if the next recurrence (WITHOUT EXCEPTIONS) is later than our max known standalone (exception) reminder
        // then "accept" the parent and display it
        const rruleset = rrulestr(rd.data.recurrence_pattern, {
          forceset: true,
        });
        const nextRecurrence = startOfDay(
          // Recurrence ignore exceptions
          // @ts-ignore
          rruleset._rrule[0].after(startOfDay(new Date()))
        );
        if (
          !maxExceptionDates[rd.id] ||
          maxExceptionDates[rd.id] < nextRecurrence
        ) {
          acceptedParentDates[rd.id] = nextRecurrence;
        }
      } else if (rd?.data?.parent_id) {
        // When reading a child reminder definition, update the latest exception date and if the reminder date is
        // greater than an "accepted" parent, then remove it from the display set
        const parentId = rd?.data?.parent_id;
        const date = new Date(rd.data.start_date_utc);
        const currentMaxException = maxExceptionDates[parentId];
        if (!currentMaxException || date > currentMaxException) {
          maxExceptionDates[parentId] = date;
        }
        const parentDate = acceptedParentDates[parentId];
        if (parentDate && date >= parentDate) {
          delete acceptedParentDates[parentId];
        }
      }

      // Push all reminders and filter out in next step
      const nextStart = rd.data.is_recurring
        ? rrulestr(rd.data.recurrence_pattern, {
            forceset: true,
          }).after(startOfDay(new Date()))
        : new Date(rd.data.start_date_utc);
      if (nextStart) {
        reminderObjs.push({ resource: rd, displayDate: nextStart });
      }
      return reminderObjs;
    },
    []
  );
  const parentsToKeep = new Set(Object.keys(acceptedParentDates));
  return unfilteredRecurrences.filter(
    (obj) =>
      !obj.resource.data.is_recurring || parentsToKeep.has(obj.resource.id)
  );
}
