import { useUser } from "../hooks/auth";
import { useSpaceMember, useSpaceMembers } from "../hooks/useSpaceMembers";
import { SpaceInfoFragment } from "../state/generated/graphql";
import {
  Text,
  Row,
  Icon,
  View,
  Pressable,
  Divider,
} from "../components/basics";
import { CoverPhotoView } from "./CoverPhotoView";
import SpaceAvatars, { PeopleAvatars } from "../components/SpaceAvatars";
import { ReactElement, useMemo } from "react";
import { SpaceCreationParams } from "../state/atoms/spaces";
import { useConnectionQuery } from "../hooks/useConnections";
import { Person } from "../utils/people";

export function SpaceIcon({ space, size }: { space: any; size: number }) {
  const currentUser = useUser();
  const spaceMembers = useSpaceMembers(space?.id) ?? [];
  if (!space) return null;
  return space.cover_photo ? (
    <View
      sx={{
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <CoverPhotoView
        size={size}
        rounded={true}
        coverPhoto={space.cover_photo}
      />
    </View>
  ) : (
    <SpaceAvatars
      spaceMembers={
        spaceMembers.length > 1
          ? spaceMembers.filter((member) => member.user_id !== currentUser!.id)
          : spaceMembers
      }
      size={size}
    />
  );
}

function SimpleSpaceListItem({
  space,
  handlePress,
  selectedSpaces,
}: {
  space: SpaceInfoFragment;
  handlePress?: () => void | Promise<void>;
  selectedSpaces?: any;
}) {
  const currentUser = useUser();
  const spaceMembers = useSpaceMembers(space.id) ?? [];
  const isSelected = !!selectedSpaces?.has(space.id);

  return (
    <SimpleSpaceListItemInner
      name={space.display_name}
      iconComponent={
        space?.cover_photo ? (
          <View
            sx={{
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CoverPhotoView
              size={48}
              rounded={true}
              coverPhoto={space.cover_photo}
            />
          </View>
        ) : (
          <SpaceAvatars
            spaceMembers={
              spaceMembers.length > 1
                ? spaceMembers.filter(
                    (member) => member.user_id !== currentUser!.id
                  )
                : spaceMembers
            }
          />
        )
      }
      handlePress={handlePress}
      isSelected={isSelected}
    />
  );
}

export function PendingSpaceListItem({
  space,
  handlePress,
}: {
  space: SpaceCreationParams;
  handlePress?: () => void | Promise<void>;
}) {
  const connectionQuery = useConnectionQuery();
  const people = useMemo(() => {
    return (space.invitations ?? []).reduce<Person[]>((people, invite) => {
      const profile = connectionQuery("profiles_by_id", invite.user_id);
      if (profile) {
        people.push(profile);
        return people;
      }
      const phone =
        invite?.external_metadata?.type === "PHONE"
          ? invite?.external_metadata?.value
          : undefined;
      if (phone) {
        const contact = phone
          ? connectionQuery("contacts_by_phone", phone)
          : undefined;
        if (contact) {
          people.push(contact);
          return people;
        } else {
          people.push({
            key: "PHONE",
            id: phone,
            name: phone,
            data: phone,
          });
          return people;
        }
      }

      return people;
    }, []);
  }, [space.invitations, connectionQuery]);

  const nameFallback = people.map((p) => p.name).join(", ");

  return (
    <SimpleSpaceListItemInner
      name={space.name || nameFallback}
      iconComponent={<PeopleAvatars people={people} />}
      handlePress={handlePress}
      isSelected={true}
    />
  );
}

export function SimpleSpaceListItemInner({
  name,
  iconComponent,
  handlePress,
  isSelected,
}: {
  name: string;
  iconComponent?: React.ReactElement;
  handlePress?: () => void | Promise<void>;
  isSelected: boolean;
}) {
  return (
    <Pressable onPress={handlePress}>
      <Row h={70} justifyContent="space-between" alignItems="center">
        <View
          sx={{
            width: 70,
            flexDirection: "row",
            justifyContent: "center",
          }}
        >
          {iconComponent}
        </View>
        <Text
          numberOfLines={1}
          sx={{
            // fontWeight: isSelected ? "bold" : "medium",
            // color: isSelected ? "$primary.600" : "$black",
            ml: 4,
            flexGrow: 1,
            flexShrink: 2,
          }}
        >
          {name}
        </Text>
        {!!handlePress && (
          <Row alignItems="center" mx={4} flexShrink={0}>
            <View
              sx={{
                borderRadius: 50,
                borderWidth: 2,
                width: 25,
                height: 25,
                borderColor: isSelected ? "$primary.600" : "$gray.400",
                backgroundColor: isSelected ? "$primary.600" : "transparent",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {isSelected && (
                <Icon name="checkmark-sharp" size={16} color={"$white"} />
              )}
            </View>
          </Row>
        )}
      </Row>
      <Divider />
    </Pressable>
  );
}

export default SimpleSpaceListItem;
