import { useNavigation } from "@react-navigation/native";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  View,
  Spinner,
  Pressable,
  Center,
  IconButton,
  Text,
} from "../../components/basics";
import { useCustomResourceCollection } from "../../hooks/useResourceCollection";
import { flushUploadQueue, getUploadUrl } from "../../utils/image-uploads";
import CenteredSpinner from "../../components/basics/CenteredSpinner";
import { optimizedImagePath } from "../../utils/images";
import { ChatImage } from "../../components/ChatImage";
import { CHAT_RESOURCE_KEY } from "../../resources/chat";
import { PHOTO_RESOURCE_KEY } from "../../resources/photo";
import usePendingResources, {
  useMergedResources,
} from "../../hooks/usePendingResources";
import {
  ImageFlatlist,
  ImageListRenderItem,
} from "../../components/ImageFlatlist";
import {
  ActionSheetOptions,
  useActionSheet,
} from "@expo/react-native-action-sheet";
import { cancelDelayedEvents, flushEventOutbox } from "../../lib/event-outbox";
import { normalizeAttachments } from "../../utils/apps/chat";
import MediaGallery from "../../components/MediaGallery";
import { ResourceVideoThumbnail } from "../../components/video-thumbail";
import { readUploadQueueState } from "../../lib/upload-queue";
import { useSpacesMap } from "../../hooks/spaces";
import { SpaceIcon } from "../../components/SimpleSpaceListItem";

export interface Photo {
  uploader?: string;
  imageKey: string;
  uri: string;
  parentResource: any;
  type: "photo" | "video";
}

const NUM_COLUMNS = 3;
const MARGIN_SIZE = 4;

function getPhotoResourceType(type: string) {
  switch (type) {
    case "video":
      return "video";
    case "image":
    default:
      return "photo";
  }
}

export function transformChatAndPhotoResources(resources: any[]) {
  return resources.flatMap<Photo>((resource) => {
    if (resource.collection_key === CHAT_RESOURCE_KEY) {
      const attachments = normalizeAttachments(resource.data.attachment) ?? [];
      return attachments.map((attachment) => {
        return {
          imageKey: attachment.id,
          uploader: resource.data.sender_id,
          uri: getUploadUrl(attachment.id),
          parentResource: resource,
          type: attachment.type,
        };
      });
    } else if (resource.collection_key === PHOTO_RESOURCE_KEY) {
      // For some reason this just doenst sit well with me
      // Would like a more consistent place to handle pending state vs real state
      // (lots of this in an effort to get thumbnails / instant data)
      const type = getPhotoResourceType(resource.data.type);
      let uri;
      if (resource._is_pending) {
        const queueState = readUploadQueueState(resource.data.key);
        uri = queueState?.localUri || queueState?.uri;
      }
      if (!uri) uri = getUploadUrl(resource.data.key);
      return [
        {
          imageKey: resource.data.key,
          uploader: undefined,
          uri,
          parentResource: resource,
          type,
        },
      ];
    } else {
      return [];
    }
  });
}

export default function PhotosScreen({ route }) {
  const navigation = useNavigation();
  const { spaceId } = route.params ?? {};

  const {
    resources: commitedPhotoResources,
    error,
    fetching,
    getMore,
    hasMore,
  } = useCustomResourceCollection({
    resourceQueries: {
      [PHOTO_RESOURCE_KEY]: {},
      [CHAT_RESOURCE_KEY]: { data: { _has_key: "attachment" } },
    },
    sort: {
      created_at: {
        _direction: "desc",
      },
      id: {
        _direction: "asc",
      },
    },
    spaceId,
    pageSize: 60,
  });

  const pendingPhotoResources = usePendingResources({
    resourceQueries: {
      [PHOTO_RESOURCE_KEY]: {},
      [CHAT_RESOURCE_KEY]: { data: { _has_key: "attachment" } },
    },
    spaceId,
  });

  const resources = useMergedResources(
    commitedPhotoResources,
    pendingPhotoResources
  );

  const [imageViewerState, setImageViewerState] = useState({
    isOpen: false,
    index: 0,
  });

  const photos = transformChatAndPhotoResources(resources);

  const handleAddButtonPress = useCallback(() => {
    navigation.navigate("PhotoPicker", { spaceId });
  }, []);

  const renderImageTile: ImageListRenderItem<Photo> = ({
    index,
    item,
    size,
  }) => {
    return (
      <ImageTile
        key={item.imageKey}
        index={index}
        item={item}
        handleImagePress={() =>
          setImageViewerState({
            index: index,
            isOpen: true,
          })
        }
        size={size}
      />
    );
  };

  const galleryPhotos = photos.map((photo) => {
    const type = photo.type || "photo";
    return {
      uri:
        type === "photo"
          ? optimizedImagePath(photo.uri, { w: 828 })
          : photo.uri,
      key: photo.imageKey,
      type,
      parentResource: photo.parentResource,
    };
  });

  const handleLightboxClose = useCallback(() => {
    setImageViewerState((prevState) => ({
      ...prevState,
      isOpen: false,
    }));
  }, [setImageViewerState]);

  if (!(commitedPhotoResources || error))
    return (
      <CenteredSpinner text="Loading photos" spinnerProps={{ size: "lg" }} />
    );

  return (
    <>
      <View
        sx={{
          flex: 1,
        }}
      >
        {!fetching && photos.length === 0 && (
          <Text
            sx={{
              fontStyle: "italic",
              color: "$gray.400",
              textAlign: "center",
              marginTop: "80%",
            }}
          >
            Photos added to your space will appear here.
          </Text>
        )}
        <ImageFlatlist
          data={photos}
          renderItem={renderImageTile}
          numColumns={NUM_COLUMNS}
          keyExtractor={(item) => item.imageKey}
          onEndReached={() => {
            if (hasMore && !fetching) getMore();
          }}
          onEndReachedThreshold={0.5}
        />
        {fetching && (
          <Center>
            <Spinner my={8} />
          </Center>
        )}
        <IconButton
          icon="add-outline"
          _iconStyles={{ fontSize: "3xl", color: "$black" }}
          variant="unstyled"
          _containerStyles={{
            position: "absolute",
            bottom: 0,
            right: 0,
            backgroundColor: "$aspenBlue",
            borderRadius: 50,
            width: 64,
            height: 64,
            padding: "$4",
            alignItems: "center",
            justifyContent: "center",
            margin: "$4",
            boxShadow: "md",
          }}
          onPress={handleAddButtonPress}
        />
      </View>
      <MediaGallery
        data={galleryPhotos}
        visible={imageViewerState.isOpen}
        index={imageViewerState.index}
        handleClose={handleLightboxClose}
      />
    </>
  );
}

function ImageTile({
  index,
  item,
  handleImagePress,
  size,
}: {
  index: number;
  item: Photo;
  handleImagePress: any;
  size: number;
}) {
  const MAX_ATTRIBUTIONS = 3;
  const attributionsOverflow = Math.max(
    item.parentResource.resource_permissions.length - MAX_ATTRIBUTIONS,
    0
  );
  const trimmedAttributions = item.parentResource.resource_permissions.slice(
    0,
    MAX_ATTRIBUTIONS
  );
  const { data: spacesMap } = useSpacesMap();
  return (
    <View
      sx={{
        height: size,
        width: size,
        overflow: "hidden",
        padding: 1,
        borderWidth: 1,
        borderColor: "$white",
      }}
    >
      <PressableImage
        item={item}
        handleImagePress={handleImagePress}
        size={size}
      />
      {spacesMap && (
        <View sx={{ position: "absolute", bottom: 1, right: 1 }}>
          {trimmedAttributions.map((rp) => {
            const space = spacesMap.get(rp.space_id);
            return (
              <View
                key={`${rp.space_id}|${rp.resource_id}|${rp.resource_key}`}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  mr: 2,
                }}
              >
                <SpaceIcon space={space} size={25} />
              </View>
            );
          })}
          {!!attributionsOverflow && (
            <Text sx={{ ml: 4 }}>+{attributionsOverflow}</Text>
          )}
        </View>
      )}
    </View>
  );
}

function PressableImage({
  item,
  handleImagePress,
  size,
}: {
  item: Photo;
  handleImagePress: any;
  size: number;
}) {
  const { showActionSheetWithOptions } = useActionSheet();
  const defaultActionSheetOptions: ActionSheetOptions = {
    options: ["Retry", "Delete", "Close"],
    destructiveButtonIndex: 1,
    cancelButtonIndex: 2,
  };
  const handleImageLongPress = useCallback(() => {
    if (item.parentResource._is_pending) {
      showActionSheetWithOptions(defaultActionSheetOptions, (buttonIndex) => {
        switch (buttonIndex) {
          case 0:
            flushUploadQueue([item.imageKey]);
            flushEventOutbox();
            break;
          case 1:
            cancelDelayedEvents([item.parentResource.id]);
            flushEventOutbox();
            break;
          default:
            break;
        }
      });
    }
  }, [item]);
  return (
    <Pressable
      key={item.imageKey}
      onPress={handleImagePress}
      onLongPress={handleImageLongPress}
      style={({ pressed }) => pressed && { opacity: 0.8 }}
    >
      {item.type === "photo" && (
        <ChatImage
          attachmentKey={item.imageKey}
          source={{ uri: optimizedImagePath(item.uri, { w: 256 }) }}
          height={size}
          width={size}
          alt="photo"
        />
      )}
      {item.type === "video" && (
        <ResourceVideoThumbnail resource={item} width={size} height={size} />
      )}
    </Pressable>
  );
}
