import { useNavigation } from "@react-navigation/native";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { Header } from "@react-navigation/elements";
import {
  Button,
  TextInput,
  View,
  Text,
  Pressable,
  Icon,
} from "../../components/basics";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  SafeAreaView,
  useSafeAreaInsets,
} from "react-native-safe-area-context";
import { useUser } from "../../hooks/auth";
import { RootStackParamList } from "../../types";
import SpaceMemberSelector, {
  SelectedMemberItem,
} from "../../components/SpaceMemberSelector";
import {
  generateExternalInvitations,
  generateProfileInvitations,
} from "../../lib/invitations";
import { parsePhoneNumber } from "../../utils/phone";
import { createNewSpace, SpaceCreationParams } from "../../state/atoms/spaces";
import { useHeaderHeight } from "@react-navigation/elements";
import usePeople from "../../hooks/usePeople";
import useSharedNavigationValue from "../../hooks/useSharedNavigationValue";
import { ProfileItem } from "../../utils/people";
import { useToast } from "../../hooks/useToast";
import { reportError } from "../../state/utils/errors";
import { useSx } from "dripsy";

export default function CreateGroupScreen({
  navigation,
}: NativeStackScreenProps<RootStackParamList, "CreateSpace">) {
  const showToast = useToast();
  const onSpaceCreation = useCallback(
    async (spaceParams) => {
      const { data: newSpace, error } = await createNewSpace(spaceParams);
      if (error || !newSpace) {
        showToast({
          title: "Error",
          description: "Failed to create space",
          variant: "ERROR",
        });
        if (error) reportError(error);
        else if (!newSpace) reportError("No space returned from create");
      } else {
        navigation.navigate("conversation", {
          spaceId: newSpace.id,
        });
      }
    },
    [showToast]
  );

  return <SpaceCreator onSpaceCreation={onSpaceCreation} />;
}

export function CreateMockSpaceScreen() {
  const [mockSpaces, setMockSpaces, evictMockSpaces] = useSharedNavigationValue<
    SpaceCreationParams[]
  >("resource_share_created_spaces", []);
  const navigation = useNavigation();
  const onSpaceCreation = useCallback(
    (spaceParams) => {
      setMockSpaces([...mockSpaces, spaceParams]);
      navigation.goBack();
    },
    [mockSpaces]
  );
  return <SpaceCreator onSpaceCreation={onSpaceCreation} />;
}

function SpaceCreator({
  onSpaceCreation,
}: {
  onSpaceCreation: (
    spaceCreationParams: SpaceCreationParams
  ) => void | Promise<void>;
}) {
  const navigation = useNavigation();
  const currentUser = useUser();
  const [isCreatingSpace, setIsCreatingSpace] = useState(false);
  const people = usePeople();
  const [spaceName, setSpaceName] = useState("");
  const [memberSelections, setMemberSelections] = useState<
    SelectedMemberItem[]
  >([
    {
      key: "PROFILE",
      id: currentUser!.id,
      name: people.get(currentUser!.id)?.name,
      data: people.get(currentUser!.id)!,
    },
  ]);

  const handleCreateDynamicSpace = useCallback(async () => {
    try {
      setIsCreatingSpace(true);

      const profileSubmissions = memberSelections.filter(
        (s) => s.key === "PROFILE" && s.id !== currentUser?.id
      ) as ProfileItem[];

      const phoneNumbers = memberSelections.reduce<string[]>(
        (phoneSubmissions, submission) => {
          if (submission.key === "PHONE") {
            const parsedPhone = parsePhoneNumber(submission.data.phone);
            if (parsedPhone) phoneSubmissions.push(parsedPhone);
          }

          return phoneSubmissions;
        },
        []
      );

      const profileInvites = generateProfileInvitations(
        currentUser!.id,
        profileSubmissions.map((s) => s.id)
      );
      const externalInvites = generateExternalInvitations(
        currentUser!.id,
        phoneNumbers.map((phone) => ({ type: "PHONE", value: phone }))
      );

      const spaceParams = {
        name: spaceName,
        invitations: [...profileInvites, ...externalInvites],
      };

      await onSpaceCreation(spaceParams);
    } catch (e) {
      reportError(e);
    } finally {
      setIsCreatingSpace(false);
    }
  }, [memberSelections, currentUser, spaceName]);
  const membersToDisplay = useMemo(
    () =>
      memberSelections.filter((selection) => selection.id !== currentUser?.id),
    [memberSelections, currentUser]
  );

  useEffect(() => {
    navigation.setOptions({
      headerLeft: () => (
        <Button
          variant="unstyled"
          _textStyles={{ color: "$primary.600" }}
          onPress={navigation.goBack}
        >
          Cancel
        </Button>
      ),
      title: "New Group",
      headerRight: () => (
        <Button
          variant="unstyled"
          _textStyles={{ color: "$primary.600" }}
          onPress={handleCreateDynamicSpace}
        >
          Finish
        </Button>
      ),
    });
  }, [navigation, handleCreateDynamicSpace]);
  return (
    <SafeAreaView style={{ flex: 1 }} edges={["bottom"]}>
      <TextInput
        sx={{ paddingBottom: "$3", paddingX: "$3" }}
        placeholder="Group name (optional)"
        onChangeText={(value) => setSpaceName(value)}
        defaultValue={spaceName}
      />

      <SpaceMemberSelector
        data={membersToDisplay}
        textInputLabel={"Members:"}
        onAdd={(selection) => {
          if (selection.key === "PROFILE" && selection.id === currentUser?.id)
            return;
          setMemberSelections((selections) => [...selections, selection]);
        }}
        onRemove={(selection) => {
          if (selection.key === "PROFILE" && selection.id === currentUser?.id)
            return;
          setMemberSelections((selections) =>
            selections.filter(
              (s) => !(s.id === selection.id && s.key === selection.key)
            )
          );
        }}
      />
    </SafeAreaView>
  );
}
