import { atom, AtomEffect, selector } from "recoil";
import client from "../graphql/client";
import { pipe, subscribe } from "wonka";
import {
  GetSpaceMembersDocument,
  SpaceMembersInfoFragment,
} from "../generated/graphql";

const spaceMembersSyncEffect = (): AtomEffect<SpaceMembersInfoFragment[]> => {
  return ({ setSelf }) => {
    const spaces_subscription = pipe(
      client.query(GetSpaceMembersDocument, {}),
      subscribe(({ data, error }) => {
        if (data) {
          setSelf(data.space_members);
        }
      })
    );
    return () => {
      spaces_subscription.unsubscribe();
    };
  };
};

export const spaceMembersState = atom<SpaceMembersInfoFragment[]>({
  key: "space-members-state", // unique ID (with respect to other atoms/selectors)
  default: [], // default value (aka initial value)
  effects_UNSTABLE: [spaceMembersSyncEffect()],
});

export const spaceMembersBySpace = selector({
  key: "SpaceMembersBySpace",
  get: ({ get }) => {
    const spaceMembers = get(spaceMembersState) ?? [];
    return spaceMembers.reduce((map, member) => {
      if (map.has(member.space_id)) {
        const memberList = map.get(member.space_id);
        memberList!.push(member);
      } else {
        const memberList = [member];
        map.set(member.space_id, memberList);
      }
      return map;
    }, new Map<string, SpaceMembersInfoFragment[]>());
  },
});
