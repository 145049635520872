import { styled, TextInput } from "dripsy";
import { Platform } from "react-native";
import type { PasteInputProps } from "@mattermost/react-native-paste-input";

let PasteInput: typeof TextInput & Partial<PasteInputProps>;
if (Platform.OS === "web") {
  PasteInput = TextInput;
} else {
  // @ts-ignore
  PasteInput = styled(
    // @ts-ignore
    require("@mattermost/react-native-paste-input").default
  )();
}

export default PasteInput;
