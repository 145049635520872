import React, { useCallback, useEffect, useState } from "react";
import { Alert } from "react-native";
import supabase, { signOut } from "../lib/supabase";
import { useTheme } from "native-base";
import { Button, Text, View } from "../components/basics";
import { useMutation } from "urql";
import { CompleteProfileDocument } from "../state/generated/graphql";
import AvatarPicker from "../components/AvatarPicker";
import { useUser } from "../hooks/auth";
import { usePerson } from "../hooks/usePeople";
import { MotiView, useAnimationState } from "moti";
import StyledTextInput from "../components/basics/StyledTextInput";

export default function CompleteProfilePage() {
  const [name, setName] = useState("");
  const [profilePicPath, setProfilePicPath] = useState("");
  const currentUser = useUser();
  const currentProfile = usePerson(currentUser?.id);

  const [updateStatus, updateProfile] = useMutation(CompleteProfileDocument);

  const isValid = name.length > 3;
  const buttonAnimationState = useAnimationState({
    from: { opacity: 0.5, translateY: 15 },
    to: { opacity: 0.5, translateY: 15 },
    active: { opacity: 1.0, translateY: 0 },
    exit: { opacity: 0.5, translateY: 15 },
  });

  const submit = useCallback(async () => {
    return updateProfile({
      id: supabase.auth.user()?.id,
      name,
      profile_image_path: profilePicPath,
    });
  }, [name, profilePicPath]);

  useEffect(() => {
    if (updateStatus.error) {
      console.error(updateStatus.error);
      Alert.alert(
        "Server Issue",
        "Oops! We ran into a problem updating your profile... try again."
      );
    }
  }, [updateStatus]);

  useEffect(() => {
    if (isValid) {
      buttonAnimationState.transitionTo("active");
    } else {
      buttonAnimationState.transitionTo("to");
    }
  }, [isValid]);
  return (
    <View
      sx={{
        flexDirection: "column",
        alignItems: "center",
        padding: 16,
        maxWidth: 750,
      }}
    >
      <Button
        variant="unstyled"
        sx={{ position: "absolute", left: 0, top: 40 }}
        leftIcon={"arrow-back"}
        onPress={() => signOut()}
      >
        Back
      </Button>
      <Text
        sx={{
          fontSize: "3xl",
          padding: "$1",
          marginBottom: "$4",
          lineHeight: 36,
          fontWeight: "500",
          textAlign: "center",
          color: "$darkText",
          marginTop: "10%",
        }}
      >
        Complete Profile
      </Text>
      <View sx={{ alignItems: "center", marginTop: "5%", marginBottom: "5%" }}>
        <AvatarPicker
          profile={{ ...currentProfile, name }}
          onAvatarChange={(key) => setProfilePicPath(key)}
        />
      </View>
      <View sx={{ width: 250 }}>
        <Text
          sx={{
            marginLeft: "$1",
            marginBottom: "$1",
            fontWeight: "bold",
            fontSize: 14,
            color: "$darkText",
          }}
        >
          Full name
        </Text>
        <StyledTextInput
          onChangeText={(text) => setName(text)}
          value={name}
          autoFocus
          placeholder="Mary Poppins"
        />
        <MotiView
          state={buttonAnimationState}
          transition={{ type: "timing" }}
          exitTransition={{ type: "timing" }}
        >
          <Button
            onPress={submit}
            variant={"unstyled"}
            _containerStyles={{ backgroundColor: "#6B73FF" }}
            _textStyles={{
              color: "$white",
              fontSize: 18,
            }}
            _iconStyles={{ color: "$white" }}
            isLoading={updateStatus.fetching}
            rightIcon={updateStatus.data ? "checkmark-outline" : undefined}
          >
            {"Save & Continue"}
          </Button>
        </MotiView>
      </View>
    </View>
  );
}
