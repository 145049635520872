import React, { useCallback, useRef, useState } from "react";
import { signUp } from "../../lib/supabase";
import { Button, View, Text, TextInput } from "../../components/basics";
import { useNavigation } from "@react-navigation/native";
import { MotiText } from "moti";
import StyledTextInput from "../../components/basics/StyledTextInput";
import { SafeAreaView } from "dripsy";

export default function SignUpScreen() {
  const [signupLoading, setSignupLoading] = useState(false);
  const [email, setEmail] = useState("");
  const navigation = useNavigation();

  const handleSubmit = useCallback(async () => {
    const trimEmail = email.trim();
    setSignupLoading(true);
    try {
      await signUp(trimEmail);
      navigation.navigate("EmailVerificationMessage", { email: trimEmail });
    } catch (e) {
      if (e instanceof Error) {
        console.error(e.message);
        //onError(e.message);
      } else {
        //onError("Something unexpected happened. We're looking into it...");
      }
    } finally {
      setSignupLoading(false);
    }
  }, [navigation, email]);
  return (
    <SafeAreaView
      sx={{
        flex: 1,
        flexDirection: "column",
        position: "relative",
        alignItems: "center",
        justifyContent: "flex-start",
        paddingBottom: 200,
      }}
    >
      <Button
        variant="unstyled"
        sx={{ position: "absolute", left: 0, top: 40 }}
        leftIcon={"arrow-back"}
        onPress={() => navigation.goBack()}
      >
        Back
      </Button>
      <MotiText
        style={{
          fontSize: 36,
          fontWeight: "500",
          marginBottom: 5,
          textAlign: "center",
          marginTop: 100,
        }}
        from={{ opacity: 0, scale: 0.85 }}
        animate={{ opacity: 1, scale: 1 }}
        transition={{ type: "timing" }}
      >
        Start your profile
      </MotiText>
      <View sx={{ width: 250, marginTop: 30 }}>
        <Text
          sx={{
            width: 50,
            fontWeight: "bold",
            marginBottom: 4,
          }}
        >
          Email
        </Text>

        <StyledTextInput
          onChangeText={(text) => setEmail(text)}
          autoFocus
          autoCapitalize={"none"}
          autoComplete="email"
          value={email}
          sx={{
            marginBottom: 30,
          }}
          keyboardType={"email-address"}
          placeholder={"email@example.com"}
        />

        <Button
          isLoading={signupLoading}
          onPress={handleSubmit}
          disabled={signupLoading}
          _textStyles={{ fontSize: 18 }}
        >
          Create Account
        </Button>
      </View>
    </SafeAreaView>
  );
}
