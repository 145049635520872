import { View, Button, Pressable } from "../../components/basics";
import { AnimatePresence, Text } from "moti";
import { useNavigation } from "@react-navigation/native";
import { TextInput } from "dripsy";
import React, { useEffect, useRef, useState } from "react";
import supabase from "../../lib/supabase";
import { parsePhoneNumberFriendly } from "../../utils/phone";
import CodeInput from "../../components/CodeInput";

export default function ConfirmSMSCode({ route }) {
  const navigation = useNavigation();

  const phone = route.params?.phone ?? "your phone number";
  const [inputError, setInputError] = useState(false);
  const [code, setCode] = useState("");
  const CODE_LENGTH = 6;
  let hiddenInput = useRef(null);

  useEffect(() => {
    if (code.length === CODE_LENGTH) {
      (async () => {
        const { user, session, error } = await supabase.auth.verifyOTP({
          phone,
          token: code,
        });
        if (error) setInputError(true);
      })();
    }
  }, [code]);

  useEffect(() => {
    if (inputError) {
      setTimeout(() => {
        setCode("");
        setInputError(false);
      }, 500);
    }
  }, [inputError]);
  return (
    <View
      sx={{
        flex: 1,
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: "white",
        paddingBottom: 200,
      }}
    >
      <Button
        variant="unstyled"
        sx={{ position: "absolute", left: 0, top: 40 }}
        leftIcon={"arrow-back"}
        onPress={() => navigation.goBack()}
      >
        Back
      </Button>
      <View sx={{ marginBottom: 50 }}>
        <Text
          style={{
            fontSize: 30,
            fontWeight: "500",
            marginBottom: 5,
            textAlign: "center",
          }}
        >
          {parsePhoneNumberFriendly(phone)}
        </Text>
        <Text style={{ textAlign: "center" }}>
          We've sent you a message with the code
        </Text>
      </View>
      <CodeInput value={code} onChange={setCode} length={CODE_LENGTH} />
    </View>
  );
}
