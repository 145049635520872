import BottomSheet from "@gorhom/bottom-sheet";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useContext } from "react";
import { Keyboard } from "react-native";
import { useSharedValue } from "react-native-reanimated";
import { Pressable, Text, View } from "../basics";
import { FlatList } from "dripsy";
import { AspenChatMessage } from "../../screens/spaces/ChatScreen";
import PersonAvatar from "../PersonAvatar";
import { useSx } from "dripsy";
import usePeople from "../../hooks/usePeople";
import { ProfileItem, profileToPerson } from "../../utils/people";
import { useNavigation } from "@react-navigation/native";

const ReactionDrawerContext = React.createContext<ReactionDrawerHandler>(
  () => {}
);

type ReactionDrawerHandler = (
  currentMessage: AspenChatMessage | undefined,
  currentReaction: string | undefined
) => void;

function flattenReactions(message: AspenChatMessage | undefined) {
  return Object.entries(message?.reactions ?? {}).filter(
    ([_emoji, user_ids]) => user_ids.length > 0
  );
}

export function ReactionDrawerProvider({ children }) {
  const [selectedReaction, setSelectedReaction] = useState(undefined);
  const [currentMessage, setCurrentMessage] = useState<AspenChatMessage>();
  const [reactionList, setReactionList] = useState([]);
  const navigation = useNavigation();
  const bottomSheetRef = useRef<BottomSheet>(null);
  const sheetSnapPoints = ["50%", "75%"];
  const currentSnapIndex = useSharedValue<number>(-1);
  let reactionListRef = useRef(null);
  const sx = useSx();
  const people = usePeople();

  function closeBottomSheet() {
    bottomSheetRef.current?.close();
  }
  const openBottomSheet = useCallback(
    (currentMessage, initialPressedReaction) => {
      if (currentSnapIndex.value === -1) {
        bottomSheetRef.current?.snapToIndex(0);
      } else {
        closeBottomSheet();
      }
      /* on open, set the selected reaction to the one that was pressed
         and display it first, on the left */
      const flatReactions = flattenReactions(currentMessage);
      const selectedReactionIndex = flatReactions.findIndex(
        ([emoji, _userIds]) => emoji === initialPressedReaction
      );
      flatReactions.unshift(flatReactions.splice(selectedReactionIndex, 1)[0]);
      setReactionList(flatReactions);
      setSelectedReaction(initialPressedReaction);
      setCurrentMessage(currentMessage);
      Keyboard.dismiss();
      reactionListRef.current.scrollToOffset({ animated: false, offset: 0 });
    },
    [currentSnapIndex, bottomSheetRef]
  );

  useEffect(() => {
    const handleKeyboardShow = () => {
      closeBottomSheet();
    };
    const showSubscription = Keyboard.addListener(
      "keyboardWillShow",
      handleKeyboardShow
    );

    return () => showSubscription.remove();
  }, []);

  const onPressReacter = useCallback(
    (id) => {
      navigation.navigate("profiles", {
        screen: "profile",
        params: {
          userId: id as string,
          type: "PROFILE",
        },
      });
    },
    [navigation]
  );

  const hydratedReactions = useMemo(
    () =>
      reactionList.map(([emoji, userIds]) => ({
        emoji,
        numReactions: userIds.length,
        isSelected: emoji === selectedReaction,
      })),
    [reactionList, selectedReaction]
  );

  const reacters = selectedReaction
    ? currentMessage?.reactions?.[selectedReaction ?? reactions[0][0]]
    : [];

  const hydratedReacters = useMemo(
    () =>
      reacters?.map((uid) => ({
        ...profileToPerson(people.get(uid)),
        onPress: onPressReacter,
      })),
    [reacters, people]
  );

  const renderReaction = useCallback((data) => {
    const { numReactions, emoji, isSelected } = data.item;
    return (
      <Pressable
        sx={{
          opacity: isSelected ? 1 : 0.5,
          minWidth: 56,
          minHeight: 40,
          flexDirection: "row",
          // backgroundColor: isSelected ? "$primary.50" : "$white",
          // borderWidth: isSelected ? "hairline" : 0,
          borderColor: "$primary.600",
          borderRadius: "md",
          justifyContent: "center",
          alignItems: "center",
          marginX: "$2",
        }}
        onPress={() => setSelectedReaction(emoji)}
      >
        <Text sx={{ fontSize: 20, marginRight: "$1" }}>
          {emoji === "LIKE" ? "❤️" : emoji}
        </Text>
        <Text
          sx={{
            fontSize: 16,
            // color: isSelected ? "$black" : "$gray.400",
            fontWeight: "medium",
          }}
        >
          {numReactions}
        </Text>
      </Pressable>
    );
  }, []);

  return (
    <ReactionDrawerContext.Provider value={openBottomSheet}>
      {children}
      <BottomSheet
        ref={bottomSheetRef}
        index={-1}
        snapPoints={sheetSnapPoints}
        enablePanDownToClose={true}
        backgroundComponent={(props) => (
          <View
            {...props}
            sx={{
              borderRadius: 16,
              backgroundColor: "$white",
              borderColor: "$systemBorder",
              borderWidth: "hairline",
            }}
          />
        )}
        onAnimate={(_fromIndex, toIndex) => {
          currentSnapIndex.value = toIndex;
        }}
      >
        <View sx={{ flex: 1 }}>
          <View
            sx={{
              height: 60,
              borderBottomWidth: "hairline",
              borderColor: "$coolGray.300",
            }}
          >
            <FlatList
              horizontal
              ref={(r) => (reactionListRef.current = r)}
              // estimatedItemSize={76}
              style={{ flex: 1 }}
              data={hydratedReactions}
              renderItem={renderReaction}
              showsHorizontalScrollIndicator={false}
              contentContainerStyle={{ alignItems: "center" }}
              scrollsToTop
            />
          </View>
          <View sx={{ flex: 1 }}>
            <FlatList
              estimatedItemSize={83}
              renderItem={renderReacter}
              data={hydratedReacters}
            />
          </View>
        </View>
      </BottomSheet>
    </ReactionDrawerContext.Provider>
  );
}

function renderReacter({ item }: { item: ProfileItem }) {
  const { name, id, onPress } = item;
  return (
    <Pressable
      sx={{
        flexDirection: "row",
        width: "100%",
        alignItems: "center",
        marginTop: "$3",
        paddingLeft: "$4",
        paddingRight: "$3",
      }}
      onPress={() => onPress(id)}
      style={({ pressed }) => ({
        opacity: pressed ? 0.7 : 1,
      })}
    >
      <View sx={{ width: 60 }}>
        <PersonAvatar person={item} size={36} />
      </View>
      <Text sx={{ fontSize: 16 }}>{name}</Text>
    </Pressable>
  );
}

export function useReactionDrawer() {
  return useContext(ReactionDrawerContext);
}
