import graphqlClient from "../graphql/client";
import { UpsertReadReceiptDocument } from "../generated/graphql";

export function pushReadReceipt(
  spaceId: string,
  userId: string,
  timestamp: string
) {
  graphqlClient
    .mutation(UpsertReadReceiptDocument, {
      receipt: {
        user_id: userId,
        space_id: spaceId,
        timestamp,
      },
    })
    .toPromise();
}
