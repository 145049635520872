import client from "../state/graphql/client";
import { MakeAccessRequestDocument } from "../state/generated/graphql";
export async function MakeAccessRequest(
  requesterId: string,
  recipientId: string,
  spaceId: string,
  resourceType: string,
  metadata: any,
  key: string
) {
  const obj = {
    requester_id: requesterId,
    recipient_id: recipientId,
    space_id: spaceId,
    resource_type: resourceType,
    metadata,
    key,
  };
  const result = await client
    .mutation(MakeAccessRequestDocument, obj)
    .toPromise();
  if (result.error) return result.error;
  return result;
}
