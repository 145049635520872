import { useCallback } from "react";
import { useMutation } from "urql";
import { AddPermissionsDocument } from "../state/generated/graphql";

export default function useImportResources() {
  const [_addPermissionsResp, addPermissions] = useMutation(
    AddPermissionsDocument
  );

  const importResource = useCallback(
    (spaceId: string, resourceCompositeKeys: string[]) => {
      const permissions = resourceCompositeKeys.map((key) => {
        const [resourceKey, resourceId] = key.split("|");
        return {
          resource_id: resourceId,
          resource_key: resourceKey,
          space_id: spaceId,
        };
      });
      return addPermissions({
        permissions,
      });
    },
    [addPermissions]
  );

  return importResource;
}
