import supabase, { SUPABASE_URL } from "../lib/supabase";
import { blobToBase64, generateOptimizedImageUploadURI } from "./images";
import * as FileSystem from "expo-file-system";

const BUCKET_KEY = "cover-photos";

export async function uploadCoverPhotoImage(
  spaceId: string,
  photoId: string,
  fileUri: string
) {
  const objectPath = `${BUCKET_KEY}/${spaceId}/${photoId}`;
  const url = `${SUPABASE_URL}/storage/v1/object/${objectPath}`;
  const uploadUri = await generateOptimizedImageUploadURI(fileUri);

  // Manually uploading (as opposed to using subabase lib) to take advantage of background work
  const { body, status } = await FileSystem.uploadAsync(url, uploadUri, {
    headers: {
      authorization: `Bearer ${supabase.auth.session()!.access_token}`,
      "cache-control": "1209600",
      "x-upsert": "true",
    },
    httpMethod: "POST",
    sessionType: FileSystem.FileSystemSessionType.BACKGROUND,
    uploadType: FileSystem.FileSystemUploadType.MULTIPART,
    fieldName: "file",
  });
  if (status < 200 || status > 299) {
    return {
      data: undefined,
      error: {
        // avatarKey,
        message:
          JSON.parse(body).error?.message ||
          "an error occurred uploading your cover photo",
      },
    };
  }

  return { data: body, error: undefined };
}
