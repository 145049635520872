import { useToast } from "native-base";
import { Button, Text, View, TextInput } from "../components/basics";
import { MotiText } from "moti";
import { useCallback, useState } from "react";
import { signOut } from "../lib/supabase";
import { sendSmsVerification } from "../lib/twilio";
import { RootStackScreenProps } from "../types";
import DismissKeyboardView from "../components/DismissKeyboardView";
import { Keyboard } from "react-native";
import { getPhoneComponents, parsePhoneNumber } from "../utils/phone";
import StyledTextInput from "../components/basics/StyledTextInput";

export default function EditMobileScreen({
  route,
  navigation,
}: RootStackScreenProps<"EditMobile">) {
  const { number } = route.params;
  const phoneComponents = number ? getPhoneComponents(number) : undefined;
  const [phone, setPhone] = useState(
    `${phoneComponents?.getNationalNumber() ?? ""}` ?? ""
  );
  const [countryCode, setCountryCode] = useState(
    `${phoneComponents?.getCountryCode() ?? "1"}` ?? "1"
  );

  const toast = useToast();

  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = useCallback(async () => {
    Keyboard.dismiss();
    setSubmitting(true);
    let phoneFormatted =
      "+" + countryCode.trim() + phone.match(/\d+/g)?.join("");
    try {
      if (!parsePhoneNumber(phoneFormatted)) {
        toast.show({
          description: "Please enter a valid phone number",
        });
      }
      const { success, error } = await sendSmsVerification(phoneFormatted);
      if (!success) {
        throw new Error(error);
      }
      navigation.navigate("VerifyMobile", {
        phone: phoneFormatted,
      });
    } catch (e) {
      toast.show({
        description: `An error occurred while submitting your phone number: ${e.message}`,
      });
    } finally {
      setSubmitting(false);
    }
  }, [phone, countryCode]);

  return (
    <DismissKeyboardView>
      <View sx={{ flex: 1, flexDirection: "column", alignItems: "center" }}>
        <Button
          variant="unstyled"
          sx={{ position: "absolute", left: 0, top: 40 }}
          leftIcon={"arrow-back"}
          onPress={() => signOut()}
        >
          Back
        </Button>
        <MotiText
          style={{
            fontSize: 36,
            fontWeight: "500",
            marginTop: 100,
            marginBottom: 5,
            textAlign: "center",
          }}
          from={{ opacity: 0, scale: 0.85 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ type: "timing" }}
        >
          Update Profile
        </MotiText>
        <Text sx={{ width: 300, textAlign: "center", marginTop: 30 }}>
          For additional account security and an improved app experience, Aspen
          requires that you verify your phone number.
        </Text>
        <View sx={{ width: 250, marginTop: 30 }}>
          <Text
            sx={{
              width: 50,
              fontWeight: "bold",
              marginBottom: 4,
            }}
          >
            Phone
          </Text>
          <StyledTextInput
            onChangeText={(text) => setPhone(text)}
            value={phone}
            autoFocus
            placeholder="--- --- ----"
            keyboardType={"phone-pad"}
            sx={{ marginBottom: 30 }}
            leftIcon={({ onFocus }) => (
              <>
                <Text sx={{ fontSize: 18, ml: "$2" }}>+</Text>
                <TextInput
                  onChangeText={(text) => setCountryCode(text)}
                  value={countryCode}
                  sx={{
                    width: 30,
                    fontSize: 18,
                  }}
                  defaultValue={"1"}
                  maxLength={3}
                  onFocus={onFocus}
                  keyboardType={"phone-pad"}
                />
                <View
                  sx={{
                    height: "50%",
                    borderLeftWidth: 1,
                    borderColor: "$primary.400",
                  }}
                />
              </>
            )}
          />

          <Button
            isLoading={submitting}
            onPress={handleSubmit}
            disabled={submitting}
            _textStyles={{ fontSize: 18 }}
          >
            Continue
          </Button>
        </View>
      </View>
    </DismissKeyboardView>
  );
}
