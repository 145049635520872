import { FlatList, SafeAreaView } from "dripsy";
// import { Image } from "moti";
import {
  ResizeMode,
  Video,
  VideoProps,
  VideoReadyForDisplayEvent,
} from "expo-av";
import { Dimensions, ModalProps, Platform, StyleSheet } from "react-native";
import { Icon, IconButton, Image, Pressable, Text, View } from "./basics";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Modal } from "react-native";
import { gestureHandlerRootHOC } from "react-native-gesture-handler";
import Zoomable from "./Zoomable";
import { useSafeAreaFrame } from "react-native-safe-area-context";
import { StatusBar } from "expo-status-bar";
import {
  ActionSheetOptions,
  useActionSheet,
} from "@expo/react-native-action-sheet";
import { copyImageToClipboard, saveImageToDevice } from "../utils/media";
import { ToastContextProvider, useToast } from "../hooks/useToast";
import { reportError } from "../state/utils/errors";
import { optimizedImagePath } from "../utils/images";
import { format } from "date-fns";
import { useResourcePermissions } from "../hooks/useResourcePermissions";
import { useSpaces, useSpacesMap } from "../hooks/spaces";
import { useChatList } from "../hooks/useChatList";
import { useUser } from "../hooks/auth";
import { SpaceIcon } from "./SimpleSpaceListItem";
import Animated, { FadeInDown, FadeOutDown } from "react-native-reanimated";
import { useNavigation } from "@react-navigation/native";
import { Audio } from "expo-av";

const WINDOW_HEIGHT = Dimensions.get("window").height;
const WINDOW_WIDTH = Dimensions.get("window").width;

function FullBleedImage(props: React.ComponentProps<typeof Image>) {
  const safeArea = useSafeAreaFrame();
  const [imageSize, setImageSize] = useState({
    width: WINDOW_WIDTH,
    height: WINDOW_HEIGHT,
  });
  const providedStyles =
    props.style instanceof Array ? props.style : [props.style];

  const [fullImageLoaded, setFullImageLoaded] = useState(false);
  useEffect(() => {
    setFullImageLoaded(false);
  }, [props.source?.url]);

  return (
    <>
      <Image
        {...props}
        style={[...providedStyles, { ...imageSize }]}
        onLoadEnd={() => {
          setFullImageLoaded(true);
        }}
        onLoad={(evt) => {
          if (!evt.nativeEvent.source) return;
          const { height, width } = evt.nativeEvent.source;
          if (height / width > safeArea.height / safeArea.width) {
            setImageSize({
              height: safeArea.height,
              width: (width / height) * safeArea.height,
            });
          } else {
            setImageSize({
              height: (height / width) * safeArea.width,
              width: safeArea.width,
            });
          }
          if (props.onLoad) {
            props.onLoad(evt);
          }
        }}
      />
      {fullImageLoaded ? null : (
        <Image
          {...props}
          source={{ uri: optimizedImagePath(props.source.uri, { w: 256 }) }}
          style={[
            ...providedStyles,
            { ...imageSize },
            {
              position: "absolute",
              left: 0,
              right: 0,
              top: 0,
              bottom: 0,
              zIndex: 20,
            },
          ]}
        />
      )}
    </>
  );
}

const FullSizeVideo = React.forwardRef<Video, VideoProps>((props, ref) => {
  const [scaledHeight, setScaledHeight] = useState(WINDOW_HEIGHT);
  function onReadyForDisplay(response: VideoReadyForDisplayEvent) {
    const { width, height } = response.naturalSize;
    const heightScaled = height * (WINDOW_WIDTH / width);
    setScaledHeight(heightScaled);
  }

  const styles = { width: WINDOW_WIDTH, height: scaledHeight };

  return (
    <Video
      ref={ref}
      style={styles}
      onReadyForDisplay={onReadyForDisplay}
      {...props}
    />
  );
});

interface MediaGalleryProps extends ModalProps {
  data: any[];
  index?: number;
  handleClose: () => void;
}

function getItemLayout(_data: any, index: number) {
  return { length: WINDOW_WIDTH, offset: WINDOW_WIDTH * index, index };
}

const MediaCarousel = gestureHandlerRootHOC(
  ({ handleClose, data, index: currentIndex }) => {
    const [isZoomedIn, setIsZoomedIn] = useState(false);
    const [scrollIndex, setScrollIndex] = useState(currentIndex);
    const renderItem = useCallback(
      ({ item, index }) => {
        return (
          <GalleryItem
            item={item}
            setIsZooming={setIsZoomedIn}
            isInView={scrollIndex === index}
          />
        );
      },
      [setIsZoomedIn, scrollIndex]
    );

    return (
      <View style={styles.modalView}>
        <FlatList
          horizontal
          contentContainerStyle={styles.flatList}
          data={data}
          getItemLayout={getItemLayout}
          initialScrollIndex={currentIndex}
          scrollEnabled={!isZoomedIn} // set this to false when doing pinching/panning
          renderItem={(props) => {
            return renderItem({ ...props, setIsZooming: setIsZoomedIn });
          }}
          snapToInterval={WINDOW_WIDTH}
          initialNumToRender={1}
          snapToAlignment="center"
          showsHorizontalScrollIndicator={false}
          decelerationRate={"fast"}
          disableIntervalMomentum={true}
          onMomentumScrollEnd={(e) => {
            setScrollIndex(e.nativeEvent.contentOffset.x / WINDOW_WIDTH);
          }}
        />
      </View>
    );
  }
);

function GalleryItem({
  item,
  setIsZooming,
  isInView,
}: {
  item: any;
  setIsZooming: any;
  isInView: boolean;
}) {
  const { showActionSheetWithOptions } = useActionSheet();
  const defaultActionSheetOptions: ActionSheetOptions = {
    options: ["Save", "Copy", "Cancel"],
    cancelButtonIndex: 2,
  };

  const addToast = useToast();

  const handleImageLongPress = useCallback(() => {
    if (Platform.OS === "web") return;
    showActionSheetWithOptions(defaultActionSheetOptions, (buttonIndex) => {
      switch (buttonIndex) {
        case 0:
          saveImageToDevice(item.uri).then(({ error }) => {
            if (error) {
              addToast({
                title: "Error",
                description: "Failed to save image to device",
                variant: "ERROR",
              });
              reportError(error, {
                extra: { message: "Failed to save image to device" },
              });
            } else {
              addToast({
                title: "Success",
                description: "Image saved to device",
                variant: "SUCCESS",
              });
            }
          });
          break;
        case 1:
          copyImageToClipboard(item.uri).then(({ error }) => {
            if (error) {
              addToast({
                title: "Error",
                description: "Could not copy image to clipboard",
                variant: "ERROR",
              });
              reportError(error, {
                extra: { message: "Failed to copy image to clipboard" },
              });
            } else {
              addToast({
                title: "Success",
                description: "Image copied to clipboard",
                variant: "SUCCESS",
              });
            }
          });
          break;
        default:
          break;
      }
    });
  }, [item, addToast]);

  // TODO: don't love using an effect here, there is just a TOUCH of lag
  const [showInfo, setShowInfo] = useState(false);
  useEffect(() => {
    if (!isInView) {
      videoRef.current?.pauseAsync();
      setShowInfo(false);
    }
  }, [isInView]);
  const videoRef = useRef<Video>(null);

  return (
    <View sx={styles.mediaView}>
      {item.type === "video" ? (
        <FullSizeVideo
          ref={videoRef}
          source={{ uri: item.uri }}
          resizeMode={ResizeMode.CONTAIN}
          useNativeControls
          // There's a few funky things with autoplay
          // 1. Everything in the gallery will try to play on mount...we could prevent this with shouldPlay={isInView}
          // 2. On ios the silent switch prevents audio and I couldnt get the override in App.tsx (Audio.setAudioModeAsync({ playsInSilentModeIOS: true });) to work on its own
          // Without actually turning on autoplay, the best bet seems to be to pause on load: https://github.com/expo/expo/issues/7485
          shouldPlay
          onLoad={() => {
            videoRef.current?.pauseAsync();
          }}
        />
      ) : (
        <Zoomable
          onZoomed={(isZoomedIn) => {
            setIsZooming(isZoomedIn);
          }}
        >
          <Pressable onLongPress={handleImageLongPress}>
            <FullBleedImage
              resizeMode="contain"
              key={item.key}
              style={styles.image}
              source={{
                uri: item.uri,
              }}
            />
          </Pressable>
        </Zoomable>
      )}
      <Pressable
        sx={{ position: "absolute", top: 16, right: 20 }}
        onPress={() => {
          setShowInfo((prev) => !prev);
        }}
      >
        <Icon name="ellipsis-vertical-outline" color="white" size={"3xl"} />
      </Pressable>
      {showInfo && item.parentResource && (
        <Animated.View
          style={{ position: "absolute", bottom: 0, left: 0, width: "100%" }}
          entering={FadeInDown}
          exiting={FadeOutDown}
        >
          <PhotoResourceInfo resource={item.parentResource} />
        </Animated.View>
      )}
    </View>
  );
}

function PhotoResourceInfo({ resource }: { resource: any }) {
  const MAX_ATTRIBUTIONS = 3;
  const attributionsOverflow = Math.max(
    resource.resource_permissions.length - MAX_ATTRIBUTIONS,
    0
  );
  const trimmedAttributions = resource.resource_permissions.slice(
    0,
    MAX_ATTRIBUTIONS
  );
  const { data: spacesMap } = useSpacesMap();
  const navigation = useNavigation();
  return (
    <View sx={{ padding: 16 }}>
      {spacesMap && (
        <Pressable
          sx={{ mb: 8 }}
          // TODO: add once we can "share" photos
          // onPress={() => {
          //   navigation.navigate("ResourceAttributionModal", {
          //     resourceId: resource.id,
          //     resourceKey: resource.collection_key,
          //   });
          // }}
        >
          {trimmedAttributions.map((rp) => {
            const space = spacesMap.get(rp.space_id);
            return (
              <View
                key={`${rp.space_id}|${rp.resource_id}|${rp.resource_key}`}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <SpaceIcon space={space} size={40} />
                <Text sx={{ color: "white", ml: 4, mt: 4 }}>
                  {space?.display_name}
                </Text>
              </View>
            );
          })}
        </Pressable>
      )}
      {!!resource.created_at && (
        <Text sx={{ color: "white" }}>
          {format(new Date(resource.created_at), "MMM d, yyyy h:mm a")}
        </Text>
      )}
    </View>
  );
}

function MediaGallery({
  visible,
  data,
  handleClose,
  index,
  ...props
}: MediaGalleryProps) {
  return (
    <Modal
      visible={visible}
      animationType="fade"
      style={styles.modal}
      onRequestClose={handleClose}
    >
      <ToastContextProvider>
        <StatusBar style="dark" />
        <View sx={{ backgroundColor: "$black", flex: 1 }}>
          <SafeAreaView sx={styles.safeAreaView}>
            <View style={styles.modalBtnView}>
              <IconButton
                icon="close"
                variant="unstyled"
                colorScheme="$white"
                onPress={handleClose}
                _containerStyles={styles.modalCloseBtnContainer}
                _iconStyles={styles.modalCloseBtnIcon}
              />
            </View>
            <MediaCarousel
              handleClose={handleClose}
              data={data}
              index={index}
            />
          </SafeAreaView>
        </View>
      </ToastContextProvider>
    </Modal>
  );
}

const styles = StyleSheet.create({
  modal: {
    backgroundColor: "black",
  },
  modalView: { flex: 1 },
  safeAreaView: {
    flex: 1,
  },
  flatList: {},
  mediaView: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    width: WINDOW_WIDTH,
  },
  image: {
    height: "100%",
    width: "100%",
  },
  video: {},
  modalBtnView: {
    zIndex: 2,
    // position: "absolute", // THIS MAKES IT WORK ON WEB, but fcks up mobile
    // left: 0,
    // top: 0,
  },
  modalCloseBtnContainer: {
    position: "absolute",
    left: 0,
    top: 0,
    alignSelf: "flex-start",
  },
  modalCloseBtnIcon: {
    fontSize: 36,
    color: "white",
    textShadowColor: "black",
    textShadowRadius: 6,
    opacity: 0.9,
  },
});

export default MediaGallery;
