import { useRecoilValue } from "recoil";
import { useQuery } from "urql";
import { spacesMap, spacesState } from "../state/atoms/spaces";
import { GetUserSpacesDocument } from "../state/generated/graphql";

export function useSpaces() {
  return useRecoilValue(spacesState);
}

export function useSpacesMap() {
  return useRecoilValue(spacesMap);
}

export function useSpace(spaceId: string) {
  const { loading, data } = useSpacesMap();
  return { loading, data: data.get(spaceId) };
}

// Non recoil version (TODO: pretty sure recoil is reduntant with our caching)
export function useSpacesQuery() {
  return useQuery({ query: GetUserSpacesDocument });
}
