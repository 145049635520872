import { atom, RecoilState } from "recoil";
import { AttachmentState } from "../../screens/spaces/ChatScreen";

const attachmentAtoms: Record<string, RecoilState<AttachmentState[]>> = {};

export const attachmentPickerStateBySpace = (spaceId: string) => {
  if (!attachmentAtoms[spaceId])
    attachmentAtoms[spaceId] = atom<AttachmentState[]>({
      key: `attachments_${spaceId}`,
      default: [],
    });
  return attachmentAtoms[spaceId];
};
