import ResourceCollection from "./resource";
import { RRule, rrulestr, RRuleSet } from "rrule";

function eventRateToRecurrenceRate(rate: string | undefined) {
  switch (rate) {
    case "DAILY":
      return RRule.DAILY;
    case "WEEKLY":
      return RRule.WEEKLY;
    case "MONTHLY":
      return RRule.MONTHLY;
    case "NEVER":
    default:
      return undefined;
  }
}

function recurrenceRateToEventRate(rrate: number | undefined) {
  switch (rrate) {
    case RRule.DAILY:
      return "DAILY";
    case RRule.WEEKLY:
      return "WEEKLY";
    case RRule.MONTHLY:
      return "MONTHLY";
    default:
      return "NEVER";
  }
}

function parseRecurrenceString(recurrenceRule: string | undefined) {
  return recurrenceRule
    ? recurrenceRateToEventRate(rrulestr(recurrenceRule)?.options?.freq)
    : undefined;
}

export interface Reminder {
  title: string;
  description: string;
  start_date_utc: string;
  end_date_utc: string;
  is_recurring: boolean;
  recurrence_pattern?: string;
  completed_by: string[];
  parent_id?: string;
}

export const REMINDER_RESOURCE_KEY = "REMINDER";

function getEndDate(
  startDate: string,
  isRecurring: boolean,
  recurrenceEndDateString: string | undefined
) {
  if (isRecurring) {
    return (
      recurrenceEndDateString ?? new Date(Date.UTC(2999, 12, 31)).toISOString()
    );
  }
  return startDate;
}

function generateRecurrencePattern(
  recurringRate: string | undefined,
  startDateUtc: string,
  endDateUtc: string
) {
  const rrset = new RRuleSet();
  const freq = eventRateToRecurrenceRate(recurringRate);
  if (freq) {
    rrset.rrule(
      new RRule({
        freq: freq,
        dtstart: new Date(startDateUtc),
        until: new Date(endDateUtc),
      })
    );
  } else {
    rrset.rrule(
      new RRule({
        freq: RRule.DAILY,
        dtstart: new Date(startDateUtc),
        until: new Date(startDateUtc),
      })
    );
  }

  return rrset.toString();
}

function updatePatternExclusion(
  recurrence_pattern: string | undefined,
  date: string
) {
  if (!recurrence_pattern) return;
  const rrset = rrulestr(recurrence_pattern, {
    forceset: true,
  }) as RRuleSet;
  rrset.exdate(new Date(date));
  return rrset.toString();
}

function applyReminderEvent(
  resource: any | undefined,
  data: {
    title?: string;
    description?: string;
    date_utc?: string;
    is_recurring?: boolean;
    recurring_rate?: string;
    recurrence_end_date_utc?: string;
    parent_id?: string;
  }
) {
  const title = data.title ?? resource?.title;
  const description = data.description ?? resource?.description;
  const start_date_utc = data.date_utc ?? resource?.start_date_utc;

  const recurring_rate =
    data.recurring_rate ?? parseRecurrenceString(resource?.recurrence_pattern);

  const is_recurring =
    !!recurring_rate && (data.is_recurring ?? resource?.is_recurring);

  const recurrence_end_date =
    data.recurrence_end_date_utc ?? resource?.end_date_utc;

  const end_date_utc = getEndDate(
    start_date_utc,
    is_recurring,
    recurrence_end_date
  );

  const recurrence_pattern = is_recurring
    ? generateRecurrencePattern(recurring_rate, start_date_utc, end_date_utc)
    : undefined;

  return {
    title,
    description,
    start_date_utc,
    end_date_utc,
    is_recurring,
    recurrence_pattern,
    completed_by: resource?.completed_by ?? [],
    parent_id: resource?.parent_id ?? data.parent_id,
  };
}

export interface ReminderNewEventParams {
  title: string;
  description: string;
  date_utc: string;
  is_recurring: boolean;
  recurring_rate: string;
  recurrence_end_date?: string;
  parent_id?: string;
}

const reminderResource = new ResourceCollection<
  Reminder,
  {
    NEW: {
      title: string;
      description: string;
      date_utc: string;
      is_recurring: boolean;
      recurring_rate: string;
      recurrence_end_date?: string;
      parent_id?: string;
    };
    EDIT: {
      title?: string;
      description?: string;
      date_utc?: string;
      is_recurring?: boolean;
      recurrence_end_date?: string;
    };
    MARK_COMPLETED: {};
    UNMARK_COMPLETED: {};
    ADD_EXCLUSION: {
      date_utc: string;
    };
  }
>(REMINDER_RESOURCE_KEY, {
  NEW: (resource, { data }) => applyReminderEvent(resource, data),
  EDIT: (resource, { data }) => applyReminderEvent(resource, data),
  MARK_COMPLETED: (resource, { user_id }) => {
    const completed_by = resource?.completed_by ?? [];

    return {
      ...resource,
      completed_by: [...completed_by.filter((uid) => uid !== user_id), user_id],
    };
  },
  UNMARK_COMPLETED: (resource, { user_id }) => {
    const completed_by = resource?.completed_by ?? [];
    return {
      ...resource,
      completed_by: [],
    };
  },
  ADD_EXCLUSION: (resource, { data }) => {
    const newPattern = updatePatternExclusion(
      resource.recurrence_pattern,
      data.date_utc
    );
    return newPattern == undefined
      ? resource
      : { ...resource, recurrence_pattern: newPattern };
  },
});

export default reminderResource;
