import ResourceCollection from "./resource";

export interface LeWordGame {
  guesses: Guess[];
  result: "IN_PROGRESS" | "LOST" | "WON";
  numGuesses: number;
  day: string;
  playerId: string;
}

interface Guess {
  word: string;
  hint: LetterHint[];
}

type LetterHint =
  | "!" // EXACT
  | "?" // PARTIAL
  | "_"; // NO MATCH

const ALL_WORDS = require("../assets/5-letter-words-en.json");
const ALL_SELECTED_WORDS = require("../assets/selected-words.json");

const allWordsSet = new Set(ALL_WORDS);

export const LE_WORD_RESOURCE_KEY = "LE_WORD";

const leWordResource = new ResourceCollection<
  LeWordGame,
  {
    NEW: {
      day?: string;
    };
    GUESS: {
      word: string;
      guessNum: number;
    };
  }
>(LE_WORD_RESOURCE_KEY, {
  NEW: (_resource, { data, user_id }, fromServer) => {
    return {
      guesses: [],
      result: "IN_PROGRESS",
      numGuesses: 0,
      playerId: user_id,
      day: data["day"] ?? new Date().toISOString().split("T")[0],
    };
  },
  GUESS: (
    resource,
    { data }: { data: { word: string; guessNum: number } },
    fromServer
  ) => {
    const guessedWord = data.word.toLowerCase();
    if (!allWordsSet.has(guessedWord) || data.guessNum != resource.numGuesses)
      return resource;
    const targetWord = getWordForDay(resource.day).toLowerCase();
    const { numGuesses, guesses, result, day } = resource;
    const newNumGuesses = numGuesses + 1;
    let newResult = result;
    if (newNumGuesses >= 6) {
      newResult = "LOST";
    }
    if (guessedWord === targetWord) {
      newResult = "WON";
    }

    return {
      ...resource,
      guesses: [
        ...guesses,
        { word: guessedWord, hint: guessToHint(targetWord, guessedWord) },
      ],
      numGuesses: newNumGuesses,
      result: newResult,
    };
  },
});

// Of format 2022-10-21
function getWordForDay(day: string) {
  const index = Math.floor(
    Math.abs((+new Date("2022-07-11") - +new Date(day)) / (24 * 60 * 60 * 1000))
  );

  return ALL_SELECTED_WORDS[index % ALL_SELECTED_WORDS.length];
}

function guessToHint(targetWord: string, guessWord: string) {
  const hints: LetterHint[] = new Array(5).fill("_");

  assign_exact_hints(hints, targetWord, guessWord);
  assign_partial_hints(hints, targetWord, guessWord);

  return hints;
}

function assign_exact_hints(
  hints: string[],
  targetWord: string,
  guessWord: string
) {
  targetWord.split("").forEach((targetLetter, i) => {
    if (targetLetter === guessWord[i]) {
      hints[i] = "!";
    }
  });
}

function assign_partial_hints(
  hints: string[],
  targetWord: string,
  guessWord: string
) {
  const guessLetters = guessWord.split("");
  const usedIndices = hints.reduce((acc, curr, i) => {
    if (curr === "!") {
      return acc.add(i);
    }
    return acc;
  }, new Set());

  targetWord.split("").forEach((targetLetter, i) => {
    const guessIndex = guessLetters.findIndex(
      (guessLetter, j) =>
        targetLetter === guessLetter && hints[j] === "_" && !usedIndices.has(i)
    );
    if (guessIndex >= 0) {
      hints[guessIndex] = "?";
      usedIndices.add(i);
    }
  });
}

export default leWordResource;
