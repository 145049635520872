import {
  Center,
  Column,
  ISpinnerProps,
  ITextProps,
  Spinner,
  Text,
  ICenterProps,
} from "native-base";

interface CenteredSpinnerProps extends ICenterProps {
  spinnerProps?: ISpinnerProps;
  textProps?: ITextProps;
  text?: string;
}

export default function CenteredSpinner(props: CenteredSpinnerProps) {
  return (
    <Center h="full" w="full" {...props}>
      <Column>
        <Spinner {...props.spinnerProps} />
        {props.text ? (
          <Text textAlign="center" {...props.textProps}>
            {props.text}
          </Text>
        ) : null}
      </Column>
    </Center>
  );
}
