import { useCallback } from "react";
import { useUser } from "./auth";
import usePeople from "./usePeople";

export default function usePersonName() {
  const people = usePeople();
  const currentUser = useUser();

  return useCallback(
    (userId: string, currentUserAware: boolean = true) => {
      if (currentUserAware && currentUser && userId === currentUser.id) {
        return "You";
      }
      return people.get(userId)?.name;
    },
    [people, currentUser]
  );
}
