// import { Linking } from "expo";
// import * as Location from 'expo-location'
// import * as Permissions from "expo-permissions";
import * as ImagePicker from "expo-image-picker";
import { Alert, Platform } from "react-native";
import * as MediaLibrary from "expo-media-library";
import * as Clipboard from "expo-clipboard";
import { downloadAsync, uriToBase64 } from "./image-uploads";
import * as Linking from "expo-linking";

// import { Alert } from "react-native";

// export default async function getPermissionAsync(permission) {
//   const { status } = await Permissions.askAsync(permission);
//   if (status !== "granted") {
//     const permissionName = permission.toLowerCase().replace("_", " ");
//     Alert.alert(
//       "Cannot be done 😞",
//       `If you would like to use this feature, you'll need to enable the ${permissionName} permission in your phone settings.`,
//       [
//         {
//           text: "Let's go!",
//           onPress: () => Linking.openURL("app-settings:"),
//         },
//         { text: "Nevermind", onPress: () => {}, style: "cancel" },
//       ],
//       { cancelable: true }
//     );

//     return false;
//   }
//   return true;
// }

// export async function getLocationAsync(onSend) {
//   if (await getPermissionAsync(Permissions.LOCATION)) {
//     const location = await Location.getCurrentPositionAsync({})
//     if (location) {
//       onSend([{ location: location.coords }])
//     }
//   }
// }

export async function pickImageAsync(
  onSend: (images: { image: string }[]) => void
) {
  // if (await getPermissionAsync(Permissions.CAMERA_ROLL)) {
  const result = await ImagePicker.launchImageLibraryAsync({
    base64: true,
  });

  if (!result.cancelled) {
    onSend([{ image: result.base64! }]);
    return result.uri;
  }
  // }
}

export async function captureCameraImage() {
  const cameraPermission = await ImagePicker.getCameraPermissionsAsync();
  if (!cameraPermission.granted) {
    if (!cameraPermission.canAskAgain) {
      Alert.alert(
        "Permission needed",
        "Aspen needs access to your camera to capture a picture. Please navigate to your settings and allow Aspen camera permissions.",
        [
          {
            text: "OK",
            onPress: () => {
              Linking.openURL("app-settings:");
            },
          },
        ]
      );
      return;
    } else {
      const permRequest = await ImagePicker.requestCameraPermissionsAsync();
      if (!permRequest.granted) return;
    }
  }
  if (
    Platform.OS === "android" ||
    (Platform.OS === "ios" && parseInt(Platform.Version) === 10)
  ) {
    const mediaPermission = await ImagePicker.getMediaLibraryPermissionsAsync();
    if (!mediaPermission.granted) {
      if (!mediaPermission.canAskAgain) {
        Alert.alert(
          "Permission needed",
          "Aspen needs access to your photos library to capture a picture. Please navigate to your settings and allow Aspen photo permissions.",
          [
            {
              text: "OK",
              onPress: () => {
                Linking.openURL("app-settings:");
              },
            },
          ]
        );
        return;
      } else {
        const permRequest =
          await ImagePicker.requestMediaLibraryPermissionsAsync();
        if (!permRequest.granted) return;
      }
    }
  }
  return await ImagePicker.launchCameraAsync({
    base64: true,
  });
}

export async function saveImageToDevice(uri: string): Promise<{
  error: string | undefined;
}> {
  if (!uri) return { error: "no uri" };
  try {
    if (uri.startsWith("http://") || uri.startsWith("https://")) {
      const { data, error } = await downloadAsync(uri);
      if (!data || error) return { error: "unable to save image" };
      uri = data;
    }

    await MediaLibrary.saveToLibraryAsync(uri);
    return { error: undefined };
  } catch {
    return { error: "unable to save image" };
  }
}

export async function copyImageToClipboard(uri: string) {
  if (!uri) return { error: "no uri" };
  try {
    if (uri?.startsWith("http://") || uri?.startsWith("https://")) {
      const { data, error } = await downloadAsync(uri);
      if (!data || error) return { error: "unable to save image" };
      uri = data;
    }
    const { data: b64, error } = await uriToBase64(uri);
    if (error || !b64) return { error: "failed to set image to clipbord" };
    await Clipboard.setImageAsync(b64);
    return { error: undefined };
  } catch {
    return { error: "unable to save image" };
  }
}
