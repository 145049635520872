import React, { useCallback } from "react";
import { View, Text } from "../../components/basics";
import { useSpace } from "../../hooks/spaces";
import { getSpaceInviteLink, PeopleCardPressable } from "./SpaceEditScreen";
import QRCode from "react-native-qrcode-svg";
import { Share } from "react-native";
import { useToast } from "../../hooks/useToast";
import * as Clipboard from "expo-clipboard";

export default function InviteLinkScreen({ route }) {
  const { spaceId } = route.params;
  const { data: space } = useSpace(spaceId);
  const addToast = useToast();
  const spaceInviteUrl = getSpaceInviteLink(spaceId);
  const handleCopyInviteLink = useCallback(async () => {
    await Clipboard.setStringAsync(spaceInviteUrl);
    addToast({
      title: "Success",
      description: "Invite link copied to clipboard",
      variant: "SUCCESS",
    });
  }, [spaceInviteUrl, addToast]);

  const handleShareInviteLink = useCallback(async () => {
    try {
      const result = await Share.share({
        message: `You've been invited to join ${
          space?.name ? `"${space?.name}"` : "a group"
        } on Aspen. ${Platform.OS === "android" ? spaceInviteUrl : ""}`,
        url: spaceInviteUrl,
      });
      if (result.action === Share.sharedAction) {
        if (result.activityType) {
          // shared with activity type of result.activityType
        } else {
          // shared
        }
      } else if (result.action === Share.dismissedAction) {
        // dismissed
      }
    } catch (error) {
      alert(error.message);
    }
  }, []);
  return (
    <View
      sx={{
        width: "100%",
        height: "100%",
        // justifyContent: "center",
        alignItems: "center",
        backgroundColor: "$systemBg",
      }}
    >
      <View
        sx={{ borderRadius: "md", overflow: "hidden", width: "80%", my: "$8" }}
      >
        <PeopleCardPressable
          onPress={handleCopyInviteLink}
          label={"Copy invite link"}
          iconName={"link-outline"}
          index={0}
        />
        <PeopleCardPressable
          onPress={handleShareInviteLink}
          label={"Share invite link"}
          iconName={"share-outline"}
          index={1}
        />
      </View>
      <View
        sx={{
          backgroundColor: "$white",
          alignItems: "center",
          paddingY: "$10",
          width: "80%",
          borderRadius: "lg",
        }}
      >
        {!!space.name && (
          <>
            <Text sx={{ fontSize: 18, fontWeight: "semibold", mb: "$1" }}>
              {space?.name}
            </Text>
            <Text sx={{ color: "$coolGray.500", mb: "$8", fontSize: 14 }}>
              Aspen group
            </Text>
          </>
        )}
        <QRCode
          size={160}
          value={spaceInviteUrl}
          logo={require("../../assets/images/logo.png")}
          logoSize={36}
          logoBackgroundColor={"white"}
          logoBorderRadius={8}
        />
      </View>
    </View>
  );
}
