import ResourceCollection from "./resource";

export interface GeoGeniusGame {
  guess: LatLng | null;
  result: "IN_PROGRESS" | "GAME_OVER";
  secretLocation: LatLng | null;
  distance: number;
  day: string;
  playerId: string;
}

interface LatLng {
  lat: number;
  lng: number;
}

const ALL_SECRET_LOCATIONS = require("../assets/geo-genius-places.json");

export const GEO_GENIUS_RESOURCE_KEY = "GEO_GENIUS";

const secretLocation = { lat: 42.345573, lng: -71.098326 };

const geoGeniusResource = new ResourceCollection<
  GeoGeniusGame,
  {
    NEW: {
      day?: string;
    };
    GUESS: {
      latlng: LatLng;
    };
  }
>(GEO_GENIUS_RESOURCE_KEY, {
  NEW: (_resource, { data, user_id }, fromServer) => {
    const day = data["day"] ?? new Date().toISOString().split("T")[0];
    return {
      guess: null,
      result: "IN_PROGRESS",
      secretLocation: getSecretLocationForDay(day),
      distance: null,
      playerId: user_id,
      day,
    };
  },
  GUESS: (resource, { data }, fromServer) => {
    return {
      ...resource,
      guess: data["latLng"],
      result: "GAME_OVER",
      distance: calcCrow(
        data["latLng"].lat,
        data["latLng"].lng,
        resource.secretLocation!.lat,
        resource.secretLocation!.lng
      ),
    };
  },
});

function calcCrow(lat1: number, lon1: number, lat2: number, lon2: number) {
  const R = 6371; // km
  const dLat = toRad(lat2 - lat1);
  const dLon = toRad(lon2 - lon1);
  const radLat1 = toRad(lat1);
  const radLat2 = toRad(lat2);

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.sin(dLon / 2) *
      Math.sin(dLon / 2) *
      Math.cos(radLat1) *
      Math.cos(radLat2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c;
  return d;
}

function toRad(Value: number) {
  return (Value * Math.PI) / 180;
}
// Of format 2022-10-21
function getSecretLocationForDay(day: string) {
  const index = Math.floor(
    Math.abs((+new Date("2022-08-02") - +new Date(day)) / (24 * 60 * 60 * 1000))
  );

  return ALL_SECRET_LOCATIONS[index % ALL_SECRET_LOCATIONS.length];
}

export default geoGeniusResource;
