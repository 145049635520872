import { nanoid } from "nanoid";
import pollResource, { PollNewEventParams } from "../../resources/poll";

export function createPollLocal(data: PollNewEventParams) {
  const resourceData = pollResource.applyEvent(
    undefined,
    "NEW",
    {
      data,
    },
    false
  );

  return {
    id: nanoid(),
    collection_key: "POLL",
    data: resourceData,
  };
}
