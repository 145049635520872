export function parseNotePreview(noteText: string | undefined) {
  if (noteText) {
    const plainText = simpleRegexParse(noteText);
    const lines = plainText.split("\n").filter((l) => l);
    if (lines.length) return { title: lines[0], snippet: lines[1] };
  }
  return { title: "Untitled", snippet: "" };
}

// Add additional char references here as needed
// https://en.wikipedia.org/wiki/List_of_XML_and_HTML_character_entity_references
const ref_re = /&(nbsp|amp|quot|lt|gt);/g;
const ref_translation = {
  nbsp: String.fromCharCode(160),
  amp: "&",
  quot: '"',
  lt: "<",
  gt: ">",
};

function simpleRegexParse(text: string) {
  return text
    .replace(/<style([\s\S]*?)<\/style>/gi, "")
    .replace(/<script([\s\S]*?)<\/script>/gi, "")
    .replace(/<\/div>/gi, "\n")
    .replace(/<\/li>/gi, "\n")
    .replace(/<li>/gi, "  *  ")
    .replace(/<\/ul>/gi, "\n")
    .replace(/<\/p>/gi, "\n")
    .replace(/<br\s*[\/]?>/gi, "\n")
    .replace(/<[^>]+>/gi, "")
    .replace(
      ref_re,
      (_match, capture: string) => ref_translation[capture] ?? ""
    );
}
