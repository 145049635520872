import { NativeStackScreenProps } from "@react-navigation/native-stack";
import {
  Icon,
  IconButton,
  Pressable,
  View,
  SafeAreaView,
  Text,
} from "../components/basics";
import React, {
  ComponentProps,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { Header } from "@react-navigation/elements";

import { useUser } from "../hooks/auth";
import createMasterDetailNavigator from "../navigators/master-detail/md-navigator";
import PhotosScreen from "./spaces/PhotosScreen";
import PagesScreen from "./spaces/PagesScreen";
import WebSpaceApp from "./spaces/WebSpaceApp";
import {
  BottomTabHeaderProps,
  BottomTabNavigationEventMap,
  createBottomTabNavigator,
} from "@react-navigation/bottom-tabs";
import SpaceAppIcon from "../components/SpaceAppIcon";
import ChatScreen from "./ChatScreen";
import { LayoutAnimation, Platform, StyleSheet, UIManager } from "react-native";
import { useSx } from "dripsy";
import ProfilesScreen from "./ProfilesScreen";
import { usePerson } from "../hooks/usePeople";
import { ProfileAvatar } from "../components/PersonAvatar";
import GroupsScreen from "./GroupsScreen";
import HomeScreen, { useGreeting } from "./HomeScreen";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import {
  NavigationHelpers,
  ParamListBase,
  StackActions,
  useNavigation,
} from "@react-navigation/native";
import {
  BottomSheetBackdrop,
  BottomSheetModal,
  BottomSheetModalProvider,
} from "@gorhom/bottom-sheet";
import AppsScreen from "./AppsScreen";
import SearchScreen from "./SearchScreen";
// import BottomSheetBackdrop from "../components/BottomSheetBackdrop";
import SpaceAppHeader from "../components/SpaceAppHeader";
import SpaceFilterSelector from "../components/SpaceFilterSelector";

export const MasterDetail = createMasterDetailNavigator<{
  Space: { spaceId: string };
  NotFound: undefined;
  NoneSelected: undefined;
  UserProfile: { userId: string };
  CreateSpace: undefined;
}>();

export const AppTabs = createBottomTabNavigator();

// TODO move to app.tsx
if (
  Platform.OS === "android" &&
  UIManager.setLayoutAnimationEnabledExperimental
) {
  UIManager.setLayoutAnimationEnabledExperimental(true);
}

// TODO update to proper props
type HomeScreenProps = any;

const MAIN_ROUTES = ["Chat", "profiles", "home", "search", "AllApps"] as const;
export const TAB_BAR_HEIGHT = 75;

const renderSpaceAppHeader = (props: BottomTabHeaderProps) => (
  <SpaceAppHeader {...props} />
);

export default function MainScreen({
  navigation,
  route,
}: NativeStackScreenProps<any, any>) {
  const [appHistory, setAppHistory] = useState<DistinctStack<string>>(
    new DistinctStack()
  );

  const appSheetRef = useRef<BottomSheetModal>(null);
  const snapPoints = useMemo(() => ["50%", "90%"], []);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  const handleAppTabPress = useCallback(() => {
    if (isDrawerOpen) {
      setIsDrawerOpen(false);
      appSheetRef.current?.close();
    } else {
      setIsDrawerOpen(true);
      appSheetRef.current?.present();
    }
  }, [isDrawerOpen]);

  useEffect(() => {
    const unsubscribe = navigation.addListener("state", (event) => {
      appSheetRef.current?.close();
      const state = event.data.state;
      const tabsHistory = state.routes.find((route) => route.name === "Home")
        ?.state?.history;
      if (!tabsHistory || tabsHistory.length < 2) return;
      const latestApp = tabsHistory[1];
      // LayoutAnimation.configureNext(LayoutAnimation.Presets.easeInEaseOut);
      setAppHistory((prev) => {
        prev.push(latestApp.key);
        return new DistinctStack(prev.values());
      });
    });
    return () => {
      unsubscribe();
    };
  }, [navigation, appSheetRef]);

  const sx = useSx();

  const Sx = useSx();

  const insets = useSafeAreaInsets();

  return (
    <BottomSheetModalProvider>
      <AppTabs.Navigator
        screenOptions={{
          headerShown: true,
          headerStyle: sx({ backgroundColor: "$white" }),
        }}
        backBehavior="history"
        sceneContainerStyle={sx({
          maxWidth: 600,
          marginX: "auto",
          width: "100%",
        })}
        tabBar={({ descriptors, state, navigation }) => {
          const activeRoute = state.routeNames[state.index];
          return (
            <SafeAreaView
              edges={["bottom"]}
              sx={{
                maxWidth: 600,
                width: "100%",
                marginX: "auto",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-around",
                // paddingX: "$4",
                // paddingY: "$2",
                height: TAB_BAR_HEIGHT + insets.bottom,
                // backgroundColor: "$white",
                overflow: "hidden",
                // borderTopLeftRadius: 8,
                // borderTopRightRadius: 8,

                borderColor: "$systemBorder",
                borderTopWidth: "hairline",

                // shadowOffset: { height: -2, width: 0 },
                // shadowColor: "$black",
                // shadowOpacity: 0.5,
                // shadowRadius: 5,
                // zIndex: 20,
                // elevation: 20,

                backgroundColor: "$white",
              }}
            >
              {MAIN_ROUTES.map((routeName) => (
                <BottomTabButton
                  key={routeName}
                  routeName={routeName}
                  navigation={navigation}
                  isActive={
                    routeName === "AllApps"
                      ? isDrawerOpen
                      : activeRoute === routeName
                  }
                  onPress={
                    routeName === "AllApps" ? handleAppTabPress : undefined
                  }
                />
              ))}
            </SafeAreaView>
          );
        }}
      >
        <AppTabs.Screen
          name="home"
          component={HomeScreen}
          options={{
            title: "Home",
            headerShown: true,
            header: () => <HomeHeader />,
            tabBarIcon: ({ focused }) => (
              <Icon
                name="people"
                color={focused ? "$blueGray.900" : "$blueGray.500"}
                size={24}
              />
            ),
          }}
        />
        <AppTabs.Screen
          name="Chat"
          component={ChatScreen}
          options={{
            // title: "Chat",
            headerShown: false,
            tabBarIcon: ({ focused }) => (
              <SpaceAppIcon focused={focused} appName="chat" />
            ),
          }}
        />
        <AppTabs.Screen
          name="le-word"
          component={WebSpaceApp}
          options={{
            title: "Le Word",
            header: renderSpaceAppHeader,
            tabBarIcon: ({ focused }) => (
              <SpaceAppIcon size="sm" focused={focused} appName="le-word" />
            ),
          }}
        />
        <AppTabs.Screen
          name="geo-genius"
          component={WebSpaceApp}
          options={{
            title: "Geo Genius",
            header: renderSpaceAppHeader,
            tabBarIcon: ({ focused }) => (
              <SpaceAppIcon size="sm" focused={focused} appName="geo-genius" />
            ),
          }}
        />
        <AppTabs.Screen
          name="schedule"
          component={WebSpaceApp}
          options={{
            title: "Schedule",
            header: renderSpaceAppHeader,
            tabBarIcon: ({ focused }) => (
              <SpaceAppIcon size="sm" focused={focused} appName="schedule" />
            ),
          }}
        />
        <AppTabs.Screen
          name="reminders"
          component={WebSpaceApp}
          options={{
            title: "Reminders",
            header: renderSpaceAppHeader,
            tabBarIcon: ({ focused }) => (
              <SpaceAppIcon size="sm" focused={focused} appName="reminders" />
            ),
          }}
        />
        <AppTabs.Screen
          name="Pages"
          component={PagesScreen}
          options={{
            header: renderSpaceAppHeader,
            title: "Notes",
            tabBarIcon: ({ focused }) => (
              <SpaceAppIcon size="sm" focused={focused} appName="pages" />
            ),
          }}
        />
        <AppTabs.Screen
          name="Photos"
          component={PhotosScreen}
          options={{
            title: "Photos",
            header: renderSpaceAppHeader,
            tabBarIcon: ({ focused }) => (
              <SpaceAppIcon size="sm" focused={focused} appName="photos" />
            ),
          }}
        />
        <AppTabs.Screen
          name="polls"
          component={WebSpaceApp}
          options={{
            title: "Polls",
            header: renderSpaceAppHeader,
            tabBarIcon: ({ focused }) => (
              <SpaceAppIcon size="sm" focused={focused} appName="polls" />
            ),
          }}
        />
        <AppTabs.Screen
          name="profiles"
          component={ProfilesScreen}
          options={{
            headerShown: false,
            title: "People",
            header: renderSpaceAppHeader,
            tabBarIcon: ({ focused }) => (
              // <SpaceAppIcon size="sm" focused={focused} appName="polls" />
              <Icon
                name="people"
                color={focused ? "$blueGray.900" : "$blueGray.500"}
                size={24}
              />
            ),
          }}
        />
        <AppTabs.Screen
          name="groups"
          component={GroupsScreen}
          options={{
            title: "Groups",
            tabBarIcon: ({ focused }) => (
              // <SpaceAppIcon size="sm" focused={focused} appName="polls" />
              <Icon
                name="people-circle"
                color={focused ? "$blueGray.900" : "$blueGray.500"}
                size={24}
              />
            ),
          }}
        />
        <AppTabs.Screen
          name="search"
          component={SearchScreen}
          options={{
            title: "Search",
          }}
        />
        <AppTabs.Screen
          name="shopping-list"
          component={WebSpaceApp}
          options={{
            title: "Shopping List",
            header: renderSpaceAppHeader,
            tabBarIcon: ({ focused }) => (
              <SpaceAppIcon
                size="sm"
                focused={focused}
                appName="shopping-list"
              />
            ),
          }}
        />
      </AppTabs.Navigator>
      <BottomSheetModal
        ref={appSheetRef}
        backdropComponent={({
          style,
          animatedIndex,
          animatedPosition,
          onPress,
        }) => (
          <BottomSheetBackdrop
            style={{ ...style, marginBottom: insets.bottom + TAB_BAR_HEIGHT }}
            onPress={onPress}
            animatedIndex={animatedIndex}
            animatedPosition={animatedPosition}
            disappearsOnIndex={-1}
            pressBehavior="close"
          />
        )}
        index={0}
        onChange={(index) => {
          if (index === -1) {
            setIsDrawerOpen(false);
          } else {
            setIsDrawerOpen(true);
          }
        }}
        snapPoints={snapPoints}
        topInset={insets.top}
        enablePanDownToClose
        backgroundStyle={Sx({
          backgroundColor: "$white",
        })}
        style={{
          shadowColor: "#000",
          shadowOffset: {
            width: 0,
            height: -2,
          },
          shadowOpacity: 0.23,
          shadowRadius: 2.62,
          elevation: 4,
        }}
        bottomInset={insets.bottom + TAB_BAR_HEIGHT} // TODO this shit is mad hardcoded... we should fix
      >
        <AppsScreen />
      </BottomSheetModal>
      <SpaceFilterSelector />
    </BottomSheetModalProvider>
  );
}

function BottomTabButton({
  routeName,
  navigation,
  isActive = false,
  onPress,
}: {
  routeName: typeof MAIN_ROUTES[number];
  navigation: NavigationHelpers<ParamListBase, BottomTabNavigationEventMap>;
  isActive?: boolean;
  onPress?: () => void;
}) {
  return (
    <View
      sx={{
        borderRadius: 24,
        backgroundColor: isActive ? "$primary.100" : "transparent",
        overflow: "hidden",
      }}
    >
      <IconButton
        onPress={() => {
          if (onPress) {
            onPress();
          } else {
            if (isActive) navigation.dispatch(StackActions.popToTop());
            navigation.navigate(routeName);
          }
        }}
        colorScheme="$primary"
        icon={`${getIconForRoute(routeName)}${isActive ? "" : "-outline"}`}
        size="lg"
        _iconStyles={
          isActive
            ? {
                color: "$primary.600",
              }
            : { color: "$coolGray.500" }
        }
      />
    </View>
  );
}

function getIconForRoute(
  routeName: typeof MAIN_ROUTES[number]
): ComponentProps<typeof Icon>["name"] {
  switch (routeName) {
    case "AllApps":
      return "apps";
    case "Chat":
      return "chatbubble";
    case "home":
      return "home";
    case "profiles":
      return "people";
    case "search":
      return "search";
  }
}

class DistinctStack<T> {
  private items: T[];
  private itemSet: Set<T>;
  constructor(values?: T[]) {
    this.items = values ?? [];
    this.itemSet = new Set(values ?? []);
  }
  push(item: T) {
    if (this.itemSet.has(item)) {
      this.items = this.items.filter((it) => it !== item);
    }
    this.itemSet.add(item);
    this.items.unshift(item);
  }
  values() {
    return [...this.items];
  }
}

function HomeHeader() {
  const user = useUser();
  const profile = usePerson(user?.id);
  const navigation = useNavigation();
  const greeting = useGreeting();
  return (
    <Header
      headerTitle={() => (
        <Text sx={{ textAlign: "center", fontSize: 16 }}>{greeting}</Text>
      )}
      headerLeft={() => (
        <View sx={{ width: 65, alignItems: "center" }}>
          <Pressable
            onPress={() => {
              navigation.navigate("profiles", {
                screen: "profile",
                params: {
                  userId: user?.id,
                  type: "PROFILE",
                },
              });
            }}
          >
            <ProfileAvatar size={32} profile={profile} />
          </Pressable>
        </View>
      )}
    />
  );
  return (
    <SafeAreaView
      edges={["top"]}
      sx={{
        // backgroundColor: "$white",
        width: "100%",
        paddingY: "$2",
        paddingX: "$3",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        borderBottomColor: "$systemBorder",
        // borderBottomWidth: "hairline",
        // boxShadow: "sm",

        backgroundColor: "$white",
      }}
    >
      <View sx={{ width: 50 }}>
        {/* <Icon size={24} name="person" /> */}
        <Pressable
          onPress={() => {
            navigation.navigate("profiles", {
              screen: "profile",
              params: {
                userId: user?.id,
                type: "PROFILE",
              },
            });
          }}
        >
          <ProfileAvatar size={32} profile={profile} />
        </Pressable>
      </View>
      <View
        sx={{
          flexDirection: "column",
          flexGrow: 1,
          justifyContent: "center",
        }}
      >
        <Text sx={{ textAlign: "center", fontSize: 16 }}>{greeting}</Text>
        {/* <StyledTextInput
          sx={{
            borderRadius: 24,
            paddingX: 12,
            mb: 0,
          }}
          placeholder="Find something..."
          rightIcon={() => (
            <Image
              source={require("../assets/images/logo.png")}
              sx={{
                width: 24,
                height: 24,
              }}
            />
          )}
          leftIcon={() => <Icon name="search" size={18} />}
        /> */}
      </View>
      {/* <View
        sx={{
          flexDirection: "row",
          alignItems: "center",
          width: 50,
          justifyContent: "flex-end",
        }}
      >
        <Icon size={24} name="notifications" />
      </View> */}
    </SafeAreaView>
  );
}
