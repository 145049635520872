import { atom, AtomEffect, selector } from "recoil";
import client from "../graphql/client";
import { pipe, subscribe } from "wonka";
import { v4 as uuidv4 } from "uuid";
import {
  DeleteSpaceDocument,
  GetUserSpacesDocument,
  GetUserSpacesQuery,
  InsertSpaceDocument,
  InsertSpacesDocument,
  RenameSpaceDocument,
  ResourcePermissionFragment,
} from "../generated/graphql";
import { InvitationCreationInfo } from "../../lib/invitations";

interface SpaceSubscriptionState {
  loading: boolean;
  data: GetUserSpacesQuery["spaces"];
}

const spacesSyncEffect = (): AtomEffect<SpaceSubscriptionState> => {
  return ({ setSelf }) => {
    const spaces_subscription = pipe(
      client.query<GetUserSpacesQuery>(GetUserSpacesDocument),
      subscribe(({ data, error }) => {
        if (data) {
          setSelf({
            loading: false,
            data: data.spaces.filter((space) => !space.deleted),
          });
        }
      })
    );
    return () => {
      spaces_subscription.unsubscribe();
    };
  };
};

export const spacesState = atom<SpaceSubscriptionState>({
  key: "spaces-state", // unique ID (with respect to other atoms/selectors)
  default: { loading: true, data: [] }, // default value (aka initial value)
  effects_UNSTABLE: [spacesSyncEffect()],
});

export const spacesMap = selector({
  key: "SpacesMap",
  get: ({ get }) => {
    const { loading, data } = get(spacesState);
    return { loading, data: new Map(data.map((space) => [space.id, space])) };
  },
});

export interface SpaceCreationParams {
  name?: string;
  invitations?: InvitationCreationInfo[];
  permissions?: ResourcePermissionFragment[];
}

export async function createNewSpace(params: SpaceCreationParams) {
  const resp = await createNewSpaces([params]);
  return { ...resp, data: resp?.data ? resp.data[0] : undefined };
}

export async function createNewSpaces(spacesParams: SpaceCreationParams[]) {
  const spaces = spacesParams.map((params) => {
    const { name, permissions, invitations } = params;
    return {
      name,
      created_at: new Date().toISOString(),
      updated_at: new Date().toISOString(),
      apps: {
        data: [
          {
            fractional_index: 0.1,
            app_name: "Chat",
          },
          {
            fractional_index: 0.2,
            app_name: "Photos",
          },
          {
            fractional_index: 0.3,
            app_name: "ShoppingList",
          },
          {
            fractional_index: 0.4,
            app_name: "Pages",
          },
          {
            fractional_index: 0.5,
            app_name: "Schedule",
          },
        ],
      },
      invitations: { data: invitations ?? [] },
      resource_permissions: { data: permissions ?? [] },
    };
  });

  const resp = await client
    .mutation(InsertSpacesDocument, {
      spaces,
    })
    .toPromise();

  return { data: resp?.data?.insert_spaces?.returning, error: resp.error };
}

export async function deleteSpace(spaceId: string) {
  const resp = await client
    .mutation(DeleteSpaceDocument, {
      space_id: spaceId,
    })
    .toPromise();

  if (resp.error) throw new Error(resp.error.message);
  return resp.data;
}

export async function renameSpace(
  spaceId: string,
  newName: string | undefined
) {
  return await client
    .mutation(RenameSpaceDocument, {
      space_id: spaceId,
      new_name: newName,
    })
    .toPromise();
}
