import { useNavigation } from "@react-navigation/native";
import React, { useCallback, useMemo, useState, useEffect } from "react";
import { View, Text, Button } from "./basics";
import { MotiView } from "moti";
import Icon from "./basics/Icon";
import Pressable from "./basics/Pressable";
import { Sx, useSx } from "dripsy";
import { useConnectionQuery } from "../hooks/useConnections";
import { SCHEDULE_RESOURCE_KEY } from "../resources/schedule";
import { useCustomResourceCollection } from "../hooks/useResourceCollection";
import { differenceInCalendarDays, format } from "date-fns";
import { Photo, PHOTO_RESOURCE_KEY } from "../resources/photo";
import { ChatImage } from "./ChatImage";
import { optimizedImagePath } from "../utils/images";
import { CHAT_RESOURCE_KEY } from "../resources/chat";
import { PAGES_RESOURCE_KEY } from "../resources/page";
import { cardInfo } from "./ResourceCard";
import { normalizeAttachments } from "../utils/apps/chat";
import { getUploadUrl } from "../utils/image-uploads";
import MediaGallery from "./MediaGallery";
import { LE_WORD_RESOURCE_KEY } from "../resources/le-word";
import SpaceAppIcon from "./SpaceAppIcon";
import { REMINDER_RESOURCE_KEY } from "../resources/reminder";
import { filterAndSetupRemindersForDisplay } from "../utils/apps/reminders";
import { useChatList } from "../hooks/useChatList";
import SpacesFeedItem from "./SpacesFeedItem";
import { useSpaceContainerId } from "../hooks/useSpaceId";
import { Skeleton } from "moti/skeleton";
import { transformChatAndPhotoResources } from "../screens/spaces/PhotosScreen";
import { ResourceVideoThumbnail } from "./video-thumbail";
import { SHOPPING_LIST_RESOURCE_KEY } from "../resources/shopping-list";
import { usePushEvent } from "../hooks/usePushEvent";

function getLatestResources(pageSize = 3, resourceKey) {
  const spaceId = useSpaceContainerId();
  const queryParams = {
    resourceQueries: { [resourceKey]: {} },
    sort: {
      created_at: {
        _direction: "desc",
      },
      id: {
        _direction: "asc",
      },
    },
    pageSize,
    spaceId,
  };
  return useCustomResourceCollection(queryParams);
}

function formatLongRelativeDate(date: Date) {
  const daysDiff = differenceInCalendarDays(date, new Date());
  if (daysDiff === -1) return "Yesterday";
  if (daysDiff === 0) return "Today";
  if (daysDiff === 1) return "Tomorrow";
  if (daysDiff > 0 && daysDiff <= 7) return format(date, "eeee");
  return format(date, "MMMM d");
}

export const wrapperStyles: Sx = {
  marginX: "$3",
  marginBottom: "$3",
  borderRadius: "md",
  flexDirection: "column",
  paddingX: "$2",
  paddingY: "$3",
};

export const itemStyles: Sx = (index: number) => ({
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "space-between",
  paddingX: "$3",
  paddingY: "$2",
  width: "100%",
  borderRadius: 0,
  backgroundColor: "$white",
  // boxShadow: "$1",
  marginTop: index > 0 ? 2 : 0,
});

function EmptyMessage({ message }: { message: string }) {
  return (
    <View sx={{ alignItems: "center", width: "100%" }}>
      <Text sx={{ color: "$gray.500" }}>{message}</Text>
    </View>
  );
}

function LoadingState() {
  return <Skeleton width={"100%"} height={50} colorMode={"light"} />;
}

function EmptyOrLoadingState({
  message,
  loading = false,
  isEmpty,
}: {
  message: string;
  loading?: boolean;
  isEmpty: boolean;
}) {
  return loading ? (
    <LoadingState />
  ) : isEmpty ? (
    <EmptyMessage message={message} />
  ) : null;
}

export function ResourceCardHeader({
  icon,
  title,
  onViewAllPress,
}: {
  icon: string;
  title: string;
  onViewAllPress: () => void;
}) {
  const navigation = useNavigation();
  // TODO: get space filtering right
  return (
    <View
      sx={{
        flexDirection: "row",
        alignItems: "center",
        width: "100%",
        justifyContent: "space-between",
        paddingBottom: "$2",
      }}
    >
      <View sx={{ flexDirection: "row", alignItems: "center" }}>
        <SpaceAppIcon appName={icon} />
        <Text sx={{ fontWeight: "semibold", fontSize: "lg", marginLeft: "$2" }}>
          {title}
        </Text>
      </View>
      <Button variant="ghost" onPress={onViewAllPress}>
        See all
      </Button>
    </View>
  );
}

const PHOTO_SIZE = 100;

export function PhotosCard() {
  const spaceId = useSpaceContainerId();

  const queryParams = {
    resourceQueries: {
      [PHOTO_RESOURCE_KEY]: {},
      [CHAT_RESOURCE_KEY]: { data: { _has_key: "attachment" } },
    },
    sort: {
      created_at: {
        _direction: "desc",
      },
      id: {
        _direction: "asc",
      },
    },
    pageSize: 3,
    spaceId,
  };
  const sx = useSx();
  const navigation = useNavigation();
  const { resources, error, fetching, getMore, hasMore } =
    useCustomResourceCollection(queryParams);

  const [imageViewerState, setImageViewerState] = useState({
    isOpen: false,
    index: 0,
  });

  const photos = transformChatAndPhotoResources(resources ?? []);

  const handleLightBoxOpen = useCallback(
    (index) => {
      setImageViewerState({
        index: index,
        isOpen: true,
      });
    },
    [setImageViewerState]
  );

  const handleLightboxClose = useCallback(() => {
    setImageViewerState((prevState) => ({
      ...prevState,
      isOpen: false,
    }));
  }, [setImageViewerState]);

  const galleryPhotos = photos.map((photo) => {
    const type = photo.type || "photo";
    return {
      uri:
        type === "photo"
          ? optimizedImagePath(photo.uri, { w: 828 })
          : photo.uri,
      key: photo.imageKey,
      type,
    };
  });

  return (
    <MotiView style={sx(wrapperStyles)}>
      <MediaGallery
        data={galleryPhotos}
        visible={imageViewerState.isOpen}
        index={imageViewerState.index}
        handleClose={handleLightboxClose}
      />
      <ResourceCardHeader
        icon="Photos"
        title="Photos"
        onViewAllPress={() => {
          navigation.navigate("Photos", { spaceId });
        }}
      />
      <View
        sx={{ flexDirection: "row", alignItems: "flex-start", width: "100%" }}
      >
        {photos?.map((item, index) => (
          <Pressable
            key={item.uri}
            sx={{
              marginLeft: "$2",
              flexDirection: "row",
              borderRadius: "md",
              overflow: "hidden",
            }}
            onPress={() => handleLightBoxOpen(index)}
          >
            {item.type === "photo" && (
              <ChatImage
                attachmentKey={item.imageKey}
                source={{ uri: optimizedImagePath(item.uri, { w: 256 }) }}
                height={PHOTO_SIZE}
                width={PHOTO_SIZE}
                alt="photo"
              />
            )}
            {item.type === "video" && (
              <ResourceVideoThumbnail
                resource={item}
                width={PHOTO_SIZE}
                height={PHOTO_SIZE}
              />
            )}
          </Pressable>
        ))}
      </View>
      <EmptyOrLoadingState
        message={"No photos to display"}
        loading={fetching}
        isEmpty={photos?.length === 0}
      />
    </MotiView>
  );
}

function CheckBox({
  isChecked,
  onPress,
}: {
  isChecked: boolean;
  onPress: () => void;
}) {
  return (
    <Pressable
      sx={{
        width: 20,
        height: 20,
        borderRadius: 2,
        borderColor: "$coolGray.500",
        borderWidth: "hairline",
        alignItems: "center",
        justifyContent: "center",
      }}
      onPress={onPress}
    >
      {isChecked && <Icon size={16} name="checkmark-outline" />}
    </Pressable>
  );
}

export function ShoppingListCard({ spaceId }: { spaceId?: string }) {
  const sx = useSx();
  const navigation = useNavigation();
  const pushEvent = usePushEvent();
  const {
    resources: todos,
    error,
    getMore,
    fetching,
    hasMore,
  } = getLatestResources(3, SHOPPING_LIST_RESOURCE_KEY);
  const [initialShoppingList, setInitialShoppingList] = useState(null);
  // TODO:Filter recurring todos
  // if (!todos || todos?.length === 0) return null;
  const filteredTodos = (todos ?? []).sort(
    (a, b) => +new Date(a.created_at) - +new Date(b.created_at)
  );

  useEffect(() => {
    if (initialShoppingList == null && filteredTodos.length > 0)
      setInitialShoppingList(
        new Set(filteredTodos.map((resource) => resource.id))
      );
  }, [filteredTodos, initialShoppingList]);

  const todosToDisplay = useMemo(() => {
    if (!todos || !initialShoppingList) return [];
    return todos?.filter((resource) => initialShoppingList.has(resource.id));
  }, [todos, initialShoppingList]);

  const setStatus = useCallback(
    (resourceId: string, isComplete: boolean) => {
      pushEvent({
        resource_key: "SHOPPING_LIST",
        resource_id: resourceId,
        data: {
          id: resourceId,
          isCompleted: isComplete,
        },
        type: "UPDATE_STATUS",
      });
    },
    [pushEvent]
  );
  return (
    <MotiView style={sx(wrapperStyles)}>
      <ResourceCardHeader
        icon="shopping-list"
        title="Shopping List"
        onViewAllPress={() => {
          navigation.navigate("shopping-list", { spaceId });
        }}
      />
      <View sx={{ overflow: "hidden", borderRadius: "sm" }}>
        {todosToDisplay?.map((resource, index) => {
          return (
            <View
              key={resource.id}
              sx={{ ...itemStyles(0), paddingY: "$3" }}
              key={resource.id}
            >
              <View
                sx={{
                  maxWidth: "90%",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <CheckBox
                  isChecked={resource.data.is_complete}
                  onPress={() => {
                    setStatus(resource.id, !resource.data.is_complete);
                  }}
                />
                <Text
                  numberOfLines={1}
                  sx={{ fontWeight: "normal", marginLeft: "$3" }}
                >
                  {resource.data.text}
                </Text>
              </View>
            </View>
          );
        })}
      </View>
      <EmptyOrLoadingState
        message={"No shopping list items to display"}
        loading={fetching}
        isEmpty={todos?.length === 0}
      />
    </MotiView>
  );
}

export function RemindersCard({ spaceId }: { spaceId?: string }) {
  const sx = useSx();
  const navigation = useNavigation();
  const {
    resources: reminders,
    error,
    getMore,
    fetching,
    hasMore,
  } = getLatestResources(3, REMINDER_RESOURCE_KEY);
  // TODO:Filter recurring reminders
  // if (!reminders || reminders?.length === 0) return null;
  const filteredReminders =
    reminders?.length === 0 || !reminders
      ? []
      : filterAndSetupRemindersForDisplay(reminders);
  return (
    <MotiView style={sx(wrapperStyles)}>
      <ResourceCardHeader
        icon="reminders"
        title="Reminders"
        onViewAllPress={() => {
          navigation.navigate("reminders", { spaceId });
        }}
      />
      <View sx={{ overflow: "hidden", borderRadius: "sm" }}>
        {filteredReminders?.map(({ resource, displayDate }, index) => {
          const { icon, title, snippet } = cardInfo(resource);

          return (
            <Pressable
              key={resource.id}
              sx={itemStyles(index)}
              // TODO: get proper linking to specific reminders
              onPress={() => {
                navigation.navigate("reminders");
              }}
              key={resource.id}
            >
              <View sx={{ maxWidth: "90%" }}>
                <Text numberOfLines={1} sx={{ fontWeight: "medium" }}>
                  {resource.data.title}
                </Text>
                <Text sx={{ color: "$coolGray.500" }}>
                  {formatLongRelativeDate(displayDate)}{" "}
                  {format(displayDate, "p")}
                </Text>
              </View>
              <Icon
                name="chevron-forward-outline"
                size={20}
                sx={{ color: "$gray.500" }}
              />
            </Pressable>
          );
        })}
      </View>
      <EmptyOrLoadingState
        message={"No reminders to display"}
        loading={fetching}
        isEmpty={reminders?.length === 0}
      />
    </MotiView>
  );
}

export function LeWordCard({ spaceId }: { spaceId?: string }) {
  const sx = useSx();
  const navigation = useNavigation();
  const connectionQuery = useConnectionQuery();

  const {
    resources: games,
    error,
    fetching,
    hasMore,
    getMore,
  } = useCustomResourceCollection({
    resourceQueries: {
      [LE_WORD_RESOURCE_KEY]: {},
    },
  });
  // TODO:Filter recurring reminders
  const today = format(new Date(), "yyyy-MM-dd");

  const todaysGames = games?.filter((game) => game?.data?.day === today);
  const hydratedGames = todaysGames?.map((game) => ({
    game: game?.data,
    playerProfile: connectionQuery("profiles_by_id", game?.data?.playedId),
  }));
  console.log(games);
  // no one has ever played
  if (!games || games.length === 0) return null;
  //people have played but no games today
  if (games && todaysGames?.length === 0) return null;

  // people have played and let's show the ranking
  // const sortedGames = hydratedGames?.sort(
  //   (a, b) => a.game.numGuesses - b.game.numGuesses
  // );
  return null;
  // return (
  //   <MotiView style={sx(wrapperStyles)}>
  //     <ResourceCardHeader icon="reminders" title="Reminders" />
  //     {filteredReminders?.map(({ resource, displayDate }) => {
  //       const { icon, title, snippet } = cardInfo(resource);

  //       return (
  //         <Pressable
  //           sx={{
  //             flexDirection: "row",
  //             alignItems: "center",
  //             justifyContent: "space-between",
  //             borderTopWidth: 1,
  //             paddingX: "$2",
  //             width: "100%",
  //             paddingTop: "$2",
  //             marginTop: "$3",
  //             borderRadius: "md",
  //             borderColor: "$gray.300",
  //           }}
  //           // TODO: get proper linking to specific reminders
  //           onPress={() => {
  //             navigation.navigate("reminders");
  //           }}
  //           key={resource.id}
  //         >
  //           <View>
  //             <Text numberOfLines={1} sx={{ fontWeight: "medium" }}>
  //               {resource.data.title}
  //             </Text>
  //             <Text>
  //               {formatLongRelativeDate(displayDate)} {format(displayDate, "p")}
  //             </Text>
  //           </View>
  //           <Icon
  //             name="chevron-forward-outline"
  //             size={20}
  //             sx={{ color: "$gray.500" }}
  //           />
  //         </Pressable>
  //       );
  //     })}
  //   </MotiView>
  // );
}

export function EventsCard({ spaceId }: { spaceId?: string }) {
  const sx = useSx();
  const navigation = useNavigation();
  const {
    resources: events,
    error,
    getMore,
    fetching,
    hasMore,
  } = getLatestResources(3, SCHEDULE_RESOURCE_KEY);
  // // TODO: maybe sort these chronologically?

  // if (events?.length === 0) return null;

  return (
    <MotiView style={sx(wrapperStyles)}>
      <ResourceCardHeader
        icon="schedule"
        title="Events"
        onViewAllPress={() => {
          navigation.navigate("schedule", { spaceId });
        }}
      />
      <View sx={{ overflow: "hidden", borderRadius: "sm" }}>
        {events?.map(({ id, data }, index) => (
          <Pressable
            key={id}
            sx={{ ...itemStyles(index), justifyContent: "flex-start" }}
            onPress={() => {
              navigation.navigate("schedule", {
                appQuery: { selectedId: id },
              });
            }}
          >
            <Text sx={{ width: 50, color: "$coolGray.500" }}>
              {format(new Date(data.start_date_utc), "MMM d")}
            </Text>
            <Text>{data.title}</Text>
          </Pressable>
        ))}
      </View>
      <EmptyOrLoadingState
        message={"No events to display"}
        loading={fetching}
        isEmpty={events?.length === 0}
      />
    </MotiView>
  );
}

export function NotesCard({ spaceId }: { spaceId?: string }) {
  const sx = useSx();
  const navigation = useNavigation();
  const {
    resources: notes,
    error,
    getMore,
    fetching,
    hasMore,
  } = getLatestResources(3, PAGES_RESOURCE_KEY);
  // if (notes?.length === 0) return null;
  return (
    <MotiView style={sx(wrapperStyles)}>
      <ResourceCardHeader
        icon="Pages"
        title="Notes"
        onViewAllPress={() => {
          navigation.navigate("Pages", { spaceId });
        }}
      />
      <View sx={{ overflow: "hidden", borderRadius: "sm" }}>
        {notes?.map((resource, index) => {
          const { icon, title, snippet } = cardInfo(resource);

          return (
            <Pressable
              key={resource.id}
              sx={itemStyles(index)}
              onPress={() => {
                navigation.navigate("Pages", { resourceId: resource.id });
              }}
              key={resource.id}
            >
              <View sx={{ maxWidth: "90%" }}>
                <Text numberOfLines={1} sx={{ fontWeight: "medium" }}>
                  {title}
                </Text>
                <Text sx={{ color: "$coolGray.500" }} numberOfLines={2}>
                  {snippet}
                </Text>
              </View>
              <Icon
                name="chevron-forward-outline"
                size={20}
                sx={{ color: "$gray.500" }}
              />
            </Pressable>
          );
        })}
      </View>
      <EmptyOrLoadingState
        message={"No notes to display"}
        loading={fetching}
        isEmpty={notes?.length === 0}
      />
    </MotiView>
  );
}

export function PollsCard({ spaceId }: { spaceId?: string }) {
  const sx = useSx();
  const navigation = useNavigation();
  const {
    resources: polls,
    error,
    getMore,
    fetching,
    hasMore,
  } = getLatestResources(3, PAGES_RESOURCE_KEY);
  // if (polls?.length === 0) return null;
  return (
    <MotiView style={sx(wrapperStyles)}>
      <ResourceCardHeader
        icon="polls"
        title="Polls"
        onViewAllPress={() => {
          navigation.navigate("polls", { spaceId });
        }}
      />
      <View sx={{ overflow: "hidden", borderRadius: "sm" }}>
        {polls?.map((resource, index) => {
          return (
            <Pressable
              key={resource.id}
              sx={itemStyles(index)}
              //TODO: Fix specific Poll linking?
              onPress={() => {
                navigation.navigate("polls", {
                  appQuery: { selectedId: resource.id },
                });
              }}
              key={resource.id}
            >
              <View sx={{ maxWidth: "90%" }}>
                <Text numberOfLines={1} sx={{ fontWeight: "medium" }}>
                  {resource.data.title ?? "Untitled Poll"}
                </Text>
                <Text sx={{ color: "$coolGray.500" }}>
                  {formatLongRelativeDate(new Date(resource.created_at))}{" "}
                  {format(new Date(resource.created_at), "p")}
                </Text>
              </View>
              <Icon
                name="chevron-forward-outline"
                size={20}
                sx={{ color: "$coolGray.500" }}
              />
            </Pressable>
          );
        })}
      </View>
      <EmptyOrLoadingState
        message={"No polls to display"}
        loading={fetching}
        isEmpty={polls?.length === 0}
      />
    </MotiView>
  );
}

export function ChatCard({ spaceId }: { spaceId?: string }) {
  const sx = useSx();
  const navigation = useNavigation();
  const [{ data: chats, fetching }] = useChatList();
  return (
    <MotiView style={sx({ ...wrapperStyles })}>
      <ResourceCardHeader
        icon="Chat"
        title="Conversations"
        onViewAllPress={() => {
          navigation.navigate("Chat", { screen: "chat-list" });
        }}
      />
      <View
        sx={{
          borderRadius: "md",
          // borderWidth: "hairline",
          // borderColor: "$coolGray.300",
          // backgroundColor: "$coolGray.50",
        }}
      >
        {(chats?.chat_list ?? []).slice(0, 3).map((chat, index) => {
          return (
            <SpacesFeedItem
              key={chat.id}
              space={chat}
              sx={{
                // marginBottom: "$1",
                marginX: 0,
                marginTop: index > 0 ? "$1" : 0,
                paddingLeft: 0,
                // boxShadow: "sm",
              }}
              onPress={() => {
                navigation.navigate("Chat", {
                  screen: "conversation",
                  params: { spaceId: chat.id },
                });
              }}
            />
          );
        })}
      </View>
      <EmptyOrLoadingState
        message={"No conversations to display"}
        loading={fetching}
        isEmpty={chats?.chat_list?.length === 0}
      />
    </MotiView>
  );
}
