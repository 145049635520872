import { NativeStackScreenProps } from "@react-navigation/native-stack";
import { RootStackParamList } from "../types";
import React from "react";
import AuthMessage from "../components/AuthMessage";
export default function EmailVerificationMessageScreen({
  route,
}: NativeStackScreenProps<RootStackParamList, "EmailVerificationMessage">) {
  return (
    <AuthMessage
      title="Welcome to Aspen!"
      message={"To verify your account, please enter the code sent to"}
      email={route.params?.email}
      page="SIGN_UP"
    />
  );
}
