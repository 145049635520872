import { v4 as uuidv4 } from "uuid";
import { useCallback, useEffect, useRef, useState } from "react";
import { useRecoilState } from "recoil";
import { AttachmentState } from "../screens/spaces/ChatScreen";
import { attachmentPickerStateBySpace } from "../state/atoms/space_attachments";
import { uploadAttachments } from "../utils/image-uploads";
import { readUploadQueueKeys } from "../lib/upload-queue";

export default function useChatAttachments(spaceId: string) {
  // Set of pending attachments for a space
  const [stagedAttachments, setStagedAttachments] = useRecoilState(
    attachmentPickerStateBySpace(spaceId)
  );
  const [isQueueingAttachments, setIsQueueingAttachments] = useState(false);

  const queueAttachments = useCallback(
    async (attachments: AttachmentState[]) => {
      try {
        setIsQueueingAttachments(true);
        // Upload only attachments we have not attempted to upload
        // TODO: ensure we don't re-upload if you go back to the image picker
        const uploadQueue = readUploadQueueKeys();
        const uploadAttempted = new Set<string>(uploadQueue);
        const newAttachments = attachments.filter(
          (attachment) => !uploadAttempted.has(attachment.id)
        );
        uploadAttachments(newAttachments);
      } finally {
        setIsQueueingAttachments(false);
      }
    },
    []
  );

  const stageAttachments = useCallback((attachments: AttachmentState[]) => {
    setStagedAttachments(attachments);
  }, []);

  const resetAttachments = useCallback(() => {
    setStagedAttachments([]);
  }, []);

  const unstageAttachment = useCallback((attachmentId: string) => {
    setStagedAttachments((prev) =>
      prev.filter((attachment) => attachment.id !== attachmentId)
    );
  }, []);

  return {
    stagedAttachments,
    stageAttachments,
    unstageAttachment,
    resetAttachments,
    isQueueingAttachments,
    queueAttachments,
  };
}
