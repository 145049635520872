import { useCallback } from "react";
import { useMutation } from "urql";
import { AddPermissionsDocument } from "../state/generated/graphql";

export default function useShareResource() {
  const [_addPermissionsResp, addPermissions] = useMutation(
    AddPermissionsDocument
  );

  const shareResource = useCallback(
    (resourceId: string, resourceKey: string, spaceIds: string[]) => {
      const permissions = spaceIds.map((sid) => ({
        resource_id: resourceId,
        resource_key: resourceKey,
        space_id: sid,
      }));
      return addPermissions({
        permissions,
      });
    },
    [addPermissions]
  );

  return shareResource;
}
